import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { errorToast, successToast } from "../../../../common/toast/toast";
import { errorToast, successToast } from "../../../../common/toast/toast";
import { createInquiry, fetchInquiry, fetchInquiryId } from "./inquiryApi";
import { Inquiry, InquiryState } from "./inquiryType";

const initialState: InquiryState = {
  inquiryData: [],
  //   inquiryDataById: {},
  loading: false,
  fetchLoading: false,
  error: null,
};

export const fetchInquiryAsync = createAsyncThunk(
  "inquiry/fetchInquiry",
  async (
    { schoolId, academicYearId }: { schoolId: number; academicYearId: number },
    thunkAPI
  ) => {
    try {
      return await fetchInquiry(schoolId, academicYearId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch Inquiry"
      );
    }
  }
);

export const fetchInquiryIdAsync = createAsyncThunk(
  "inquiry/fetchInquiryId",
  async ({ inquiryId }: { inquiryId: number }, thunkAPI) => {
    try {
      return await fetchInquiryId(inquiryId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch Inquiry"
      );
    }
  }
);

export const createInquiryAsync = createAsyncThunk(
  "inquiry/createInquiry",
  async (
    {
      schoolId,
      academicYearId,
      newInquiry,
    }: {
      schoolId: number;
      academicYearId: number;
      newInquiry: Inquiry;
    },
    thunkAPI
  ) => {
    try {
      return await createInquiry(schoolId, academicYearId, newInquiry);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Inquiry"
      );
    }
  }
);

const inquirySlice = createSlice({
  name: "inquiry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInquiryAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchInquiryAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.inquiryData = action.payload?.data;
      })
      .addCase(fetchInquiryAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = (action.payload as string) || "Failed to fetch Inquiry";
        errorToast(state.error);
      })
      .addCase(fetchInquiryIdAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchInquiryIdAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.inquiryData = action.payload?.data;
      })
      .addCase(fetchInquiryIdAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = (action.payload as string) || "Failed to fetch Inquiry";
        errorToast(state.error);
      })
      .addCase(createInquiryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createInquiryAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Failed to create Inquiry";
        errorToast(state.error);
        // errorToast("Failed to create Inquiry");
      })
      .addCase(createInquiryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.inquiryData.push(action.payload.data);
        successToast(action.payload.message);
      });
  },
});

export default inquirySlice.reducer;
