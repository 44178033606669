import { useEffect, useState } from "react";
import {
  ErrorMessage,
  Field,
  FieldArray,
  useFormikContext,
  FormikErrors,
  FormikTouched,
} from "formik";
import Preloader from "../../../../../core/common/loader/preLoader";
import { Accordion } from "react-bootstrap";
import CommonSelect from "../../../../../core/common/commonSelect";
import PhoneInput from "react-phone-number-input";
import CollapsibleCard from "../../../../../core/common/CollapsibleCard";
import { fetchParentData } from "../../../../../core/data/redux/features/admission/admissionApi";
import { commonPhoneSchema } from "../schema";
interface SectionProps {
  fetchLoading: boolean;
  hasErrors: boolean;
}

interface ParentDataProps extends SectionProps {
  values: any;
  yesOrNo: any[];
  schoolId: number | undefined;
  genderDataOption: any[];
  parentTypeDataOptions: any[];
  qualificationsOptions: any[];
  isEdit: boolean;
}

const ParentDataSection: React.FC<ParentDataProps> = ({
  fetchLoading,
  values,
  yesOrNo,
  schoolId,
  genderDataOption,
  parentTypeDataOptions,
  qualificationsOptions,
  hasErrors,
  isEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const { setFieldValue, setFieldTouched, errors, touched, handleChange } =
    useFormikContext<any>();

  useEffect(() => {
    if (values.parentData) {
      values.parentData.forEach((parent: any, index: number) => {
        const parentType = parent?.parentTypeId;
        if (parentType === 1) {
          setFieldValue(`parentData.${index}.genderId`, 1);
        } else if (parentType === 2) {
          setFieldValue(`parentData.${index}.genderId`, 2);
        }
      });
    }
  }, [values.parentData, setFieldValue]);

  const handleParentDataChange = (index: number, data: any) => {
    setFieldValue(`parentData.${index}.firstName`, data?.firstName || "");
    setFieldValue(`parentData.${index}.middleName`, data?.middleName || "");
    setFieldValue(`parentData.${index}.lastName`, data?.lastName || "");
    setFieldValue(`parentData.${index}.email`, data?.email || "");
    setFieldValue(
      `parentData.${index}.qualification`,
      data?.qualification || ""
    );

    setFieldValue(
      `parentData.${index}.currentAddress`,
      data?.currentAddress || ""
    );
    setFieldValue(
      `parentData.${index}.relationshipWithStudent`,
      data?.relationshipWithStudent || ""
    );
    setFieldValue(`parentData.${index}.businessName`, data?.businessName || "");
    setFieldValue(
      `parentData.${index}.businessAddress`,
      data?.businessAddress || ""
    );
  };

  return (
    <CollapsibleCard
      title={`Parent Data`}
      icon={<i className="ti ti-user fs-16" />}
      initiallyExpanded
      hasErrors={hasErrors}
    >
      {fetchLoading ? (
        <Preloader />
      ) : (
        <FieldArray name="parentData">
          {(arrayHelpers) => (
            <Accordion defaultActiveKey="0">
              {values.parentData &&
                values.parentData.map((parent: any, index: number) => (
                  <Accordion.Item
                    key={index}
                    eventKey={index.toString()}
                    className="row shadow-md add-border mb-2 p-0"
                  >
                    <div className="row d-flex justify-content-between align-items-center p-2">
                      <Accordion.Header className="col">
                        <h6 className="fw-bold">
                          Parent {index + 1} ( {parent.firstName}{" "}
                          {parent.lastName} -{" "}
                          {
                            parentTypeDataOptions.find(
                              (type) => type.value === parent.parentTypeId
                            )?.label
                          }
                          )
                        </h6>
                      </Accordion.Header>
                    </div>
                    <Accordion.Body>
                      <div className="row">
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Phone Number</label>
                            <PhoneInput
                              placeholder="Enter phone number"
                              className="form-control"
                              value={parent.phoneNumber}
                              name={`parentData.${index}.phoneNumber`}
                              onChange={async (value) => {
                                setFieldValue(
                                  `parentData.${index}.phoneNumber`,
                                  value
                                );

                                if (
                                  value &&
                                  values.parentData[index].parentTypeId
                                ) {
                                  try {
                                    const phoneNumberSchema =
                                      commonPhoneSchema(true);
                                    const result =
                                      await phoneNumberSchema.validate(value);
                                    if (result && schoolId) {
                                      setLoading(true);
                                      fetchParentData(
                                        schoolId,
                                        values.parentData[index].parentTypeId,
                                        result
                                      )
                                        .then((response) => {
                                          if (response.data) {
                                            handleParentDataChange(
                                              index,
                                              response.data
                                            );
                                          } else {
                                            handleParentDataChange(index, {});
                                          }
                                        })
                                        .finally(() => {
                                          setLoading(false);
                                        });
                                    }
                                  } catch (error) {
                                    console.log("error", error);
                                  }
                                }
                              }}
                              onBlur={() =>
                                setFieldTouched(
                                  `parentData.${index}.phoneNumber`,
                                  true
                                )
                              }
                            />
                            <ErrorMessage
                              name={`parentData.${index}.phoneNumber`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Parent Type</label>
                            <Field
                              name={`parentData.${index}.parentTypeId`}
                              component={CommonSelect}
                              options={parentTypeDataOptions}
                              onValueSelect={async (value: any) => {
                                try {
                                  const parentNumber =
                                    values.parentData?.[index]?.phoneNumber;
                                  const phoneNumberError = (
                                    errors.parentData as FormikErrors<any>[]
                                  )?.[index]?.phoneNumber;
                                  const phoneNumberSchema =
                                    commonPhoneSchema(true);
                                  const result =
                                    await phoneNumberSchema.validate(
                                      values.parentData?.[index]?.phoneNumber
                                    );

                                  if (
                                    parentNumber &&
                                    !phoneNumberError &&
                                    value &&
                                    schoolId &&
                                    result
                                  ) {
                                    setLoading(true);
                                    fetchParentData(
                                      schoolId,
                                      value,
                                      parentNumber
                                    ).then((response) => {
                                      console.log("response", response.data);
                                      if (response.data) {
                                        handleParentDataChange(
                                          index,
                                          response.data
                                        );
                                      } else {
                                        handleParentDataChange(index, {});
                                      }
                                      setLoading(false);
                                    });
                                  }
                                } catch (error) {
                                  console.log("error", error);
                                }
                              }}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.parentTypeId`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        {values?.parentData?.[index]?.parentTypeId === 3 && (
                          <div className="col-lg-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Relationship With Student
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                name={`parentData.${index}.relationshipWithStudent`}
                              />
                              <ErrorMessage
                                name={`parentData.${index}.relationshipWithStudent`}
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Gender</label>
                            <Field
                              name={`parentData.${index}.genderId`}
                              component={CommonSelect}
                              options={genderDataOption}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.genderId`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">First Name</label>
                            <Field
                              type="text"
                              className="form-control"
                              name={`parentData.${index}.firstName`}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.firstName`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Middle Name</label>
                            <Field
                              type="text"
                              className="form-control"
                              name={`parentData.${index}.middleName`}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.middleName`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Last Name</label>
                            <Field
                              type="text"
                              className="form-control"
                              name={`parentData.${index}.lastName`}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.lastName`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <Field
                              type="text"
                              className="form-control"
                              name={`parentData.${index}.email`}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.email`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Current Address
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name={`parentData.${index}.currentAddress`}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.currentAddress`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Qualification</label>
                            <Field
                              name={`parentData.${index}.qualification`}
                              component={CommonSelect}
                              options={qualificationsOptions}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.qualification`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">Business Name</label>
                            <Field
                              type="text"
                              className="form-control"
                              name={`parentData.${index}.businessName`}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.businessName`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Business Address
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name={`parentData.${index}.businessAddress`}
                            />
                            <ErrorMessage
                              name={`parentData.${index}.businessAddress`}
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        {loading && (
                          <div className="col-12 text-center">
                            <Preloader />
                          </div>
                        )}
                        <div className="text-end">
                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            className="btn btn-sm btn-danger"
                          >
                            <i className="ti ti-trash me-2" />
                            Remove
                          </button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              <div className="mt-2 mb-3">
                <button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      firstName: "",
                      middleName: "",
                      lastName: "",
                      email: "",
                      phoneNumber: "",
                      genderId: 1,
                      relationshipWithStudent: "",
                      currentAddress: "",
                      qualification: "",
                      businessName: "",
                      businessAddress: "",
                    })
                  }
                  className="btn btn-sm btn-primary"
                >
                  <i className="ti ti-plus me-2" />
                  Add Parent
                </button>
              </div>
            </Accordion>
          )}
        </FieldArray>
      )}
    </CollapsibleCard>
  );
};

export default ParentDataSection;
