// Other static options
export const yesOrNo = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
export const bloodGroups = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
];
export const castCategories = [
  { value: "GENERAL", label: "GENERAL" },
  { value: "OBC", label: "OBC" },
  { value: "SC", label: "SC" },
  { value: "ST", label: "ST" },
];
export const religionCategories = [
  { value: "Hindu", label: "Hindu" },
  { value: "Muslim", label: "Muslim" },
  { value: "Christian", label: "Christian" },
  { value: "Sikh", label: "Sikh" },
  { value: "Buddhist", label: "Buddhist" },
  { value: "Jain", label: "Jain" },
  { value: "Parsi", label: "Parsi" },
  { value: "Jewish", label: "Jewish" },
  { value: "Other", label: "Other" },
];
export const qualificationsOptions = [
  { value: "Undergraduate", label: "Undergraduate" },
  { value: "Graduate", label: "Graduate" },
  { value: "Postgraduate", label: "Postgraduate" },
  { value: "PHD", label: "PHD" },
  { value: "Diploma", label: "Diploma" },
  { value: "Certificate", label: "Certificate" },
];
// Relationship options (used in sibling section inside its inline definition)
export const relationshipOptions = [
  { value: "Brother", label: "Brother" },
  { value: "Sister", label: "Sister" },
];
