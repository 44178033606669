import { ErrorMessage, Field } from "formik";
import Preloader from "../../../../../core/common/loader/preLoader";
import CollapsibleCard from "../../../../../core/common/CollapsibleCard";

interface SectionProps {
  fetchLoading: boolean;
}

interface AddressSectionProps extends SectionProps {
  hasErrors: boolean;
}

const AddressSection: React.FC<AddressSectionProps> = ({
  fetchLoading,
  hasErrors,
}) => (
  <CollapsibleCard
    title="Address"
    icon={<i className="ti ti-map fs-16" />}
    initiallyExpanded
    hasErrors={hasErrors}
  >
    {fetchLoading ? (
      <Preloader />
    ) : (
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Current Address</label>
            <Field type="text" className="form-control" name="currentAddress" />
            <ErrorMessage
              name="currentAddress"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Permanent Address</label>
            <Field
              type="text"
              className="form-control"
              name="permanentAddress"
            />
            <ErrorMessage
              name="permanentAddress"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
      </div>
    )}
  </CollapsibleCard>
);

export default AddressSection;
