import React, { createContext, useState, useContext } from "react";

interface CollapsibleCardContextType {
  expandedCardId: string | null;
  setExpandedCardId: (id: string | null) => void;
}

const CollapsibleCardContext = createContext<
  CollapsibleCardContextType | undefined
>(undefined);

export const CollapsibleCardProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [expandedCardId, setExpandedCardId] = useState<string | null>(
    "Personal Information"
  );

  return (
    <CollapsibleCardContext.Provider
      value={{ expandedCardId, setExpandedCardId }}
    >
      {children}
    </CollapsibleCardContext.Provider>
  );
};

export const useCollapsibleCard = () => {
  const context = useContext(CollapsibleCardContext);
  if (context === undefined) {
    throw new Error(
      "useCollapsibleCard must be used within a CollapsibleCardProvider"
    );
  }
  return context;
};
