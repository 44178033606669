import apiClient from "../../../api/apiClient";
import { GrneratePublicLink, SchoolUsers } from "./schoolAdminTypes";

//Base URL

const BASE_PATH = "/school-admin";

export const fetchAllSchoolUser = async (
  schoolId: number,
  userRoleId: number
): Promise<{ data: SchoolUsers[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/school-users/${schoolId}/${userRoleId}`
  );
  return response?.data;
};

export const createSchoolUser = async (
  schoolId: number,
  userRoleId: number,
  newSchoolUser: SchoolUsers
): Promise<{ data: SchoolUsers; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/school-users/${schoolId}/${userRoleId}`,
    newSchoolUser
  );
  return response?.data;
};

export const updateSchoolUser = async (
  schoolId: number,
  userRoleId: number,
  id: number,
  updatedSchoolUser: SchoolUsers
): Promise<{ data: SchoolUsers; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/school-users/${schoolId}/${userRoleId}/${id}`,
    updatedSchoolUser
  );
  return response?.data;
};

export const deleteSchoolUser = async (
  schoolId: number,
  userRoleId: number,
  id: number
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/school-users/${schoolId}/${userRoleId}/${id}`
  );
};

export const sendAdmissonForm = async (
  schoolId: number,
  generatedData: GrneratePublicLink
): Promise<{ data: GrneratePublicLink; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/send-admission-link/${schoolId}`,
    generatedData
  );
  return response?.data;
};
