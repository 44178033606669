import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import { accounts_transactions_data } from "../../core/data/json/accounts_transactions_data";
import dayjs from "dayjs";
import Table from "../../core/common/dataTable/index";
import { TableData } from "../../core/data/interface";
import PredefinedDateRanges from "../../core/common/datePicker";
import CommonSelect from "../../core/common/commonSelect";
import {
  transactionDate,
  transactionId,
  transactionType,
} from "../../core/common/selectoption/selectoption";
import { all_routes } from "../router/all_routes";
import TooltipOption from "../../core/common/tooltipOption";
import { useAppDispatch, useAppSelector } from "../../core/data/redux/hooks";
import { fetchStudentFeePaymentsAsync } from "../../core/data/redux/features/fee/feeSlice";

const AccountsTransactions = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );
  const { studentFeePayments, fetchLoading } = useAppSelector(
    (state) => state.fee
  );

  useEffect(() => {
    if (schoolId && globalAcademicYearId) {
      dispatch(
        fetchStudentFeePaymentsAsync({
          schoolId,
          academicYearId: globalAcademicYearId,
        })
      );
    }
  }, [schoolId, globalAcademicYearId]);

  const columns = [
    {
      title: "Receipt Number",
      dataIndex: "receiptNumber",
      render: (receiptNumber: string, record: any) => {
        return (
          <div className="">
            <Link
              to={record.receiptUrl}
              target="_blank"
              style={{color: "blue"}}
            >
              {receiptNumber}
            </Link>
          </div>
        );
      },
      sorter: (a: TableData, b: TableData) =>
        a.receiptNumber.localeCompare(b.receiptNumber),
    },
    {
      title: "Student Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a: TableData, b: TableData) =>
        a.firstName.length - b.firstName.length,
    },
    {
      title: "Class",
      dataIndex: "className",
      key: "className",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (date: string) => dayjs(date).format("DD MMM YYYY"),
      sorter: (a: TableData, b: TableData) =>
        dayjs(a.paymentDate).unix() - dayjs(b.paymentDate).unix(),
    },
    {
      title: "Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      sorter: (a: TableData, b: TableData) =>
        a.paymentAmount.length - b.paymentAmount.length,
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      sorter: (a: TableData, b: TableData) =>
        a.paymentMode.length - b.paymentMode.length,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   sorter: (a: TableData, b: TableData) => a.status.length - b.status.length,
    //   render: (status: any) => (
    //     <>
    //       <span
    //         className={`badge d-inline-flex align-items-center badge-soft-success
    //     ${
    //       status === "Completed"
    //         ? "badge-soft-success"
    //         : status === "Pending"
    //         ? "badge-soft-warning"
    //         : ""
    //     }`}
    //       >
    //         <i className="ti ti-circle-filled fs-5 me-1" />
    //         {status}
    //       </span>
    //     </>
    //   ),
    // },
  ];

  return (
    <div>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Transactions</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Finance &amp; Accounts</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Transactions
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />
            </div> */}
          </div>
          {/* /Page Header */}
          {/* Filter Section */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Transactions List</h4>
              <div className="d-flex align-items-center flex-wrap">
                {/* <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div> */}
                {/* <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div className="dropdown-menu drop-width">
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 pb-0 border-bottom">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Transaction ID
                              </label>
                              <CommonSelect
                                className="select"
                                options={transactionId}
                                defaultValue={transactionId[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Transaction Type
                              </label>
                              <CommonSelect
                                className="select"
                                options={transactionType}
                                defaultValue={transactionType[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Transaction Date
                              </label>
                              <CommonSelect
                                className="select"
                                options={transactionDate}
                                defaultValue={transactionDate[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          Apply
                        </button>
                      </div>
                    </form>
                  </div>
                </div> */}
                {/* <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Transaction List */}
              <Table
                dataSource={studentFeePayments}
                columns={columns}
                loading={fetchLoading}
                // Selection={true}
              />
              {/* /Transaction List */}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </div>
  );
};

export default AccountsTransactions;
