import * as Yup from "yup";
const commonPhoneSchema = (isMandatory: Boolean, message?: string) =>
  Yup.string()
    .nullable()
    .when([], {
      is: () => isMandatory,
      then: (schema) =>
        schema
          .required(message || "Phone Number is required")
          .phone("*" as any, "Please enter a valid Phone Number"),
      otherwise: (schema) =>
        schema.test({
          name: "phoneValidation",
          skipAbsent: true,
          test(value, ctx) {
            if (!value) return true;
            if (!value || !value.includes(" ")) return true;
            const spaceIndex = value.indexOf(" ");
            let splitValue = value.slice(spaceIndex + 1);
            if (!splitValue) return true;

            const isValid = schema
              .phone("*" as any, "Please enter a valid Phone Number")
              .isValidSync(value);

            if (!isValid) {
              return ctx.createError({
                message: "Please enter a valid Phone Number",
              });
            }

            return true;
          },
        }),
    });

const studentSchema = Yup.object().shape({
  userUniqueNumber: Yup.string().required("User unique number is required"),
  academicYearName: Yup.string().required("Academic year name is required"),
  className: Yup.string().required("Class name is required"),
  classSectionName: Yup.string().required("Class section name is required"),
  firstName: Yup.string().required("First name is required"),
  middleName: Yup.string().nullable(),
  lastName: Yup.string().required("Last name is required"),
  dob: Yup.date().required("Date of birth is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  phoneNumber: commonPhoneSchema(true, "Phone number is required"),
  genderName: Yup.string().required("Gender is required"),
  interviewDate: Yup.date().required("Interview date is required"),
  admissionStatusName: Yup.string()
    .oneOf(["Approved"], "Invalid admission status")
    .required("Admission status is required"),
  approvalDate: Yup.date().required("Approval date is required"),
  admissionDate: Yup.date().required("Admission date is required"),
  aadharCardNumber: Yup.string().required("Aadhar card number is required"),
  studentBankAccountNumber: Yup.string().nullable(),
  studentBankAccountIFSCCode: Yup.string().nullable(),
  studentBankBranchName: Yup.string().nullable(),
  studentBankAddress: Yup.string().nullable(),
  bloodGroup: Yup.string().required("Blood group is required"),
  cast: Yup.string().required("Cast is required"),
  castCertificate: Yup.string().nullable(),
  isConveyanceNeeded: Yup.string()
    .oneOf(["Yes", "No"], "Invalid conveyance option")
    .required("Conveyance needed is required"),
  lastSchoolName: Yup.string().nullable(),
  isSpecialMedicalNeed: Yup.string()
    .oneOf(["Yes", "No"], "Invalid medical need option")
    .required("Special medical need is required"),
  specialMedicalNeedDetails: Yup.string().when(
    "isSpecialMedicalNeed",
    ([value]) =>
      value === "Yes"
        ? Yup.string().required("Special medical need details are required")
        : Yup.string().nullable()
  ),
  declaration: Yup.string().nullable(),
  declarationSignature: Yup.string().nullable(),
  monthlyIncomeCurrencyName: Yup.string().required(
    "Monthly income currency is required"
  ),
  monthlyIncome: Yup.number().when("monthlyIncomeCurrencyName", ([value]) =>
    value
      ? Yup.number().required("Monthly income is required").min(0)
      : Yup.number().nullable()
  ),
  incomeTaxPayer: Yup.string()
    .oneOf(["Yes", "No"], "Invalid tax payer option")
    .required("Income tax payer is required"),
  PANNumber: Yup.string().when("incomeTaxPayer", ([value]) =>
    value === "Yes"
      ? Yup.string()
          .length(10, "PAN number must be 10 characters")
          .required("PAN number is required")
      : Yup.string().nullable()
  ),
  religion: Yup.string().nullable(),
  currentAddress: Yup.string().nullable(),
  permanentAddress: Yup.string().nullable(),
  PENNumber: Yup.string().nullable(),
  parentData: Yup.array()
    .of(
      Yup.object().shape({
        userUniqueNumber: Yup.string().required(
          "User unique number is required"
        ),
        firstName: Yup.string().required("First name is required"),
        middleName: Yup.string().nullable(),
        lastName: Yup.string().required("Last name is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        phoneNumber: commonPhoneSchema(true, "Phone number is required"),
        genderName: Yup.string()
          .oneOf(["Male", "Female", "Other"], "Invalid gender")
          .required("Gender is required"),
        parentTypeName: Yup.string()
          .oneOf(["Father", "Mother", "Guardian"], "Invalid parent type")
          .required("Parent type is required"),
        relationshipWithStudent: Yup.string().when(
          "parentTypeName",
          ([value]) =>
            value === "Guardian"
              ? Yup.string().required("Relationship with student is required")
              : Yup.string().nullable()
        ),
        currentAddress: Yup.string().nullable(),
        qualification: Yup.string().nullable(),
        businessName: Yup.string().nullable(),
        businessAddress: Yup.string().nullable(),
      })
    )
    .min(1, "At least one parent is required")
    .required("Parent data is required"),
  appliedDiscount: Yup.object().shape({
    feeCategories: Yup.array().of(
      Yup.object().shape({
        feeCategoryName: Yup.string().required("Fee category name is required"),
        allowedDiscount: Yup.boolean().required("Allowed discount is required"),
      })
    ),
    discountTypes: Yup.array()
      .of(
        Yup.object().shape({
          discountTypeName: Yup.string().required(
            "Discount type name is required"
          ),
          discountPercentage: Yup.number().required(
            "Discount percentage is required"
          ),
          allowedDiscount: Yup.boolean().required(
            "Allowed discount is required"
          ),
        })
      )
      .test(
        "discount-required",
        "Discount details are required when at least one fee category allows a discount.",
        function (value) {
          const { feeCategories } = this.parent;
          const hasDiscountAllowed = feeCategories?.some(
            (category: { allowedDiscount: boolean }) => category.allowedDiscount
          );
          return !hasDiscountAllowed || (value && value.length > 0);
        }
      )
      .default([]),
  }),
  notifyStudentAndParent: Yup.string()
    .oneOf(["Yes", "No"], "Invalid notify option")
    .required("Notify student and parent is required"),
  oldSystemIdentityNumber: Yup.string().required(
    "Old system identity number is required"
  ),
});

const studentArraySchema = Yup.array()
  .of(studentSchema)
  .min(1, "Student data is required")
  .required("Student data is required");

export { studentArraySchema };
