import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { all_routes } from "../../../router/all_routes";
import StudentModals from "../../admissions/studentModals";
import StudentSidebar from "./studentSidebar";
import StudentBreadcrumb from "./studentBreadcrumb";
import { StudentDetailsNavbar } from "../common/studentDetailsNavbar";
import StudentPersonal from "./studentPersonal";
import StudentFees from "./studentFees";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import {
  fetchStudentByClassSectionAsync,
  resetStudentData,
} from "../../../../core/data/redux/features/admission/admissionSlice";
import FeeCollect from "../common/feeCollect";
import LoginDetails from "../common/loginDetails";
import { useActionPermission } from "../../../router/useActionPermission";
import { ACTIONS } from "../../../../core/data/constants";

const StudentDetails = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const { canPerform } = useActionPermission();
  const { fetchLoading, studentData } = useAppSelector(
    (state) => state.admission
  );
  const { studentClassSectionId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  let student = searchParams.get("student") as string;
  const [refresh, setRefresh] = useState(false);
  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );

  useEffect(() => {
    if (studentClassSectionId && globalAcademicYearId) {
      dispatch(
        fetchStudentByClassSectionAsync({
          studentClassSectionId: +studentClassSectionId,
          academicYearId: globalAcademicYearId,
        })
      );
    }

    return () => {
      dispatch(resetStudentData());
    };
  }, [
    studentClassSectionId,
    dispatch,
    globalAcademicYearId,
    fetchStudentByClassSectionAsync,
    refresh,
  ]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row g-3">
            {/* Page Header */}
            <StudentBreadcrumb id={studentData?.studentId} />
            {/* /Page Header */}
          </div>

          <div className="row g-3">
            {/* Student Information */}
            <StudentSidebar
              studentData={studentData}
              loading={fetchLoading}
              setRefresh={setRefresh}
            />
            {/* /Student Information */}
            <div className="col-xxl-9 col-xl-8">
              <div className="row g-3">
                <div className="col-12">
                  {/* List */}
                  <StudentDetailsNavbar student={student} />
                  {/* /List */}
                </div>
              </div>
              {student === "personal" ? (
                <StudentPersonal
                  studentData={studentData}
                  loading={fetchLoading}
                  setRefresh={setRefresh}
                />
              ) : canPerform(ACTIONS.STUDENT_DETAILS_FEE) &&
                student === "fees" ? (
                <StudentFees studentData={studentData} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <FeeCollect studentData={studentData} />
      <LoginDetails />
    </>
  );
};

export default StudentDetails;
