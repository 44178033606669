import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";

import Table from "../../../../core/common/dataTable/index";
import { TableData } from "../../../../core/data/interface";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import { fetchStudentFeeRecordsAsync } from "../../../../core/data/redux/features/fee/feeSlice";
import StudentFeeFormModal from "./studentFeeFormModal";
import DeleteConfirmationModal from "../../../../core/common/delete-confirmation-modal";
import { useActionPermission } from "../../../router/useActionPermission";
import { ACTIONS } from "../../../../core/data/constants";

const StudentFees = ({ studentData }: { studentData?: any }) => {
  const { studentClassSectionId } = useParams();
  const { canPerform } = useActionPermission();
  const dispatch = useAppDispatch();
  const [selectedStudentFee, setSelectedStudentFee] =
    useState<TableData | null>(null);
  const schoolId = useAppSelector((state) => state.auth?.user?.schoolId);
  const { studentFeeRecords, fetchLoading } = useAppSelector(
    (state) => state.fee
  );
  const routes = all_routes;

  useEffect(() => {
    if (schoolId && studentData?.studentClassSectionId) {
      dispatch(
        fetchStudentFeeRecordsAsync({
          studentId: studentData?.studentId,
          studentClassSectionId: studentData?.studentClassSectionId,
        })
      );
    }
  }, [studentData?.studentClassSectionId, schoolId]);

  const columns = [
    {
      title: "Academic Year",
      dataIndex: "academicYearName",
      sorter: (a: TableData, b: TableData) =>
        a.academicYearName.length - b.academicYearName.length,
    },
    {
      title: "Student Fee Name",
      dataIndex: "studentFeeName",
      sorter: (a: TableData, b: TableData) =>
        a.studentFeeName.length - b.studentFeeName.length,
    },
    {
      title: "Fee Amount",
      dataIndex: "feeAmount",
      sorter: (a: TableData, b: TableData) => a.feeAmount - b.feeAmount,
    },
    {
      title: "Total Discount",
      dataIndex: "totalDiscountAmount",
      sorter: (a: TableData, b: TableData) =>
        a.totalDiscountAmount - b.totalDiscountAmount,
    },
    // {
    //   title: "Discount Type",
    //   dataIndex: "feeDiscountName",
    //   sorter: (a: TableData, b: TableData) =>
    //     (a.feeDiscountName?.length || 0) - (b.feeDiscountName.length || 0),
    // },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      sorter: (a: TableData, b: TableData) => a.netAmount - b.netAmount,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      sorter: (a: TableData, b: TableData) =>
        dayjs(a.dueDate).unix() - dayjs(b.dueDate).unix(),
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatusName",
      render: (text: string) => (
        <>
          {text === "PAID" ? (
            <span className="badge badge-soft-success d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : text === "PARTIALLY PAID" ? (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          ) : (
            <span className="badge badge-soft-danger d-inline-flex align-items-center">
              <i className="ti ti-circle-filled fs-5 me-1"></i>
              {text}
            </span>
          )}
        </>
      ),
      sorter: (a: TableData, b: TableData) =>
        a.paymentStatusName.length - b.paymentStatusName.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => {
        return record.paymentStatusName === "PAID" ? null : (
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                {canPerform(ACTIONS.EDIT_STUDENT_FEE) ? (
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      data-bs-toggle="modal"
                      data-bs-target="#add_student_fee"
                      onClick={() => {
                        setSelectedStudentFee(record);
                      }}
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit
                    </div>
                  </li>
                ) : (
                  <p className="text-danger fs-md d-flex align-items-center">
                    <i className="ti ti-lock me-2" /> Edit : No Access
                  </p>
                )}
              </ul>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Fees</h4>
              {/* <div className="d-flex align-items-center flex-wrap">
                <div className="dropdown mb-3 me-2">
                  <Link
                    to=""
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-calendar-due me-2" />
                    Year : 2024 / 2025
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="" className="dropdown-item rounded-1">
                        Year : 2024 / 2025
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="dropdown-item rounded-1">
                        Year : 2023 / 2024
                      </Link>
                    </li>
                    <li>
                      <Link to="" className="dropdown-item rounded-1">
                        Year : 2022 / 2023
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="card-body p-0 py-3">
              {/* Fees List */}
              <div className="custom-datatable-filter table-responsive">
                <Table
                  dataSource={studentFeeRecords}
                  columns={columns}
                  loading={fetchLoading}
                />
              </div>
              {/* /Fees List */}
            </div>
          </div>
        </div>
      </div>
      <StudentFeeFormModal
        selectedStudentFee={selectedStudentFee}
        studentData={studentData}
      />
      {/* <DeleteConfirmationModal
        onConfirm={async () => {
          try {
            if (selectedStudentFee) {
              const result = await dispatch(
                deleteFeeAsync(selectedStudentFee.id)
              ).unwrap();

              if (result) {
                if (schoolId) {
                  let resultData = await dispatch(
                    fetchStudentFeeRecordsAsync({
                      studentId: studentData?.studentId,
                      studentClassSectionId: studentData?.studentClassSectionId,
                    })
                  );
                  if (resultData) {
                    const cancelButton = document.getElementById(
                      "delete-cancel"
                    ) as HTMLElement;
                    cancelButton.click();
                    setSelectedStudentFee(null);
                  }
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }}
      /> */}
      {/* <StudentModals /> */}
    </>
  );
};

export default StudentFees;
