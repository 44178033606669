import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Fee,
  FeeQueryCriteria,
  FeeState,
  FeeUpdate,
  StudentFeeRecordUpdate,
  StudentFeePaymentData,
  FeeDiscountAndCategoryQueryCriteria,
  CollectFeesInitialValue,
} from "./feeType";
import {
  createFee,
  deleteFee,
  fetchFeesByCriteria,
  updateFee,
  manageStudentFee,
  fetchStudentFeeRecords,
  addStudentFeePayment,
  fetchStudentFeePayments,
  updateStudentFeeRecord,
  fetchFeediscountandcategory,
} from "./feeApi";
import { errorToast, successToast } from "../../../../common/toast/toast";

const initialState: FeeState = {
  feesData: [],
  feeDiscountAndCategory: {
    discountTypes: [],
    feeCategories: [],
  },
  studentFeeRecords: [],
  studentFeePayments: [],
  loading: false,
  fetchLoading: false,
  fetchfeeLoading: false,
  fetchModelLoading: false,
  error: null,
};

export const fetchFeesByCriteriaAsync = createAsyncThunk(
  "fee/fetchFeesByCriteria",
  async (
    {
      schoolId,
      criteria,
    }: {
      schoolId: number;
      criteria: FeeQueryCriteria;
    },
    thunkAPI
  ) => {
    try {
      return await fetchFeesByCriteria(schoolId, criteria);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch fees"
      );
    }
  }
);

export const createFeeAsync = createAsyncThunk(
  "fee/createFee",
  async ({ schoolId, newFee }: { schoolId: number; newFee: Fee }, thunkAPI) => {
    try {
      return await createFee(schoolId, newFee);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create fee"
      );
    }
  }
);

export const updateFeeAsync = createAsyncThunk(
  "fee/updateFee",
  async (
    {
      schoolId,
      feeId,
      feeData,
    }: { schoolId: number; feeId: number; feeData: FeeUpdate },
    thunkAPI
  ) => {
    try {
      return await updateFee(schoolId, feeId, feeData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update fee"
      );
    }
  }
);

export const deleteFeeAsync = createAsyncThunk(
  "fee/deleteFee",
  async (
    { schoolId, feeId }: { schoolId: number; feeId: number },
    thunkAPI
  ) => {
    try {
      await deleteFee(schoolId, feeId);
      return feeId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete fee"
      );
    }
  }
);

export const manageStudentFeeAsync = createAsyncThunk(
  "fee/manageStudentFee",
  async (
    {
      schoolId,
      studentFeeData,
    }: {
      schoolId: number;
      studentFeeData: {
        userId: number;
        feesData: Array<{
          feeId: number;
          paymentStatusId: number;
          discountPercentage: number;
          discountTypeId: number;
          discountRemarks: string;
        }>;
      };
    },
    thunkAPI
  ) => {
    try {
      return await manageStudentFee(schoolId, studentFeeData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to manage student fee"
      );
    }
  }
);

export const fetchStudentFeeRecordsAsync = createAsyncThunk(
  "fee/fetchStudentFeeRecords",
  async (
    {
      studentId,
      studentClassSectionId,
    }: { studentId: number; studentClassSectionId: number },
    thunkAPI
  ) => {
    try {
      return await fetchStudentFeeRecords(studentId, studentClassSectionId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch student fee record"
      );
    }
  }
);

export const addStudentFeePaymentAsync = createAsyncThunk(
  "fee/addStudentFeePayment",
  async (
    {
      schoolId,
      studentId,
      paymentData,
    }: {
      schoolId: number;
      studentId: number;
      paymentData: CollectFeesInitialValue;
    },
    thunkAPI
  ) => {
    try {
      return await addStudentFeePayment(schoolId, studentId, paymentData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to add student fee payment"
      );
    }
  }
);

export const fetchStudentFeePaymentsAsync = createAsyncThunk(
  "fee/fetchStudentFeePayments",
  async (
    {
      schoolId,
      userId,
      academicYearId,
    }: { schoolId: number; userId?: number; academicYearId: number },
    thunkAPI
  ) => {
    try {
      return await fetchStudentFeePayments({
        schoolId,
        userId,
        academicYearId,
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch student fee payments"
      );
    }
  }
);

export const updateStudentFeeRecordAsync = createAsyncThunk(
  "fee/updateStudentFeeRecord",
  async (
    {
      studentFeeRecordId,
      updateData,
    }: {
      studentFeeRecordId: number;
      updateData: {
        discountDetails: {
          discountSchoolMappingId: number;
          discountPercentage: number;
        }[];
      };
    },
    thunkAPI
  ) => {
    try {
      return await updateStudentFeeRecord(studentFeeRecordId, updateData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update student fee record"
      );
    }
  }
);

export const fetchFeeDiscountAndCategoryAsync = createAsyncThunk(
  "fee/fetchFeediscountandcategory",
  async (
    {
      schoolId,
      criteria,
    }: {
      schoolId: number;
      criteria: FeeDiscountAndCategoryQueryCriteria;
    },
    thunkAPI
  ) => {
    try {
      return await fetchFeediscountandcategory(schoolId, criteria);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch feediscount and category"
      );
    }
  }
);

const FeeSlice = createSlice({
  name: "fee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeesByCriteriaAsync.pending, (state) => {
        state.fetchfeeLoading = true;
      })
      .addCase(fetchFeesByCriteriaAsync.fulfilled, (state, action) => {
        state.fetchfeeLoading = false;
        state.feesData = action.payload.data;
      })
      .addCase(fetchFeesByCriteriaAsync.rejected, (state, action) => {
        state.fetchfeeLoading = false;
        state.error = (action.payload as string) || "Failed to fetch fees";
        errorToast(state.error);
      })
      .addCase(createFeeAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFeeAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.feesData.push(action.payload.data);
        successToast(action.payload.message);
      })
      .addCase(createFeeAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Failed to create fee";
        errorToast(state.error);
      })
      .addCase(updateFeeAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFeeAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(updateFeeAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Failed to update fee";
        errorToast(state.error);
      })
      .addCase(deleteFeeAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteFeeAsync.fulfilled, (state, action) => {
        state.feesData = state.feesData.filter(
          (feeItem) => feeItem.id !== action.payload
        );
        state.loading = false;
      })
      .addCase(deleteFeeAsync.rejected, (state, action) => {
        state.error = (action.payload as string) || "Failed to delete fee";
        state.loading = false;
        errorToast(state.error);
      })
      .addCase(manageStudentFeeAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(manageStudentFeeAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(manageStudentFeeAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to manage student fee";
        errorToast(state.error);
      })
      .addCase(fetchStudentFeeRecordsAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchStudentFeeRecordsAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.studentFeeRecords = action.payload.data;
      })
      .addCase(fetchStudentFeeRecordsAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch student fee record";
        errorToast(state.error);
      })
      .addCase(addStudentFeePaymentAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStudentFeePaymentAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(addStudentFeePaymentAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to add student fee payment";
        errorToast(state.error);
      })
      .addCase(fetchStudentFeePaymentsAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchStudentFeePaymentsAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.studentFeePayments = action.payload.data;
      })
      .addCase(fetchStudentFeePaymentsAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch student fee payments";
        errorToast(state.error);
      })
      .addCase(updateStudentFeeRecordAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStudentFeeRecordAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(updateStudentFeeRecordAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update student fee record";
        errorToast(state.error);
      })
      .addCase(fetchFeeDiscountAndCategoryAsync.pending, (state) => {
        state.fetchModelLoading = true;
      })
      .addCase(fetchFeeDiscountAndCategoryAsync.fulfilled, (state, action) => {
        state.fetchModelLoading = false;
        state.feeDiscountAndCategory = action.payload.data;
      })
      .addCase(fetchFeeDiscountAndCategoryAsync.rejected, (state, action) => {
        state.fetchModelLoading = false;
        state.error = (action.payload as string) || "Failed to fetch fees";
        errorToast(state.error);
      });
  },
});

export default FeeSlice.reducer;
