import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UtilState } from "./utilsType";
import {
  fetchFeeCategories,
  fetchFeeCategoryFrequencies,
  fetchFeeDiscountTypes,
  fetchPaymentStatuses,
  fetchGenders,
  fetchUserRoles,
  fetchAdmissionStatuses,
  fetchParentTypes,
  fetchCurrencyList,
  uploadMultipleFiles,
  fetchAcademicYearsOfSchool,
  updateFileDataByLink,
} from "./utilsApi";
import { errorToast, successToast } from "../../../../common/toast/toast";

const initialState: UtilState = {
  feeCategoryData: [],
  feeCategoryFrequencyData: [],
  feeDiscountTypeData: [],
  paymentStatusData: [],
  parentTypeData: [],
  genderData: [],
  userRoleData: [],
  admissionStatusData: [],
  currencyData: [],
  fetchLoading: false,
  uploadedFiles: [],
  loading: false,
  error: null,
};

export const fetchAcademicYearsAsync = createAsyncThunk(
  "academicYear/fetchAcademicYears",
  async (schoolId: number) => {
    return await fetchAcademicYearsOfSchool(schoolId);
  }
);

export const uploadMultipleFilesAsync = createAsyncThunk(
  "utils/uploadMultipleFiles",
  async (
    { formData, fileCategory ,studentId}: { formData: FormData; fileCategory: string ,studentId?:number},
    thunkAPI
  ) => {
    try {
      const response = await uploadMultipleFiles(formData, fileCategory,studentId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to upload file"
      );
    }
  }
);

export const updateFileDataByLinkAsync = createAsyncThunk(
  "utils/updateFileDataByLink",
  async (
    { fileurl, fileCategory ,studentClassSectionId}: { fileurl: string; fileCategory: string ,studentClassSectionId?:number},
    thunkAPI
  ) => {
    try {
      const response = await updateFileDataByLink(fileurl, fileCategory,studentClassSectionId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to upload image"
      );
    }
  }
);

export const fetchFeeCategoriesAsync = createAsyncThunk(
  "utils/fetchFeeCategories",
  async (_, thunkAPI) => {
    try {
      return await fetchFeeCategories();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch fee categories"
      );
    }
  }
);

export const fetchFeeCategoryFrequenciesAsync = createAsyncThunk(
  "utils/fetchFeeCategoryFrequencies",
  async (_, thunkAPI) => {
    try {
      return await fetchFeeCategoryFrequencies();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message ||
          "Failed to fetch fee category frequencies"
      );
    }
  }
);

export const fetchFeeDiscountTypesAsync = createAsyncThunk(
  "utils/fetchFeeDiscountTypes",
  async (_, thunkAPI) => {
    try {
      return await fetchFeeDiscountTypes();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch fee discount types"
      );
    }
  }
);

export const fetchPaymentStatusesAsync = createAsyncThunk(
  "utils/fetchPaymentStatuses",
  async (_, thunkAPI) => {
    try {
      return await fetchPaymentStatuses();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch payment statuses"
      );
    }
  }
);

export const fetchGendersAsync = createAsyncThunk(
  "utils/fetchGenders",
  async (_, thunkAPI) => {
    try {
      return await fetchGenders();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch genders"
      );
    }
  }
);

export const fetchUserRolesAsync = createAsyncThunk(
  "utils/fetchUserRoles",
  async (_, thunkAPI) => {
    try {
      return await fetchUserRoles();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch user roles"
      );
    }
  }
);

export const fetchAdmissionStatusesAsync = createAsyncThunk(
  "utils/fetchAdmissionStatuses",
  async (_, thunkAPI) => {
    try {
      return await fetchAdmissionStatuses();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch admission statuses"
      );
    }
  }
);

export const fetchParentTypesAsync = createAsyncThunk(
  "utils/fetchParentTypes",
  async (_, thunkAPI) => {
    try {
      return await fetchParentTypes();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch parent types"
      );
    }
  }
);

export const fetchCurrencyListAsync = createAsyncThunk(
  "utils/fetchCurrencyList",
  async (_, thunkAPI) => {
    try {
      return await fetchCurrencyList();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch currency list"
      );
    }
  }
);

const UtilSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadMultipleFilesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadMultipleFilesAsync.fulfilled, (state, action) => {
        state.loading = false;
        console.log("files", action.payload.uploadedFiles);
        state.uploadedFiles = action.payload.uploadedFiles as string[];
      })
      .addCase(uploadMultipleFilesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update admission";
        errorToast(state.error);
      })

      .addCase(updateFileDataByLinkAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateFileDataByLinkAsync.fulfilled, (state, action) => {
        state.loading = false;
        // console.log("files", action.payload.uploadedFiles);
        // state.uploadedFiles = action.payload.uploadedFiles as string[];
        successToast("Uploaded files successfully");
      })
      .addCase(updateFileDataByLinkAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update admission";
        errorToast(state.error);
      })
      // Fee Categories
      .addCase(fetchFeeCategoriesAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchFeeCategoriesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.feeCategoryData = action.payload.data;
      })
      .addCase(fetchFeeCategoriesAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch fee categories";
        errorToast(state.error);
      })

      // Fee Category Frequencies
      .addCase(fetchFeeCategoryFrequenciesAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchFeeCategoryFrequenciesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.feeCategoryFrequencyData = action.payload.data;
      })
      .addCase(fetchFeeCategoryFrequenciesAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) ||
          "Failed to fetch fee category frequencies";
        errorToast(state.error);
      })

      // Fee Discount Types
      .addCase(fetchFeeDiscountTypesAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchFeeDiscountTypesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.feeDiscountTypeData = action.payload.data;
      })
      .addCase(fetchFeeDiscountTypesAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch fee discount types";
        errorToast(state.error);
      })

      // Payment Statuses
      .addCase(fetchPaymentStatusesAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchPaymentStatusesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.paymentStatusData = action.payload.data;
      })
      .addCase(fetchPaymentStatusesAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch payment statuses";
        errorToast(state.error);
      })

      // Genders
      .addCase(fetchGendersAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchGendersAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.genderData = action.payload.data;
      })
      .addCase(fetchGendersAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = (action.payload as string) || "Failed to fetch genders";
        errorToast(state.error);
      })

      // User Roles
      .addCase(fetchUserRolesAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchUserRolesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.userRoleData = action.payload.data;
      })
      .addCase(fetchUserRolesAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch user roles";
        errorToast(state.error);
      })

      // Admission Statuses
      .addCase(fetchAdmissionStatusesAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchAdmissionStatusesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.admissionStatusData = action.payload.data;
      })
      .addCase(fetchAdmissionStatusesAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch admission statuses";
        errorToast(state.error);
      })

      // Parent Types
      .addCase(fetchParentTypesAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchParentTypesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.parentTypeData = action.payload.data;
      })
      .addCase(fetchParentTypesAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch parent types";
        errorToast(state.error);
      })

      // Currency List
      .addCase(fetchCurrencyListAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchCurrencyListAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.currencyData = action.payload.data;
      })
      .addCase(fetchCurrencyListAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch currency list";
        errorToast(state.error);
      });
  },
});

export default UtilSlice.reducer;
