import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import Preloader from "../../../../core/common/loader/preLoader";
import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import {
  commonPhoneSchema,
  commonEmailSchema,
} from "../../admissions/add-admission/schema";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { EditableFieldType, EditableFieldError } from "./types";
import EditableField from "./components/EditableField";
import { updateStudentFieldAsync } from "../../../../core/data/redux/features/admission/admissionSlice";
import { useAppDispatch } from "../../../../core/data/redux/hooks";

const EDITABLE_FIELDS: EditableFieldType[] = [
  {
    key: "currentAddress",
    label: "Current Address",
    type: "textarea",
    validate: async (value) => {
      if (!value.trim()) return "Current address is required";
      if (value.length > 500) return "Address cannot exceed 500 characters";
      return null;
    },
  },
  {
    key: "permanentAddress",
    label: "Permanent Address",
    type: "textarea",
    validate: async (value) => {
      if (!value.trim()) return "Permanent address is required";
      if (value.length > 500) return "Address cannot exceed 500 characters";
      return null;
    },
  },
  {
    key: "studentBankAccountNumber",
    label: "Bank Account Number",
    type: "text",
    validate: async (value) => {
      if (!value.trim()) return "Bank Account Number is required";
      if (value.length > 500)
        return "Bank Account Number cannot exceed 500 characters";
      return null;
    },
  },
  {
    key: "studentBankBranchName",
    label: "Bank Branch Name",
    type: "text",
    validate: async (value) => {
      if (!value.trim()) return "Bank Branch Name is required";
      if (value.length > 500)
        return "Bank Branch Name cannot exceed 500 characters";
      return null;
    },
  },
  {
    key: "studentBankAccountIFSCCode",
    label: "IFSC Code",
    type: "text",
    validate: async (value) => {
      if (!value.trim()) return "IFSC Code is required";
      if (value.length > 500) return "IFSC Code 500 characters";
      return null;
    },
  },
  {
    key: "studentBankAddress",
    label: "Bank Address",
    type: "text",
    validate: async (value) => {
      if (!value.trim()) return "Bank Address is required";
      if (value.length > 500) return "Bank Address exceed 500 characters";
      return null;
    },
  },
];

const PARENT_EDITABLE_FIELDS: EditableFieldType[] = [
  {
    key: "email",
    label: "Email",
    type: "email",
    validate: async (value) => {
      try {
        await commonEmailSchema(true).validateSync(value);
        return null;
      } catch (error: any) {
        return error.message;
      }
    },
  },
  {
    key: "phoneNumber",
    label: "Phone",
    type: "phone",
    validate: async (value) => {
      try {
        await commonPhoneSchema(true).validateSync(value);
        return null;
      } catch (error: any) {
        return error.message;
      }
    },
  },
];

const StudentPersonal = ({
  studentData,
  loading,
  setRefresh,
}: {
  studentData: any;
  loading?: boolean;
  setRefresh?: (value: boolean | ((prev: boolean) => boolean)) => void;
}) => {
  const [editedValues, setEditedValues] = useState<
    Record<string, string | null | number>
  >({});
  const dispatch = useAppDispatch();
  console.log("Student Personal:", editedValues);
  const [errors, setErrors] = useState<EditableFieldError>({});

  const handleFieldChange = async (
    field: string,
    value: string,
    parentId?: number
  ) => {
    setEditedValues((prev) => ({
      ...prev,
      [field]: value,
      parentId: parentId || null,
    }));
    await validateField(field, value);
  };

  const handleFieldSave = async (field: string) => {
    try {
      const error = await validateField(
        field,
        (editedValues[field] as string) || ""
      );
      if (error) {
        setErrors({ [field]: error });
        return;
      }

      console.log(`Saving ${field}:`, editedValues[field]);
      // Save the edited value to the backend

      if (editedValues.parentId) {
        // Update the parent field
        await dispatch(
          updateStudentFieldAsync({
            schoolId: studentData.schoolId,
            studentId: studentData.studentId,
            fieldData: {
              field: "parentData",
              value: {
                [field]: editedValues[field],
                parentId: editedValues["parentId"],
              },
            },
          })
        ).unwrap();
      } else {
        // Update the student field
        await dispatch(
          updateStudentFieldAsync({
            schoolId: studentData.schoolId,
            studentId: studentData.studentId,
            fieldData: {
              field: field,
              value: editedValues[field],
            },
          })
        ).unwrap();
      }
      setErrors({});
      setEditedValues((prev) => {
        const newValues = { ...prev };
        delete newValues[field];
        delete newValues["parentId"];
        return newValues;
      });
      if (setRefresh) setRefresh((prevState: boolean) => !prevState);

      // Clear the edited value after successful save
    } catch (error: any) {
      setErrors({ [field]: error.message });
      throw error;
    }
  };

  const handleFieldCancel = (field: string) => {
    setEditedValues((prev) => {
      const newValues = { ...prev };
      delete newValues[field];
      return newValues;
    });
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });
  };

  const validateField = async (field: string, value: string) => {
    const fieldConfig = [...EDITABLE_FIELDS, ...PARENT_EDITABLE_FIELDS].find(
      (f) => f.key === field
    );
    if (!fieldConfig) return null;

    const error = await fieldConfig.validate(value);
    setErrors((prev) => ({
      ...prev,
      [field]: error || "",
    }));
    return error;
  };

  const renderEditableField = (
    field: EditableFieldType,
    value: string,
    parentId?: number
  ) => {
    return (
      <EditableField
        field={field}
        value={(editedValues[field.key] as string) || value}
        error={errors[field.key]}
        onSave={() => handleFieldSave(field.key)}
        onCancel={() => handleFieldCancel(field.key)}
        onChange={(value) => handleFieldChange(field.key, value, parentId)}
      />
    );
  };

  return (
    <div className="row g-3">
      <div className="col-12">
        {/* Parents Information */}
        <div className="card">
          <div className="card-header">
            <h5>Parents Information</h5>
          </div>
          {loading ? (
            <Preloader />
          ) : (
            <div className="card-body">
              <div className="border rounded p-3 pb-0 mb-3">
                {studentData?.parentData?.length ? (
                  studentData.parentData.map((parent: any, index: number) => {
                    return (
                      <div className="row g-3" key={index}>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="d-flex align-items-center mb-3">
                            <span className="avatar avatar-lg flex-shrink-0">
                              <ImageWithBasePath
                                src="assets/img/parents/parent-13.jpg"
                                className="img-fluid rounded"
                                alt="img"
                              />
                            </span>
                            <div className="ms-2 overflow-hidden min-w-0">
                              <h6 className="text-truncate mb-1">
                                {parent?.firstName || ""}{" "}
                                {parent?.lastName || ""}
                              </h6>
                              <p className="text-primary mb-0">
                                {parent?.parentTypeName || ""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">Phone</p>
                            {renderEditableField(
                              PARENT_EDITABLE_FIELDS[1],
                              parent?.phoneNumber || "",
                              parent?.id
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="mb-3 overflow-hidden me-3 flex-grow-1 min-w-0">
                              <p className="text-dark fw-medium mb-1">Email</p>
                              {renderEditableField(
                                PARENT_EDITABLE_FIELDS[0],
                                parent?.email || "",
                                parent?.id
                              )}
                            </div>
                            <div
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Print"
                              data-bs-original-title="Reset Password"
                              className="btn btn-dark btn-icon btn-sm mb-3 flex-shrink-0"
                            >
                              <i className="ti ti-lock-x" />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex align-items-center justify-content-center py-3">
                    No Data Found
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* /Parents Information */}
      </div>
      <div className="col-12">
        {/* Parents Information */}
        <div className="card">
          <div className="card-header">
            <h5>Siblings Information</h5>
          </div>
          {loading ? (
            <Preloader />
          ) : (
            <div className="card-body">
              <div className="border rounded p-3 pb-0 mb-3">
                {studentData?.siblings?.length ? (
                  studentData.siblings.map((siblings: any, index: number) => {
                    return (
                      <div className="row g-3" key={index}>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="d-flex align-items-center mb-3">
                            <span className="avatar avatar-lg flex-shrink-0">
                              {!siblings.studentProfilePic ? (
                                <ImageWithBasePath
                                  src="assets/img/parents/parent-13.jpg"
                                  className="img-fluid rounded"
                                  alt="img"
                                />
                              ) : (
                                <img
                                  src={siblings.studentProfilePic}
                                  className="img-fluid rounded"
                                  alt="img"
                                />
                              )}
                            </span>
                            <div className="ms-2 overflow-hidden min-w-0">
                              <h6 className="text-truncate mb-1">
                                {siblings?.firstName || ""}{" "}
                                {siblings?.lastName || ""}
                              </h6>
                              <p className="text-primary mb-0">
                                {siblings?.relationship || ""}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">
                              Class Name
                            </p>
                            <p className="badge badge-primary">
                              {siblings?.className}
                            </p>

                            {/* {renderEditableField(
                              PARENT_EDITABLE_FIELDS[1],
                              siblings?.phoneNumber || "",
                              siblings?.id
                            )} */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                          <div className="mb-3">
                            <p className="text-dark fw-medium mb-1">
                              Section Name
                            </p>
                            <p>{siblings?.sectionName}</p>
                          </div>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-lg-4">
                          <div className="d-flex align-items-start justify-content-between">
                            <div className="mb-3 overflow-hidden me-3 flex-grow-1 min-w-0">
                              <p className="text-dark fw-medium mb-1">
                                Admission Number
                              </p>
                              <span className="badge badge-primary">
                                {siblings?.admissionNumber || ""}
                              </span>
                              {renderEditableField(
                                PARENT_EDITABLE_FIELDS[0],
                                parent?.email || "",
                                parent?.id
                              )}
                            </div>
                            <div
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              aria-label="Print"
                              data-bs-original-title="Reset Password"
                              className="btn btn-dark btn-icon btn-sm mb-3 flex-shrink-0"
                            >
                              <i className="ti ti-lock-x" />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex align-items-center justify-content-center py-3">
                    No Sibling Found
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* /Parents Information */}
      </div>
      {/* Documents */}
      <div className="col-12 col-xxl-6">
        <div className="card h-100">
          <div className="card-header">
            <h5>Documents</h5>
          </div>
          {loading ? (
            <Preloader />
          ) : (
            <div className="card-body">
              {studentData && studentData?.castCertificate && (
                <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between mb-3 p-2">
                  <div className="d-flex align-items-center overflow-hidden flex-grow-1 min-w-0">
                    <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                      <i className="ti ti-pdf fs-15" />
                    </span>
                    <div className="ms-2 min-w-0">
                      <p className="text-truncate fw-medium text-dark mb-0">
                        {`Caste Certificate.${
                          studentData?.castCertificate
                            ? studentData.castCertificate.split(".").pop()
                            : "Not Available"
                        }`}
                      </p>
                    </div>
                  </div>
                  <a
                    href={`${studentData?.castCertificate}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-dark btn-icon btn-sm flex-shrink-0 ms-2"
                  >
                    <i className="ti ti-download" />
                  </a>
                </div>
              )}

              {studentData && studentData?.idCardUrl && (
                <div className="bg-light-300 border rounded d-flex align-items-center justify-content-between mb-3 p-2">
                  <div className="d-flex align-items-center overflow-hidden flex-grow-1 min-w-0">
                    <span className="avatar avatar-md bg-white rounded flex-shrink-0 text-default">
                      <i className="ti ti-id-badge fs-15" />
                    </span>
                    <div className="ms-2 min-w-0">
                      <p className="text-truncate fw-medium text-dark mb-0">
                        {`Id Card.${
                          studentData?.idCardUrl
                            ? studentData.idCardUrl.split(".").pop()
                            : "Not Available"
                        }`}
                      </p>
                    </div>
                  </div>
                  <a
                    href={`${studentData?.idCardUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-dark btn-icon btn-sm flex-shrink-0 ms-2"
                  >
                    <i className="ti ti-download" />
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* /Documents */}
      {/* Address */}
      <div className="col-12 col-xxl-6">
        <div className="card h-100">
          <div className="card-header">
            <h5>Address</h5>
          </div>
          {loading ? (
            <Preloader />
          ) : (
            <div className="card-body">
              <div className="d-flex align-items-start mb-3">
                <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                  <i className="ti ti-map-pin-up" />
                </span>
                <div className="flex-grow-1 min-w-0">
                  <p className="text-dark fw-medium mb-1">Current Address</p>
                  {renderEditableField(
                    EDITABLE_FIELDS[0],
                    studentData?.[EDITABLE_FIELDS[0].key] || ""
                  )}
                </div>
              </div>
              <div className="d-flex align-items-start">
                <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                  <i className="ti ti-map-pins" />
                </span>
                <div className="flex-grow-1 min-w-0">
                  <p className="text-dark fw-medium mb-1">Permanent Address</p>
                  {renderEditableField(
                    EDITABLE_FIELDS[1],
                    studentData?.[EDITABLE_FIELDS[1].key] || ""
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-12">
        <div className="card h-100">
          <div className="card-header">
            <h5>Bank Details</h5>
          </div>
          {loading ? (
            <Preloader />
          ) : (
            <div className="card-body">
              <div className="d-flex align-items-start col-12 mb-3 gap-2">
                <div className="col-6 d-flex align-items-start">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-credit-card" />
                  </span>
                  <div className="flex-grow-1 min-w-0">
                    <p className="text-dark fw-medium mb-1">
                      Bank Account Number
                    </p>
                    {renderEditableField(
                      EDITABLE_FIELDS[2],
                      studentData?.studentBankAccountNumber || ""
                    )}
                  </div>
                </div>

                <div className="col-6 d-flex align-items-start">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-building-bank" />
                  </span>
                  <div className="flex-grow-1 min-w-0">
                    <p className="text-dark fw-medium mb-1">Bank Branch Name</p>
                    {renderEditableField(
                      EDITABLE_FIELDS[3],
                      studentData?.studentBankBranchName || ""
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-start col-12 mb-3 gap-2">
                <div className="col-6 d-flex align-items-start">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-barcode" />
                  </span>
                  <div className="flex-grow-1 min-w-0">
                    <p className="text-dark fw-medium mb-1">IFSC Code</p>
                    {renderEditableField(
                      EDITABLE_FIELDS[4],
                      studentData?.studentBankAccountIFSCCode || ""
                    )}
                  </div>
                </div>

                <div className="col-6 d-flex align-items-start">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-map" />
                  </span>
                  <div className="flex-grow-1 min-w-0">
                    <p className="text-dark fw-medium mb-1">Bank Address</p>
                    {renderEditableField(
                      EDITABLE_FIELDS[5],
                      studentData?.studentBankAddress || ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* /Address */}
      {/* Other Info */}
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h5>Declaration</h5>
          </div>
          {loading ? (
            <Preloader />
          ) : (
            <div className="card-body">
              <p className="mb-0">{studentData?.declaration}</p>
            </div>
          )}
        </div>
      </div>
      {/* /Other Info */}
    </div>
  );
};
export default StudentPersonal;
