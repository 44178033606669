import { useAppSelector } from "../../core/data/redux/hooks";

export const useActionPermission = () => {
  const actionPermissions = useAppSelector(
    (state) => state.auth.user?.permissions?.actionPermissions
  );

  const canPerform = (actionName: string) => {
    const action = actionPermissions?.find(
      (perm) => perm.actionName === actionName
    );
    return !!action;
  };

  return { canPerform };
};
