import * as Yup from "yup";

export const createStudentFeeValidationSchema = Yup.object().shape({
  discountDetails: Yup.array().of(
    Yup.object().shape({
      discountSchoolMappingId: Yup.number().required(),
      feeDiscountName: Yup.string().required(),
      allowedDiscount: Yup.boolean().required(),
      discountPercentage: Yup.number()
        .default(0)
        .when("allowedDiscount", {
          is: true,
          then: (schema) => schema.required("Discount percentage is required"),
        }),
    })
  ),
});

export const collectFeesValidationSchema = Yup.object().shape({
  studentClassSectionId: Yup.number().required(
    "Student Class Section is required"
  ),
  academicYearId: Yup.number().required("Academic Year is required"),
  paymentDate: Yup.date().required("Payment Date is required"),
  currencyId: Yup.number().required("Currency is required"),
  paymentMode: Yup.string().required("Payment Mode is required"),
  transactionId: Yup.string().when("paymentMode", {
    is: "ONLINE",
    then: (schema) =>
      schema.required("Transaction ID is required for online payments"),
    otherwise: (schema) => schema.nullable(),
  }),
  paymentData: Yup.array().of(
    Yup.object().shape({
      studentFeeId: Yup.number().required("Student Fee is required"),
      paymentAmount: Yup.number().required("Payment Amount is required"),
    })
  ),
});
