import * as Yup from "yup";
import "yup-phone-lite";

export const commonEmailSchema = (
  isMandatory: Boolean = true,
  message?: string
) =>
  Yup.string()
    .nullable()
    .when([], {
      is: () => isMandatory,
      then: (schema) =>
        schema
          .required(message || "Email is required")
          .email("Please enter a valid Email"),
      otherwise: (schema) =>
        schema.test({
          name: "emailValidation",
          skipAbsent: true,
          test(value, ctx) {
            if (!value) return true;
            const isValid = schema
              .email("Please enter a valid Email")
              .isValidSync(value);
            if (!isValid) {
              return ctx.createError({
                message: "Please enter a valid Email",
              });
            }
            return true;
          },
        }),
    });

export const commonPhoneSchema = (isMandatory: Boolean, message?: string) =>
  Yup.string()
    .nullable()
    .when([], {
      is: () => isMandatory,
      then: (schema) =>
        schema
          .required(message || "Phone Number is required")
          .phone("*" as any, "Please enter a valid Phone Number"),
      otherwise: (schema) =>
        schema.test({
          name: "phoneValidation",
          skipAbsent: true,
          test(value, ctx) {
            if (!value) return true;
            if (!value || !value.includes(" ")) return true;
            const spaceIndex = value.indexOf(" ");
            let splitValue = value.slice(spaceIndex + 1);
            if (!splitValue) return true;

            const isValid = schema
              .phone("*" as any, "Please enter a valid Phone Number")
              .isValidSync(value);

            if (!isValid) {
              return ctx.createError({
                message: "Please enter a valid Phone Number",
              });
            }

            return true;
          },
        }),
    });

// Schema for create admission
export const createAdmissionSchema = Yup.object().shape({
  academicYearId: Yup.number().required("Academic Year is required"),
  classId: Yup.number().required("Class is required"),
  firstName: Yup.string().required("First Name is required").max(50),
  middleName: Yup.string().nullable().max(50),
  lastName: Yup.string().required("Last Name is required").max(50),
  dob: Yup.date().required("Date of Birth is required"),
  email: Yup.string()
    .email("Please enter a valid Email")
    .required("Email is required"),
  phoneNumber: commonPhoneSchema(true, "Primary Contact Number is required"),
  genderId: Yup.number().required("Gender is required"),
  currentAddress: Yup.string(),
  permanentAddress: Yup.string(),
  monthlyIncomeCurrencyId: Yup.number()
    .required("Monthly Income Currency is required")
    .max(255),
  monthlyIncome: Yup.number()
    .positive("Monthly Income must be positive")
    .required("Monthly Income is required"),
  religion: Yup.string().required("Religion is required"),
  PENNumber: Yup.string().notRequired().max(255),
  notifyStudentAndParent: Yup.string()
    .oneOf(["Yes", "No"], "Invalid selection")
    .required("Notify Student And Parent is required"),
  declaration: Yup.string().notRequired(),
  declarative: Yup.string().trim().required("Declarative Name is required"),
  parentType: Yup.string().notRequired(),
  declarationSignature: Yup.string(),
  interviewDate: Yup.date()
    .required("Interview Date is required")
    .typeError("Please enter a valid Interview Date"),
  aadharCardNumber: Yup.string()
    .notRequired()
    .length(12, "Aadhaar Card Number must be exactly 12 digits"),
  bloodGroup: Yup.string().required("Blood Group is required"),
  isConveyanceNeeded: Yup.string().required(
    "Is Conveyance Needed field selection is required"
  ),
  lastSchoolName: Yup.string(),
  studentBankAccountNumber: Yup.string(),
  studentBankAccountIFSCCode: Yup.string(),
  studentBankBranchName: Yup.string(),
  studentBankAddress: Yup.string(),
  cast: Yup.string()
    .oneOf(["GENERAL", "OBC", "SC", "ST"], "Please select a valid Caste")
    .required("Caste is required"),

  castCertificate: Yup.string().when("cast", {
    is: (value: any) => value !== "GENERAL",
    then: (schema) => schema.required("Caste Certificate is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  incomeTaxPayer: Yup.string()
    .oneOf(["Yes", "No"], "Please select Yes or No")
    .required("Please specify if you are an Income Tax Payer"),

  PANNumber: Yup.string().when("incomeTaxPayer", {
    is: "Yes",
    then: (schema) =>
      schema
        .required("PAN Number is required")
        .length(10, "PAN Number should be 10 characters long"),
    otherwise: (schema) => schema.notRequired(),
  }),

  isSpecialMedicalNeed: Yup.string()
    .oneOf(["Yes", "No"], "Invalid selection")
    .required("Is Special Medical Need field selection Required"),

  specialMedicalNeedDetails: Yup.string().when("isSpecialMedicalNeed", {
    is: "Yes",
    then: (schema) =>
      schema.required("Special Medical Need Details is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  parentData: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      middleName: Yup.string().nullable(),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Please enter a valid Email")
        .required("Email is required"),
      phoneNumber: commonPhoneSchema(true),
      genderId: Yup.number().required("Gender is required"),
      parentTypeId: Yup.number().required("Parent Type is required"),
      relationshipWithStudent: Yup.string().when("parentTypeId", {
        is: (value: any) => value === 3,
        then: (schema) =>
          schema.required("Relationship With Student is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      currentAddress: Yup.string().required("Current Address is required"),
      qualification: Yup.string().required("Qualification is required"),
      businessName: Yup.string().notRequired(),
      businessAddress: Yup.string().notRequired(),
    })
  ),
});

// Schema for update admission
export const updateAdmissionSchema = Yup.object().shape({
  academicYearId: Yup.number().required("Academic Year is required"),
  classId: Yup.number().required("Class is required"),
  firstName: Yup.string().required("First Name is required").max(50),
  middleName: Yup.string().nullable().max(50),
  lastName: Yup.string().required("Last Name is required").max(50),
  dob: Yup.date().required("Date of Birth is required"),
  email: Yup.string()
    .email("Please enter a valid Email")
    .required("Email is required"),
  phoneNumber: commonPhoneSchema(true, "Primary Contact Number is required"),
  genderId: Yup.number().required("Gender is required"),
  interviewDate: Yup.date()
    .required("Interview Date is required")
    .typeError("Please enter a valid Interview Date"),
  admissionStatusId: Yup.number().required("Admission Status is required"),
  classSectionId: Yup.number()
    .nullable()
    .when(["admissionStatusId"], ([admissionStatusId], schema) => {
      return admissionStatusId === 3
        ? schema.required(
            "Please select a Section when Admission Status is APPROVED"
          )
        : schema;
    }),

  approvalDate: Yup.date()
    .nullable()
    .when(["admissionStatusId"], ([admissionStatusId], schema) =>
      admissionStatusId === 3
        ? schema.required(
            "Please enter Approval Date when Admission Status is APPROVED"
          )
        : schema
    ),
  admissionDate: Yup.date().nullable(),
  currentAddress: Yup.string(),
  permanentAddress: Yup.string(),
  monthlyIncomeCurrencyId: Yup.number()
    .required("Monthly Income Currency is required")
    .max(255),
  monthlyIncome: Yup.number()
    .positive("Monthly Income must be positive")
    .required("Monthly Income is required"),
  religion: Yup.string().required("Religion is required"),
  PENNumber: Yup.string().notRequired().max(255),
  notifyStudentAndParent: Yup.string().required(
    "Notify Student And Parent is required"
  ),
  declaration: Yup.string().notRequired(),
  declarative: Yup.string().trim().required("Declarative Name is required"),
  parentType: Yup.string().notRequired(),
  declarationSignature: Yup.string(),
  aadharCardNumber: Yup.string()
    .notRequired()
    .length(12, "Aadhaar Card Number must be exactly 12 digits"),
  bloodGroup: Yup.string().required("Blood Group is required"),
  isConveyanceNeeded: Yup.string().required(
    "Is Conveyance Needed field selection is required"
  ),
  lastSchoolName: Yup.string(),
  studentBankAccountNumber: Yup.string(),
  studentBankAccountIFSCCode: Yup.string(),
  studentBankBranchName: Yup.string(),
  studentBankAddress: Yup.string(),
  cast: Yup.string()
    .oneOf(["GENERAL", "OBC", "SC", "ST"], "Please select a valid Caste")
    .required("Caste is required"),

  castCertificate: Yup.string().when("cast", {
    is: (value: any) => value !== "GENERAL",
    then: (schema) => schema.required("Caste Certificate is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  incomeTaxPayer: Yup.string()
    .oneOf(["Yes", "No"], "Please select Yes or No")
    .required("Please specify if you are an Income Tax Payer"),

  PANNumber: Yup.string().when("incomeTaxPayer", {
    is: "Yes",
    then: (schema) =>
      schema
        .required("PAN Number is required")
        .length(10, "PAN Number should be 10 characters long"),
    otherwise: (schema) => schema.notRequired(),
  }),

  isSpecialMedicalNeed: Yup.string()
    .oneOf(["Yes", "No"], "Invalid selection")
    .required("Is Special Medical Need field selection Required"),

  specialMedicalNeedDetails: Yup.string().when("isSpecialMedicalNeed", {
    is: "Yes",
    then: (schema) =>
      schema.required("Special Medical Need Details is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  appliedDiscount: Yup.object()
    .shape({
      discountTypes: Yup.array().of(
        Yup.object().shape({
          allowedDiscount: Yup.boolean().required(
            "Allowed Discount is required"
          ),
          feeDiscountName: Yup.string().required(
            "Fee Discount Name is required"
          ),
          discountPercentage: Yup.number().required(
            "Discount Percentage is required"
          ),
          discountSchoolMappingId: Yup.number().required(
            "Discount School Mapping Id is required"
          ),
        })
      ),
      feeCategories: Yup.array().of(
        Yup.object().shape({
          feeId: Yup.number().required("Fee Id is required"),
          allowedDiscount: Yup.boolean().required(
            "Allowed Discount is required"
          ),
          feeCategoryName: Yup.string().required(
            "Fee Category Name is required"
          ),
        })
      ),
    })
    .nullable()
    .when("admissionStatusId", {
      is: 3,
      then: (schema) => schema.required("Applied Discount is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

  parentData: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      middleName: Yup.string().nullable(),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Please enter a valid Email")
        .required("Email is required"),
      phoneNumber: commonPhoneSchema(true),
      genderId: Yup.number().required("Gender is required"),
      parentTypeId: Yup.number().required("Parent Type is required"),
      relationshipWithStudent: Yup.string().when("parentTypeId", {
        is: (value: any) => value === 3,
        then: (schema) =>
          schema.required("Relationship With Student is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
      currentAddress: Yup.string().required("Current Address is required"),
      qualification: Yup.string().required("Qualification is required"),
      businessName: Yup.string().notRequired(),
      businessAddress: Yup.string().notRequired(),
    })
  ),
});
