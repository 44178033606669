import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import { Formik, Form, FormikProps, Field, ErrorMessage } from "formik";
import { getIn } from "formik";
import CommonSelect from "../../../../core/common/commonSelect";
import { useAdmissionOptions } from "../hooks/useAdmissionOptions";

import { useAppDispatch } from "../../../../core/data/redux/hooks";
import {
  createAdmissionAsync,
  createAdmissionPublicAsync,
  fetchAdmissionByIdAsync,
  resetAdmissionData,
  updateAdmissionAsync,
} from "../../../../core/data/redux/features/admission/admissionSlice";
import { uploadMultipleFilesAsync } from "../../../../core/data/redux/features/utils/utilsSlice";
import { createAdmissionSchema, updateAdmissionSchema } from "./schema";
import PersonalInformationSection from "./components/personal-information-section";
import AddressSection from "./components/address-section";
import BankDetailsSection from "./components/bank-details-section";
import SiblingDataSection from "./components/sibling-data-section";
import FamilyAttestationSection from "./components/family-attestation-section";
import ParentDataSection from "./components/parent-data-section";
import DeclarationSection from "./components/declaration-section";
import {
  bloodGroups,
  castCategories,
  qualificationsOptions,
  religionCategories,
  yesOrNo,
} from "./constant";
import { CollapsibleCardProvider } from "../../../../core/common/CollapsibleCardContext";
import { errorToast, successToast } from "../../../../core/common/toast/toast";
import AppliedDiscountModal from "./components/applied-discount-modal";

// Import the extracted DiscountHandler here
import DiscountHandler from "./components/discount-handler";

// Updated helper function that checks both touched and error.
const hasErrorsInSection = (
  errors: any,
  touched: any,
  fieldNames: string[]
): boolean => {
  return fieldNames.some(
    (field) => !!(getIn(touched, field) && getIn(errors, field))
  );
};

// Add this import

const AddAdmission = () => {
  const { id } = useParams<{ id: string }>();

  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    classDataOption,
    academicYearsOption,
    parentTypeDataOptionsForString,
    parentTypeDataOptions,
    currencyOptions,
    admissionStatusDataOption,
    genderDataOption,
    classSectionDataOption,
    token,
    publicData,
    schoolId,
    admissionData,
    fetchLoading,
    globalAcademicYearId,
    setRefresh,
    setPublicData,
    errormessage,
    response,
    finalInitialValues,
  } = useAdmissionOptions();
  // console.log("token", token, publicData, globalAcademicYearId);
  const [width, setWidth] = useState(
    token ? "100%" : window.innerWidth > 990 ? "calc(100% - 240px)" : "100%"
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(
        token ? "100%" : window.innerWidth > 990 ? "calc(100% - 240px)" : "100%"
      );
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchAdmissionByIdAsync(parseInt(id)));
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    return () => {
      dispatch(resetAdmissionData());
    };
  }, [id, dispatch, location.pathname]);

  const handleFormSubmit = async (values: any, actions: any) => {
    const declaration = `I ${values.declarative} of ${values.firstName} ${values.lastName} hereby declare that information given above by me is based on facts and authentic records, Admission of my child may be cancelled if any
            information is found to be false.`;
    console.log(declaration, values.declarative);
    if (schoolId) {
      try {
        let result: any;
        if (admissionData && id) {
          const updatedParentData = values.parentData.map((parent: any) => {
            const {
              id,
              userId,
              studentId,
              createdBy,
              updatedAt,
              updatedBy,
              createdAt,
              ...rest
            } = parent;
            return { ...rest };
          });
          result = await dispatch(
            updateAdmissionAsync({
              admissionId: parseInt(id),
              admissionData: {
                academicYearId: values.academicYearId,
                classId: values.classId,
                classSectionId: values.classSectionId,
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                permanentAddress: values.permanentAddress,
                dob: dayjs(values.dob).format("YYYY-MM-DD"),
                email: values.email,
                phoneNumber: values.phoneNumber,
                genderId: values.genderId,
                interviewDate: dayjs(values.interviewDate)
                  .format("YYYY-MM-DD")
                  ?.toString() as string,
                admissionStatusId: values.admissionStatusId,
                approvalDate: values.approvalDate
                  ? dayjs(values.approvalDate).format("YYYY-MM-DD")
                  : null,
                admissionDate: values.admissionDate
                  ? dayjs(values.admissionDate).format("YYYY-MM-DD")
                  : null,
                parentData: updatedParentData,
                aadharCardNumber: values.aadharCardNumber || null,
                studentBankAccountNumber: values.studentBankAccountNumber,
                studentBankAccountIFSCCode: values.studentBankAccountIFSCCode,
                studentBankAddress: values.studentBankAddress,
                studentBankBranchName: values.studentBankBranchName,
                bloodGroup: values.bloodGroup,
                castCertificate: values.castCertificate,
                cast: values.cast,
                isConveyanceNeeded: values.isConveyanceNeeded,
                lastSchoolName: values.lastSchoolName,
                isSpecialMedicalNeed: values.isSpecialMedicalNeed,
                specialMedicalNeedDetails: values.specialMedicalNeedDetails,
                declaration: declaration,
                declarationSignature: values.declarationSignature,
                monthlyIncomeCurrencyId: values.monthlyIncomeCurrencyId,
                monthlyIncome: values.monthlyIncome,
                incomeTaxPayer: values.incomeTaxPayer,
                PANNumber: values.PANNumber,
                religion: values.religion,
                currentAddress: values.currentAddress,
                PENNumber: values.PENNumber,
                notifyStudentAndParent: values.notifyStudentAndParent,
                siblings: values.siblings?.map((s: any) => {
                  return {
                    siblingId: s.siblingId,
                    relationship: s.relationship,
                  };
                }),
                appliedDiscount: values?.appliedDiscount,
              },
            })
          ).unwrap();
        } else {
          if (!token) {
            result = await dispatch(
              createAdmissionAsync({
                newAdmission: {
                  academicYearId: values.academicYearId,
                  classId: values.classId,
                  firstName: values.firstName,
                  middleName: values.middleName,
                  lastName: values.lastName,
                  dob: dayjs(values.dob).format("YYYY-MM-DD"),
                  email: values.email,
                  phoneNumber: values.phoneNumber,
                  genderId: values.genderId,
                  permanentAddress: values.permanentAddress,
                  interviewDate: dayjs(values.interviewDate)
                    .format("YYYY-MM-DD")
                    ?.toString() as string,
                  aadharCardNumber: values.aadharCardNumber || null,
                  studentBankAccountNumber: values.studentBankAccountNumber,
                  studentBankAccountIFSCCode: values.studentBankAccountIFSCCode,
                  studentBankAddress: values.studentBankAddress,
                  studentBankBranchName: values.studentBankBranchName,
                  bloodGroup: values.bloodGroup,
                  castCertificate: values.castCertificate,
                  cast: values.cast,
                  isConveyanceNeeded: values.isConveyanceNeeded,
                  lastSchoolName: values.lastSchoolName,
                  isSpecialMedicalNeed: values.isSpecialMedicalNeed,
                  specialMedicalNeedDetails: values.specialMedicalNeedDetails,
                  declaration: declaration,
                  declarationSignature: values.declarationSignature,
                  monthlyIncomeCurrencyId: values.monthlyIncomeCurrencyId,
                  monthlyIncome: values.monthlyIncome,
                  incomeTaxPayer: values.incomeTaxPayer,
                  PANNumber: values.PANNumber,
                  religion: values.religion,
                  currentAddress: values.currentAddress,
                  PENNumber: values.PENNumber,
                  notifyStudentAndParent: values.notifyStudentAndParent,
                  parentData: values.parentData,
                  siblings: values.siblings?.map((s: any) => {
                    return {
                      siblingId: s.siblingId,
                      relationship: s.relationship,
                    };
                  }),
                },
                schoolId,
              })
            ).unwrap();
          } else {
            try {
              const result1 = await dispatch(
                createAdmissionPublicAsync({
                  newAdmission: {
                    inquiryId: values.inquiryId,
                    academicYearId: values.academicYearId,
                    classId: values.classId,
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    dob: dayjs(values.dob).format("YYYY-MM-DD"),
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    genderId: values.genderId,
                    permanentAddress: values.permanentAddress,
                    interviewDate: dayjs(values.interviewDate)
                      .format("YYYY-MM-DD")
                      ?.toString() as string,
                    aadharCardNumber: values.aadharCardNumber || null,
                    studentBankAccountNumber: values.studentBankAccountNumber,
                    studentBankAccountIFSCCode:
                      values.studentBankAccountIFSCCode,
                    studentBankAddress: values.studentBankAddress,
                    studentBankBranchName: values.studentBankBranchName,
                    bloodGroup: values.bloodGroup,
                    castCertificate: values.castCertificate,
                    cast: values.cast,
                    isConveyanceNeeded: values.isConveyanceNeeded,
                    lastSchoolName: values.lastSchoolName,
                    isSpecialMedicalNeed: values.isSpecialMedicalNeed,
                    specialMedicalNeedDetails: values.specialMedicalNeedDetails,
                    declaration: declaration,
                    declarationSignature: values.declarationSignature,
                    monthlyIncomeCurrencyId: values.monthlyIncomeCurrencyId,
                    monthlyIncome: values.monthlyIncome,
                    incomeTaxPayer: values.incomeTaxPayer,
                    PANNumber: values.PANNumber,
                    religion: values.religion,
                    currentAddress: values.currentAddress,
                    PENNumber: values.PENNumber,
                    notifyStudentAndParent: values.notifyStudentAndParent,
                    parentData: values.parentData,
                    siblings: values.siblings?.map((s: any) => {
                      return {
                        siblingId: s.siblingId,
                        relationship: s.relationship,
                      };
                    }),
                  },
                  schoolId,
                })
              ).unwrap();
              actions.resetForm();
              setPublicData(null);
              // successToast("Admission created successfully!");
              setRefresh((prev) => !prev);
            } catch (error) {
              // errorToast("Failed to create admission. Please try again.");
            }
          }
        }
        if (result) {
          navigate(all_routes.admissionList);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handelfileupload = async (
    file: any,
    name: string,
    setFieldValue: any
  ) => {
    if (file) {
      const formData = new FormData();
      formData.append("files", file);

      try {
        const response = await dispatch(
          uploadMultipleFilesAsync({
            formData,
            fileCategory: "studentCastCertificate",
          })
        ).unwrap();

        setFieldValue(name, response.uploadedFiles[0].fileUrl);
      } catch (error) {
        errorToast("Upload failed:");
      }
    }
  };

  // if (token && !publicData) {
  //   return null;
  // }
  if (token && publicData === null && !errormessage && response) {
    return (
      <div className="bg-gradient-primary min-vh-100 d-flex flex-column align-items-center justify-content-center p-3">
        <div
          className="p-4 bg-white shadow-lg rounded-4 d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp"
          style={{
            maxWidth: "400px",
            width: "100%",
            // border: "1px solid rgba(98, 237, 17, 0.44)",
            transition: "all 0.3s ease",
          }}
        >
          <div className="bg-opacity-10 rounded-circle p-3 fs-3">✅️</div>

          <div
            className="alert alert-success d-flex align-items-center w-100"
            role="alert"
          >
            <i className="bi bi-x-circle-fill me-2"></i>
            <div className="text-center">{response?.message}</div>
          </div>

          <p className="text-muted text-center fs-11 ">
            Please review your information and try again.
            <span className="d-block mt-1">Need help? Contact Support</span>
          </p>
        </div>
      </div>
    );
  }

  if (token && publicData === null && !response && errormessage) {
    return (
      <div className="bg-gradient-primary min-vh-100 d-flex flex-column align-items-center justify-content-center p-3">
        <div
          className="p-4 bg-white shadow-lg rounded-4 d-flex flex-column align-items-center justify-content-center animate__animated animate__fadeInUp"
          style={{
            maxWidth: "400px",
            width: "100%",
            // border: "0px solid rgba(237, 17, 17, 0.84)",
            transition: "all 0.3s ease",
          }}
        >
          <div className=" bg-opacity-10 rounded-circle p-3 fs-3">⛔</div>

          <div
            className="alert alert-danger d-flex align-items-center justify-content-center text-center w-100"
            role="alert"
          >
            {errormessage}
          </div>

          <p className="text-muted text-center fs-11  ">
            Please review your information and try again.
            <span className="d-block mt-1">Need help? Contact Support</span>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={`${token ? "m-3" : "page-wrapper"}`}>
      <div className="content content-two">
        <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
          <div className="my-auto mb-2">
            <h3 className="mb-1">{isEdit ? "Edit" : "Add"} Admission</h3>
            <nav>
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to={all_routes.adminDashboard}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={all_routes.admissionList}>Admissions</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {isEdit ? "Edit" : "Add"} Admission
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row" style={{ paddingBottom: "60px" }}>
          <div className="col-md-12">
            <Formik
              initialValues={finalInitialValues}
              enableReinitialize
              validationSchema={
                id ? updateAdmissionSchema : createAdmissionSchema
              }
              onSubmit={handleFormSubmit}
            >
              {({
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
                resetForm,
                isSubmitting,
              }: FormikProps<any>) => {
                return (
                  <>
                    {!admissionData?.appliedDiscount && admissionData && (
                      <DiscountHandler
                        isEdit={isEdit}
                        schoolId={schoolId}
                        globalAcademicYearId={globalAcademicYearId}
                      />
                    )}
                    <CollapsibleCardProvider>
                      <Form onSubmit={handleSubmit}>
                        <PersonalInformationSection
                          isEdit={isEdit}
                          schoolId={schoolId}
                          globalAcademicYearId={globalAcademicYearId}
                          fetchLoading={fetchLoading}
                          academicYearsOption={academicYearsOption}
                          classDataOption={classDataOption}
                          genderDataOption={genderDataOption}
                          classSectionDataOption={classSectionDataOption}
                          admissionStatusDataOption={admissionStatusDataOption}
                          yesOrNo={yesOrNo}
                          bloodGroups={bloodGroups}
                          castCategories={castCategories}
                          handleFileUpload={handelfileupload}
                          dispatch={dispatch}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          values={values}
                          errors={errors}
                          hasErrors={hasErrorsInSection(errors, touched, [
                            "academicYearId",
                            "classId",
                            "classSectionId",
                            "admissionStatusId",
                            "firstName",
                            "middleName",
                            "lastName",
                            "genderId",
                            "dob",
                            "phoneNumber",
                            "email",
                            "interviewDate",
                            "aadharCardNumber",
                            "PENNumber",
                            "bloodGroup",
                            "cast",
                            "castCertificate",
                            "lastSchoolName",
                            "isConveyanceNeeded",
                            "isSpecialMedicalNeed",
                            "specialMedicalNeedDetails",
                            "admissionDate",
                            // Add all other personal info fields
                          ])}
                          token={token}
                        />
                        <AddressSection
                          fetchLoading={fetchLoading}
                          hasErrors={hasErrorsInSection(errors, touched, [
                            "currentAddress",
                            "permanentAddress",
                          ])}
                        />
                        <BankDetailsSection
                          fetchLoading={fetchLoading}
                          hasErrors={hasErrorsInSection(errors, touched, [
                            "studentBankAccountNumber",
                            "studentBankAccountIFSCCode",
                            "studentBankBranchName",
                            "studentBankAddress",
                          ])}
                        />
                        <SiblingDataSection
                          fetchLoading={fetchLoading}
                          values={values}
                          classDataOption={classDataOption}
                          hasErrors={hasErrorsInSection(errors, touched, [
                            "siblings",
                          ])}
                          schoolId={schoolId}
                          studentId={id ? parseInt(id) : undefined}
                        />
                        <FamilyAttestationSection
                          fetchLoading={fetchLoading}
                          yesOrNo={yesOrNo}
                          currencyOptions={currencyOptions}
                          religionCategories={religionCategories}
                          hasErrors={hasErrorsInSection(errors, touched, [
                            "monthlyIncomeCurrencyId",
                            "monthlyIncome",
                            "incomeTaxPayer",
                            "PANNumber",
                            "religion",
                          ])}
                        />
                        <ParentDataSection
                          fetchLoading={fetchLoading}
                          values={values}
                          yesOrNo={yesOrNo}
                          schoolId={schoolId}
                          genderDataOption={genderDataOption}
                          parentTypeDataOptions={parentTypeDataOptions}
                          qualificationsOptions={qualificationsOptions}
                          hasErrors={hasErrorsInSection(errors, touched, [
                            "parentData",
                          ])}
                          isEdit={isEdit}
                        />
                        <DeclarationSection
                          fetchLoading={fetchLoading}
                          values={values}
                          parentTypeDataOptionsForString={
                            parentTypeDataOptionsForString
                          }
                          parentTypeDataOptions={parentTypeDataOptions}
                          isEdit={isEdit}
                          setFieldValue={setFieldValue}
                          hasErrors={hasErrorsInSection(errors, touched, [
                            "declaration",
                            "declarationSignature",
                            "declarative",
                          ])}
                        />
                      </Form>
                    </CollapsibleCardProvider>

                    <div
                      className="position-fixed bg-white py-3 px-4"
                      style={{
                        width: width, // Subtracting sidebar width
                        borderTop: "1px solid #dee2e6",
                        zIndex: 999,
                        boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
                        bottom: 0,
                        right: 0,
                      }}
                    >
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12">
                            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
                              {/* Admission Status Dropdown in Footer */}

                              <div className="d-flex justify-content-center gap-3">
                                {id && (
                                  <div
                                    style={{
                                      maxWidth: "160px",
                                      minWidth: "120px",
                                    }}
                                  >
                                    <label className="form-label mb-1">
                                      Admission Status
                                    </label>
                                    <Field
                                      name="admissionStatusId"
                                      component={CommonSelect}
                                      options={admissionStatusDataOption}
                                      menuPlacement="top"
                                      disabled={
                                        admissionData?.admissionStatusId === 3
                                      }
                                      onValueSelect={(statusId: number) => {
                                        setFieldValue(
                                          "admissionStatusId",
                                          statusId
                                        );
                                        if (statusId && isEdit) {
                                          setFieldValue(
                                            "approvalDate",
                                            statusId === 3
                                              ? dayjs().format("YYYY-MM-DD")
                                              : null
                                          );
                                        }
                                        if (statusId && isEdit) {
                                          setFieldValue(
                                            "admissionDate",
                                            statusId === 3 ? dayjs() : null
                                          );
                                        }
                                      }}
                                    />
                                    <ErrorMessage
                                      name="admissionStatusId"
                                      component="div"
                                      className="text-danger"
                                    />
                                  </div>
                                )}

                                {isEdit && values.admissionStatusId === 3 && (
                                  <div
                                    className="col-xxl col-xl-3 col-md-6"
                                    // style={{
                                    //   maxWidth: "110px",
                                    //   minWidth: "100px",
                                    // }}
                                  >
                                    <div className="mb-2">
                                      <label className="form-label form-label mb-1">
                                        Section
                                      </label>
                                      <Field
                                        name="classSectionId"
                                        component={CommonSelect}
                                        options={classSectionDataOption}
                                        menuPlacement="top"
                                      />
                                      <ErrorMessage
                                        name="classSectionId"
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                )}
                                {values.admissionStatusId === 3 && (
                                  <div className="d-flex align-items-center">
                                    <button
                                      type="button"
                                      className="btn btn-primary mt-3"
                                      data-bs-toggle="modal"
                                      data-bs-target="#applied_discount_modal"
                                    >
                                      {admissionData &&
                                      !admissionData.appliedDiscount
                                        ? "Apply Discount"
                                        : "Applied Discount"}
                                    </button>
                                  </div>
                                )}
                              </div>

                              <div className="d-flex flex-column flex-md-row align-items-center gap-3">
                                {values.admissionStatusId === 3 &&
                                  admissionData?.admissionStatusId !== 3 && (
                                    <Field name="notifyStudentAndParent">
                                      {({ field, form }: any) => (
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            {...field}
                                            className="form-check-input"
                                            id="notifyStudentAndParent"
                                            checked={field.value === "Yes"}
                                            onChange={(
                                              e: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                              form.setFieldValue(
                                                "notifyStudentAndParent",
                                                e.target.checked ? "Yes" : "No"
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor="notifyStudentAndParent"
                                          >
                                            Send Form Copy to Student/Parent?
                                          </label>
                                        </div>
                                      )}
                                    </Field>
                                  )}
                                <div className="d-flex gap-2">
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={() => {
                                      navigate(-1);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                    onClick={() => handleSubmit()}
                                  >
                                    {isEdit
                                      ? "Save Admission"
                                      : "Add Admission"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isEdit && values.admissionStatusId === 3 && (
                      <AppliedDiscountModal classId={values.classId} />
                    )}
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmission;
