import { ErrorMessage, Field } from "formik";
import Preloader from "../../../../../core/common/loader/preLoader";
import CollapsibleCard from "../../../../../core/common/CollapsibleCard";

interface SectionProps {
  fetchLoading: boolean;
  hasErrors: boolean;
}

const BankDetailsSection: React.FC<SectionProps> = ({
  fetchLoading,
  hasErrors,
}) => (
  <CollapsibleCard
    title="Bank Details"
    icon={<i className="ti ti-building-bank fs-16" />}
    hasErrors={hasErrors}
  >
    {fetchLoading ? (
      <Preloader />
    ) : (
      <div className="row">
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Account Number</label>
            <Field
              type="text"
              className="form-control"
              name="studentBankAccountNumber"
            />
            <ErrorMessage
              name="studentBankAccountNumber"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">IFSC Code</label>
            <Field
              type="text"
              className="form-control"
              name="studentBankAccountIFSCCode"
            />
            <ErrorMessage
              name="studentBankAccountIFSCCode"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Branch Name</label>
            <Field
              type="text"
              className="form-control"
              name="studentBankBranchName"
            />
            <ErrorMessage
              name="studentBankBranchName"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-3">
            <label className="form-label">Bank Address</label>
            <Field
              type="text"
              className="form-control"
              name="studentBankAddress"
            />
            <ErrorMessage
              name="studentBankAddress"
              className="text-danger"
              component="div"
            />
          </div>
        </div>
      </div>
    )}
  </CollapsibleCard>
);

export default BankDetailsSection;
