export const createAnnouncementsInitialValues = {
  title: "",
  message: "",
  classIds: null,
  sentTo: [],
  scheduledAt: null,
  attachments: [
    {
      attachmentName: "",
      attachmentUrl: "",
    },
  ],
  sentToWhatsapp: true,
  sentToEmail: true,
};
