import FeatherIcon from "feather-icons-react";
import { useEffect, useRef, useState } from "react";

interface UploadButtonProps {
  onFileUpload: (file: File | File[]) => void;
  buttonText?: string;
  accept?: string;
  buttonClassName?: string;
  className?: string;
  multiple?: boolean;
  initialFileNames?: string[];
  name: string;
  setFieldValue?: (fieldName: string, value: string | File[]) => void;
  values?: any;
  fieldName: string;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  onFileUpload,
  buttonText,
  className,
  accept = ".csv",
  buttonClassName = "btn btn-primary",
  multiple = false,
  initialFileNames = [],
  setFieldValue,
  name,
  values,
  fieldName,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    setFileNames(initialFileNames);
  }, [initialFileNames]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;

    if (selectedFiles) {
      const fileArray = Array.from(selectedFiles);

      if (multiple) {
        setFiles((prevFiles) => [...prevFiles, ...fileArray]);
        setFileNames((prevFileNames) => [
          ...prevFileNames,
          ...fileArray.map((f) => f.name),
        ]);
        onFileUpload([...files, ...fileArray]);
      } else {
        setFiles(fileArray);
        setFileNames([
          fieldName === "castCertificate"
            ? "Cast Certificate"
            : fileArray[0].name,
        ]);
        onFileUpload(fileArray[0]);
      }
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);
      onFileUpload(multiple ? updatedFiles : updatedFiles[0]);
      return updatedFiles;
    });
    if (setFieldValue) {
      setFieldValue(
        fieldName,
        multiple
          ? (values?.[fieldName] || []).filter(
              (_: any, i: number) => i !== index
            )
          : null
      );
    }
    setFileNames((prevFileNames) =>
      prevFileNames.filter((_, i) => i !== index)
    );
  };

  return (
    <>
      <div className={`d-flex align-items-center ${className}`}>
        <button
          type="button"
          className={buttonClassName}
          onClick={handleButtonClick}
        >
          <FeatherIcon icon="upload" size={16} />
          {buttonText}
        </button>
        <div className="mt-1 w-100">
          {fileNames.map((fileName, index) => (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between p-2 mb-2"
              style={{ maxWidth: "85%" }}
            >
              <span className="text-truncate me-2">{fileName}</span>
              <button
                type="button"
                className="btn btn-link text-danger p-0"
                onClick={() => handleRemoveFile(index)}
                aria-label={`Remove ${fileName}`}
              >
                <FeatherIcon icon="x" size={16} />
              </button>
            </div>
          ))}
        </div>
      </div>
      <input
        type="file"
        accept={accept}
        onChange={handleFileChange}
        ref={fileInputRef}
        hidden
        multiple={multiple}
      />
    </>
  );
};
export default UploadButton;
