import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AcademicYear, AcademicYearState } from "./academicYearType";
import {
  createAcademicYear,
  deleteAcademicYear,
  fetchAcademicYearsOfSchool,
  updateAcademicYear,
} from "./academicYearApi";
import { errorToast, successToast } from "../../../../common/toast/toast";

const initialState: AcademicYearState = {
  academicYearData: [],
  globalAcedemicYearId: null,
  fetchLoading: false,
  loading: false,
  error: null,
};

export const fetchAcademicYearsAsync = createAsyncThunk(
  "academicYear/fetchAcademicYears",
  async (schoolId: number) => {
    return await fetchAcademicYearsOfSchool(schoolId);
  }
);

export const createAcademicYearAsync = createAsyncThunk(
  "academicYear/createAcademicYear",
  async ({
    schoolId,
    newAcademicYear,
  }: {
    schoolId: number;
    newAcademicYear: AcademicYear;
  }) => {
    return await createAcademicYear(schoolId, newAcademicYear);
  }
);

export const updateAcademicYearAsync = createAsyncThunk(
  "academicYear/updateAcademicYear",
  async ({
    academicYearId,
    academicYearData,
  }: {
    academicYearId: number;
    academicYearData: AcademicYear;
  }) => {
    return await updateAcademicYear(academicYearId, academicYearData);
  }
);

export const deleteAcademicYearAsync = createAsyncThunk(
  "academicYear/deleteAcademicYear",
  async (academicYearId: number) => {
    await deleteAcademicYear(academicYearId);
    return academicYearId;
  }
);

const academicYearSlice = createSlice({
  name: "academicYear",
  initialState,
  reducers: {
    setGlobalAcedemicYearId: (state, action) => {
      state.globalAcedemicYearId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAcademicYearsAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchAcademicYearsAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.academicYearData = action.payload?.data;
      })
      .addCase(fetchAcademicYearsAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = action.error.message || "Failed to fetch academic years";
        errorToast(state.error);
      })
      .addCase(createAcademicYearAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAcademicYearAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to create academic year";
        errorToast(state.error);
      })
      .addCase(createAcademicYearAsync.fulfilled, (state, action) => {
        state.academicYearData.push(action.payload?.data);
        successToast(action.payload?.message);
      })
      .addCase(updateAcademicYearAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAcademicYearAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update academic year";
        errorToast(state.error);
      })
      .addCase(updateAcademicYearAsync.fulfilled, (state, action) => {
        const index = state.academicYearData.findIndex(
          (item) => item.id === action.payload?.data?.id
        );
        if (index >= 0) {
          state.academicYearData[index] = action.payload?.data;
          successToast(action.payload?.message);
        }
      })
      .addCase(deleteAcademicYearAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAcademicYearAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to delete academic year";
        errorToast(state.error);
      })
      .addCase(deleteAcademicYearAsync.fulfilled, (state, action) => {
        state.academicYearData = state.academicYearData.filter(
          (item) => item.id !== action.payload
        );
        successToast("Academic Year deleted successfully");
      });
  },
});

export const { setGlobalAcedemicYearId } = academicYearSlice.actions;

export default academicYearSlice.reducer;
