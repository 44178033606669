import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import { useState } from "react";
import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import CommonSelect from "../../../../core/common/commonSelect";
import { ErrorMessage, Field, Formik } from "formik";
import { collectFeesInitialValue } from "../student-details/initialValue";
import { collectFeesValidationSchema } from "../student-details/schema";
import { useEffect, useMemo, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import {
  addStudentFeePaymentAsync,
  fetchStudentFeeRecordsAsync,
} from "../../../../core/data/redux/features/fee/feeSlice";
import { errorToast } from "../../../../core/common/toast/toast";

const FeeCollect = ({ studentData }: { studentData: any }) => {
  console.log(studentData, "studentData");
  const routes = all_routes;
  const formRef = useRef(null);
  const dispatch = useAppDispatch();
  const schoolId = useAppSelector((state) => state.auth?.user?.schoolId);
  const loading = useAppSelector((state) => state.fee.loading);
  const { studentFeeRecords, fetchLoading } = useAppSelector(
    (state) => state.fee
  );

  const paymentModeOptions = [
    { value: "CASH", label: "Cash" },
    { value: "ONLINE", label: "Online" },
    // Add other payment modes as needed
  ];

  const studentFeeOptions = useMemo(() => {
    return studentFeeRecords
      .filter((record) => record.paymentStatusName === "UNPAID")
      .map((record) => ({
        value: record.studentFeeId,
        label: `${record.studentFeeName}`,
      }));
  }, [studentFeeRecords]);

  console.log(studentFeeRecords, "studentFeeRecords");

  // const handleFeeDeselect = (deselectedFeeId:any) => {
  //   const feeToAdd = studentFeeOptions.find(
  //     (fee) => fee.value === deselectedFeeId
  //   );
  //   setAvailableFees((prevFees) => [...prevFees, feeToAdd]);
  // };

  useEffect(() => {
    if (schoolId && studentData?.studentClassSectionId) {
      dispatch(
        fetchStudentFeeRecordsAsync({
          studentClassSectionId: studentData?.studentClassSectionId,
          studentId: studentData?.studentId,
        })
      );
    }
  }, [schoolId, studentData?.studentClassSectionId]);

  useEffect(() => {
    const modalElement = document.getElementById("add_fees_collect");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const formInitialValues = useMemo(
    () => collectFeesInitialValue(studentFeeRecords),
    [studentFeeRecords]
  );

  const getFilteredFeeOptions = (id: any, values: any) => {
    const duplicates = values.paymentData.find(
      (value: any) => value.studentFeeId === id
    );
    if (duplicates) {
      errorToast("Duplicates Keys Not Allowed !");
      return true;
    }
    return false;
    // return studentFeeOptions;
  };

  return (
    <>
      {/* Add Fees Collect */}
      {studentFeeRecords?.length && (
        <div className="modal fade" id="add_fees_collect">
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <h4 className="modal-title">Collect Fees</h4>
                  <span className="badge badge-sm bg-primary ms-2">
                    {studentData?.admissionNumber}
                  </span>
                </div>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              {studentFeeRecords?.length ? (
                <Formik
                  initialValues={formInitialValues}
                  enableReinitialize
                  innerRef={formRef}
                  validationSchema={collectFeesValidationSchema}
                  onSubmit={async (values, actions) => {
                    try {
                      if (schoolId) {
                        let result = await dispatch(
                          addStudentFeePaymentAsync({
                            schoolId,
                            studentId: studentData?.studentId,
                            paymentData: {
                              studentClassSectionId:
                                values?.studentClassSectionId,
                              academicYearId: values?.academicYearId,
                              currencyId: values?.currencyId,
                              paymentDate: dayjs(values.paymentDate)
                                .format("YYYY-MM-DD")
                                .toString(),
                              paymentMode: values.paymentMode,
                              transactionId: values.transactionId,
                              paymentData: values.paymentData.map(
                                (payment) => ({
                                  studentFeeId: payment.studentFeeId!,
                                  paymentAmount: payment.paymentAmount!,
                                })
                              ),
                            },
                          })
                        ).unwrap();

                        if (result) {
                          const cancelButton = document.getElementById(
                            "btn-cancel1"
                          ) as HTMLElement;

                          cancelButton.click();

                          await dispatch(
                            fetchStudentFeeRecordsAsync({
                              studentClassSectionId:
                                studentData?.studentClassSectionId,
                              studentId: studentData?.studentId,
                            })
                          ).unwrap();
                        }
                      }
                    } catch (error) {
                      console.error("Error adding fee payment", error);
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    values,
                    setFieldValue,
                    setFieldTouched,
                    resetForm,
                    isSubmitting,
                  }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <div id="modal-datepicker" className="modal-body">
                          <div className="bg-light-300 p-3 pb-0 rounded mb-4">
                            <div className="row align-items-center">
                              <div className="col-lg-3 col-md-6">
                                <div className="d-flex align-items-center mb-3">
                                  <Link
                                    to={routes.studentGrid}
                                    className="avatar avatar-md me-2"
                                  >
                                    {studentData &&
                                    studentData.studentProfilePic ? (
                                      <>
                                        <img
                                          src={studentData.studentProfilePic}
                                          alt=""
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <ImageWithBasePath
                                          src="assets/img/students/student-01.jpg"
                                          alt="img"
                                        />
                                      </>
                                    )}
                                  </Link>
                                  <Link
                                    to={routes.studentGrid}
                                    className="d-flex flex-column"
                                  >
                                    <span className="text-dark">
                                      {studentData?.firstName}
                                    </span>
                                    {studentData?.className},{" "}
                                    {studentData?.sectionName}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Payment Date
                                </label>
                                <div className="input-icon position-relative">
                                  <DatePicker
                                    className="form-control datetimepicker"
                                    format="YYYY-MM-DD"
                                    onChange={(date) =>
                                      setFieldValue("paymentDate", date)
                                    }
                                    onBlur={() =>
                                      setFieldTouched("paymentDate", true)
                                    }
                                    value={values.paymentDate}
                                    placeholder="Select Date"
                                  />
                                  <span className="input-icon-addon">
                                    <i className="ti ti-calendar" />
                                  </span>
                                </div>
                                <ErrorMessage
                                  name="paymentDate"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label className="form-label">
                                  Payment Mode
                                </label>
                                <Field
                                  className="select"
                                  component={CommonSelect}
                                  options={paymentModeOptions}
                                  name="paymentMode"
                                />
                                <ErrorMessage
                                  name="paymentMode"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>

                            {values.paymentMode === "ONLINE" && (
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Transaction ID
                                  </label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="transactionId"
                                  />
                                  <ErrorMessage
                                    name="transactionId"
                                    component="div"
                                    className="text-danger"
                                  />
                                </div>
                              </div>
                            )}
                            {values.paymentData.map(
                              (payment, index: number) => (
                                <div
                                  key={index}
                                  className="payment-section border rounded p-3 mb-4"
                                >
                                  <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">
                                      Payment {index + 1}
                                    </h5>
                                    {index > 0 && (
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-danger"
                                        onClick={() => {
                                          const newPayments = [
                                            ...values.paymentData,
                                          ];
                                          newPayments.splice(index, 1);
                                          setFieldValue(
                                            "paymentData",
                                            newPayments
                                          );
                                        }}
                                      >
                                        <i className="ti ti-trash me-1"></i>
                                        Remove
                                      </button>
                                    )}
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Student Fee
                                        </label>
                                        <Field
                                          className="select"
                                          component={CommonSelect}
                                          options={studentFeeOptions}
                                          name={`paymentData.${index}.studentFeeId`}
                                          // value={payment.studentFeeId || null}
                                          onValueSelect={(id: number) => {
                                            const flag = getFilteredFeeOptions(
                                              id,
                                              values
                                            );
                                            if (flag) {
                                              setFieldValue(
                                                `paymentData.${index}.studentFeeId`,
                                                null
                                              );
                                              setFieldValue(
                                                `paymentData.${index}.paymentAmount`,
                                                null
                                              );
                                              return;
                                            }
                                            const selectedRecord =
                                              studentFeeRecords.find(
                                                (record: any) =>
                                                  record.studentFeeId === id
                                              );
                                            const paymentAmount = selectedRecord
                                              ? selectedRecord.netAmount
                                              : 0;
                                            setFieldValue(
                                              `paymentData.${index}.paymentAmount`,
                                              paymentAmount
                                            );
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`paymentData.${index}.studentFeeId`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Payment Amount
                                        </label>
                                        <Field
                                          type="number"
                                          className="form-control"
                                          name={`paymentData.${index}.paymentAmount`}
                                          value={
                                            values.paymentData[index]
                                              .paymentAmount || ""
                                          }
                                          disabled={true}
                                        />
                                        <ErrorMessage
                                          name={`paymentData.${index}.paymentAmount`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}

                            <div className="col-12 mb-3 border-top pt-4">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={() => {
                                  const newPayment = {
                                    studentFeeId: null,
                                    paymentAmount: null,
                                  };
                                  setFieldValue("paymentData", [
                                    ...values.paymentData,
                                    newPayment,
                                  ]);
                                }}
                              >
                                <i className="ti ti-plus me-1"></i>
                                Add Another Payment
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            onClick={() => resetForm()}
                            type="button"
                            id="btn-cancel1"
                            className="btn btn-light me-2"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Pay Fees
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              ) : (
                <div className="modal-body">
                  <p>No fees to collect</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeeCollect;
