import React from "react";
import Select, { MultiValue, SingleValue, ActionMeta } from "react-select";

export type Option = {
  value: string | number;
  label: string;
  [key: string]: any;
};

export interface SelectProps {
  options: Option[];
  defaultValue?: Option | Option[];
  className?: string;
  styles?: any;
  form?: any;
  field?: any;
  disabled?: boolean;
  isMulti?: boolean;
  onValueSelect?: (value: any) => void | null | undefined;
  menuPlacement?: "top" | "bottom" | "auto";
}

const CommonSelect: React.FC<SelectProps> = ({
  options,
  defaultValue,
  className,
  field,
  form,
  disabled = false,
  isMulti = false,
  onValueSelect = null,
  menuPlacement = "bottom",
}) => {
  const { name, value } = field;
  // console.log(name, value);
  const { setFieldValue } = form;

  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      menuPlacement={menuPlacement}
      options={options}
      value={
        isMulti
          ? options.filter(
              (option) => Array.isArray(value) && value.includes(option.value)
            )
          : options.find((option) => option.value === value) || null
      }
      onChange={(selectedoptions: any) => {
        let selectedValues;
        if (isMulti) {
          selectedValues = selectedoptions?.map((o: any) => o.value);
        } else {
          selectedValues = selectedoptions?.value;
          if (onValueSelect) {
            onValueSelect(selectedValues);
          }
        }
        setFieldValue(name, selectedValues);
        return selectedoptions;
      }}
      onBlur={() => form.setFieldTouched(name, true)}
      placeholder="Select"
      isDisabled={disabled}
      isMulti={isMulti}
    />
  );
};

export default CommonSelect;
