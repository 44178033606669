import React from "react";
import { Link } from "react-router-dom";

interface PageHeaderProps {
  title: string;
  breadcrumbs: { label: string; path?: string }[];
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, breadcrumbs }) => {
  return (
    <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
      <div className="my-auto mb-2">
        <h3 className="mb-1">{title}</h3>
        <nav>
          <ol className="breadcrumb mb-0">
            {breadcrumbs.map((breadcrumb, index) => (
              <li
                key={index}
                className={`breadcrumb-item ${
                  !breadcrumb.path ? "active" : ""
                }`}
                aria-current={!breadcrumb.path ? "page" : undefined}
              >
                {breadcrumb.path ? (
                  <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
                ) : (
                  breadcrumb.label
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default PageHeader;
