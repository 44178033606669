import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  FeeDiscountSchoolState,
  FeeDiscountSchool,
} from "./feeDiscountTypeSchoolMappingType";
import {
  fetchFeeDiscountSchoolMapping,
  createFeeDiscountSchoolMapping,
  updateFeeDiscountSchoolMapping,
  deleteFeeDiscountSchoolMapping,
} from "./feeDiscountTypeSchoolMappingApi";
import { errorToast, successToast } from "../../../../common/toast/toast";

const initialState: FeeDiscountSchoolState = {
  feeSchoolMappingData: [],
  globalAcedemicYearId: null,
  fetchLoading: false,
  loading: false,
  error: null,
};

export const fetchFeeDiscountSchoolMappingAsync = createAsyncThunk(
  "feeDiscountTypeSchoolMapping/fetchFeeDiscountSchoolMapping",
  async (schoolId: number) => {
    return await fetchFeeDiscountSchoolMapping(schoolId);
  }
);

export const createFeeDiscountSchoolMappingAsync = createAsyncThunk(
  "feeDiscountTypeSchoolMapping/createFeeDiscountSchoolMapping",
  async (
    {
      schoolId,
      newFeeDiscount,
    }: { schoolId: number; newFeeDiscount: FeeDiscountSchool },
    thunkAPI
  ) => {
    try {
      return await createFeeDiscountSchoolMapping(schoolId, newFeeDiscount);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create class"
      );
    }
  }
);

export const updateFeeDiscountSchoolMappingAsync = createAsyncThunk(
  "feeDiscountTypeSchoolMapping/updateFeeDiscountSchoolMapping",
  async (
    {
      mappingId,
      updatedFeeDiscount,
    }: { mappingId: number; updatedFeeDiscount: FeeDiscountSchool },
    thunkAPI
  ) => {
    try {
      return await updateFeeDiscountSchoolMapping(mappingId, updatedFeeDiscount);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create class"
      );
    }
  }
);

export const deleteFeeDiscountSchoolMappingAsync = createAsyncThunk(
  "feeDiscountTypeSchoolMapping/deleteFeeDiscountSchoolMapping",
  async (mappingId: number, thunkAPI) => {
    try {
      await deleteFeeDiscountSchoolMapping(mappingId);
      return mappingId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete class section"
      );
    }
  }
);

const feeDiscountSchoolSlice = createSlice({
  name: "feeDiscount",
  initialState,
  reducers: {
    setGlobalAcedemicYearId: (state, action) => {
      state.globalAcedemicYearId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFeeDiscountSchoolMappingAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(
        fetchFeeDiscountSchoolMappingAsync.fulfilled,
        (state, action) => {
          state.fetchLoading = false;
          state.feeSchoolMappingData = action.payload?.data;
        }
      )
      .addCase(fetchFeeDiscountSchoolMappingAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = action.error.message || "Failed to fetch academic years";
        errorToast(state.error);
      })
      .addCase(createFeeDiscountSchoolMappingAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createFeeDiscountSchoolMappingAsync.fulfilled,
        (state, action) => {
          state.feeSchoolMappingData.push(action.payload?.data);
          successToast(action.payload?.message);
        }
      )
      .addCase(
        createFeeDiscountSchoolMappingAsync.rejected,
        (state, action) => {
          state.loading = false;
          state.error =
            (action.payload as string) || "Failed to create Fee Discount";
          errorToast(state.error);
        }
      )

      .addCase(updateFeeDiscountSchoolMappingAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        updateFeeDiscountSchoolMappingAsync.rejected,
        (state, action) => {
          state.loading = false;
          state.error =
          (action.payload as string) || "Failed to update Fee Discount";
          console.log(action, "action");
          errorToast(state.error);
        }
      )
      .addCase(
        updateFeeDiscountSchoolMappingAsync.fulfilled,
        (state, action) => {
          console.log(action, "action");
          successToast(action.payload?.message || "Update successful!");
        }
      )
      .addCase(deleteFeeDiscountSchoolMappingAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        deleteFeeDiscountSchoolMappingAsync.rejected,
        (state, action) => {
          state.loading = false;
          state.error =
          (action.payload as string) || "Failed to delete Fee Discount";
          errorToast(state.error);
        }
      )
      .addCase(
        deleteFeeDiscountSchoolMappingAsync.fulfilled,
        (state, action) => {
          state.feeSchoolMappingData = state.feeSchoolMappingData.filter(
            (item) => item.id !== action.payload
          );
          successToast("Fee Discount deleted successfully");
        }
      );
  },
});

export const { setGlobalAcedemicYearId } = feeDiscountSchoolSlice.actions;

export default feeDiscountSchoolSlice.reducer;
