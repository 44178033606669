import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Class, ClassSection, ClassState } from "./classTypes";
import {
  createClass,
  createClassSection,
  deleteClass,
  deleteClassSection,
  fetchClassesOfSchool,
  fetchClassesSectionOfSchool,
  fetchClassSections,
  updateClass,
  updateClassSection,
} from "./classApi";
import { errorToast, successToast } from "../../../../common/toast/toast";

const initialState: ClassState = {
  classData: [],
  classSectionData: [],
  loading: false,
  fetchLoading: false,
  error: null,
};

export const fetchClassesAsync = createAsyncThunk(
  "class/fetchClasses",
  async (
    { schoolId, section }: { schoolId: number; section?: any },
    thunkAPI
  ) => {
    try {
      return await fetchClassesOfSchool(schoolId, section);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch classes"
      );
    }
  }
);

export const fetchClassesSectionsAsync = createAsyncThunk(
  "class/fetchClassesSections",
  async (
    { schoolId, section }: { schoolId: number; section?: any },
    thunkAPI
  ) => {
    try {
      return await fetchClassesSectionOfSchool(schoolId, section);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch class sections"
      );
    }
  }
);

export const fetchClassSectionsAsync = createAsyncThunk(
  "class/fetchClassSections",
  async (
    { classId, schoolId }: { classId: number; schoolId: number },
    thunkAPI
  ) => {
    try {
      return await fetchClassSections(schoolId, classId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch class sections"
      );
    }
  }
);

export const createClassAsync = createAsyncThunk(
  "class/createClass",
  async (
    { schoolId, newClass }: { schoolId: number; newClass: Class },
    thunkAPI
  ) => {
    try {
      return await createClass(schoolId, newClass);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create class"
      );
    }
  }
);

export const updateClassAsync = createAsyncThunk(
  "class/updateClass",
  async (
    {
      classId,
      classData,
      schoolId,
    }: { classId: number; schoolId: number; classData: Class },
    thunkAPI
  ) => {
    try {
      return await updateClass(schoolId, classId, classData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update class"
      );
    }
  }
);

export const deleteClassAsync = createAsyncThunk(
  "class/deleteClass",
  async (
    { schoolId, classId }: { schoolId: number; classId: number },
    thunkAPI
  ) => {
    try {
      await deleteClass(schoolId, classId);
      return classId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete class"
      );
    }
  }
);

export const createClassSectionAsync = createAsyncThunk(
  "class/createClassSection",
  async (
    {
      schoolId,
      newClassSection,
      classId,
    }: {
      schoolId: number;
      newClassSection: ClassSection;
      classId: number;
    },
    thunkAPI
  ) => {
    try {
      return await createClassSection(schoolId, classId, newClassSection);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create class section"
      );
    }
  }
);

export const updateClassSectionAsync = createAsyncThunk(
  "class/updateClassSection",
  async (
    {
      schoolId,
      classSectionId,
      classSectionData,
    }: {
      schoolId: number;
      classSectionId: number;
      classSectionData: ClassSection;
    },
    thunkAPI
  ) => {
    try {
      return await updateClassSection(
        schoolId,
        classSectionId,
        classSectionData
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update class section"
      );
    }
  }
);

export const deleteClassSectionAsync = createAsyncThunk(
  "class/deleteClassSection",
  async (
    { classSectionId, schoolId }: { classSectionId: number; schoolId: number; },
    thunkAPI
  ) => {
    try {
      await deleteClassSection(schoolId, classSectionId);
      return classSectionId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete class section"
      );
    }
  }
);

const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassesAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchClassesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.classData = action.payload?.data;
      })
      .addCase(fetchClassesAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = (action.payload as string) || "Failed to fetch classes";
        errorToast(state.error);
      })
      .addCase(fetchClassesSectionsAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchClassesSectionsAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.classSectionData = action.payload?.data;
      })
      .addCase(fetchClassesSectionsAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch class sections";
        errorToast(state.error);
      })
      .addCase(fetchClassSectionsAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchClassSectionsAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.classSectionData = action.payload?.data;
      })
      .addCase(fetchClassSectionsAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch class sections";
        errorToast(state.error);
      })
      .addCase(createClassAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createClassAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Failed to create class";
        errorToast(state.error);
      })
      .addCase(createClassAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.classData.push(action.payload.data);
        successToast(action.payload.message);
      })
      .addCase(updateClassAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateClassAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Failed to update class";
        errorToast(state.error);
      })
      .addCase(updateClassAsync.fulfilled, (state, action) => {
        const index = state.classData.findIndex(
          (classItem) => classItem.id === action.payload?.data?.id
        );
        if (index >= 0) {
          state.classData[index] = action.payload?.data;
          successToast(action.payload.message);
        }
      })
      .addCase(deleteClassAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteClassAsync.fulfilled, (state, action) => {
        state.classData = state.classData.filter(
          (classItem) => classItem.id !== action.payload
        );
        successToast("Class deleted successfully");
      })
      .addCase(deleteClassAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Failed to delete class";
        errorToast(state.error);
      })
      
      .addCase(createClassSectionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createClassSectionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast(action.payload.message);
      })
      .addCase(createClassSectionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create class section";
        errorToast(state.error);
      })
      .addCase(updateClassSectionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateClassSectionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast(action.payload.message);
      })
      .addCase(updateClassSectionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update class section";
        errorToast(state.error);
      })
      .addCase(deleteClassSectionAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteClassSectionAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast("Class section deleted successfully");
      })
      .addCase(deleteClassSectionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to delete class section";
        errorToast(state.error);
      });
  },
});

export default classSlice.reducer;
