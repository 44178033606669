import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { useAppSelector, useAppDispatch } from "../../../core/data/redux/hooks";
import "bootstrap-daterangepicker/daterangepicker.css";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { all_routes } from "../../router/all_routes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdminDashboardModal from "./adminDashboardModal";
import { fetchDashboardDataAsync } from "../../../core/data/redux/features/statistics/statisticsSlice";
import Preloader from "../../../core/common/loader/preLoader";
import { useActionPermission } from "../../router/useActionPermission";
import { ACTIONS } from "../../../core/data/constants";
// import { currentUserAsync } from "../../../core/data/redux/features/auth/authSlice";

const AdminDashboard = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const { canPerform } = useActionPermission();
  const { dashboardData, fetchLoading, error } = useAppSelector(
    (state) => state.statistics
  );
  const { user } = useAppSelector((state) => state.auth);
  // const {}
  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );
  const [selectedPeriod, setSelectedPeriod] = useState("today");
  const selectedData =
    selectedPeriod === "today"
      ? dashboardData.totalFeesCollected.totalFeesCollectedToday
      : selectedPeriod === "thisMonth"
      ? dashboardData.totalFeesCollected.totalFeesCollectedThisMonth
      : dashboardData.totalFeesCollected.totalFeesCollectedThisYear;

  // const routes = all_routes;
  // const [date, setDate] = useState<Nullable<Date>>(null);

  useEffect(() => {
    if (globalAcademicYearId) {
      dispatch(
        fetchDashboardDataAsync({ academicYearId: globalAcademicYearId })
      );
    }
  }, [dispatch]);

  console.log(dashboardData);
  const [date, setDate] = useState<Nullable<Date>>(null);
  function SampleNextArrow(props: any) {
    const { style, onClick } = props;
    return (
      <div
        className="slick-nav slick-nav-next"
        style={{ ...style, display: "flex", top: "30%", right: "30%" }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-right" style={{ color: "#677788" }}></i>
      </div>
    );
  }

  function SamplePrevArrow(props: any) {
    const { style, onClick } = props;
    return (
      <div
        className="slick-nav slick-nav-prev"
        style={{ ...style, display: "flex", top: "30%", left: "30%" }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-left" style={{ color: "#677788" }}></i>
      </div>
    );
  }
  const settings = {
    dots: false,
    autoplay: false,
    arrows: false,
    slidesToShow: 2,
    margin: 24,
    speed: 500,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const student = {
    dots: false,
    autoplay: false,
    slidesToShow: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const teacher = {
    dots: false,
    autoplay: false,
    slidesToShow: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const [studentDonutChart] = useState<any>({
    chart: {
      height: 218,
      width: 218,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    colors: ["#3D5EE1", "#6FCCD8"],
    series: [3610, 44],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 180,
          },
        },
      },
    ],
  });
  const [teacherDonutChart] = useState<any>({
    chart: {
      height: 218,
      width: 218,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    colors: ["#3D5EE1", "#6FCCD8"],
    series: [346, 54],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 180,
          },
        },
      },
    ],
  });
  const [staffDonutChart] = useState<any>({
    chart: {
      height: 218,
      width: 218,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    colors: ["#3D5EE1", "#6FCCD8"],
    series: [620, 80],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 180,
          },
        },
      },
    ],
  });
  const [classDonutChart] = useState<any>({
    chart: {
      height: 218,
      width: 218,
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    labels: ["Good", "Average", "Below Average"],
    legend: { show: false },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      tickAmount: 3,
      labels: {
        offsetX: -15,
      },
    },
    grid: {
      padding: {
        left: -8,
      },
    },
    colors: ["#3D5EE1", "#EAB300", "#E82646"],
    series: [45, 11, 2],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 180,
          },
        },
      },
    ],
  });
  const [feesBar] = useState<any>({
    chart: {
      height: 275,
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      horizontalAlign: "left",
      position: "top",
      fontSize: "14px",
      labels: {
        colors: "#5D6369",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    colors: ["#3D5EE1", "#E9EDF4"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    grid: {
      padding: {
        left: -8,
      },
    },
    series: [
      {
        name: "Collected Fee",
        data: [30, 40, 38, 40, 38, 30, 35, 38, 40],
      },
      {
        name: "Total Fee",
        data: [45, 50, 48, 50, 48, 40, 40, 50, 55],
      },
    ],
    xaxis: {
      categories: [
        "Q1: 2023",
        "Q1: 2023",
        "Q1: 2023",
        "Q1: 2023",
        "Q1: 2023",
        "uQ1: 2023l",
        "Q1: 2023",
        "Q1: 2023",
        "Q1: 2023",
      ],
    },
    yaxis: {
      tickAmount: 3,
      labels: {
        offsetX: -15,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val + " thousands";
        },
      },
    },
  });
  const [totalEarningArea] = useState<any>({
    chart: {
      height: 90,
      type: "area",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#3D5EE1"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    series: [
      {
        name: "Earnings",
        data: [50, 55, 40, 50, 45, 55, 50],
      },
    ],
  });
  const [totalExpenseArea] = useState<any>({
    chart: {
      height: 90,
      type: "area",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#E82646"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    series: [
      {
        name: "Expense",
        data: [40, 30, 60, 55, 50, 55, 40],
      },
    ],
  });

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <>
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Admin Dashboard</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Admin Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                <div className="mb-2">
                  {canPerform(ACTIONS.ADD_ADMISSION) && (
                    <Link
                      to={routes.addAdmission}
                      className="btn btn-primary d-flex align-items-center me-3"
                    >
                      <i className="ti ti-square-rounded-plus me-2" />
                      Add New Admission
                    </Link>
                  )}
                </div>
                <div className="mb-2">
                  {canPerform(ACTIONS.STUDENT_DETAILS_FEE) && (
                    <Link
                      to={routes.accountsTransactions}
                      className="btn btn-light d-flex align-items-center"
                    >
                      Fees Details
                    </Link>
                  )}
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-md-12">
                {/* <div className="alert-message">
                  <div
                    className="alert alert-success rounded-pill d-flex align-items-center justify-content-between border-success mb-4"
                    role="alert"
                  >
                    <div className="d-flex align-items-center">
                      <span className="me-1 avatar avatar-sm flex-shrink-0">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-27.jpg"
                          alt="Img"
                          className="img-fluid rounded-circle"
                        />
                      </span>
                      <p>
                        Fahed III,C has paid Fees for the{" "}
                        <strong className="mx-1">“Term1”</strong>
                      </p>
                    </div>
                    <button
                      type="button"
                      className="btn-close p-0"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                    >
                      <span>
                        <i className="ti ti-x" />
                      </span>
                    </button>
                  </div>
                </div> */}
                {/* Dashboard Content */}
                <div className="card bg-dark">
                  <div className="overlay-img">
                    <ImageWithBasePath
                      src="assets/img/bg/shape-04.png"
                      alt="img"
                      className="img-fluid shape-01"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-01.png"
                      alt="img"
                      className="img-fluid shape-02"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-02.png"
                      alt="img"
                      className="img-fluid shape-03"
                    />
                    <ImageWithBasePath
                      src="assets/img/bg/shape-03.png"
                      alt="img"
                      className="img-fluid shape-04"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-xl-center justify-content-xl-between flex-xl-row flex-column">
                      <div className="mb-3 mb-xl-0">
                        <div className="d-flex align-items-center flex-wrap mb-2">
                          <h1 className="text-white me-2">
                            Welcome Back, {user && user.firstName}
                          </h1>
                        </div>
                        <p className="text-white">Have a Good day at work</p>
                      </div>
                      <p className="text-white custom-text-white">
                        {/* <i className="ti ti-refresh me-1" /> */}
                        Updated Recently on 15 Jun 2024
                      </p>
                    </div>
                  </div>
                </div>
                {/* /Dashboard Content */}
              </div>
            </div>
            <div className="row">
              {/* Total Students */}

              {/* <div className="row"> */}
              <div className="container col-md-6">
                <div className="card p-3 rounded shadow-sm">
                  <div className="d-flex">
                    <div className="col-md-3 mb-3">
                      <div className="avatar avatar-xl bg-success-transparent me-3 p-2 rounded">
                        <ImageWithBasePath
                          src="assets/img/icons/staff.svg"
                          alt="Student Icon"
                          className="img-fluid mb-1"
                        />
                      </div>
                      <div>
                        <h2 className="counter mt-2">
                          <CountUp
                            end={
                              dashboardData.totalUsers.students +
                              dashboardData.totalUsers.parents +
                              dashboardData.totalUsers.schoolStaff.accountants +
                              dashboardData.totalUsers.schoolStaff
                                .receptionists +
                              dashboardData.totalUsers.schoolStaff.teachers
                            }
                          />
                        </h2>
                        <p className="mb-0">Total Users</p>
                      </div>
                    </div>

                    <div className="row col-9 g-3 mb-3">
                      <div className="col-6">
                        <div className="border rounded d-flex justify-content-between p-2">
                          Students:{" "}
                          <strong>{dashboardData.totalUsers.students}</strong>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="border rounded d-flex justify-content-between p-2">
                          Parents:{" "}
                          <strong>{dashboardData.totalUsers.parents}</strong>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="border rounded d-flex justify-content-between p-2">
                          Accountants:{" "}
                          <strong>
                            {dashboardData.totalUsers.schoolStaff.accountants}
                          </strong>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="border rounded d-flex justify-content-between p-2">
                          Receptionists:{" "}
                          <strong>
                            {dashboardData.totalUsers.schoolStaff.receptionists}
                          </strong>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="border rounded d-flex justify-content-between p-2">
                          Teachers:
                          <strong>
                            {dashboardData.totalUsers.schoolStaff.teachers}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between border-top pt-2">
                    <span>
                      Active:{" "}
                      <strong>
                        {dashboardData.totalUsers.totalActiveUsers}
                      </strong>
                    </span>
                    <span>
                      Inactive:{" "}
                      <strong>
                        {dashboardData.totalUsers.totalInactiveUsers}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>

              <div className="container col-md-6">
                <div className="card p-3 rounded shadow-sm">
                  <div className="d-flex  mb-3">
                    <div className="col-md-4 mb-2">
                      <div className="avatar avatar-xl bg-danger-transparent me-3 p-2 rounded">
                        <ImageWithBasePath
                          src="assets/img/icons/student.svg"
                          alt="Student Icon"
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h2 className="counter mt-2">
                          <CountUp end={dashboardData.students.totalStudents} />
                        </h2>
                        <p className="mb-0">Total Students</p>
                      </div>
                    </div>

                    <div className="row col-8 g-3 mb-3 ">
                      {Object.entries(dashboardData.classWiseStudents).map(
                        ([className, sections]) => (
                          <div key={className} className="col-6">
                            <div className="border rounded p-2 d-flex justify-content-between">
                              {className}:{" "}
                              <strong>
                                {Object.values(sections).reduce(
                                  (sum, count) => sum + parseInt(count),
                                  0
                                )}
                              </strong>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between border-top pt-2">
                    <span>
                      Active:{" "}
                      <strong>
                        {dashboardData.students.totalActiveStudents}
                      </strong>
                    </span>
                    <span>
                      Inactive:{" "}
                      <strong>
                        {dashboardData.students.totalInactiveStudents}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              {/* </div> */}

              <div className="container col-md-6">
                <div className="card p-3 shadow-sm">
                  <h5 className="fw-bold medium mb-3">Admissions</h5>

                  <div className="row mb-3">
                    <div className="col-md-12 mb-2">
                      <p className="mb-0">
                        New Admissions this Year:{" "}
                        <span className="badge bg-primary">
                          {dashboardData.totalNewAdmissions}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-12">
                      <p>
                        New Admissions Pending Approval:{" "}
                        <span className="badge bg-success">
                          {dashboardData.admissionStatusDistribution &&
                            (dashboardData.admissionStatusDistribution.find(
                              (item) => item.admissionStatusId === 1
                            )?.count ??
                              0)}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <Link
                      to={all_routes.admissionList}
                      className="btn btn-primary btn-sm rounded-pill "
                    >
                      Approve Now
                    </Link>
                  </div>
                </div>
              </div>

              {/* <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  {fetchLoading ? (
                    <Preloader />
                  ) : (
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl bg-danger-transparent me-2 p-1">
                          <ImageWithBasePath
                            src="assets/img/icons/student.svg"
                            alt="img"
                          />
                        </div>
                        <div className="overflow-hidden flex-fill">
                          <h2 className="counter">
                            <CountUp end={dashboardData.totalStudents} />
                          </h2>
                          <p>Total Students</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
              {/* <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  {fetchLoading ? (
                    <Preloader />
                  ) : (
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl bg-danger-transparent me-2 p-1">
                          <ImageWithBasePath
                            src="assets/img/icons/teacher.svg"
                            alt="img"
                          />
                        </div>
                        <div className="overflow-hidden flex-fill">
                          <h2 className="counter">
                            <CountUp
                              end={
                                dashboardData.totalUsers.schoolStaff.teachers
                              }
                            />
                          </h2>
                          <p>Total Teacher</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  {fetchLoading ? (
                    <Preloader />
                  ) : (
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl bg-transparent me-2 p-0">
                          <img
                            src="https://img.freepik.com/free-vector/accountant-concept-illustration_114360-5968.jpg"
                            alt="img"
                          />
                        </div>
                        <div className="overflow-hidden flex-fill">
                          <h2 className="counter">
                            <CountUp
                              end={
                                dashboardData.totalUsers.schoolStaff.accountants
                              }
                            />
                          </h2>
                          <p>Total Accountants</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  {fetchLoading ? (
                    <Preloader />
                  ) : (
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl bg-transparent me-2 p-0">
                          <img
                            src="https://img.freepik.com/premium-vector/young-woman-with-headphones-microphone-laptop-working-customer-service-support-call-center-concept_313242-107.jpg?ga=GA1.1.53500688.1741751401&semt=ais_hybrid"
                            alt="img"
                          />
                        </div>
                        <div className="overflow-hidden flex-fill">
                          <h2 className="counter">
                            <CountUp
                              end={
                                dashboardData.totalUsers.schoolStaff
                                  .receptionists
                              }
                            />
                          </h2>
                          <p>Total Receptionists</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  {fetchLoading ? (
                    <Preloader />
                  ) : (
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl bg-transparent me-2 p-0">
                          <img
                            src="https://www.svgrepo.com/show/227577/parents.svg"
                            alt="img"
                          />
                        </div>
                        <div className="overflow-hidden flex-fill">
                          <h2 className="counter">
                            <CountUp end={dashboardData.totalUsers.parents} />
                          </h2>
                          <p>Total Parents</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  {fetchLoading ? (
                    <Preloader />
                  ) : (
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl bg-secondary-transparent me-2 p-1">
                          <ImageWithBasePath
                            src="assets/img/icons/subject.svg"
                            alt="img"
                          />
                        </div>
                        <div className="overflow-hidden flex-fill">
                          <h2 className="counter">
                            <CountUp end={dashboardData.totalClasses} />
                          </h2>
                          <p>Total Classes</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div> */}

              <div className="col-xxl-6 col-sm-6 d-flex">
                {/* Header */}
                <div className="card flex-fill  animate-card border-0 p-3">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="fw-bold medium mb-0">Fees Collection</h5>
                    <div className="d-flex align-items-center">
                      {/* <FaCalendarAlt className="me-2" /> */}
                      <select
                        className="form-select form-select-sm "
                        style={{ border: "none", outline: "none" }}
                        value={selectedPeriod}
                        onChange={(e) => setSelectedPeriod(e.target.value)}
                      >
                        <option value="today">Today</option>
                        <option value="thisMonth">This Month</option>
                        <option value="thisYear">This Year</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 text-center">
                      <h6 className="fw-bold">
                        {Number(selectedData.cash) +
                          Number(selectedData.online)}
                      </h6>
                      <p className="text-muted small mb-0">Total</p>
                    </div>
                    <div className="col-4 text-center">
                      <h6 className="fw-bold">{selectedData.cash}</h6>
                      <p className="text-muted small mb-0">Cash</p>
                    </div>
                    <div className="col-4 text-center">
                      <h6 className="fw-bold">{selectedData.online}</h6>
                      <p className="text-muted small mb-0">Online</p>
                    </div>
                  </div>
                </div>

                {/* Fees Details */}
              </div>

              {/* <div className="row"> */}
              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill  animate-card border-0">
                  <div className="card-body">
                    <p className="mb-2">Total Outstanding Fees</p>
                    <div className="d-flex align-items-end justify-content-between">
                      {fetchLoading ? (
                        <Preloader />
                      ) : (
                        <h4>
                          {"₹ "}
                          {
                            dashboardData.totalOutstandingFees
                              .totalOutstandingFees
                          }
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  <div className="card-body">
                    <p className="mb-2">Total Pending Fees Due</p>
                    <div className="d-flex align-items-end justify-content-between">
                      {fetchLoading ? (
                        <Preloader />
                      ) : (
                        <h4>
                          {"₹ "}
                          {
                            dashboardData.totalOutstandingFees
                              .totalPendingFeesDue
                          }
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-sm-6 d-flex">
                <div className="card flex-fill animate-card border-0">
                  <div className="card-body">
                    <p className="mb-2">Total Pending Fees Within Due</p>
                    <div className="d-flex align-items-end justify-content-between">
                      {fetchLoading ? (
                        <Preloader />
                      ) : (
                        <h4>
                          {"₹ "}
                          {
                            dashboardData.totalOutstandingFees
                              .totalPendingFeesWithinDue
                          }
                        </h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}

              {/* Total Fee Amount */}
            </div>
          </>
        </div>
      </div>
      {/* /Page Wrapper */}
      <AdminDashboardModal />
    </>
  );
};

export default AdminDashboard;
