import { useEffect, useRef, useState } from "react";
// import { classSection as sectionsData } from "../../../core/data/json/class-section";
import Table from "../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../core/common/datePicker";
import {
  activeList,
  classSection,
  classSylabus,
} from "../../../core/common/selectoption/selectoption";
import CommonSelect from "../../../core/common/commonSelect";
import { TableData } from "../../../core/data/interface";
import { Link } from "react-router-dom";
import TooltipOption from "../../../core/common/tooltipOption";
import { all_routes } from "../../router/all_routes";
import ClassSectionFormModal from "./class-section-form-modal";
import DeleteConfirmationModal from "../../../core/common/delete-confirmation-modal";
import {
  deleteClassSectionAsync,
  fetchClassesAsync,
  fetchClassesSectionsAsync,
} from "../../../core/data/redux/features/class/classSlice";
import { useActionPermission } from "../../router/useActionPermission";
import { ACTIONS } from "../../../core/data/constants";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";

const ClassSection = () => {
  // const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  // const handleApplyClick = () => {
  //   if (dropdownMenuRef.current) {
  //     dropdownMenuRef.current.classList.remove("show");
  //   }
  // };
  const [selectedClassSection, setSelectedClassSection] =
    useState<TableData | null>(null);
  const { classSectionData, fetchLoading } = useAppSelector(
    (state) => state.class
  );
  const dispatch = useAppDispatch();
  const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const { canPerform } = useActionPermission();
  useEffect(() => {
    if (schoolId) {
      dispatch(fetchClassesSectionsAsync({ schoolId, section: 1 }));
    }
  }, [schoolId]);
  const route = all_routes;
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   render: (text: string, record: any, index: number) => (
    //     <>
    //       <Link to="#" className="link-primary">
    //         {record.id}
    //       </Link>
    //     </>
    //   ),
    // },

    {
      title: "Class",
      dataIndex: "className",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    {
      title: "Section",
      dataIndex: "sectionName",
      sorter: (a: TableData, b: TableData) =>
        a.sectionName.length - b.sectionName.length,
    },
    // {
    //   title: "No of Student",
    //   dataIndex: "noOfStudents",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.noOfStudents.length - b.noOfStudents.length,
    // },
    // {
    //   title: "No of Subjects",
    //   dataIndex: "noOfSubjects",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.noOfSubjects.length - b.noOfSubjects.length,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (text: string) => (
    //     <>
    //       {text === "Active" ? (
    //         <span className="badge badge-soft-success d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : (
    //         <span className="badge badge-soft-danger d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       )}
    //     </>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <Link
                  to="#"
                  className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical fs-14" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right p-3">
                  {canPerform(ACTIONS.EDIT_SECTION) ? (
                    <li>
                      <div
                        className="dropdown-item rounded-1"
                        onClick={() => {
                          setSelectedClassSection(record);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#add_class_section"
                      >
                        <i className="ti ti-edit-circle me-2" />
                        Edit
                      </div>
                    </li>
                  ) : (
                    <p className="text-danger fs-sm d-flex align-items-center">
                      <i className="ti ti-lock me-2" />Edit : No Access
                    </p>
                  )}
                  {canPerform(ACTIONS.DELETE_SECTION) ? (
                    <li>
                      <div
                        className="dropdown-item rounded-1"
                        onClick={() => {
                          setSelectedClassSection(record);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#delete-modal"
                      >
                        <i className="ti ti-trash-x me-2" />
                        Delete
                      </div>
                    </li>
                  ) : (
                    <p className="text-danger fs-sm d-flex align-items-center">
                      <i className="ti ti-lock me-2" />
                       Delete : No Access
                    </p>
                  )}
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Section List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={route.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Academic </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Sections
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              {/* <TooltipOption /> */}
              <div className="mb-2">
                {canPerform(ACTIONS.ADD_SECTION) && (
                  <div
                    onClick={() => {
                      setSelectedClassSection(null);
                    }}
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_class_section"
                  >
                    <i className="ti ti-square-rounded-plus-filled me-2" />
                    Add Section
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Guardians List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Sections List</h4>
              <div className="d-flex align-items-center flex-wrap">
                {/* <div className="input-icon-start mb-3 me-2 position-relative">
                  <PredefinedDateRanges />
                </div> */}
                {/* <div className="dropdown mb-3 me-2">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                  >
                    <i className="ti ti-filter me-2" />
                    Filter
                  </Link>
                  <div
                    className="dropdown-menu drop-width"
                    ref={dropdownMenuRef}
                  >
                    <form>
                      <div className="d-flex align-items-center border-bottom p-3">
                        <h4>Filter</h4>
                      </div>
                      <div className="p-3 border-bottom pb-0">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <CommonSelect
                                className="select"
                                options={classSylabus}
                                defaultValue={classSylabus[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Section</label>
                              <CommonSelect
                                className="select"
                                options={classSection}
                                defaultValue={classSection[0]}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label className="form-label">Status</label>
                              <CommonSelect
                                className="select"
                                options={activeList}
                                defaultValue={activeList[0]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 d-flex align-items-center justify-content-end">
                        <Link to="#" className="btn btn-light me-3">
                          Reset
                        </Link>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={handleApplyClick}
                        >
                          Apply
                        </Link>
                      </div>
                    </form>
                  </div>
                </div> */}
                {/* <div className="dropdown mb-3">
                  <Link
                    to="#"
                    className="btn btn-outline-light bg-white dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <i className="ti ti-sort-ascending-2 me-2" />
                    Sort by A-Z
                  </Link>
                  <ul className="dropdown-menu p-3">
                    <li>
                      <Link to="#" className="dropdown-item rounded-1 active">
                        Ascending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Descending
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="dropdown-item rounded-1">
                        Recently Added
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Guardians List */}
              <Table
                columns={columns}
                dataSource={classSectionData}
                loading={fetchLoading}
                // Selection={true}
              />
              {/* /Guardians List */}
            </div>
          </div>
          {/* /Guardians List */}
        </div>
      </div>
      ;{/* /Page Wrapper */}
      <>
        {/* Add Edit Sections */}
        <ClassSectionFormModal selectedClassSection={selectedClassSection} />
        {/* /Add EDit Sections */}

        {/* Delete Modal */}
        <DeleteConfirmationModal
          onConfirm={async () => {
            try {
              if (schoolId && selectedClassSection) {
                const result = await dispatch(
                  deleteClassSectionAsync({
                    schoolId: schoolId,
                    classSectionId: selectedClassSection.classSectionId,
                  })
                ).unwrap();

                if (result) {
                  if (schoolId) {
                    let resultData = await dispatch(
                      fetchClassesSectionsAsync({ schoolId, section: 1 })
                    );
                    if (resultData) {
                      const cancelButton = document.getElementById(
                        "delete-cancel"
                      ) as HTMLElement;
                      cancelButton.click();
                      setSelectedClassSection(null);
                    }
                  }
                }
              }
            } catch (error) {
              console.error(error);
            }
          }}
        />
        {/* /Delete Modal */}
        {/* View Sections */}
        {/* <div className="modal fade" id="view_class">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex align-items-center">
                  <h4 className="modal-title">Class Details</h4>
                  <span className="badge badge-soft-success ms-2">
                    <i className="ti ti-circle-filled me-1 fs-5" />
                    Active
                  </span>
                </div>
                <button
                  type="button"
                  className="btn-close custom-btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x" />
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Class Name</p>
                        <span>III</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>Section</p>
                        <span>A</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Subjects</p>
                        <span>05</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="class-detail-info">
                        <p>No of Students</p>
                        <span>25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> */}
        {/* /View ClassSection */}
      </>
    </div>
  );
};

export default ClassSection;
