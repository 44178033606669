import { useEffect, useState } from "react";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import { resetPasswordAsync } from "../../../core/data/redux/features/auth/authSlice";
import { ErrorMessage, Formik } from "formik";
import { all_routes } from "../../router/all_routes";
// import { loginInitialValues } from "./initialValue";
// import { loginValidationSchema } from "./schema";
// import { loginValidationSchema } from "../login/schema";
import { resetInitialValues } from "./initialValue";
import { passwordresetValidationSchema } from "./schema";
import { useLocation } from "react-router-dom";

const Resetpassword = () => {
  const routes = all_routes;
  const dispatch = useAppDispatch();
  const [token, setToken] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  // const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const schoolData = useAppSelector((state) => state.auth.schoolData);

  // const [isPasswordVisible, setPasswordVisible] = useState(false);
  // // const [rememberMe, setRememberMe] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setPasswordVisible((prevState) => !prevState);
  // };

  const date = () => {
    return new Date().getFullYear();
  };

  // if (isAuthenticated === null) {
  //   return null;
  // }

  // if (isAuthenticated) {
  //   return <Navigate to={routes.adminDashboard} />;
  // }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromURL = queryParams.get("token");
    if (tokenFromURL) {
      setToken(tokenFromURL);
    }
  }, [location.search]);

  return (
    <div className="container-fuild">
      <div className="w-100 overflow-hidden position-relative flex-wrap d-block vh-100">
        <div className="row">
          <div className="col-lg-6">
            <div className="login-background d-lg-flex align-items-center justify-content-center d-lg-block d-none flex-wrap vh-100 overflowy-auto">
              <div className="authen-overlay-item p-4">
                <img alt="Profile" src={schoolData?.schoolLogo} />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row justify-content-center align-items-center vh-100 overflow-auto flex-wrap ">
              <div className="col-md-8 mx-auto p-4">
                <Formik
                  initialValues={resetInitialValues}
                  validationSchema={passwordresetValidationSchema}
                  onSubmit={async (values, actions) => {
                    try {
                      const result = await dispatch(
                        resetPasswordAsync({
                          token: token,
                          newPassword: values.newPassword,
                        })
                      ).unwrap();
                      if (result) {
                        // console.log(result.token);
                        navigate(all_routes.login);
                        // if (rememberMe) {
                        //   localStorage.setItem("authToken", result.token);
                        // }
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div>
                        <div className=" mx-auto text-center">
                          <img
                            alt="Profile"
                            className=""
                            style={{ height: "25vh" }}
                            src={schoolData?.schoolLogo}
                          />
                          {/* <ImageWithBasePath
                            src="assets/img/authentication/authentication-logo.svg"
                            className="img-fluid"
                            alt="Logo"
                          /> */}
                        </div>
                        <div className="card">
                          <div className="card-body pb-3">
                            <div className=" mb-4">
                              {/* <h2 className="mb-2">Welcome</h2> */}
                              <p className="mb-0">
                                Please enter your new password
                              </p>
                            </div>
                            <div className="mb-3 ">
                              <label className="form-label">New Password</label>
                              <div className="input-icon mb-3 position-relative">
                                <span className="input-icon-addon">
                                  <i className="ti ti-eye" />
                                </span>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.newPassword}
                                  name="newPassword"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="newPassword"
                                  className="text-danger"
                                  component="div"
                                />
                              </div>
                              {/* <div className="input-icon mb-3 position-relative">
                                <span className="input-icon-addon">
                                  <i className="ti ti-eye"/>
                                </span>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.conformPassword}
                                  name="conformPassword"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="conformPassword"
                                  className="text-danger"
                                  component="div"
                                />
                              </div> */}
                            </div>
                            <div className="mb-3 ">
                              <label className="form-label">
                                Confirm Password
                              </label>

                              <div className="input-icon mb-3 position-relative">
                                <span className="input-icon-addon">
                                  <i className="ti ti-eye" />
                                </span>
                                <input
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.confirmPassword}
                                  name="confirmPassword"
                                  className="form-control"
                                />
                                <ErrorMessage
                                  name="confirmPassword"
                                  className="text-danger"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="p-4 pt-0">
                            <div className="mb-3">
                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                                disabled={isSubmitting}
                              >
                                Reset Password
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 text-center">
                          <p className="mb-0 ">
                            Copyright © {date()} - eSchool-App
                          </p>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
