import { Formik, ErrorMessage, Field } from "formik";
import { admissonFormSendValidationSchema } from "../schema";
import { admissonFormSendInitialValues } from "../initialValues";
import { useEffect, useMemo, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import { sendAdmissonFormAsync } from "../../../../core/data/redux/features/schoolAdmin/schoolAdminSlice";
import CommonSelect from "../../../../core/common/commonSelect";
import PhoneInput from "react-phone-number-input";

const SendAdmissonFormModal = ({ selectedInquiryData }: any) => {
  console.log(selectedInquiryData);
  const dispatch = useAppDispatch();
  const schoolId = useAppSelector((state: any) => state.auth.user?.schoolId);
  const schoolData = useAppSelector((state: any) => state.auth.schoolData);
  const formRef = useRef(null);
  useEffect(() => {
    const modalElement = document.getElementById("add_class");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedInquiryData) {
      return {
        inquiryId: selectedInquiryData.id,
        academicYearId: selectedInquiryData.academicYearId,
        classId: selectedInquiryData.classId,
        validFor: selectedInquiryData.validFor || null,
        phoneNumber: selectedInquiryData.parentData[0].phoneNumber,
        email: selectedInquiryData.parentData[0].email,
      };
    }
    return admissonFormSendInitialValues;
  }, [selectedInquiryData]);

  const classDataOption = schoolData?.classes.map((item: any) => {
    return { value: item.id, label: item.className };
  });
  const acedemicYearDataOption = schoolData?.academicYears.map((item: any) => {
    return { value: item.id, label: item.academicYearDisplayName };
  });

  console.log(selectedInquiryData, schoolId, schoolData);

  return (
    <div className="modal fade" id="send_admisson_form_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedInquiryData ? "Send Admisson Form" : "Add Academic Year"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={admissonFormSendValidationSchema}
            onSubmit={async (values, actions) => {
              console.log(values, "values");
              try {
                if (schoolId) {
                  let result;
                  const { classId, ...restValue } = values;
                  result = await dispatch(
                    sendAdmissonFormAsync({
                      schoolId: schoolId,
                      generatedData: restValue,
                    })
                  ).unwrap();
                  if (result) {
                    document.getElementById("btn-cancel")?.click();
                  }
                }
              } catch (error) {
                console.error("Error creating academic year:", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Academic Year</label>
                            <Field
                              type="text"
                              component={CommonSelect}
                              options={acedemicYearDataOption}
                              name="academicYearId"
                              disabled={selectedInquiryData}
                            />
                            <ErrorMessage
                              name="academicYearId"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Class</label>
                            <Field
                              type="text"
                              component={CommonSelect}
                              options={classDataOption}
                              name="classId"
                              disabled={selectedInquiryData}
                            />
                            <ErrorMessage
                              name="classId"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">
                              Valid For (in days)
                            </label>
                            <Field
                              type="number"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.validFor || ""}
                              name="validFor"
                            />
                            <ErrorMessage
                              name="validFor"
                              className="text-danger"
                              component="div"
                            />
                          </div>
                        </div>

                        <div className="col-xxl-6 col-xl-6 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Phone</label>
                            <PhoneInput
                              placeholder="Enter phone number"
                              className="form-control"
                              name="phoneNumber"
                              value={values.phoneNumber}
                              onChange={(e) => setFieldValue("phoneNumber", e)}
                            />
                            <ErrorMessage
                              name="phoneNumber"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-md-12">
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <Field
                              type="email"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              name="email"
                            />
                            <ErrorMessage
                              name="email"
                              className="text-danger"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <div
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {selectedInquiryData
                      ? "Send Admisson Form"
                      : "Add Academic Year"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default SendAdmissonFormModal;