import * as Yup from "yup";

const commonPhoneSchema = (isMandatory: Boolean, message?: string) =>
  Yup.string()
    .nullable()
    .when([], {
      is: () => isMandatory,
      then: (schema) =>
        schema
          .required(message || "Phone Number is required")
          .phone("*" as any, "Please enter a valid Phone Number"),
      otherwise: (schema) =>
        schema.test({
          name: "phoneValidation",
          skipAbsent: true,
          test(value, ctx) {
            if (!value) return true;
            if (!value || !value.includes(" ")) return true;
            const spaceIndex = value.indexOf(" ");
            let splitValue = value.slice(spaceIndex + 1);
            if (!splitValue) return true;

            const isValid = schema
              .phone("*" as any, "Please enter a valid Phone Number")
              .isValidSync(value);

            if (!isValid) {
              return ctx.createError({
                message: "Please enter a valid Phone Number",
              });
            }

            return true;
          },
        }),
    });

export const createSchoolUserValidationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  middleName: Yup.string().notRequired(),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid Email Format")
    .required("Email is required"),
  phoneNumber: commonPhoneSchema(true, "Phone number is required"),
  genderId: Yup.number().required("Gender is required"),
  dob: Yup.date().notRequired(),
  aadharCardNumber: Yup.string()
    .notRequired()
    .length(12, "Aadhaar Card Number must be exactly 12 digits"),
});
