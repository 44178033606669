export const createFeeInitialValue = {
  academicYearId: null,
  classIds: [],
  feeCategoryId: null,
  feeCategoryFrequencyId: null,
  currencyId: 67,
  feeAmount: null,
  // discountTypeId: null,
  // discountPercentage: null,
  // discountRemarks: "",
  feeDueDate: [],
};
