import { configureStore } from "@reduxjs/toolkit";
import themeSettingSlice from "./themeSettingSlice";
import sidebarSlice from "./sidebarSlice";
import classReducer from "./features/class/classSlice";
import authReducer from "./features/auth/authSlice";
import feeReducer from "./features/fee/feeSlice";
import utilsReducer from "./features/utils/utilsSlice";
import academicYearReducer from "./features/academicYear/academicYearSlice";
import admissionReducer from "./features/admission/admissionSlice";
import feeCategoryReducer from "./features/feeCategory/feeCategorySlice";
import feeDiscountReucer from "./features/feeDiscountTypeSchoolMapping/feeDiscountTypeSchoolMappingSlice";
import statisticsReducer from "./features/statistics/statisticsSlice";
import schoolAdminReducer from "./features/schoolAdmin/schoolAdminSlice";
import announcementReducer from "./features/announcement/announcementSlice";
import inquiryReducer from "./features/inquiry/inquirySlice";

export const store = configureStore({
  reducer: {
    themeSetting: themeSettingSlice,
    sidebarSlice: sidebarSlice,
    class: classReducer,
    auth: authReducer,
    fee: feeReducer,
    utils: utilsReducer,
    academicYear: academicYearReducer,
    admission: admissionReducer,
    feeCategory: feeCategoryReducer,
    feeDiscountmap: feeDiscountReucer,
    statistics: statisticsReducer,
    schoolAdmin: schoolAdminReducer,
    announcement: announcementReducer,
    inquiry: inquiryReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
