import apiClient from "../../../api/apiClient";
import {
  Admission,
  CreateAdmissionRequest,
  UpdateAdmissionRequest,
  CreateMultipleAdmissionRequest,
} from "./admissionTypes";

const BASE_PATH = "/admission";

export const fetchStudentsListbySchool = async (criteria: {
  schoolId: number;
  academicYearId: number;
  classId: number | null;
  sectionId: number | null;
}): Promise<{ data: any; message: string }> => {
  const params = new URLSearchParams();
  params.set("academicYearId", criteria.academicYearId.toString());
  criteria.classId && params.set("classId", criteria.classId.toString());
  criteria.sectionId && params.set("sectionId", criteria.sectionId.toString());

  const response = await apiClient.get(
    `${BASE_PATH}/get-students-by-school/${criteria.schoolId}?${params}`
  );
  return response?.data;
};

export const generateExcelForMultipleAdmission = async (
  schoolId: number,
  academicYearId: number,
  classId: number
): Promise<{ data: any; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/generate-excel-for-creating-multiple-admission/${schoolId}/${academicYearId}/${classId}`
  );
  return response?.data;
};

export const fetchStudentByClassSection = async (
  studentClassSectionId: number,
  academicYearId: number
): Promise<{ data: any; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-student-by-student-class-section/${studentClassSectionId}?academicYearId=${academicYearId}`
  );
  return response?.data;
};

export const fetchParentData = async (
  schoolId: number,
  parentTypeId: number,
  phoneNumber: string
): Promise<{ data: any; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-parent-data/${schoolId}/${parentTypeId}/${phoneNumber}`
  );
  return response?.data;
};

export const createAdmission = async (
  schoolId: number,
  newAdmission: CreateAdmissionRequest
): Promise<{ data: Admission; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/create-admission/${schoolId}`,
    newAdmission
  );
  return response?.data;
};

export const createAdmissionPublic = async (
  schoolId: number,
  newAdmission: CreateAdmissionRequest
): Promise<{ data: Admission; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/create-student-admission/${schoolId}`,
    newAdmission
  );
  return response?.data;
};

export const updateAdmission = async (
  admissionId: number,
  updatedAdmission: UpdateAdmissionRequest
): Promise<{ data: Admission; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-admission/${admissionId}`,
    updatedAdmission
  );
  return response?.data;
};

export const fetchAdmissionsBySchool = async (criteria: {
  schoolId: number;
  classId: number | null;
  academicYearId: number;
}): Promise<{ data: Admission[]; message: string }> => {
  const params = new URLSearchParams({
    academicYearId: criteria.academicYearId.toString(),
  });
  if (criteria.classId) {
    criteria.classId && params.set("classId", criteria.classId.toString());
  }
  const response = await apiClient.get(
    `${BASE_PATH}/get-admissions-by-school/${criteria.schoolId}?${params}`
  );
  return response?.data;
};

export const fetchAdmissionsBySchoolAndSearchParams = async (criteria: {
  schoolId: number;
  searchText: string;
  studentId: number | null | undefined;
}): Promise<{ data: Admission[]; message: string }> => {
  const params = new URLSearchParams({
    searchText: criteria.searchText.toString(),
  });
  if (criteria.studentId) {
    params.set("studentId", criteria.studentId.toString());
  }
  const response = await apiClient.get(
    `${BASE_PATH}/get-admissions-by-school-and-search-params/${criteria.schoolId}?${params}`
  );
  return response?.data;
};

export const fetchAdmissionById = async (
  admissionId: number
): Promise<{ data: Admission; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-admission/${admissionId}`
  );
  return response?.data;
};

export const createMultipleAdmissions = async (
  schoolId: number,
  admissionsData: CreateMultipleAdmissionRequest
): Promise<{ data: any; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/create-multiple-admission/${schoolId}`,
    admissionsData
  );
  return response?.data;
};

export const getRegisterMultipleStudentsBySchoolAndClass = async (
  schoolId: number,
  academicYearName: string,
  className: string
): Promise<{ data: any; message: string }> => {
  const params = new URLSearchParams({
    className: className,
    academicYearName: academicYearName,
  });
  const response = await apiClient.get(
    `${BASE_PATH}/get-register-multiple-students-by-school-and-class/${schoolId}?${params}`
  );
  return response?.data;
};

export const sendImageLink = async (
  id: number,
  studentProfilePicturetype: string
) => {
  const response = await apiClient.post(
    `${BASE_PATH}/send-upload-link/?type=${studentProfilePicturetype}&id=${id}`
  );
  return response?.data;
};

export const updateStudentField = async (
  schoolId: number,
  studentId: number,
  fieldData: { [key: string]: any }
): Promise<{ data: any; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-student-field/${schoolId}/${studentId}`,
    fieldData
  );
  return response?.data;
};
