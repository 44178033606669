import React, { useEffect, useState } from "react";
import { useCollapsibleCard } from "./CollapsibleCardContext";

interface CollapsibleCardProps {
  title: string;
  icon?: React.ReactNode;
  initiallyExpanded?: boolean;
  hasErrors?: boolean;
  children: React.ReactNode;
  id?: string;
}

const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
  title,
  icon,
  initiallyExpanded = false,
  hasErrors = false,
  children,
  id = title, // Use title as fallback id
}) => {
  const { expandedCardId, setExpandedCardId } = useCollapsibleCard();
  const [expanded, setExpanded] = useState(initiallyExpanded);

  // Set initial expansion state
  useEffect(() => {
    if (initiallyExpanded && !expandedCardId) {
      setExpandedCardId(id);
    }
  }, []);

  const isCollapsed = expandedCardId !== id;

  const toggleCollapse = () => {
    setExpanded(!expanded);
    setExpandedCardId(isCollapsed ? id : null);
  };

  return (
    <div className="card mb-3">
      <div
        className={`card-header cursor-pointer ${
          hasErrors ? "bg-danger-subtle" : "bg-light"
        }`}
        onClick={toggleCollapse}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            {icon && <span className="me-2">{icon}</span>}
            <h5 className={`mb-0 ${hasErrors ? "text-danger" : ""}`}>
              {title}
            </h5>
          </div>
          <i
            className={`ti ${
              isCollapsed ? "ti-chevron-down" : "ti-chevron-up"
            }`}
          />
        </div>
      </div>
      <div className={`card-body ${isCollapsed ? "d-none" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default CollapsibleCard;
