import apiClient from "../../../api/apiClient";
import { DashboardData } from "./statisticsTypes";

const BASE_PATH = "statistics";

export const fetchSystemAdminDashboardData = async (
  academicYearId: number
): Promise<{
  data: DashboardData;
  message: string;
}> => {
  const response = await apiClient.get(
    `${BASE_PATH}/school-dashboard/${academicYearId}`
  );
  return response?.data;
};
