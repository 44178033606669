import { useState, useRef } from "react";
import { ErrorMessage, Field, FieldArray } from "formik";
import Preloader from "../../../../../core/common/loader/preLoader";
import CommonSelect from "../../../../../core/common/commonSelect";
import CollapsibleCard from "../../../../../core/common/CollapsibleCard";
import {
  fetchAdmissionsBySchoolAndSearchParamsAsync,
  resetSiblingFilterData,
} from "../../../../../core/data/redux/features/admission/admissionSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../core/data/redux/hooks";

interface SectionProps {
  fetchLoading: boolean;
  hasErrors: boolean;
}

interface SiblingDataProps extends SectionProps {
  values: any;
  studentId?: number | null | undefined;
  classDataOption: any[]; // retained for compatibility if needed
  schoolId: number | undefined; // added prop to use with the redux search thunk
}

interface Student {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  className: string;
  sectionName: string;
}

interface SiblingData {
  siblingId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  className: string;
  sectionName: string;
  relationship: string;
}

const SiblingDataSection: React.FC<SiblingDataProps> = ({
  fetchLoading,
  values,
  hasErrors,
  schoolId,
  studentId,
}) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  // Get search results from the redux state (admissionsData holds search results)
  const searchResults: Student[] = useAppSelector(
    (state: any) => state.admission.siblingFilterData
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchText(text);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (text.length > 2 && schoolId) {
        const queryObject = {
          schoolId,
          searchText: text,
          studentId: studentId || null,
        };
        dispatch(fetchAdmissionsBySchoolAndSearchParamsAsync(queryObject));
      } else {
        dispatch(resetSiblingFilterData());
      }
    }, 300);
  };

  return (
    <CollapsibleCard
      title="Sibling Data"
      icon={<i className="ti ti-user fs-16" />}
      hasErrors={hasErrors}
    >
      {fetchLoading ? (
        <Preloader />
      ) : (
        <FieldArray name="siblings" validateOnChange={true}>
          {(arrayHelpers) => (
            <div>
              <div className="mb-3">
                <label className="form-label">Search Student</label>
                <div className="search-wrapper position-relative">
                  <input
                    type="text"
                    className="form-control"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Type student name to search..."
                  />
                  {searchText.length > 0 && (
                    <button
                      className="btn btn-link position-absolute top-50 end-0 translate-middle-y"
                      onClick={() => {
                        setSearchText("");
                        dispatch(resetSiblingFilterData());
                      }}
                    >
                      <i className="ti ti-x"></i>
                    </button>
                  )}
                </div>
              </div>
              {searchResults && searchResults.length > 0 && (
                <div className="search-results border rounded p-2 mb-3">
                  <div className="list-group">
                    {searchResults.map((student: Student) => (
                      <button
                        key={student.id}
                        type="button"
                        className="list-group-item list-group-item-action"
                        onClick={() => {
                          if (
                            !values.siblings.some(
                              (sibling: SiblingData) =>
                                sibling.siblingId === student.id
                            )
                          ) {
                            const newSibling: SiblingData = {
                              siblingId: student.id,
                              firstName: student.firstName,
                              middleName: student.middleName,
                              lastName: student.lastName,
                              className: student.className,
                              sectionName: student.sectionName,
                              relationship: "",
                            };
                            arrayHelpers.push(newSibling);
                          }
                          setSearchText("");
                          dispatch(resetSiblingFilterData());
                        }}
                      >
                        <div className="d-flex align-items-center">
                          {/* <i className="ti ti-user-circle me-2"></i> */}
                          <div>
                            <div className="fw-medium">
                              {student.firstName} {student.middleName}{" "}
                              {student.lastName}
                            </div>
                            {(student.className || student.sectionName) && (
                              <small className="text-muted ms-2">
                                (
                                {[student.className, student.sectionName]
                                  .filter(Boolean)
                                  .join(" - ")}
                                )
                              </small>
                            )}
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              )}
              {values.siblings && values.siblings.length > 0 && (
                <div className="selected-siblings mt-4">
                  <h6 className="mb-3">Selected Siblings</h6>
                  {values.siblings.map(
                    (sibling: SiblingData, index: number) => (
                      <div
                        key={index}
                        className="selected-sibling border rounded p-3 mb-3 bg-white shadow-sm d-flex justify-content-between align-items-center"
                      >
                        <div className="d-flex align-items-center">
                          <span className="fw-medium">
                            {sibling.firstName} {sibling.middleName}{" "}
                            {sibling.lastName}
                          </span>
                          {(sibling.className || sibling.sectionName) && (
                            <small className="text-muted ms-2">
                              (
                              {[sibling.className, sibling.sectionName]
                                .filter(Boolean)
                                .join(" - ")}
                              )
                            </small>
                          )}
                          <div className="ms-3">
                            <Field
                              name={`siblings.${index}.relationship`}
                              component={CommonSelect}
                              options={[
                                { value: "", label: "Select Relationship" },
                                { value: "Brother", label: "Brother" },
                                { value: "Sister", label: "Sister" },
                              ]}
                              validate={(value: string) => {
                                if (!value) {
                                  return "Relationship is required";
                                }
                              }}
                            />
                            <ErrorMessage
                              name={`siblings.${index}.relationship`}
                              component="div"
                              className="text-danger small mt-1"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                          className="btn btn-outline-danger btn-sm"
                        >
                          <i className="ti ti-trash me-1" />
                          Remove
                        </button>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          )}
        </FieldArray>
      )}
    </CollapsibleCard>
  );
};

export default SiblingDataSection;
