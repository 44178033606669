import React, { useState } from "react";
import { Popover } from "antd";
import PhoneInput from "react-phone-number-input";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { EditableFieldType, EditableFieldError } from "../types";
import "./EditableField.css";

interface EditableFieldProps {
  field: EditableFieldType;
  value: string;
  error?: string;
  onSave: (value: string) => void;
  onCancel: () => void;
  onChange: (value: string) => void;
}

const EditableField: React.FC<EditableFieldProps> = ({
  field,
  value,
  error,
  onSave,
  onCancel,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const handleSave = async () => {
    try {
      await onSave(value);
      setIsOpen(false);
    } catch (error) {
      console.error("Save failed:", error);
    }
  };

  const handleCancel = () => {
    onCancel();
    setIsOpen(false);
  };

  const renderInput = () => {
    switch (field.type) {
      case "textarea":
        return (
          <textarea
            className={`form-control form-control-sm ${
              error ? "is-invalid" : ""
            }`}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            rows={3}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case "email":
        return (
          <input
            type="email"
            className={`form-control form-control-sm ${
              error ? "is-invalid" : ""
            }`}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        );
      case "phone":
        return (
          <PhoneInput
            placeholder="Enter phone number"
            className={`form-control form-control-sm ${
              error ? "is-invalid" : ""
            }`}
            value={value}
            onChange={(value) => onChange(value || "")}
            onClick={(e: React.MouseEvent<HTMLInputElement>) =>
              e.stopPropagation()
            }
          />
        );
      case "date":
        return (
          <DatePicker
            className={`form-control form-control-sm ${
              error ? "is-invalid" : ""
            }`}
            format={{ format: "YYYY-MM-DD", type: "mask" }}
            onChange={(date: dayjs.Dayjs) => {
              const value = date?.format("YYYY-MM-DD") || "";
              onChange(value);
            }}
            value={value ? dayjs(value) : null}
            placeholder="Select Date"
            onClick={(e) => e.stopPropagation()}
          />
        );
      default:
        return (
          <input
            type="text"
            className={`form-control form-control-sm ${
              error ? "is-invalid" : ""
            }`}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        );
    }
  };

  const content = (
    <div
      className="editable-field-content"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="mb-2">
        <label className="form-label small mb-1">{field.label}</label>
        {renderInput()}
        {error && <div className="invalid-feedback d-block">{error}</div>}
      </div>
      <div className="d-flex justify-content-end gap-2">
        <button className="btn btn-sm btn-light" onClick={handleCancel}>
          Cancel
        </button>
        <button
          className="btn btn-sm btn-primary"
          onClick={handleSave}
          disabled={!!error}
        >
          Save
        </button>
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={isOpen}
      onOpenChange={setIsOpen}
      placement="bottom"
      overlayStyle={{ width: "300px" }}
      overlayClassName="editable-field-popover"
      getPopupContainer={() => document.body}
      align={{ offset: [0, 4] }}
    >
      <div
        className="d-flex align-items-center editable-field-wrapper"
        data-field-type={field.type}
      >
        <span className="me-2 text-truncate">
          {field.formatter ? field.formatter(value) : value || "Not Available"}
        </span>
        <button
          className="btn btn-sm btn-link p-0 flex-shrink-0"
          onClick={handleEdit}
        >
          <i className="ti ti-edit" />
        </button>
      </div>
    </Popover>
  );
};

export default EditableField;
