import { Formik, ErrorMessage, Field } from "formik";
import { createFeeDiscountMappingInitialValues } from "../initialValue";
import { createFeeDiscountValidationSchema } from "../schema";
import CommonSelect from "../../../../core/common/commonSelect";
import { useEffect, useMemo, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import {
  updateFeeDiscountSchoolMappingAsync,
  createFeeDiscountSchoolMappingAsync,
  fetchFeeDiscountSchoolMappingAsync,
} from "../../../../core/data/redux/features/feeDiscountTypeSchoolMapping/feeDiscountTypeSchoolMappingSlice";
import { fetchFeeDiscountTypesAsync } from "../../../../core/data/redux/features/utils/utilsSlice";
const FeeDiscountFormModal = ({ selectedFeeDiscount }: any) => {
  console.log(selectedFeeDiscount, "selectedFeeDiscount");
  const dispatch = useAppDispatch();
  const schoolId = useAppSelector((state) => state.auth?.user?.schoolId);
  const feeDiscountTypeData = useAppSelector(
    (state) => state.utils.feeDiscountTypeData
  );
  const formRef = useRef(null);
  useEffect(() => {
    const modalElement = document.getElementById("add_fee_discount_modal");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchFeeDiscountTypesAsync());
  }, [dispatch]);

  const feeDiscountTypeOptions = [
    { value: null, label: "Select Discount Type" },
    ...feeDiscountTypeData.map((item: any) => ({
      value: item.id,
      label: item.feeDiscountName,
    })),
  ];

  const finalInitialValues = useMemo(() => {
    if (selectedFeeDiscount) {
      return {
        discountTypeId: selectedFeeDiscount.discountTypeId,
        maximumAllowedDiscountPercentage:
          selectedFeeDiscount.maximumAllowedDiscountPercentage,
      };
    }
    return createFeeDiscountMappingInitialValues;
  }, [selectedFeeDiscount]);

  return (
    <div className="modal fade" id="add_fee_discount_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedFeeDiscount
                ? "Edit Discount Settings"
                : "Add Discount Settings"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createFeeDiscountValidationSchema}
            onSubmit={async (values, actions) => {
              try {
                if (schoolId) {
                  let result;
                  if (selectedFeeDiscount) {
                    result = await dispatch(
                      updateFeeDiscountSchoolMappingAsync({
                        mappingId: selectedFeeDiscount.id,
                        updatedFeeDiscount: {
                          maximumAllowedDiscountPercentage:
                            values.maximumAllowedDiscountPercentage,
                        },
                      })
                    ).unwrap();
                  } else {
                    result = await dispatch(
                      createFeeDiscountSchoolMappingAsync({
                        schoolId,
                        newFeeDiscount: values,
                      })
                    ).unwrap();
                  }

                  if (result) {
                    document.getElementById("btn-cancel")?.click();
                    await dispatch(
                      fetchFeeDiscountSchoolMappingAsync(Number(schoolId))
                    );
                  }
                }
              } catch (error) {
                console.error("Error creatig Discount Settings :", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Discount Type</label>
                        <Field
                          className="select"
                          component={CommonSelect}
                          options={feeDiscountTypeOptions}
                          disabled={selectedFeeDiscount}
                          name="discountTypeId"
                        />
                      </div>
                      <ErrorMessage
                        name="discountTypeId"
                        className="text-danger"
                        component="div"
                      />
                      <div className="mb-3">
                        <label className="form-label">
                          Discount Percentage
                        </label>
                        <Field
                          type="number"
                          className="form-control"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.maximumAllowedDiscountPercentage || ""}
                          name="maximumAllowedDiscountPercentage"
                        />
                      </div>
                      <ErrorMessage
                        name="maximumAllowedDiscountPercentage"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {selectedFeeDiscount
                      ? "Edit Discount Settings"
                      : "Add Discount Settings"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default FeeDiscountFormModal;
