import React, { useState, useEffect } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { errorToast, successToast } from "../../../core/common/toast/toast";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";

// Constants
const CONSTANTS = {
  BASE_PATH: "utils",
  MAX_FILE_SIZE: 5 * 1024 * 1024, // 5MB
  ALLOWED_FILE_TYPES: ["image/jpeg", "image/png", "application/pdf"] as const,
  IMAGE_DIMENSIONS: {
    width: 150,
    height: 150,
    borderRadius: 20,
  },
} as const;

// Types
interface FormValues {
  studentProfilePicture: string;
  file: File | null;
}

interface FileUploadResponse {
  success: boolean;
  data: {
    uploadedFiles: Array<{
      fileUrl: string;
    }>;
  };
}

// Validation Schema
const validationSchema = Yup.object().shape({
  studentProfilePicture: Yup.string().required("Profile picture is required"),
  file: Yup.mixed()
    .required("File is required")
    .test("fileSize", "File size too large. Maximum size is 5MB", (value) => {
      if (!value) return true;
      return (value as File).size <= CONSTANTS.MAX_FILE_SIZE;
    })
    .test(
      "fileType",
      "Invalid file type. Please upload a JPG, PNG or PDF file",
      (value: any) => {
        if (!value || !(value instanceof File)) return true;
        return CONSTANTS.ALLOWED_FILE_TYPES.includes(
          value.type as (typeof CONSTANTS.ALLOWED_FILE_TYPES)[number]
        );
      }
    ),
});

const PreviewImage: React.FC<{ preview: string | null }> = ({ preview }) => (
  <div className="mb-4 d-flex justify-content-center">
    {preview ? (
      <img
        src={preview}
        alt="Preview"
        className="img-fluid rounded border"
        style={{
          width: CONSTANTS.IMAGE_DIMENSIONS.width,
          height: CONSTANTS.IMAGE_DIMENSIONS.height,
          borderRadius: CONSTANTS.IMAGE_DIMENSIONS.borderRadius,
        }}
      />
    ) : (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: CONSTANTS.IMAGE_DIMENSIONS.width,
          height: CONSTANTS.IMAGE_DIMENSIONS.height,
          borderRadius: CONSTANTS.IMAGE_DIMENSIONS.borderRadius,
          backgroundColor: "#E6E8E9",
        }}
      >
        No Image
      </div>
    )}
  </div>
);

const FileUploadButton: React.FC<{
  setFieldValue: (field: string, value: any) => void;
  errors: any;
  touched: any;
  isLoading: boolean;
  onFileSelect: (file: File) => void;
}> = ({ setFieldValue, errors, touched, isLoading, onFileSelect }) => {
  return (
    <div className="mb-4 d-flex flex-column align-items-center">
      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        accept=".pdf,.png,.jpg,.jpeg"
        onChange={(event) => {
          const file = event.target.files?.[0];
          if (file) {
            setFieldValue("file", file);
            onFileSelect(file);
          }
        }}
      />
      <button
        className="btn btn-primary w-50 mb-2"
        type="button"
        onClick={() => document.getElementById("fileInput")?.click()}
        disabled={isLoading}
      >
        {isLoading ? "Processing..." : "Upload Image"}
      </button>
      {errors.file && touched.file && (
        <div className="text-danger">{errors.file}</div>
      )}
    </div>
  );
};

// Add this new component
const SuccessMessage: React.FC<{ onUploadAgain: () => void }> = ({
  onUploadAgain,
}) => (
  <div className="text-center">
    <h3 className="mb-3 text-success fw-bold">✅ Upload Successful!</h3>
    <p className="text-muted">Thank you for uploading your document.</p>
    <button className="btn btn-primary" onClick={onUploadAgain}>
      Upload Again
    </button>
  </div>
);

const Upload: React.FC = () => {
  const [preview, setPreview] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const token = searchParams.get("token") || "";
  const fileCategory = searchParams.get("fileCategory") || "";

  const initialValues: FormValues = {
    studentProfilePicture: "",
    file: null,
  };

  const handlePreview = (file: File) => {
    if (file.type.startsWith("image/")) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    }
  };

  const handleUpdateFileData = async (attachments: string) => {
    try {
      const host = window.location.hostname;
      const subdomain = host.split(".")[0];

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/${CONSTANTS.BASE_PATH}/update-file-data-by-link?fileCategory=studentProfilePicture`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            withCredentials: "true",
            Uploadtoken: `Bearer ${token}`,
            Subdomain: subdomain,
          },
          body: JSON.stringify({ attachments }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update file data");
      }

      const result = await response.json();
      successToast("File data updated successfully!");
      setUploadSuccess(true);
    } catch (error) {
      errorToast(`Update failed: ${(error as Error).message}`);
    }
  };

  const handleFileUpload = async (
    file: File,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (!file) return;

    setIsLoading(true);
    handlePreview(file);

    const formData = new FormData();
    formData.append("files", file);

    const host = window.location.hostname;
    const subdomain = host.split(".")[0];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/${CONSTANTS.BASE_PATH}/upload-multiple-file?fileCategory=${fileCategory}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Uploadtoken: `Bearer ${token}`,
            Subdomain: subdomain,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Upload failed with status: ${response.status}`);
      }

      const result: FileUploadResponse = await response.json();

      if (result?.success) {
        const fileUrl = result.data.uploadedFiles[0].fileUrl;
        await handleUpdateFileData(fileUrl);
      } else {
        throw new Error("No files uploaded");
      }
    } catch (error) {
      errorToast(
        `Upload failed: ${
          error instanceof Error ? error.message : "Unknown error"
        }`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      setIsLoading(true);
      if (!values.studentProfilePicture) {
        errorToast("No file uploaded!");
        return;
      }
      await handleUpdateFileData(values.studentProfilePicture);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const handleUploadAgain = () => {
    setUploadSuccess(false);
    setPreview(null);
  };

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  return (
    <div className="p-5 bg-light min-vh-100 d-flex flex-column align-items-center justify-content-center">
      <div
        className="p-4 bg-white shadow rounded d-flex flex-column align-items-center justify-content-center rounded"
        style={{ maxWidth: "400px", width: "100%" }}
      >
        {uploadSuccess ? (
          <SuccessMessage onUploadAgain={handleUploadAgain} />
        ) : (
          <>
            <h2 className="mb-4 text-center fs-17">Please Upload Your Image</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                handleSubmit,
                setFieldValue,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} className="w-100">
                  <PreviewImage preview={preview} />
                  <FileUploadButton
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    isLoading={isLoading}
                    onFileSelect={(file) =>
                      handleFileUpload(file, setFieldValue)
                    }
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default Upload;
