import dayjs from "dayjs";
// import { paymentsData } from "../../../../core/data/json/payment-list";
import {
  CollectFeesInitialValue,
  StudentFeePaymentDataInitialValue,
  StudentFeeRecord,
} from "../../../../core/data/redux/features/fee/feeType";

export const createStudentFeeInitialValue = {
  discountTypeId: null,
  discountPercentage: null,
  discountRemarks: "",
};

export const collectFeesInitialValue = (
  data: StudentFeeRecord[]
): CollectFeesInitialValue => {
  const currentDate = new Date();

  const result = Object.values(
    data
      .filter((item) => item.paymentStatusId === 3) // Records with paymentStatusId === 3 means unpaid
      .reduce<Record<number, StudentFeeRecord[]>>((acc, item) => {
        if (!acc[item.feeId]) {
          acc[item.feeId] = [];
        }
        acc[item.feeId].push(item);
        return acc;
      }, {})
  ).map(
    (group) =>
      group.sort(
        (a, b) =>
          Math.abs(new Date(a.startDate).getTime() - currentDate.getTime()) -
          Math.abs(new Date(b.startDate).getTime() - currentDate.getTime())
      )[0] // Pick the record with the start date closest to the current date
  );

  const paymentData: StudentFeePaymentDataInitialValue[] = result.length
    ? result.map((item) => ({
        studentFeeId: item.studentFeeId,
        paymentAmount: parseFloat(item.netAmount),
      }))
    : [
        {
          studentFeeId: null,
          paymentAmount: null,
        },
      ];

  const preparePaymentData = {
    studentClassSectionId: data[0]?.studentClassSectionId,
    academicYearId: data[0]?.academicYearId,
    paymentDate: dayjs(),
    currencyId: data[0]?.currencyId,
    paymentMode: "",
    transactionId: "",
    paymentData,
  };

  console.log("preparePaymentData", preparePaymentData);

  return preparePaymentData;
};
