import { AxiosInstance } from "axios";

// Request interceptor
export const attachRequestInterceptor = (client: AxiosInstance): void => {
  client.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      const host = window.location.hostname;

      const parts = host.split(".")[0];

      if (parts?.split("-")?.length === 1) {
        config.headers.Groupsubdomain = parts?.split("-")[0];
      } else if (parts?.split("-")?.length === 2) {
        config.headers.Subdomain = parts?.split("-")[0];
        config.headers.Groupsubdomain = parts?.split("-")[1];
      }

      // config.headers.Subdomain = "sgs";
      //  config.headers.Groupsubdomain = sgs;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Response interceptor
export const attachResponseInterceptor = (client: AxiosInstance): void => {
  client.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem("authToken");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
};
