import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  Admission,
  AdmissionState,
  CreateAdmissionRequest,
  UpdateAdmissionRequest,
  CreateMultipleAdmissionRequest,
} from "./admissionTypes";
import {
  createAdmission,
  updateAdmission,
  fetchAdmissionsBySchool,
  fetchAdmissionById,
  fetchStudentsListbySchool,
  fetchStudentByClassSection,
  fetchAdmissionsBySchoolAndSearchParams,
  createMultipleAdmissions,
  getRegisterMultipleStudentsBySchoolAndClass,
  sendImageLink,
  updateStudentField,
  createAdmissionPublic,
} from "./admissionApi";
import { errorToast, successToast } from "../../../../common/toast/toast";

const initialState: AdmissionState = {
  admissionData: null,
  studentData: null,
  admissionsData: [],
  siblingFilterData: [],
  multipleAdmissionsData: [],
  uploadLink: [],
  loading: false,
  fetchLoading: false,
  error: null,
};

export const createAdmissionAsync = createAsyncThunk(
  "admission/createAdmission",
  async (
    {
      schoolId,
      newAdmission,
    }: { schoolId: number; newAdmission: CreateAdmissionRequest },
    thunkAPI
  ) => {
    try {
      return await createAdmission(schoolId, newAdmission);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create admission"
      );
    }
  }
);

export const createAdmissionPublicAsync = createAsyncThunk(
  "admission/createAdmissionPublic",
  async (
    {
      schoolId,
      newAdmission,
    }: { schoolId: number; newAdmission: CreateAdmissionRequest },
    thunkAPI
  ) => {
    try {
      return await createAdmissionPublic(schoolId, newAdmission);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create admission"
      );
    }
  }
);

export const updateAdmissionAsync = createAsyncThunk(
  "admission/updateAdmission",
  async (
    {
      admissionId,
      admissionData,
    }: { admissionId: number; admissionData: UpdateAdmissionRequest },
    thunkAPI
  ) => {
    try {
      return await updateAdmission(admissionId, admissionData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update admission"
      );
    }
  }
);

export const fetchAdmissionsBySchoolAsync = createAsyncThunk(
  "admission/fetchAdmissionsBySchool",
  async (
    {
      schoolId,
      academicYearId,
      classId,
    }: { schoolId: number; classId: number; academicYearId: number },
    thunkAPI
  ) => {
    try {
      return await fetchAdmissionsBySchool({
        schoolId,
        academicYearId,
        classId,
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch admissions"
      );
    }
  }
);

export const fetchAdmissionsBySchoolAndSearchParamsAsync = createAsyncThunk(
  "admission/fetchAdmissionsBySchoolAndSearchParams",
  async (
    {
      schoolId,
      searchText,
      studentId,
    }: {
      schoolId: number;
      searchText: string;
      studentId: number | null | undefined;
    },
    thunkAPI
  ) => {
    try {
      return await fetchAdmissionsBySchoolAndSearchParams({
        schoolId,
        searchText,
        studentId,
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch admissions"
      );
    }
  }
);

export const fetchAdmissionByIdAsync = createAsyncThunk(
  "admission/fetchAdmissionById",
  async (admissionId: number, thunkAPI) => {
    try {
      return await fetchAdmissionById(admissionId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch admission"
      );
    }
  }
);

export const fetchStudentsListbySchoolAsync = createAsyncThunk(
  "admission/fetchStudentsListbySchool",
  async (
    {
      schoolId,
      academicYearId,
      classId,
      sectionId,
    }: {
      schoolId: number;
      academicYearId: number;
      classId: number | null;
      sectionId: number | null;
    },
    thunkAPI
  ) => {
    try {
      return await fetchStudentsListbySchool({
        schoolId,
        academicYearId,
        classId,
        sectionId,
      });
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch admission"
      );
    }
  }
);

export const fetchStudentByClassSectionAsync = createAsyncThunk(
  "admission/fetchStudentByClassSection",
  async (
    {
      studentClassSectionId,
      academicYearId,
    }: { studentClassSectionId: number; academicYearId: number },
    thunkAPI
  ) => {
    try {
      return await fetchStudentByClassSection(
        studentClassSectionId,
        academicYearId
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch admission"
      );
    }
  }
);

export const createMultipleAdmissionsAsync = createAsyncThunk(
  "admission/createMultipleAdmissions",
  async (
    {
      schoolId,
      admissionsData,
    }: {
      schoolId: number;
      admissionsData: CreateMultipleAdmissionRequest;
    },
    thunkAPI
  ) => {
    try {
      return await createMultipleAdmissions(schoolId, admissionsData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create multiple admissions"
      );
    }
  }
);

export const getRegisterMultipleStudentsBySchoolAndClassAsync =
  createAsyncThunk(
    "admission/getRegisterMultipleStudentsBySchoolAndClass",
    async (
      {
        schoolId,
        academicYearName,
        className,
      }: {
        schoolId: number;
        academicYearName: string;
        className: string;
      },
      thunkAPI
    ) => {
      try {
        return await getRegisterMultipleStudentsBySchoolAndClass(
          schoolId,
          academicYearName,
          className
        );
      } catch (error: any) {
        return thunkAPI.rejectWithValue(
          error.response?.data?.message || "Failed to fetch registered students"
        );
      }
    }
  );

export const sendImageLinkAsync = createAsyncThunk(
  "admission/sendImageLink",
  async (
    {
      id,
      studentProfilePicturetype,
    }: { id: number; studentProfilePicturetype: string },
    thunkAPI
  ) => {
    try {
      const response = await sendImageLink(id, studentProfilePicturetype);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to Send Link"
      );
    }
  }
);

export const updateStudentFieldAsync = createAsyncThunk(
  "admission/updateStudentField",
  async (
    {
      schoolId,
      studentId,
      fieldData,
    }: {
      schoolId: number;
      studentId: number;
      fieldData: { [key: string]: any };
    },
    thunkAPI
  ) => {
    try {
      return await updateStudentField(schoolId, studentId, fieldData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update student field"
      );
    }
  }
);

const admissionSlice = createSlice({
  name: "admission",
  initialState,
  reducers: {
    resetAdmissionData: (state) => {
      state.admissionData = null;
      state.error = null;
    },
    resetStudentData: (state) => {
      state.studentData = null;
      state.error = null;
    },
    resetSiblingFilterData: (state) => {
      state.siblingFilterData = [];
    },
    resetMultipleAdmissionsData: (state) => {
      state.multipleAdmissionsData = [];
    },
  },
  extraReducers: (builder) => {
    builder

      // Students-List-by-School

      .addCase(fetchStudentsListbySchoolAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchStudentsListbySchoolAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.admissionsData = action.payload.data;
      })
      .addCase(fetchStudentsListbySchoolAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch admissions";
        errorToast(state.error);
      })

      // Students-by-class-section

      .addCase(fetchStudentByClassSectionAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchStudentByClassSectionAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.studentData = action.payload.data;
      })
      .addCase(fetchStudentByClassSectionAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch admissions";
        errorToast(state.error);
      })

      // Create Admission
      .addCase(createAdmissionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAdmissionAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(createAdmissionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create admission";
        errorToast(state.error);
      })

      // Create Admission Public
      .addCase(createAdmissionPublicAsync.pending, (state) => {
        // state.loading = true;
      })
      .addCase(createAdmissionPublicAsync.fulfilled, (state, action) => {
        // state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(createAdmissionPublicAsync.rejected, (state, action) => {
        // state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create admission";
        errorToast(state.error);
      })
      // Update Admission
      .addCase(updateAdmissionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAdmissionAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(updateAdmissionAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update admission";
        errorToast(state.error);
      })
      // Get Admissions by School
      .addCase(fetchAdmissionsBySchoolAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchAdmissionsBySchoolAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.admissionsData = action.payload.data;
      })
      .addCase(fetchAdmissionsBySchoolAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch admissions";
        errorToast(state.error);
      })
      // Get Admissions by School with Search Params
      .addCase(fetchAdmissionsBySchoolAndSearchParamsAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(
        fetchAdmissionsBySchoolAndSearchParamsAsync.fulfilled,
        (state, action) => {
          state.fetchLoading = false;
          state.siblingFilterData = action.payload.data;
        }
      )
      .addCase(
        fetchAdmissionsBySchoolAndSearchParamsAsync.rejected,
        (state, action) => {
          state.fetchLoading = false;
          state.error =
            (action.payload as string) || "Failed to fetch admissions";
          errorToast(state.error);
        }
      )
      // Get Admission by Id
      .addCase(fetchAdmissionByIdAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchAdmissionByIdAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.admissionData = action.payload.data;
      })
      .addCase(fetchAdmissionByIdAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error = (action.payload as string) || "Failed to fetch admission";
        errorToast(state.error);
      })
      // Create Multiple Admissions
      .addCase(createMultipleAdmissionsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMultipleAdmissionsAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(createMultipleAdmissionsAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create multiple admissions";
        errorToast(state.error);
      })
      // Get Register Multiple Students
      .addCase(
        getRegisterMultipleStudentsBySchoolAndClassAsync.pending,
        (state) => {
          state.fetchLoading = true;
        }
      )
      .addCase(
        getRegisterMultipleStudentsBySchoolAndClassAsync.fulfilled,
        (state, action) => {
          state.fetchLoading = false;
          state.multipleAdmissionsData = action.payload.data;
        }
      )
      .addCase(
        getRegisterMultipleStudentsBySchoolAndClassAsync.rejected,
        (state, action) => {
          state.fetchLoading = false;
          state.error =
            (action.payload as string) || "Failed to fetch registered students";
          errorToast(state.error);
        }
      )
      .addCase(sendImageLinkAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendImageLinkAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast("Upload link sent successfully");
      })
      .addCase(sendImageLinkAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update admission";
        errorToast(state.error);
      })
      .addCase(updateStudentFieldAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStudentFieldAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(updateStudentFieldAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to update student field";
        errorToast(state.error);
      });
  },
});

export const {
  resetAdmissionData,
  resetStudentData,
  resetSiblingFilterData,
  resetMultipleAdmissionsData,
} = admissionSlice.actions;
export default admissionSlice.reducer;
