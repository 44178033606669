import { useEffect, useRef } from "react";
import { ErrorMessage, Field } from "formik";
import Preloader from "../../../../../core/common/loader/preLoader";
import CommonSelect from "../../../../../core/common/commonSelect";
import CollapsibleCard from "../../../../../core/common/CollapsibleCard";

interface SectionProps {
  fetchLoading: boolean;
}

interface DeclarationProps extends SectionProps {
  values: any;
  parentTypeDataOptionsForString: any[];
  parentTypeDataOptions: any[];
  hasErrors: boolean;
  setFieldValue: (field: string, value: any) => void;
  isEdit: boolean;
}

const DeclarationSection: React.FC<DeclarationProps> = ({
  fetchLoading,
  values,
  parentTypeDataOptionsForString,
  parentTypeDataOptions,
  setFieldValue,
  isEdit,
  hasErrors,
}) => {
  const parentOptions = values.parentData.map((option: any) => {
    const parentType = parentTypeDataOptions.find(
      (type) => type.value === option.parentTypeId
    );
    return {
      value: `${option.firstName} ${option.lastName} (${parentType?.label})`,
      label: ` ${option.firstName} ${option.lastName} (${parentType?.label})`,
    };
  });

  useEffect(() => {
    if (parentOptions.length > 0 && values.declarative !== parentOptions[0].value) {
      setFieldValue("declarative", parentOptions[0].value);
    }
  }, [values.declarative, parentOptions, setFieldValue]);
  
  const match = values.declaration.match(/^I (.*?) of /);
  const declarativeactual = match ? match[1] : null;
  return (
    <CollapsibleCard
      title="UnderTaking"
      icon={<i className="ti ti-pencil fs-16" />}
      hasErrors={hasErrors}
    >
      {fetchLoading ? (
        <Preloader />
      ) : (
        <div className="declaration-wrapper">
          <p className="mb-3">
            I
            {!declarativeactual ? (
              <span className="d-inline-flex flex-column me-2">
                <Field
                  name="declarative"
                  component={CommonSelect}
                  options={parentOptions}
                  style={{ minWidth: "120px", maxWidth: "150px" }}
                  menuPlacement="top"
                />
                <ErrorMessage
                  name="declarative"
                  component="div"
                  className="text-danger mt-1"
                />
              </span>
            ) : (
              <span> {declarativeactual} </span>
            )}
            {/* <span className="d-inline-flex flex-column me-2" >
              <Field
                name="parentType"
                component={CommonSelect}
                options={parentTypeDataOptionsForString}
                style={{ minWidth: "120px", maxWidth: "150px" }}
                menuPlacement="top"
              />
              <ErrorMessage
                name="parentType"
                component="div"
                className="text-danger mt-1"
              />
            </span> */}
            of{" "}
            <span
              style={{ fontWeight: "bold", color: "black" }}
            >{` ${values.firstName} ${values.lastName} `}</span>
            hereby declare that information given above by me is based on facts
            and authentic records, Admission of my child may be cancelled if any
            information is found to be false.
          </p>
        </div>
      )}
    </CollapsibleCard>
  );
};

export default DeclarationSection;
