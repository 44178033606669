import * as Yup from 'yup';

export const passwordresetValidationSchema = Yup.object({
  newPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .max(20, 'Password must not exceed 20 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one digit')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .required('Password is required'),

    confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Confirm password must match new password')
    .required('Confirm password is required'),
});
