export const ACTIONS = {
  ADD_ADMISSION: "addAdmission",
  ADD_MULTIPLE_ADMISSION: "addMultipleAdmission",
  EDIT_ADMISSION: "editAdmission",
  VIEW_STUDENT: "viewStudent",
  COLLECT_FEE: "collectFee",
  SEND_PROFILE_IMAGE_LINK: "sendProfileImageLink",
  UPLOAD_PROFILE_IMAGE_LINK: "uploadProfileImageLink",
  ADD_CLASS: "addClass",
  EDIT_CLASS: "editClass",
  DELETE_CLASS: "deleteClass",
  ADD_SECTION: "addSection",
  EDIT_SECTION: "editSection",
  DELETE_SECTION: "deleteSection",
  ADD_FEE_CATALOG: "addFeeCatalog",
  EDIT_FEE_CATALOG: "editFeeCatalog",
  DELETE_FEE_CATALOG: "deleteFeeCatalog",
  ADD_FEE_DISCOUNT: "addFeeDiscount",
  EDIT_FEE_DISCOUNT: "editFeeDiscount",
  DELETE_FEE_DISCOUNT: "deleteFeeDiscount",
  EDIT_STUDENT_FEE: "editStudentFee",
  STUDENT_DETAILS_FEE: "studentDetailsFee",
  CREATE_SCHOOL_USER: "createSchoolUser",
  EDIT_SCHOOL_USER: "editSchoolUser",
  DELETE_SCHOOL_USER: "deleteSchoolUser"
};
