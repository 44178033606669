import {
  AcademicYear,
  AdmissionStatus,
  Currency,
  FeeCategory,
  FeeCategoryFrequency,
  FeeDiscountType,
  Gender,
  ParentType,
  PaymentStatus,
  UserRole,
} from "./utilsType";
import apiClient from "../../../api/apiClient";
import { base_path } from "../../../../../environment";

//Base URL

const BASE_PATH = "/utils";

export const fetchAcademicYearsOfSchool = async (
  schoolId: number
): Promise<{ data: AcademicYear[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-academic-year-of-school/${schoolId}`
  );
  return response?.data;
};

export const uploadMultipleFiles = async (
  formData: any,
  fileCategory: string,
  studentId?: number
) => {
  const params = new URLSearchParams({
    fileCategory: fileCategory,
  });
  if (studentId) {
    params.append("studentId", studentId.toString());
  }
  const response = await apiClient.post(
    `${BASE_PATH}/upload-multiple-file?${params}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response?.data;
};

export const updateFileDataByLink = async (
  fileurl: string,
  fileCategory: string,
  studentClassSectionId?: number
) => {
  const params = new URLSearchParams({
    fileCategory: fileCategory,
  });
  if (studentClassSectionId) {
    params.append("id", studentClassSectionId.toString());
  }
  const response = await apiClient.post(
    `${BASE_PATH}/update-file-data-by-link?${params}`,
    { attachments: fileurl }
  );
  return response?.data;
};

export const fetchFeeCategories = async (): Promise<{
  data: FeeCategory[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/get-fee-categories`);
  return response?.data;
};

export const fetchFeeCategoryFrequencies = async (): Promise<{
  data: FeeCategoryFrequency[];
  message: string;
}> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-fee-categories-frequencies`
  );
  return response?.data;
};

export const fetchFeeDiscountTypes = async (): Promise<{
  data: FeeDiscountType[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/get-fee-discount-types`);
  return response?.data;
};

export const fetchPaymentStatuses = async (): Promise<{
  data: PaymentStatus[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/get-payment-status`);
  return response?.data;
};

export const fetchGenders = async (): Promise<{
  data: Gender[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/get-gender-list`);
  return response?.data;
};

export const fetchUserRoles = async (): Promise<{
  data: UserRole[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/get-user-roles`);
  return response?.data;
};

export const fetchAdmissionStatuses = async (): Promise<{
  data: AdmissionStatus[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/get-admission-status`);
  return response?.data;
};

export const fetchParentTypes = async (): Promise<{
  data: ParentType[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/get-parent-type`);
  return response?.data;
};

export const fetchCurrencyList = async (): Promise<{
  data: Currency[];
  message: string;
}> => {
  const response = await apiClient.get(`${BASE_PATH}/get-currency-list`);
  return response?.data;
};

export const validatePublicAdmissionLink = async (
  schoolId: number,
  data: { token: string }
) => {
  const response = await apiClient.post(
    `${BASE_PATH}/validate-public-admission-link/${schoolId}`,
    data
  );
  return response?.data;
};
