import { ErrorMessage, Field } from "formik";
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../../../../../core/common/loader/preLoader";
import CommonSelect from "../../../../../core/common/commonSelect";
import { fetchClassSectionsAsync } from "../../../../../core/data/redux/features/class/classSlice";
import dayjs from "dayjs";
import { useAppSelector } from "../../../../../core/data/redux/hooks";
import { DatePicker } from "antd";
import PhoneInput from "react-phone-number-input";
import UploadButton from "../../../../../core/common/UploadButton";
import CollapsibleCard from "../../../../../core/common/CollapsibleCard";
import { fetchFeesByCriteriaAsync } from "../../../../../core/data/redux/features/fee/feeSlice";
import { all_routes } from "../../../../router/all_routes";

interface SectionProps {
  fetchLoading: boolean;
}

interface PersonalInfoProps extends SectionProps {
  isEdit: boolean;
  academicYearsOption: any[];
  classDataOption: any[];
  genderDataOption: any[];
  classSectionDataOption: any[];
  admissionStatusDataOption: any[];
  schoolId: any;
  globalAcademicYearId: any;
  yesOrNo: any[];
  bloodGroups: any[];
  castCategories: any[];
  handleFileUpload: any;
  dispatch: any;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (field: string, isTouched?: boolean) => void;
  values: any;
  errors: any;
  hasErrors: boolean;
  token: undefined | string | null;
}

const PersonalInformationSection: React.FC<PersonalInfoProps> = ({
  isEdit,
  fetchLoading,
  academicYearsOption,
  classDataOption,
  genderDataOption,
  schoolId,
  globalAcademicYearId,
  yesOrNo,
  bloodGroups,
  castCategories,
  handleFileUpload,
  dispatch,
  setFieldValue,
  setFieldTouched,
  values,
  hasErrors,
  token,
}) => {
  const routes = all_routes;
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { feesData, fetchfeeLoading } = useAppSelector((state) => state.fee);

  useEffect(() => {
    if (values.classId && values.admissionStatusId === 3) {
      console.log(values.classId);
      dispatch(
        fetchFeesByCriteriaAsync({
          schoolId,
          criteria: {
            academicYearId: globalAcademicYearId,
            classId: values.classId,
          },
        })
      );
    }
  }, [values.classId, dispatch, values.admissionStatusId]);

  useEffect(() => {
    if (
      !fetchfeeLoading &&
      values.classId &&
      feesData.length === 0 &&
      values.admissionStatusId === 3
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [feesData, values.classId, fetchfeeLoading, values.admissionStatusId]);

  var selectedClass = classDataOption.find(
    (item: any) => item.value === values.classId
  );

  const handleClose = () => {
    setShowModal(false);
    navigate(routes.feesCatalog);
  };
  console.log(values.classId, feesData, token, "token");
  return (
    <>
      <div>
        <div
          className={`modal ${showModal ? "show d-block" : "d-none"}`}
          onClick={handleClose}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Alert</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                />
              </div>
              <div className="modal-body">
                <div className="alert alert-danger" role="alert">
                  <strong>Action Required:</strong> Please add a fee catalog for
                  the class{" "}
                  <strong>({selectedClass?.label || "Unknown Class"})</strong>{" "}
                  to ensure a successful admission process.
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModal && <div className="modal-backdrop show"></div>}
      </div>
      <CollapsibleCard
        title="Personal Information"
        icon={<i className="ti ti-info-square-rounded fs-16" />}
        initiallyExpanded
        hasErrors={hasErrors}
      >
        {fetchLoading ? (
          <Preloader />
        ) : (
          <div className="row row-cols-xxl-5 row-cols-md-6">
            {/* Academic Year */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Academic Year</label>
                <Field
                  name="academicYearId"
                  component={CommonSelect}
                  options={academicYearsOption}
                  disabled={true}
                />
                <ErrorMessage
                  name="academicYearId"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Class */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Class</label>
                <Field
                  name="classId"
                  component={CommonSelect}
                  options={classDataOption}
                  onValueSelect={(classId: number) => {
                    if (classId && isEdit) {
                      dispatch(
                        fetchClassSectionsAsync({ schoolId: schoolId, classId })
                      );
                      setFieldValue("classSectionId", null);
                    }
                  }}
                />
                <ErrorMessage
                  name="classId"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Section (if editing) */}
            {/* {isEdit && values.admissionStatusId === 3 && (
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Section</label>
                <Field
                  name="classSectionId"
                  component={CommonSelect}
                  options={classSectionDataOption}
                />
                <ErrorMessage
                  name="classSectionId"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
          )} */}
            {/* First Name */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">First Name</label>
                <Field type="text" className="form-control" name="firstName" />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            {/* Middle Name */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Middle Name</label>
                <Field type="text" className="form-control" name="middleName" />
                <ErrorMessage
                  name="middleName"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            {/* Last Name */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Last Name</label>
                <Field type="text" className="form-control" name="lastName" />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            {/* Gender */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Gender</label>
                <Field
                  name="genderId"
                  component={CommonSelect}
                  options={genderDataOption}
                />
                <ErrorMessage
                  name="genderId"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Date of Birth */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Date of Birth</label>
                <div className="input-icon position-relative">
                  <DatePicker
                    className="form-control datetimepicker"
                    format={{ format: "YYYY-MM-DD", type: "mask" }}
                    onChange={(date: dayjs.Dayjs) => setFieldValue("dob", date)}
                    onBlur={() => setFieldTouched("dob", true)}
                    value={values.dob}
                    name="dob"
                    placeholder="Select Date"
                  />
                  <span className="input-icon-addon">
                    <i className="ti ti-calendar" />
                  </span>
                </div>
                <ErrorMessage
                  name="dob"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Phone Number */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Primary Contact Number</label>
                <PhoneInput
                  placeholder="Enter phone number"
                  className="form-control"
                  value={values.phoneNumber}
                  name="phoneNumber"
                  onChange={(value) => setFieldValue("phoneNumber", value)}
                  onBlur={() => setFieldTouched("phoneNumber", true)}
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            {/* Email */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Email</label>
                <Field type="email" className="form-control" name="email" />
                <ErrorMessage
                  name="email"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Interview Date */}
            {!token && (
              <div className="col-xxl col-xl-3 col-md-6">
                <div className="mb-3">
                  <label className="form-label">Interview Date</label>
                  <div className="input-icon position-relative">
                    <DatePicker
                      className="form-control datetimepicker"
                      format={{ format: "YYYY-MM-DD", type: "mask" }}
                      onChange={(date: dayjs.Dayjs) =>
                        setFieldValue("interviewDate", date)
                      }
                      onBlur={() => setFieldTouched("interviewDate", true)}
                      value={values.interviewDate}
                      name="interviewDate"
                      placeholder="Select Date"
                    />
                    <span className="input-icon-addon">
                      <i className="ti ti-calendar" />
                    </span>
                  </div>
                  <ErrorMessage
                    name="interviewDate"
                    className="text-danger"
                    component="div"
                  />
                </div>
              </div>
            )}
            {/* Aadhar Card Number */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Aadhar Card Number</label>
                <Field
                  type="text"
                  className="form-control"
                  name="aadharCardNumber"
                />
                <ErrorMessage
                  name="aadharCardNumber"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* PEN Number */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">PEN Number</label>
                <Field type="text" className="form-control" name="PENNumber" />
                <ErrorMessage
                  name="PENNumber"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Blood Group */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Blood Group</label>
                <Field
                  name="bloodGroup"
                  component={CommonSelect}
                  options={bloodGroups}
                />
                <ErrorMessage
                  name="bloodGroup"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Caste */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Caste</label>
                <Field
                  name="cast"
                  component={CommonSelect}
                  options={castCategories}
                  onValueSelect={(cast: string) => {
                    if (cast === "GENERAL") {
                      setFieldValue("castCertificate", null);
                    }
                  }}
                />
                <ErrorMessage
                  name="cast"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Cast Certificate */}
            {castCategories
              ?.filter((v) => v.value !== "GENERAL")
              ?.map((v) => v.value)
              .includes(values.cast) && (
              <div className="col-xxl col-xl-3 col-md-6">
                <div className="mb-3">
                  <label className="form-label">Caste Certificate</label>
                  <Field
                    name="castCertificate"
                    component={UploadButton}
                    className="form-control"
                    buttonClassName="btn btn-primary btn-sm"
                    onFileUpload={async (file: any) =>
                      handleFileUpload(file, "castCertificate", setFieldValue)
                    }
                    initialFileNames={
                      values.castCertificate ? ["Cast Certificate.pdf"] : []
                    }
                    accept=".pdf,.png,.jpg,.jpeg"
                  />
                  <ErrorMessage
                    name="castCertificate"
                    className="text-danger"
                    component="div"
                  />
                </div>
              </div>
            )}
            {/* Last School Name */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Last School Name</label>
                <Field
                  type="text"
                  className="form-control"
                  name="lastSchoolName"
                />
                <ErrorMessage
                  name="lastSchoolName"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Conveyance Needed */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Is Conveyance Needed?</label>
                <Field
                  name="isConveyanceNeeded"
                  component={CommonSelect}
                  options={yesOrNo}
                />
                <ErrorMessage
                  name="isConveyanceNeeded"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Special Medical Need */}
            <div className="col-xxl col-xl-3 col-md-6">
              <div className="mb-3">
                <label className="form-label">Is Special Medical Need?</label>
                <Field
                  name="isSpecialMedicalNeed"
                  component={CommonSelect}
                  options={yesOrNo}
                />
                <ErrorMessage
                  name="isSpecialMedicalNeed"
                  className="text-danger"
                  component="div"
                />
              </div>
            </div>
            {/* Special Medical Need Details */}
            {values.isSpecialMedicalNeed === "Yes" && (
              <div className="col-xxl col-xl-3 col-md-6">
                <div className="mb-3">
                  <label className="form-label">
                    Special Medical Need Details
                  </label>
                  <Field
                    type="text"
                    className="form-control"
                    name="specialMedicalNeedDetails"
                  />
                  <ErrorMessage
                    name="specialMedicalNeedDetails"
                    className="text-danger"
                    component="div"
                  />
                </div>
              </div>
            )}

            {isEdit && values.admissionStatusId === 3 && (
              <div className="col-xxl col-xl-3 col-md-6">
                <div className="mb-3">
                  <label className="form-label">Admission Date</label>
                  <div className="input-icon position-relative">
                    <DatePicker
                      className="form-control datetimepicker"
                      format={{
                        format: "YYYY-MM-DD",
                        type: "mask",
                      }}
                      onChange={(date: Date) =>
                        setFieldValue("admissionDate", date)
                      }
                      onBlur={() => setFieldTouched("admissionDate", true)}
                      //@ts-ignore
                      value={values.admissionDate}
                      name="admissionDate"
                      placeholder="Select Date"
                      minDate={
                        values.approvalDate
                          ? dayjs(values.approvalDate)
                          : dayjs()
                      }
                    />
                    <span className="input-icon-addon">
                      <i className="ti ti-calendar" />
                    </span>
                  </div>
                  <ErrorMessage
                    name="admissionDate"
                    className="text-danger"
                    component="div"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </CollapsibleCard>
    </>
  );
};

export default PersonalInformationSection;
