import React, { useEffect, useRef, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
// import { Studentlist } from "../../../../core/data/json/studentList";
import { TableData } from "../../../../core/data/interface";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
// import StudentModals from "../studentModals";
import Table from "../../../../core/common/dataTable/index";
// import CommonSelect from "../../../../core/common/commonSelect";
import TooltipOption from "../../../../core/common/tooltipOption";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
// import FeeCollect from "../common/feeCollect";
// import LoginDetails from "../common/loginDetails";
import { ErrorMessage, Field } from "formik";
import CommonSelect from "../../../../core/common/commonSelect";
import { useActionPermission } from "../../../router/useActionPermission";
import { ACTIONS } from "../../../../core/data/constants";
import { fetchInquiryAsync } from "../../../../core/data/redux/features/inquiry/inquirySlice";
import InquiryViewDetailsModal from "../components/inquiry-view-modal";
import SendAdmissonFormModal from "../components/send-admisson-form-modal";

const InquiryList = () => {
  const routes = all_routes;
  const [studentData, setStudentData] = React.useState(null);
  const [admissonFormData, setAdmissonFormData] = React.useState(null);
  const { canPerform } = useActionPermission();
  const [classId, setClassId] = useState<null | number | undefined>(null);
  const dispatch = useAppDispatch();
  const { inquiryData, fetchLoading } = useAppSelector(
    (state) => state.inquiry
  );
  const schoolId = useAppSelector((state) => state.auth?.user?.schoolId);
  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );
  //   const classData = useAppSelector((state) => state.class.classData);

  useEffect(() => {
    if (schoolId && globalAcademicYearId) {
      dispatch(
        fetchInquiryAsync({
          schoolId: schoolId,
          academicYearId: globalAcademicYearId,
        })
      );
    }
  }, [schoolId, globalAcademicYearId]);

  console.log(inquiryData, schoolId, globalAcademicYearId, "inquiryData");

  const columns = [
    // {
    //   title: "Admission No",
    //   dataIndex: "admissionNumber",
    //   render: (text: string) => (
    //     <Link to={routes.studentDetail} className="link-primary">
    //       {text}
    //     </Link>
    //   ),
    //   sorter: (a: TableData, b: TableData) =>
    //     a.admissionNumber.length - b.admissionNumber.length,
    // },
    {
      title: "Name",
      dataIndex: "firstName",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          {/* <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src={record.imgSrc}
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link> */}
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">
                {record.firstName} {record.lastName}
              </Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) =>
        a.firstName.length - b.firstName.length,
    },
    {
      title: "Class Name",
      dataIndex: "className",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    {
      title: "Academic Year",
      dataIndex: "academicYearName",
      sorter: (a: TableData, b: TableData) =>
        a.academicYearName.length - b.academicYearName.length,
    },
    // {
    //   title: "Section",
    //   dataIndex: "section",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.section.length - b.section.length,
    // },
    {
      title: "Gender",
      dataIndex: "genderName",
      sorter: (a: TableData, b: TableData) =>
        a.genderName.length - b.genderName.length,
    },

    // {
    //   title: "Status",
    //   dataIndex: "userStatus",
    //   render: (text: string) => (
    //     <>
    //       {text === "ACTIVE" ? (
    //         <span className="badge badge-soft-success d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : (
    //         <span className="badge badge-soft-danger d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a: TableData, b: TableData) =>
    //     a.userStatus.length - b.userStatus.length,
    // },
    // {
    //   title: "Date of Join",
    //   dataIndex: "DateofJoin",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.DateofJoin.length - b.DateofJoin.length,
    // },
    {
      title: "DOB",
      dataIndex: "dob",
      render: (date: string) => dayjs(date).format("DD MMM YYYY"),
      sorter: (a: TableData, b: TableData) =>
        dayjs(a.dob).unix() - dayjs(b.dob).unix(),
    },

    // {
    //   title: "Admission Status",
    //   dataIndex: "admissionStatusName",
    //   render: (text: string) => (
    //     <>
    //       {text === "Approved" ? (
    //         <span className="badge badge-soft-success d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : text === "Interviewed" || text === "Applied" ? (
    //         <span className="badge badge-soft-warning d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : (
    //         <span className="badge badge-soft-danger d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a: TableData, b: TableData) =>
    //     a.admissionStatusName.length - b.admissionStatusName.length,
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <>
          <div className="d-flex align-items-center gap-2">
            {canPerform(ACTIONS.VIEW_STUDENT) ? (
              <button
                // to={`${routes.studentDetail}/${record.studentClassSectionId}?student=personal`}
                // to={"#"}
                onClick={() => setAdmissonFormData(record)}
                data-bs-toggle="modal"
                data-bs-target="#send_admisson_form_modal"
                className="btn btn-success btn-sm"
              >
                Send Admission Form
              </button>
            ) : (
              <p className="text-danger fs-md d-flex align-items-center">
                <i className="ti ti-lock me-2" /> View : No Access
              </p>
            )}
            <button
              onClick={() => setStudentData(record)}
              data-bs-toggle="modal"
              data-bs-target="#inquiry_view_details"
              className="btn btn-sm btn-primary"
            >
              <i className="ti ti-eye"></i>
            </button>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Inquiry List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">Inquiry</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Inquiry
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />

              <div className="mb-2 d-flex flex-wrap justify-content-center">
                <Link
                  to={routes.addMultipleAdmission}
                  className="btn btn-primary d-flex align-items-center me-2 mb-2"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Multiple Admission
                </Link>
                <Link
                  to={routes.addAdmission}
                  className="btn btn-primary d-flex align-items-center mb-2"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Admission
                </Link>
              </div>
            </div> */}
          </div>
          {/* /Page Header */}
          {/* Students List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Inquiry List</h4>
              <div className="d-flex align-items-center flex-nowrap gap-3"></div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Student List */}
              <Table
                dataSource={inquiryData}
                columns={columns}
                loading={fetchLoading}
              />
              {/* /Student List */}
            </div>
          </div>
          {/* /Students List */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* <StudentModals /> */}
      <InquiryViewDetailsModal selectedInquiry={studentData} />
      <SendAdmissonFormModal selectedInquiryData={admissonFormData} />
      {/* <FeeCollect studentData={studentData} /> */}
      {/* <LoginDetails /> */}
    </>
  );
};

export default InquiryList;
