import { Formik, ErrorMessage, Field } from "formik";
// import { createFeeDiscountMappingInitialValues } from "../initialValue";
import { createSchoolUserInitialValues } from "../initialValues";
import { createSchoolUserValidationSchema } from "../schema";
// import { createFeeDiscountValidationSchema } from "../schema";
import CommonSelect from "../../../../core/common/commonSelect";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { useEffect, useMemo, useRef } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import dayjs from "dayjs";
import {
  createSchoolUsersAsync,
  updateSchoolUsersAsync,
  fetchAllSchoolUsersAsync,
} from "../../../../core/data/redux/features/schoolAdmin/schoolAdminSlice";
import {
  fetchFeeDiscountTypesAsync,
  fetchGendersAsync,
} from "../../../../core/data/redux/features/utils/utilsSlice";
import { DatePicker } from "antd";
import PhoneInput from "react-phone-number-input";
const SchoolUserFormModal = ({ roleData, userRoleId }: any) => {
  console.log(roleData, "selectedFeeDiscount");
  const dispatch = useAppDispatch();
  const schoolId = useAppSelector((state) => state.auth?.user?.schoolId);
  const genderData = useAppSelector((state) => state.utils.genderData);
  const formRef = useRef(null);
  useEffect(() => {
    const modalElement = document.getElementById("add_school_user_modal");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchFeeDiscountTypesAsync());
    dispatch(fetchGendersAsync());
  }, [dispatch]);

  const genderDataOption = useMemo(
    () =>
      genderData.map((item: any) => ({
        value: item.id,
        label: item.genderName,
      })),
    [genderData]
  );

  const finalInitialValues = useMemo(() => {
    if (roleData) {
      return {
        firstName: roleData.firstName,
        middleName: roleData.middleName,
        lastName: roleData.lastName,
        phoneNumber: roleData.phoneNumber,
        email: roleData.email,
        genderId: roleData.genderId,
        dob: roleData.dob,
        aadharCardNumber: roleData.aadharCardNumber,
      };
    }
    return createSchoolUserInitialValues;
  }, [roleData]);

  return (
    <div className="modal fade" id="add_school_user_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {roleData ? "Edit  School Staff" : "Add  School Staff"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createSchoolUserValidationSchema}
            onSubmit={async (values, actions) => {
              try {
                if (schoolId) {
                  let result;
                  if (roleData) {
                    result = await dispatch(
                      updateSchoolUsersAsync({
                        id: roleData.id,
                        schoolId: schoolId,
                        userRoleId: userRoleId,
                        updatedSchoolUser: values,
                      })
                    ).unwrap();
                  } else {
                    result = await dispatch(
                      createSchoolUsersAsync({
                        schoolId: schoolId,
                        userRoleId: userRoleId,
                        newSchoolUser: values,
                      })
                    ).unwrap();
                  }

                  if (result) {
                    document.getElementById("btn-cancel")?.click();
                    await dispatch(
                      fetchAllSchoolUsersAsync({
                        schoolId,
                        userRoleId: Number(userRoleId),
                      })
                    );
                  }
                }
              } catch (error) {
                console.error("Error creatig Fee Discount :", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              values,
              resetForm,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="row">
                    {/* First Name */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">First Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="firstName"
                        />
                        <ErrorMessage
                          name="firstName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    {/* Middle Name */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Middle Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="middleName"
                        />
                        <ErrorMessage
                          name="middleName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    {/* Last Name */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Last Name</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="lastName"
                        />
                        <ErrorMessage
                          name="lastName"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    {/* Email */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <Field
                          type="email"
                          className="form-control"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    {/* Phone Number */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Primary Contact Number
                        </label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          className="form-control"
                          value={values.phoneNumber}
                          name="phoneNumber"
                          onChange={(value: any) =>
                            setFieldValue("phoneNumber", value)
                          }
                          onBlur={() => setFieldTouched("phoneNumber", true)}
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    {/* Gender */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Gender</label>
                        <Field
                          name="genderId"
                          component={CommonSelect}
                          options={genderDataOption}
                        />
                        <ErrorMessage
                          name="genderId"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    {/* Date of Birth */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Date of Birth</label>
                        <div className="input-icon position-relative">
                          <DatePicker
                            className="form-control datetimepicker"
                            format="YYYY-MM-DD"
                            onChange={(date: dayjs.Dayjs | null) =>
                              setFieldValue(
                                "dob",
                                date ? date.format("YYYY-MM-DD") : ""
                              )
                            }
                            onBlur={() => setFieldTouched("dob", true)}
                            value={
                              values.dob
                                ? dayjs(values.dob, "YYYY-MM-DD")
                                : null
                            }
                            name="dob"
                            placeholder="Select Date"
                          />
                          <span className="input-icon-addon">
                            <i className="ti ti-calendar" />
                          </span>
                        </div>
                        <ErrorMessage
                          name="dob"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>

                    {/* Aadhar Card Number */}
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">Aadhar Card Number</label>
                        <Field
                          type="text"
                          className="form-control"
                          name="aadharCardNumber"
                        />
                        <ErrorMessage
                          name="aadharCardNumber"
                          className="text-danger"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <div
                    onClick={() => resetForm()}
                    id="btn-cancel"
                    className="btn btn-light me-2"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {roleData ? "Edit School Staff" : "Add School Staff"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default SchoolUserFormModal;
