import { Navigate, Outlet, Route, Routes, useLocation } from "react-router";
import { authRoutes, publicRoutes } from "./router.link";
import Feature from "../feature";
import AuthFeature from "../authFeature";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../core/data/redux/hooks";
import {
  currentUserAsync,
  fetchSchoolDataAsync,
  fetchSchoolGroupDataAsync,
  logoutAsync,
} from "../../core/data/redux/features/auth/authSlice";
import { all_routes } from "./all_routes";
import { ToastContainer } from "react-toastify";
import { usePagePermission } from "./usePagePermission";
import Error404 from "../pages/error/error-404";
import Preloader from "../../core/common/loader/preLoader";

const routes = all_routes;

const ProtectedRoutes: React.FC = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const currentRef = useRef(true);
  const { hasAccess, currentPath } = usePagePermission();
  const pagePermissions = useAppSelector(
    (state) => state.auth.user?.permissions?.pagePermissions
  );
  const dispatch = useAppDispatch();

  if (isAuthenticated === null) {
    return null;
  }

  if (!isAuthenticated && currentRef.current) {
    currentRef.current = false;
    return <Navigate to={routes.login} replace />;
  }

  if (pagePermissions && isAuthenticated && !hasAccess(currentPath)) {
    if (pagePermissions.length > 0) {
      return <Navigate to="/index" replace />;
    } else {
      dispatch(logoutAsync());
    }
  }

  return <Outlet />;
};

const ALLRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { schoolData, loading, schoolGroupData } = useAppSelector(
    (state) => state.auth
  );

  useEffect(() => {
    const host = window.location.hostname;

    const parts = host.split(".")[0];
    if (schoolData || schoolGroupData) {
      dispatch(currentUserAsync());
    } else {
      if (parts?.split("-")?.length === 1) {
        dispatch(fetchSchoolGroupDataAsync());
      } else if (parts?.split("-")?.length === 2) {
        dispatch(fetchSchoolDataAsync());
      }
      // dispatch(fetchSchoolDataAsync());
      // dispatch(fetchSchoolGroupDataAsync());
    }
  }, [location.pathname, schoolData, schoolGroupData]);

  return (
    <>
      <ToastContainer />
      {schoolData || schoolGroupData ? (
        <Routes>
          <Route element={<ProtectedRoutes />}>
            <Route element={<Feature />}>
              {publicRoutes.map((route, idx) => (
                <Route path={route.path} element={route.element} key={idx} />
              ))}
            </Route>
          </Route>

          <Route element={<AuthFeature />}>
            {authRoutes.map((route, idx) => (
              <Route path={route.path} element={route.element} key={idx} />
            ))}
          </Route>

          <Route path="*" element={<Navigate to={routes.login} replace />} />
        </Routes>
      ) : !loading ? (
        <>
          <Error404 text="This School You Are Looking For Is Not Register !" />
        </>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default ALLRoutes;
