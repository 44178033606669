import * as Yup from "yup";

export const createAnnouncementsValidationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  message: Yup.string().required("Message is required"),
  classIds: Yup.array().of(Yup.number().notRequired()).nullable(),
  sentTo: Yup.array().of(Yup.number().required("Sent To is required")),
  scheduledAt: Yup.date().notRequired(),
  attachments: Yup.array()
    .of(
      Yup.object().shape({
        attachmentName: Yup.string().required("Attachment Name Is Required"),
        attachmentUrl: Yup.string().notRequired(),
      })
    )
    .optional(),
  sentToWhatsapp: Yup.bool().required("Sent To Whatsapp is required"),
  sentToEmail: Yup.bool().required("Sent To Email is required"),
});
