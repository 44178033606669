import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  currentUser,
  fetchSchoolData,
  fetchSchoolGroupData,
  forgotPassword,
  login,
  resetPassword,
} from "./authApi";
import { errorToast, successToast } from "../../../../common/toast/toast";
import { useNavigate } from "react-router-dom";

interface AuthState {
  isAuthenticated: boolean | null;
  user: {
    dob: string;
    email: string;
    firstName: string;
    genderId: number;
    id: number;
    lastName: string;
    phoneNumber: string;
    schoolId: number;
    userUniqueId: string;
    roleName: string;
    userRoleId: string;
    permissions: {
      pagePermissions: {
        id: number;
        pageId: number;
        pageName: string;
        pageUrl: string;
        userRoleId: number;
      }[];
      actionPermissions: {
        id: number;
        actionId: number;
        actionName: string;
        userRoleId: number;
      }[];
    };
  } | null;
  schoolData: {
    id: number;
    academicYears: [
      {
        academicYearDisplayName: string;
        academicYearName: string;
        endDate: string;
        id: number;
        schoolId: number;
        startDate: string;
      }
    ];
    classes: [
      {
        classDisplayName: string;
        className: string;
        id: number;
        schoolId: number;
      }
    ];
    schoolName: string;
    schoolLogo: string;
    schoolDisplayName: string;
    schoolSubDomain: string;
    schoolShortCode: string;
    schoolAddress: string;
  } | null;
  schoolGroupData: {
    id: number;
    schoolGroupName: string;
    schoolGroupDisplayName: string;
    groupSubDomain: string;
    groupLogo: string;
    groupShortCode: string;
  } | null;
  token: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  isAuthenticated: null,
  user: null,
  token: null,
  schoolData: null,
  schoolGroupData: null,
  loading: false,
  error: null,
};

export const fetchSchoolDataAsync = createAsyncThunk(
  "auth/fetchSchoolData",
  async (_, thunkAPI) => {
    try {
      const response = await fetchSchoolData();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch school data"
      );
    }
  }
);

export const fetchSchoolGroupDataAsync = createAsyncThunk(
  "auth/fetchSchoolGroupData",
  async (_, thunkAPI) => {
    try {
      const response = await fetchSchoolGroupData();
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch school data"
      );
    }
  }
);

// Async thunk for login
export const loginAsync = createAsyncThunk(
  "auth/login",
  async (credentials: { userUniqueId: string; password: string }, thunkAPI) => {
    try {
      const response = await login(credentials);
      localStorage.setItem("authToken", response.token);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Login failed"
      );
    }
  }
);

export const currentUserAsync = createAsyncThunk(
  "auth/currentUser",
  async (_, thunkAPI) => {
    try {
      const response = await currentUser();
      return response;
    } catch (error: any) {
      if (error.response?.status === 401 && localStorage.getItem("authToken")) {
        localStorage.removeItem("authToken");
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
      }
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch current user"
      );
    }
  }
);

// Async thunk for logout
export const logoutAsync = createAsyncThunk("auth/logout", async () => {
  // await logout();
  // localStorage.removeItem("authToken");
  // window.location.href = "/login";

  return new Promise((resolve: any) => {
    setTimeout(() => {
      localStorage.removeItem("authToken");
      window.location.href = "/login";
      resolve();
    }, 1000);
  });
});

export const forgotPasswordAsync = createAsyncThunk(
  "auth/forgotPassword",
  async (userUniqueId: string, thunkAPI) => {
    try {
      const response = await forgotPassword(userUniqueId);
      successToast("Password reset link sent to your email.");
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to send password reset link"
      );
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "auth/resetPassword",
  async (
    { token, newPassword }: { token: string; newPassword: string },
    thunkAPI
  ) => {
    try {
      const response = await resetPassword(token, newPassword);
      successToast("Password reset successfully.");
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to reset password"
      );
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Optional for manual login/logout
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        errorToast(action.payload as string);
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(currentUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        currentUserAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.isAuthenticated = true;
          state.user = action.payload.user;
        }
      )
      .addCase(currentUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload as string;
      })
      .addCase(fetchSchoolDataAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSchoolDataAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.schoolData = action.payload?.data;
        }
      )
      .addCase(fetchSchoolDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload as string;
      })
      .addCase(fetchSchoolGroupDataAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSchoolGroupDataAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.schoolGroupData = action.payload?.data;
        }
      )
      .addCase(fetchSchoolGroupDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.error = action.payload as string;
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        successToast("You have been logged out successfully. Goodbye!");
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
      })
      .addCase(forgotPasswordAsync.rejected, (state, action) => {
        errorToast(action.payload as string);
      })

      .addCase(forgotPasswordAsync.fulfilled, (state, action) => {
        errorToast(action.payload.message);
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        errorToast(action.payload as string);
      });
  },
});

export default authSlice.reducer;
