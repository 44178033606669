import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
import { TableData } from "../../../../core/data/interface";
import Table from "../../../../core/common/dataTable/index";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import { useActionPermission } from "../../../router/useActionPermission";
import { ACTIONS } from "../../../../core/data/constants";
import { fetchUserRolesAsync } from "../../../../core/data/redux/features/utils/utilsSlice";
import {
  fetchAllSchoolUsersAsync,
  deleteSchoolUserAsync,
} from "../../../../core/data/redux/features/schoolAdmin/schoolAdminSlice";
import SchoolUserFormModal from "../component/school-user-form-modal";
import DeleteConfirmationModal from "../../../../core/common/delete-confirmation-modal";

const UserList = () => {
  const routes = all_routes;
  // const [roleData, setroleData] = React.useState(null);
  const [roleData, setroleData] = useState<{ id: number } | null>(null);
  const { canPerform } = useActionPermission();
  const [userRoleId, setuserRoleId] = useState(null);
  const dispatch = useAppDispatch();

  const { schoolUsersData, fetchLoading } = useAppSelector(
    (state) => state.schoolAdmin
  );
  const schoolId = useAppSelector((state) => state.auth?.user?.schoolId);

  const { userRoleData } = useAppSelector((state) => state.utils);

  useEffect(() => {
    dispatch(fetchUserRolesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (schoolId && userRoleId) {
      dispatch(
        fetchAllSchoolUsersAsync({ schoolId, userRoleId: Number(userRoleId) })
      );
    }
  }, [schoolId, userRoleId, dispatch]);

  const userRoleDataOption = useMemo(
    () =>
      userRoleData
        .filter((item: any) => [2, 3].includes(item.id))
        .map((item: any) => ({
          value: item.id,
          label: item.roleName,
        })),
    [userRoleData]
  );

  console.log(userRoleDataOption, schoolUsersData, "userRoleDataOption");

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      sorter: (a: TableData, b: TableData) =>
        a.firstName.length - b.firstName.length,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      sorter: (a: TableData, b: TableData) =>
        a.lastName.length - b.lastName.length,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a: TableData, b: TableData) => a.email.length - b.email.length,
    },
    {
      title: "Gender",
      dataIndex: "genderName",
      sorter: (a: TableData, b: TableData) =>
        a.genderName.length - b.genderName.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <>
          <div className="d-flex align-items-center">
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                {canPerform(ACTIONS.EDIT_SCHOOL_USER) ? (
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => setroleData(record)}
                      data-bs-toggle="modal"
                      data-bs-target="#add_school_user_modal"
                    >
                      <i className="ti ti-edit me-2" />
                      Edit
                    </div>
                  </li>
                ) : (
                  <p className="text-danger fs-md d-flex align-items-center">
                    <i className="ti ti-lock me-2" /> Edit : No Access
                  </p>
                )}
                {canPerform(ACTIONS.DELETE_SCHOOL_USER) ? (
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => {
                        setroleData(record);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#delete-modal"
                    >
                      <i className="ti ti-trash-x me-2" />
                      Delete
                    </div>
                  </li>
                ) : (
                  <p className="text-danger fs-md d-flex align-items-center">
                    <i className="ti ti-lock me-2" /> Delete : No Access
                  </p>
                )}
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="d-flex justify-content-center">
          <div className="col-md-3">
            <label className="form-label">1.Please Select Role First</label>
            <select
              name="classId"
              className="form-select mb-3"
              onChange={(e: any) => {
                setuserRoleId(e.target.value);
              }}
              defaultValue=""
            >
              <option value="">Please Select Role</option>
              {userRoleDataOption.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {userRoleId && (
          <div className="">
            <div className="">
              <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
                <div className="my-auto mb-2">
                  <h3 className="page-title mb-1">Staff List</h3>
                  <nav>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to={routes.adminDashboard}>Dashboard</Link>
                      </li>
                      <li className="breadcrumb-item">Staff</li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        All Staff
                      </li>
                    </ol>
                  </nav>
                </div>

                <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                  {/* <TooltipOption /> */}
                  <div className="mb-2">
                    {canPerform(ACTIONS.CREATE_SCHOOL_USER) && (
                      <div
                        onClick={() => {
                          setroleData(null);
                        }}
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#add_school_user_modal"
                      >
                        <i className="ti ti-square-rounded-plus-filled me-2" />
                        Add Staff
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                  <h4 className="mb-3">Staff List</h4>
                  <div className="d-flex align-items-center flex-nowrap gap-3"></div>
                </div>
                <div className="card-body p-0 py-3">
                  <Table
                    dataSource={schoolUsersData}
                    columns={columns}
                    loading={fetchLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <SchoolUserFormModal roleData={roleData} userRoleId={userRoleId} />
        {/* Delete Modal */}
        <DeleteConfirmationModal
          onConfirm={async () => {
            if (roleData && schoolId) {
              const result = await dispatch(
                deleteSchoolUserAsync({
                  schoolId: Number(schoolId),
                  userRoleId: Number(userRoleId),
                  id: roleData.id,
                })
              );
              if (result) {
                const cancelButton = document.getElementById(
                  "delete-cancel"
                ) as HTMLElement;
                cancelButton.click();
                setroleData(null);
                dispatch(
                  fetchAllSchoolUsersAsync({
                    schoolId,
                    userRoleId: Number(userRoleId),
                  })
                );
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default UserList;
