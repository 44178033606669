import { Link } from "react-router-dom";

const InquiryViewDetailsModal = ({ selectedInquiry }: any) => {
  return (
    <div className="modal fade" id="inquiry_view_details">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header  p-3">
            <h4 className="modal-title h5 mb-0">Inquiry Details</h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>

          <div className="modal-body p-3">
            <div className="row g-3">
              {/* Student Column */}
              <div className="col-md-6">
                <div className="card border-0 shadow-sm h-100">
                  <div className="card-body">
                    <h5 className="card-title text-muted mb-3">
                      Student Information
                    </h5>
                    <dl className="row mb-0">
                      <dt className="col-sm-5 text-muted">Full Name</dt>
                      <dd className="col-sm-7">
                        {`${selectedInquiry?.firstName} ${
                          selectedInquiry?.middleName || ""
                        } ${selectedInquiry?.lastName}`.trim()}
                      </dd>
                      <dt className="col-sm-5 text-muted">Class</dt>
                      <dd className="col-sm-7">
                        {selectedInquiry?.classDisplayName}
                      </dd>
                      <dt className="col-sm-5 text-muted">Academic Year</dt>
                      <dd className="col-sm-7">
                        {selectedInquiry?.academicYearDisplayName}
                      </dd>

                      <dt className="col-sm-5 text-muted">Date of Birth</dt>
                      <dd className="col-sm-7">
                        {selectedInquiry?.dateOfBirth}
                      </dd>

                      <dt className="col-sm-5 text-muted">Gender</dt>
                      <dd className="col-sm-7">
                        {selectedInquiry?.genderDisplayName}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                {" "}
                <div className="card border-0 shadow-sm h-100">
                  <div className="card-body">
                    <h5 className="card-title  text-muted mb-3">
                      Parent Information
                    </h5>
                    {selectedInquiry?.parentData?.[0] ? (
                      <div className="bg-light p-3 rounded">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <span className="badge bg-primary">
                            {
                              selectedInquiry.parentData[0]
                                .parentTypeDisplayName
                            }
                          </span>
                        </div>
                        <dl className="row mb-0">
                          <div className="col-12">
                            <dt className="text-muted small">Name</dt>
                            <dd className="mb-2">
                              {`${selectedInquiry.parentData[0].firstName} ${selectedInquiry.parentData[0].lastName}`}
                            </dd>
                          </div>

                          <div className="col-12">
                            <dt className="text-muted small">Contact</dt>
                            <dd className="mb-2">
                              {selectedInquiry.parentData[0].phoneNumber || "-"}
                            </dd>
                          </div>

                          <div className="col-12">
                            <dt className="text-muted small">Email</dt>
                            <dd className="mb-2">
                              {selectedInquiry.parentData[0].email || "-"}
                            </dd>
                          </div>
                        </dl>
                      </div>
                    ) : (
                      <div className="text-muted">
                        No parent information available
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* School Column */}
              <div className="col-md-6">
                <div className="card border-0 shadow-sm h-100">
                  <div className="card-body">
                    <h5 className="card-title text-muted mb-3">
                      Previous School Details
                    </h5>
                    {selectedInquiry?.previousSchoolDetails && (
                      <dl className="row mb-0">
                        <dt className="col-sm-5 text-muted">Previous School</dt>
                        <dd className="col-sm-7">
                          {selectedInquiry.previousSchoolDetails
                            .previousSchoolName || "-"}
                        </dd>

                        <dt className="col-sm-5 text-muted">Last Class</dt>
                        <dd className="col-sm-7">
                          {selectedInquiry.previousSchoolDetails
                            .lastClassAttended || "-"}
                        </dd>

                        <dt className="col-sm-5 text-muted">Education Board</dt>
                        <dd className="col-sm-7">
                          {selectedInquiry.previousSchoolDetails
                            .boardOfEducation || "-"}
                        </dd>
                      </dl>
                    )}
                    {!selectedInquiry?.previousSchoolDetails && (
                      <div className="text-muted">
                        No previous school information
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Communication Column */}
              <div className="col-md-6">
                <div className="card border-0 shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title  text-muted mb-3">
                      Communication
                    </h5>
                    <div className="d-flex gap-3 mb-3">
                      <div
                        className={`badge ${
                          selectedInquiry?.preferredCommunicationMode?.call
                            ? "bg-success"
                            : "bg-secondary"
                        }`}
                      >
                        Call
                      </div>
                      <div
                        className={`badge ${
                          selectedInquiry?.preferredCommunicationMode?.email
                            ? "bg-success"
                            : "bg-secondary"
                        }`}
                      >
                        Email
                      </div>
                      <div
                        className={`badge ${
                          selectedInquiry?.preferredCommunicationMode?.whatsapp
                            ? "bg-success"
                            : "bg-secondary"
                        }`}
                      >
                        WhatsApp
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <span className="text-muted">School Visit:</span>
                      <span
                        className={`badge ${
                          selectedInquiry?.interestedInSchoolVisit
                            ? "bg-success"
                            : "bg-secondary"
                        }`}
                      >
                        {selectedInquiry?.interestedInSchoolVisit
                          ? "Yes"
                          : "No"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Reasons Column */}
              <div className="col-md-6">
                <div className="card border-0 shadow-sm h-100">
                  <div className="card-body">
                    <h5 className="card-title  text-muted mb-3">
                      Enrollment Reasons
                    </h5>
                    <ul className="list-unstyled mb-0">
                      {Object.entries(
                        selectedInquiry?.reasonForEnrollment || {}
                      )
                        .filter(([key, value]) => value && key !== "other")
                        .map(([key]) => (
                          <li
                            key={key}
                            className="d-flex align-items-center gap-2 mb-2"
                          >
                            <i className="ti ti-check text-success" />
                            {key.replace(/([A-Z])/g, " $1").trim()}
                          </li>
                        ))}
                      {selectedInquiry?.reasonForEnrollment?.other && (
                        <li className="d-flex align-items-center gap-2">
                          <i className="ti ti-check text-success" />
                          Other: {selectedInquiry.reasonForEnrollment.other}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              {/* Comments Column */}
              <div className="col-12">
                <div className="card border-0 shadow-sm">
                  <div className="card-body">
                    <h5 className="card-title text-muted mb-3">
                      Additional Comments
                    </h5>
                    <div className="text-muted fst-italic">
                      {selectedInquiry?.additionalComments ||
                        "No additional comments provided"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InquiryViewDetailsModal;
