import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
import dayjs from "dayjs";
import Preloader from "../../../../core/common/loader/preLoader";
import { useActionPermission } from "../../../router/useActionPermission";
import { ACTIONS } from "../../../../core/data/constants";
import PhoneInput from "react-phone-number-input";
import { DatePicker } from "antd";
import {
  sendImageLinkAsync,
  fetchStudentByClassSectionAsync,
  updateStudentFieldAsync,
} from "../../../../core/data/redux/features/admission/admissionSlice";
import {
  uploadMultipleFilesAsync,
  updateFileDataByLinkAsync,
} from "../../../../core/data/redux/features/utils/utilsSlice";
import {
  commonEmailSchema,
  commonPhoneSchema,
} from "../../admissions/add-admission/schema";
import { EditableFieldType, EditableFieldError } from "./types";
import EditableField from "./components/EditableField";

const SIDEBAR_EDITABLE_FIELDS: EditableFieldType[] = [
  {
    key: "email",
    label: "Email",
    type: "email",
    validate: async (value) => {
      try {
        await commonEmailSchema(true).validateSync(value);
        return null;
      } catch (error: any) {
        return error.message;
      }
    },
  },
  {
    key: "phoneNumber",
    label: "Phone Number",
    type: "phone",
    validate: async (value) => {
      try {
        await commonPhoneSchema(true).validateSync(value);
        return null;
      } catch (error: any) {
        return error.message;
      }
    },
  },
  {
    key: "dob",
    label: "Date of Birth",
    type: "date",
    validate: async (value) => {
      if (!value) return "Date of birth is required";
      if (!dayjs(value).isValid()) return "Please enter a valid date";
      if (dayjs(value).isAfter(dayjs()))
        return "Date of birth cannot be in the future";
      return null;
    },
    formatter: (value) => (value ? dayjs(value).format("DD MMM YYYY") : ""),
  },
];

const StudentSidebar = ({
  studentData,
  loading,
  setRefresh,
}: {
  studentData?: any;
  loading?: boolean;
  setRefresh?: (value: boolean | ((prev: boolean) => boolean)) => void;
}) => {
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  console.log(studentData, "studentData");
  const { canPerform } = useActionPermission();
  const [fileurl, setFileUrl] = useState(null);
  const [editedValues, setEditedValues] = useState<Record<string, string>>({});
  const [studentPhoneError, setStudentPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [dobError, setDobError] = useState<string>("");
  const [errors, setErrors] = useState<EditableFieldError>({});

  const handleSendImageLink = () => {
    const id = studentData.studentClassSectionId;
    const studentProfilePicturetype = "studentProfilePicture";
    dispatch(sendImageLinkAsync({ id, studentProfilePicturetype }));
  };
  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );
  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      console.log(file);
      const formData = new FormData();
      formData.append("files", file);

      const response = await dispatch(
        uploadMultipleFilesAsync({
          formData,
          fileCategory: "studentProfilePicture",
          studentId: studentData.studentId,
        })
      );

      const uploadedFileUrl = response?.payload?.uploadedFiles[0]?.fileUrl;
      setFileUrl(uploadedFileUrl);
      console.log(uploadedFileUrl, "uploadedFileUrl");

      if (uploadedFileUrl) {
        console.log(uploadedFileUrl);
        try {
          const result = await dispatch(
            updateFileDataByLinkAsync({
              fileurl: uploadedFileUrl,
              fileCategory: "studentProfilePicture",
              studentClassSectionId: studentData.studentClassSectionId,
            })
          );
          if (globalAcademicYearId && result) {
            await dispatch(
              fetchStudentByClassSectionAsync({
                studentClassSectionId: studentData.studentClassSectionId,
                academicYearId: globalAcademicYearId,
              })
            ).unwrap();
          }
        } catch (error) {
          console.error("Error updating file:", error);
        }
      }
    }
  };

  const handleFieldChange = async (field: string, value: string) => {
    setEditedValues((prev) => ({
      ...prev,
      [field]: value,
    }));
    await validateField(field, value);
  };

  const handleFieldSave = async (field: string) => {
    try {
      const error = await validateField(field, editedValues[field] || "");
      if (error) {
        setErrors({ [field]: error });
        return;
      }

      console.log(`Saving ${field}:`, editedValues[field]);

      await dispatch(
        updateStudentFieldAsync({
          schoolId: studentData.schoolId,
          studentId: studentData.studentId,
          fieldData: {
            field: field,
            value: editedValues[field],
          },
        })
      ).unwrap();
      setErrors({});
      // Clear the edited value after successful save
      setEditedValues((prev) => {
        const newValues = { ...prev };
        delete newValues[field];
        return newValues;
      });
      if (setRefresh) setRefresh((prevState: boolean) => !prevState);
    } catch (error: any) {
      setErrors({ [field]: error.message });
      throw error;
    }
  };

  const handleFieldCancel = (field: string) => {
    setEditedValues((prev) => {
      const newValues = { ...prev };
      delete newValues[field];
      return newValues;
    });
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[field];
      return newErrors;
    });
  };

  const validateField = async (field: string, value: string) => {
    const fieldConfig = SIDEBAR_EDITABLE_FIELDS.find((f) => f.key === field);
    if (!fieldConfig) return null;

    const error = await fieldConfig.validate(value);
    setErrors((prev) => ({
      ...prev,
      [field]: error || "",
    }));
    return error;
  };

  const renderField = (field: EditableFieldType) => {
    if (!studentData) return null;

    return (
      <EditableField
        field={field}
        value={editedValues[field.key] || studentData[field.key] || ""}
        error={errors[field.key]}
        onSave={() => handleFieldSave(field.key)}
        onCancel={() => handleFieldCancel(field.key)}
        onChange={(value) => handleFieldChange(field.key, value)}
      />
    );
  };

  return (
    <div className="col-xxl-3 col-xl-4 theiaStickySidebar">
      <div className="stickybar pb-4">
        <div className="card border-white">
          <div className="card-header">
            <div className="d-flex align-items-center flex-wrap row-gap-3">
              {loading ? (
                <Preloader />
              ) : (
                <div className="d-flex align-items-center justify-content-center avatar avatar-xxl me-2 flex-shrink-0 text-dark frames">
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="d-none"
                    accept=".pdf,.png,.jpg,.jpeg"
                    onChange={handleFileChange}
                  />
                  {studentData && studentData.studentProfilePic && (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={handleUploadClick}
                      className="w-100 h-100"
                    >
                      <img
                        src={studentData.studentProfilePic}
                        className="img-fluid rounded"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                        }}
                        alt="img"
                      />
                    </div>
                  )}
                  {studentData &&
                    !studentData.studentProfilePic &&
                    canPerform(ACTIONS.UPLOAD_PROFILE_IMAGE_LINK) && (
                      <div
                        className="fs-11 d-flex align-items-center justify-content-center rounded w-100 h-100"
                        style={{
                          backgroundColor: "#F0FFF8",
                          cursor: "pointer",
                          color: "grayText",
                        }}
                        onClick={handleUploadClick}
                      >
                        No Image
                      </div>
                    )}
                </div>
              )}
              <div className="overflow-hidden flex-grow-1 min-w-0">
                {studentData?.studentStatus === "ACTIVE" ? (
                  <span className="badge badge-soft-success d-inline-flex align-items-center mb-1">
                    <i className="ti ti-circle-filled fs-5 me-1"></i>
                    {studentData?.studentStatus}
                  </span>
                ) : (
                  <span className="badge badge-soft-danger d-inline-flex align-items-center mb-1">
                    <i className="ti ti-circle-filled fs-5 me-1"></i>
                    {studentData?.studentStatus}
                  </span>
                )}
                <h5 className="mb-1 text-truncate">
                  {studentData?.firstName} {studentData?.lastName}
                </h5>
                <p className=" badge badge-primary">
                  {studentData?.admissionNumber}
                </p>
              </div>
              {studentData && !studentData.studentProfilePic && (
                <div className="col-12 d-flex gap-1 justify-content-center">
                  {canPerform(ACTIONS.SEND_PROFILE_IMAGE_LINK) && (
                    <button
                      onClick={handleSendImageLink}
                      className="btn btn-primary btn-sm w-100"
                      type="button"
                      title="Send Image Link"
                    >
                      Send Profile Image Link
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Basic Information */}
          <div className="card-body">
            <h5 className="mb-3 ">Basic Information</h5>
            {loading ? (
              <Preloader />
            ) : (
              <>
                <dl className="row mb-0 mt-0 g-2">
                  <dt className="col-6 fw-medium text-dark">Class Name</dt>
                  <dd className="col-6">{studentData?.classDisplayName}</dd>
                </dl>
                <dl className="row mb-0 mt-0 g-2">
                  <dt className="col-6 fw-medium text-dark">Section Name</dt>
                  <dd className="col-6">{studentData?.sectionName}</dd>
                </dl>
                <dl className="row mb-0 mt-0 g-2">
                  <dt className="col-6 fw-medium text-dark">Roll Number</dt>
                  <dd className="col-6">{studentData?.rollNumber}</dd>
                </dl>
                <dl className="row mb-0 mt-0 g-2">
                  <dt className="col-6 fw-medium text-dark">Blood Group</dt>
                  <dd className="col-6">{studentData?.bloodGroup}</dd>
                </dl>
                <dl className="row mb-0 mt-0 g-2">
                  <dt className="col-6 fw-medium text-dark">Cast</dt>
                  <dd className="col-6">{studentData?.cast}</dd>
                </dl>
                <dl className="row mb-0 mt-0 g-2">
                  <dt className="col-6 fw-medium text-dark">Religion</dt>
                  <dd className="col-6">{studentData?.religion}</dd>
                </dl>
              </>
            )}
            {loading ? (
              <Preloader />
            ) : (
              <dl className="row mb-0 mt-0 g-2">
                <dt className="col-6 fw-medium text-dark">Date Of Birth</dt>
                <dd className="col-6">
                  {renderField(
                    SIDEBAR_EDITABLE_FIELDS.find((f) => f.key === "dob")!
                  )}
                </dd>
              </dl>
            )}

            {canPerform(ACTIONS.COLLECT_FEE) && (
              <div
                onClick={() => {}}
                data-bs-toggle="modal"
                data-bs-target="#add_fees_collect"
                className="btn btn-primary btn-sm w-100 mt-3"
              >
                Collect Fees
              </div>
            )}
          </div>
          {/* /Basic Information */}
        </div>
        {/* Primary Contact Info */}
        <div className="card">
          <div className="card-body">
            <h5 className="mb-3">Primary Contact Info</h5>
            {loading ? (
              <Preloader />
            ) : (
              <div className="d-flex align-items-start mb-3">
                <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                  <i className="ti ti-phone" />
                </span>
                <div className="flex-grow-1 min-w-0">
                  <span className="text-dark fw-medium mb-1 d-block">
                    Phone Number
                  </span>
                  {renderField(
                    SIDEBAR_EDITABLE_FIELDS.find(
                      (f) => f.key === "phoneNumber"
                    )!
                  )}
                </div>
              </div>
            )}
            {loading ? (
              <Preloader />
            ) : (
              <div className="d-flex align-items-start">
                <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                  <i className="ti ti-mail" />
                </span>
                <div className="flex-grow-1 min-w-0">
                  <span className="text-dark fw-medium mb-1 d-block">
                    Email Address
                  </span>
                  {renderField(
                    SIDEBAR_EDITABLE_FIELDS.find((f) => f.key === "email")!
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* /Primary Contact Info */}
        {/* Sibiling Information */}
        {/* <div className="card border-white">
          <div className="card-body">
            <h5 className="mb-3">Sibiling Information</h5>
            <div className="d-flex align-items-center bg-light-300 rounded p-3 mb-3">
              <span className="avatar avatar-lg">
                <ImageWithBasePath
                  src="assets/img/students/student-06.jpg"
                  className="img-fluid rounded"
                  alt="img"
                />
              </span>
              <div className="ms-2">
                <h5 className="fs-14">Ralph Claudia</h5>
                <p>III, B</p>
              </div>
            </div>
            <div className="d-flex align-items-center bg-light-300 rounded p-3">
              <span className="avatar avatar-lg">
                <ImageWithBasePath
                  src="assets/img/students/student-07.jpg"
                  className="img-fluid rounded"
                  alt="img"
                />
              </span>
              <div className="ms-2">
                <h5 className="fs-14">Julie Scott</h5>
                <p>V, A</p>
              </div>
            </div>
          </div>
        </div> */}
        {/* /Sibiling Information */}
        {/* Transport Information */}
        {/* <div className="card border-white mb-0">
          <div className="card-body pb-1">
            <ul className="nav nav-tabs nav-tabs-bottom mb-3">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to="#hostel"
                  data-bs-toggle="tab"
                >
                  Hostel
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="#transport" data-bs-toggle="tab">
                  Transportation
                </Link>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="hostel">
                <div className="d-flex align-items-center mb-3">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-building-fortress fs-16" />
                  </span>
                  <div>
                    <h6 className="fs-14 mb-1">HI-Hostel, Floor</h6>
                    <p className="text-primary">Room No : 25</p>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="transport">
                <div className="d-flex align-items-center mb-3">
                  <span className="avatar avatar-md bg-light-300 rounded me-2 flex-shrink-0 text-default">
                    <i className="ti ti-bus fs-16" />
                  </span>
                  <div>
                    <span className="fs-12 mb-1">Route</span>
                    <p className="text-dark">Newyork</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <span className="fs-12 mb-1">Bus Number</span>
                      <p className="text-dark">AM 54548</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <span className="fs-12 mb-1">Pickup Point</span>
                      <p className="text-dark">Cincinatti</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* /Transport Information */}
      </div>
    </div>
  );
};

export default StudentSidebar;
