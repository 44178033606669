import { Provider } from "react-redux";
import { store } from "./core/data/redux/store";
import { base_path } from "./environment";
import { BrowserRouter } from "react-router-dom";
import ALLRoutes from "./feature-module/router/router";
import "react-phone-number-input/style.css";

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter basename={base_path}>
        <ALLRoutes />
      </BrowserRouter>
    </Provider>
  );
};
export default App;
