import { useEffect, useMemo, useRef } from "react";
import { Formik, ErrorMessage, Field, Form, FieldArray } from "formik";
import CommonSelect from "../../../core/common/commonSelect";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import { fetchClassesAsync } from "../../../core/data/redux/features/class/classSlice";
import { Class } from "../../../core/data/redux/features/class/classTypes";
import { uploadMultipleFilesAsync } from "../../../core/data/redux/features/utils/utilsSlice";
import UploadButton from "../../../core/common/UploadButton";
import {
  createAnnouncementAsync,
  fetchAnnouncementAsync,
  updateAnnouncementAsync,
} from "../../../core/data/redux/features/announcement/announcementSlice";
import { errorToast, successToast } from "../../../core/common/toast/toast";
import { createAnnouncementsInitialValues } from "../initialValue";
import { createAnnouncementsValidationSchema } from "../schema";

const recipientMapping = {
  "School Admin": 1,
  Accountant: 2,
  Receptionist: 3,
  Teacher: 4,
  Parent: 5,
  Student: 6,
  "School Group Admin": 7,
};

const AnnouncementModal = ({ selectedAnnouncement }: any) => {
  console.log("selectedFee1234", selectedAnnouncement);
  const classData = useAppSelector((state) => state.class.classData);
  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );
  const academicYearData = useAppSelector(
    (state) => state.academicYear.academicYearData
  );
  const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const formRef = useRef(null);

  const classDataOption = classData.map((item: Class) => {
    return { value: item.id, label: item.className };
  });

  console.log(academicYearData, "academicYearData");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (schoolId && !classData.length) {
      dispatch(fetchClassesAsync({ schoolId }));
    }
  }, [schoolId]);

  useEffect(() => {
    const modalElement = document.getElementById("add_announcement_modal");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);
  const finalInitialValues = useMemo(() => {
    if (selectedAnnouncement) {
      return { ...selectedAnnouncement };
    }
    return createAnnouncementsInitialValues;
  }, [selectedAnnouncement]);

  const handelfileupload = async (
    index: any,
    file: any,
    name: string,
    setFieldValue: any
  ) => {
    console.log(file.name, "file");
    if (file) {
      const formData = new FormData();
      formData.append("files", file);

      try {
        const response = await dispatch(
          uploadMultipleFilesAsync({
            formData,
            fileCategory: "announcementAttachment",
          })
        ).unwrap();

        setFieldValue(name, response.uploadedFiles[0].fileUrl);
        setFieldValue(`attachments.${index}.attachmentName`, file.name);
        successToast("Uploaded file successfully");
      } catch (error) {
        console.log(error);
        errorToast("Upload failed:");
      }
    }
  };

  return (
    <div className="modal fade" id="add_announcement_modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedAnnouncement ? "Edit Announcement" : "Add Announcement"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={createAnnouncementsValidationSchema}
            onSubmit={async (values, actions) => {
              try {
                if (schoolId && globalAcademicYearId) {
                  let result: any;
                  if (selectedAnnouncement) {
                    const {
                      sentAt,
                      isDeleted,
                      createdBy,
                      createdAt,
                      updatedBy,
                      updatedAt,
                      ...filteredValues
                    } = values;

                    result = await dispatch(
                      updateAnnouncementAsync({
                        schoolId,
                        academicYearId: globalAcademicYearId,
                        id: selectedAnnouncement.id,
                        updateannouncement: filteredValues,
                      })
                    ).unwrap();
                  } else {
                    result = await dispatch(
                      createAnnouncementAsync({
                        schoolId: schoolId,
                        academicYearId: globalAcademicYearId,
                        newAnnouncement: values,
                      })
                    ).unwrap();
                  }

                  if (result) {
                    await dispatch(
                      fetchAnnouncementAsync({
                        schoolId: schoolId,
                        academicYearId: globalAcademicYearId,
                      })
                    ).unwrap();

                    const cancelButton = document.getElementById(
                      "btn-cancel"
                    ) as HTMLElement;
                    cancelButton.click();
                  }
                }
              } catch (error) {
                console.error("Error in creating/updating fee", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              resetForm,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              errors,
            }) => {
              console.log("1234", values, errors);
              return (
                <Form onSubmit={handleSubmit}>
                  <div
                    className="modal-body"
                    // style={{ maxHeight: "60vh", overflowY: "auto" }}
                  >
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Title</label>{" "}
                        <Field
                          type="text"
                          className="form-control"
                          name="title"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <label className="form-label">Class</label>
                        <Field
                          component={CommonSelect}
                          options={classDataOption}
                          isMulti
                          name="classIds"
                        />
                        <ErrorMessage
                          name="classIds"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <FieldArray name="attachments">
                        {({ push, remove, form }) => (
                          <div className="col-12 p-3 rounded-lg">
                            {form.values.attachments.map(
                              (attachment: any, index: number) => (
                                <div
                                  key={index}
                                  className="attachment-group mb-3"
                                >
                                  <label className="form-label">
                                    Attachments
                                  </label>

                                  {/* Custom Upload Section */}
                                  <div className="bg-light p-3 pb-2 rounded mb-2">
                                    <label className="form-label">
                                      Attachment
                                    </label>
                                    <p>
                                      Upload size of 4MB, Accepted Format: PDF
                                    </p>
                                    <div className="d-flex align-items-center flex-wrap border">
                                      <div className="btn btn-primary drag-upload-btn mb-2 me-2">
                                        <i className="ti ti-file-upload me-1" />
                                        Upload
                                        <input
                                          type="file"
                                          onChange={(e: any) =>
                                            handelfileupload(
                                              index,
                                              e.target.files[0],
                                              `attachments.${index}.attachmentUrl`,
                                              setFieldValue
                                            )
                                          }
                                          className="form-control image_sign"
                                          multiple
                                          accept=".pdf"
                                        />
                                      </div>

                                      {attachment.attachmentName && (
                                        <div className="ms-2">
                                          <span>
                                            {attachment.attachmentName.length >
                                            30
                                              ? `${attachment.attachmentName.substring(
                                                  0,
                                                  30
                                                )}...`
                                              : attachment.attachmentName}
                                          </span>
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-danger ms-2"
                                            onClick={() => {
                                              remove(index);
                                              setFieldValue(
                                                `attachments.${index}.attachmentName`,
                                                ""
                                              );
                                              setFieldValue(
                                                `attachments.${index}.attachmentUrl`,
                                                ""
                                              );
                                            }}
                                          >
                                            <i className="ti ti-trash" />
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}

                            {/* Add Attachment Button */}
                            {/* <div className="col-12 d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() =>
                                  push({
                                    attachmentName: "",
                                    attachmentUrl: "",
                                  })
                                }
                              >
                                <i className="ti ti-plus" /> Add Attachment
                              </button>
                            </div> */}
                          </div>
                        )}
                      </FieldArray>

                      <div className="col-md-12 mb-3">
                        <label className="form-label">Message</label>
                        <Field
                          as="textarea"
                          className="form-control"
                          name="message"
                          // className="h-40"
                        />
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-md-12 mb-3 d-flex flex-wrap gap-3">
                        <label className="d-flex align-items-center justify-content-center gap-1">
                          <Field
                            type="checkbox"
                            name="sentToWhatsapp"
                            className="form-check-input"
                            defaultChecked={true}
                          />
                          Send to WhatsApp
                        </label>

                        <label className="d-flex align-items-center gap-1">
                          <Field
                            type="checkbox"
                            name="sentToEmail"
                            className="form-check-input"
                            defaultChecked
                          />
                          Send to Email
                        </label>
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="form-label fw-bold">Send To:</label>
                        <div className="col-12 d-flex flex-wrap">
                          {Object.entries(recipientMapping).map(
                            ([key, value], index) => (
                              <div
                                key={key}
                                className={`col-6 form-check ${
                                  index % 2 === 0 ? "pr-2" : "pl-2"
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  id={`sentTo-${key}`}
                                  className="form-check-input"
                                  checked={values.sentTo.includes(value)}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    const updatedValues = isChecked
                                      ? [...values.sentTo, value]
                                      : values.sentTo.filter(
                                          (item: any) => item !== value
                                        );
                                    setFieldValue("sentTo", updatedValues);
                                  }}
                                />
                                <label
                                  htmlFor={`sentTo-${key}`}
                                  className="form-check-label"
                                >
                                  {key.charAt(0).toUpperCase() + key.slice(1)}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    {/* <button
                      type="button"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                      id="btn-cancel"
                      onClick={() => resetForm()}
                    >
                      Cancel
                    </button> */}
                    <div
                      onClick={() => resetForm()}
                      id="btn-cancel"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {selectedAnnouncement
                        ? "Edit Announcement"
                        : "Add Announcement"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default AnnouncementModal;
