import React, { useEffect, useRef, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { all_routes } from "../../../router/all_routes";
// import { Studentlist } from "../../../../core/data/json/studentList";
import { TableData } from "../../../../core/data/interface";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";
// import StudentModals from "../studentModals";
import Table from "../../../../core/common/dataTable/index";
import PredefinedDateRanges from "../../../../core/common/datePicker";
import {
  allClass,
  allSection,
  gender,
  names,
  state,
  status,
} from "../../../../core/common/selectoption/selectoption";
// import CommonSelect from "../../../../core/common/commonSelect";
import TooltipOption from "../../../../core/common/tooltipOption";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import {
  fetchAdmissionsBySchoolAsync,
  fetchStudentsListbySchoolAsync,
} from "../../../../core/data/redux/features/admission/admissionSlice";
import {
  fetchClassSectionsAsync,
  fetchClassesAsync,
} from "../../../../core/data/redux/features/class/classSlice";
import FeeCollect from "../common/feeCollect";
import LoginDetails from "../common/loginDetails";
import { ErrorMessage, Field } from "formik";
import CommonSelect from "../../../../core/common/commonSelect";
import { useActionPermission } from "../../../router/useActionPermission";
import { ACTIONS } from "../../../../core/data/constants";

const StudentList = () => {
  const routes = all_routes;
  const [studentData, setStudentData] = React.useState(null);
  const { canPerform } = useActionPermission();
  const [classId, setClassId] = useState<null | number | undefined>(null);
  const [sectionId, setSectionId] = useState(null);
  const dispatch = useAppDispatch();
  const { admissionsData, fetchLoading } = useAppSelector(
    (state) => state.admission
  );
  const schoolId = useAppSelector((state) => state.auth?.user?.schoolId);
  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );
  const classData = useAppSelector((state) => state.class.classData);
  const classSectionData = useAppSelector(
    (state) => state.class.classSectionData
  );

  useEffect(() => {
    if (schoolId && globalAcademicYearId && classId) {
      let queryObject: {
        schoolId: number;
        academicYearId: number;
        classId: number;
        sectionId: number | null;
      } = {
        schoolId,
        academicYearId: globalAcademicYearId,
        classId,
        sectionId: sectionId || null,
      };

      dispatch(fetchStudentsListbySchoolAsync(queryObject));
    }
    if (!classData.length && schoolId) {
      dispatch(fetchClassesAsync({ schoolId: schoolId, section: 0 }));
    }
  }, [schoolId, globalAcademicYearId, sectionId, classId, dispatch]);

  useEffect(() => {
    if (classData.length && !classId) {
      setClassId(classData[0].id);
    }
  }, [classData.length]);

  const classDataOption = useMemo(
    () =>
      classData.map((item: any) => ({
        value: item.id,
        label: item.className,
      })),

    [classData]
  );

  const columns = [
    // {
    //   title: "Admission No",
    //   dataIndex: "admissionNumber",
    //   render: (text: string) => (
    //     <Link to={routes.studentDetail} className="link-primary">
    //       {text}
    //     </Link>
    //   ),
    //   sorter: (a: TableData, b: TableData) =>
    //     a.admissionNumber.length - b.admissionNumber.length,
    // },
    {
      title: "Name",
      dataIndex: "firstName",
      render: (text: string, record: any) => (
        <div className="d-flex align-items-center">
          {/* <Link to="#" className="avatar avatar-md">
            <ImageWithBasePath
              src={record.imgSrc}
              className="img-fluid rounded-circle"
              alt="img"
            />
          </Link> */}
          <div className="ms-2">
            <p className="text-dark mb-0">
              <Link to="#">
                {record.firstName} {record.lastName}
              </Link>
            </p>
          </div>
        </div>
      ),
      sorter: (a: TableData, b: TableData) =>
        a.firstName.length - b.firstName.length,
    },
    {
      title: "Class Name",
      dataIndex: "className",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    {
      title: "SectionName",
      dataIndex: "sectionName",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    // {
    //   title: "Section",
    //   dataIndex: "section",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.section.length - b.section.length,
    // },
    {
      title: "Gender",
      dataIndex: "genderName",
      sorter: (a: TableData, b: TableData) =>
        a.genderName.length - b.genderName.length,
    },

    // {
    //   title: "Status",
    //   dataIndex: "userStatus",
    //   render: (text: string) => (
    //     <>
    //       {text === "ACTIVE" ? (
    //         <span className="badge badge-soft-success d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : (
    //         <span className="badge badge-soft-danger d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a: TableData, b: TableData) =>
    //     a.userStatus.length - b.userStatus.length,
    // },
    // {
    //   title: "Date of Join",
    //   dataIndex: "DateofJoin",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.DateofJoin.length - b.DateofJoin.length,
    // },
    {
      title: "DOB",
      dataIndex: "dob",
      render: (date: string) => dayjs(date).format("DD MMM YYYY"),
      sorter: (a: TableData, b: TableData) =>
        dayjs(a.dob).unix() - dayjs(b.dob).unix(),
    },
    {
      title: "Academic Year",
      dataIndex: "academicYearName",
      sorter: (a: TableData, b: TableData) =>
        a.academicYearName.length - b.academicYearName.length,
    },
    // {
    //   title: "Admission Status",
    //   dataIndex: "admissionStatusName",
    //   render: (text: string) => (
    //     <>
    //       {text === "Approved" ? (
    //         <span className="badge badge-soft-success d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : text === "Interviewed" || text === "Applied" ? (
    //         <span className="badge badge-soft-warning d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       ) : (
    //         <span className="badge badge-soft-danger d-inline-flex align-items-center">
    //           <i className="ti ti-circle-filled fs-5 me-1"></i>
    //           {text}
    //         </span>
    //       )}
    //     </>
    //   ),
    //   sorter: (a: TableData, b: TableData) =>
    //     a.admissionStatusName.length - b.admissionStatusName.length,
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <>
          <div className="d-flex align-items-center">
            {canPerform(ACTIONS.VIEW_STUDENT) ? (
              <Link
                to={`${routes.studentDetail}/${record.studentClassSectionId}?student=personal`}
                className="btn btn-light fs-12 fw-semibold me-3"
              >
                View Student
              </Link>
            ) : (
              <p className="text-danger fs-md d-flex align-items-center">
                <i className="ti ti-lock me-2" /> View : No Access
              </p>
            )}
            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>
              <ul className="dropdown-menu dropdown-menu-right p-3">
                {canPerform(ACTIONS.COLLECT_FEE) ? (
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => setStudentData(record)}
                      data-bs-toggle="modal"
                      data-bs-target="#add_fees_collect"
                    >
                      <i className="ti ti-menu me-2" />
                      Collect Fee
                    </div>
                  </li>
                ) : (
                  <p className="text-danger fs-md d-flex align-items-center">
                    <i className="ti ti-lock me-2" /> Collect : No Access
                  </p>
                )}
                {/* <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to={`${routes.editAdmission}/${record.studentId}`}
                  >
                    <i className="ti ti-edit-circle me-2" />
                    Edit
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#login_detail"
                  >
                    <i className="ti ti-lock me-2" />
                    Login Details
                  </Link>
                </li> */}
                {/* <li>
                  <Link className="dropdown-item rounded-1" to="#">
                    <i className="ti ti-toggle-right me-2" />
                    Disable
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="student-promotion"
                  >
                    <i className="ti ti-arrow-ramp-right-2 me-2" />
                    Promote Student
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    className="dropdown-item rounded-1"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-modal"
                  >
                    <i className="ti ti-trash-x me-2" />
                    Delete
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Students List</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">Students</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Students
                  </li>
                </ol>
              </nav>
            </div>
            {/* <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              <TooltipOption />

              <div className="mb-2 d-flex flex-wrap justify-content-center">
                <Link
                  to={routes.addMultipleAdmission}
                  className="btn btn-primary d-flex align-items-center me-2 mb-2"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Multiple Admission
                </Link>
                <Link
                  to={routes.addAdmission}
                  className="btn btn-primary d-flex align-items-center mb-2"
                >
                  <i className="ti ti-square-rounded-plus me-2" />
                  Add Admission
                </Link>
              </div>
            </div> */}
          </div>
          {/* /Page Header */}
          {/* Students List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Students List</h4>
              <div className="d-flex align-items-center flex-nowrap gap-3">
                <select
                  name="classId"
                  className="form-select mb-3"
                  onChange={(e: any) => {
                    setClassId(e.target.value);
                    setSectionId(null);
                    dispatch(
                      fetchClassSectionsAsync({
                        schoolId: Number(schoolId),
                        classId: e.target.value,
                      })
                    );
                  }}
                  defaultValue=""
                >
                  {/* <option value="">Select Class</option> */}
                  {classDataOption.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
                <select
                  name="sectionId"
                  className="form-select mb-3"
                  onChange={(e: any) => setSectionId(e.target.value)}
                  defaultValue=""
                >
                  <option value="">Select Class Section</option>
                  {classSectionData.map(({ id, sectionDisplayName }) => (
                    <option key={id} value={id}>
                      {sectionDisplayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Student List */}
              <Table
                dataSource={admissionsData}
                columns={columns}
                loading={fetchLoading}
              />
              {/* /Student List */}
            </div>
          </div>
          {/* /Students List */}
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* <StudentModals /> */}
      <FeeCollect studentData={studentData} />
      <LoginDetails />
    </>
  );
};

export default StudentList;
