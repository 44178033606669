import { Formik, ErrorMessage, Field, FieldArray } from "formik";
import { useState } from "react";
// import { createClassInitialValues } from "./initialValue";
import CommonSelect from "../../../core/common/commonSelect";
import PhoneInput from "react-phone-number-input";
import { createClassInitialValues } from "../../academic/classes/initialValue";
import { studentEnrollmentValidationSchema } from "./schema";
// import { createClassValidationSchema } from "./schema";
import { studentEnrollmentInitialValues } from "./initialValues";
import { useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
// import { fetchClassesAsync } from "../../../core/data/redux/features/class/classSlice";
import {
  fetchParentTypesAsync,
  fetchGendersAsync,
} from "../../../core/data/redux/features/utils/utilsSlice";
import { createInquiryAsync } from "../../../core/data/redux/features/inquiry/inquirySlice";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const InquiryForm = ({ selectedClass }: any) => {
  console.log(selectedClass);
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState(false);
  // const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const parentTypeData = useAppSelector((state) => state.utils.parentTypeData);
  const genderData = useAppSelector((state) => state.utils.genderData);
  const schoolData = useAppSelector((state) => state.auth.schoolData);
  const formRef = useRef(null);
  console.log(schoolData, "schoolData");
  useEffect(() => {
    const modalElement = document.getElementById("add_class");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchParentTypesAsync());
    dispatch(fetchGendersAsync());
  }, []);

  const parentTypeDataOptions = parentTypeData.map((item: any) => ({
    value: item.id,
    label: item.parentTypeName,
  }));
  const genderDataOptions = genderData.map((item: any) => ({
    value: item.id,
    label: item.genderName,
  }));
  const classDataOption = schoolData?.classes.map((item: any) => {
    return { value: item.id, label: item.className };
  });
  const acedemicYearDataOption = schoolData?.academicYears.map((item: any) => {
    return { value: item.id, label: item.academicYearDisplayName };
  });
  const finalInitialValues = useMemo(() => {
    if (selectedClass) {
      return {
        className: selectedClass?.className,
        classDisplayName: selectedClass?.classDisplayName,
      };
    } else {
      return createClassInitialValues;
    }
  }, [selectedClass]);

  return (
    <>
      {modal && (
        <div className="bg-light min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <div
            className="p-4 bg-white shadow rounded d-flex flex-column align-items-center justify-content-center rounded"
            style={{ maxWidth: "400px", width: "100%" }}
          >
            <div className="text-center mb-4">
              {/* Header Image */}
              <img
                src={schoolData?.schoolLogo}
                alt="School Logo"
                className="img-fluid rounded-circle mb-0"
                style={{ maxWidth: "120px" }}
              />
              <h3 className="fw-bold">{schoolData?.schoolDisplayName}</h3>
            </div>
            <h3 className="mb-3 text-success fw-bold">
              ✅ Submission Successful!
            </h3>
            <p className="text-muted text-center">
              Your data has been successfully received by{" "}
              <strong>{schoolData?.schoolDisplayName}</strong>. Our team will
              review the details and get back to you if necessary.
            </p>
            {/* <button
              className="btn btn-primary"
              onClick={() => setModal(!modal)}
            >
              Inquiry Again ?
            </button> */}
          </div>
        </div>
      )}
      {!modal && (
        <div className="">
          <div className="content p-2">
            <div className="text-center mb-4">
              {/* Header Image */}
              <img
                src={schoolData?.schoolLogo}
                alt="School Logo"
                className="img-fluid rounded-circle mb-0"
                style={{ maxWidth: "120px" }}
              />
              <h3 className="fw-bold">
                Welcome To {schoolData?.schoolDisplayName}
              </h3>

              <p className="text-muted mt-2">
                Please add your details so that we can stay in touch.
              </p>
            </div>

            <Formik
              initialValues={studentEnrollmentInitialValues}
              enableReinitialize
              innerRef={formRef}
              validationSchema={studentEnrollmentValidationSchema}
              onSubmit={async (values, actions) => {
                try {
                  if (schoolData?.id) {
                    let result: any;
                    if (schoolData?.id) {
                      const { acedemicYearId, ...newValues } = values;
                      result = await dispatch(
                        createInquiryAsync({
                          schoolId: schoolData?.id,
                          academicYearId: values.acedemicYearId,
                          newInquiry: newValues,
                        })
                      ).unwrap();
                    } else {
                      // result = await dispatch(
                      //   createClassAsync({ schoolId, newClass: values })
                      // ).unwrap();
                    }

                    if (result) {
                      // const cancelButton = document.getElementById(
                      //   "btn-cancel"
                      // ) as HTMLElement;
                      // cancelButton.click();
                      setModal(true);
                    }
                  }
                } catch (error) {
                  console.error("Error creating class:", error);
                }
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                values,
                resetForm,
                isSubmitting,
              }) => {
                console.log(values);
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="body">
                      <div className="border rounded mb-3">
                        <div
                          className="mb-3 d-flex flex-row gap-2 justify-content-start align-items-center p-3 rounded"
                          style={{ backgroundColor: "#f0f4f8" }}
                        >
                          <i className="ti ti-info-square-rounded fs-16" />
                          <span className="text-dark">
                            Student Personal Information
                          </span>
                        </div>
                        <div className="row row-cols-xxl-5 row-cols-md-6 p-3">
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Acedemic Year
                              </label>
                              <Field
                                type="text"
                                component={CommonSelect}
                                options={acedemicYearDataOption}
                                name="acedemicYearId"
                              />
                              <ErrorMessage
                                name="acedemicYearId"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Class</label>
                              <Field
                                type="text"
                                component={CommonSelect}
                                options={classDataOption}
                                name="classId"
                              />
                              <ErrorMessage
                                name="classId"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">First Name</label>
                              <Field
                                type="text"
                                className="form-control"
                                name="firstName"
                              />
                              <ErrorMessage
                                name="firstName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Middle Name</label>
                              <Field
                                type="text"
                                className="form-control"
                                name="middleName"
                              />
                              <ErrorMessage
                                name="middleName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Last Name</label>
                              <Field
                                type="text"
                                className="form-control"
                                name="lastName"
                              />
                              <ErrorMessage
                                name="lastName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Date Of Birth
                              </label>
                              <div className="input-icon position-relative">
                                <DatePicker
                                  className="form-control datetimepicker"
                                  format="YYYY-MM-DD"
                                  name="dateOfBirth"
                                  onChange={(date: dayjs.Dayjs) =>
                                    setFieldValue(
                                      "dateOfBirth",
                                      date
                                        ? dayjs(date).format("YYYY-MM-DD")
                                        : ""
                                    )
                                  }
                                />
                                <span className="input-icon-addon">
                                  <i className="ti ti-calendar" />
                                </span>
                              </div>
                              <ErrorMessage
                                name="dateOfBirth"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Gender</label>
                              <Field
                                type="text"
                                component={CommonSelect}
                                options={genderDataOptions}
                                name="genderId"
                              />
                              <ErrorMessage
                                name="genderId"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Previous School Name
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                name="previousSchoolDetails.previousSchoolName"
                              />
                              <ErrorMessage
                                name="previousSchoolDetails.previousSchoolName"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Last Class Attended
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                name="previousSchoolDetails.lastClassAttended"
                              />
                              <ErrorMessage
                                name="previousSchoolDetails.lastClassAttended"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Board Of Education
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                name="previousSchoolDetails.boardOfEducation"
                              />
                              <ErrorMessage
                                name="previousSchoolDetails.boardOfEducation"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <FieldArray name="parentData">
                        {({ push, remove, form }) => (
                          <div className="border rounded mb-3">
                            <div
                              className="mb-3 d-flex flex-row gap-2 justify-content-start align-items-center p-3 rounded"
                              style={{ backgroundColor: "#f0f4f8" }}
                            >
                              <i className="ti ti-info-square-rounded fs-16" />
                              <span className="text-dark">
                                Parent Information
                              </span>
                            </div>
                            {form.values.parentData.map(
                              (_: any, index: any) => (
                                <div
                                  key={index}
                                  className="row row-cols-xxl-5 row-cols-md-6 p-3"
                                >
                                  <div className="col-xxl col-xl-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        First Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name={`parentData.${index}.firstName`}
                                      />
                                      <ErrorMessage
                                        name={`parentData.${index}.firstName`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl col-xl-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Middle Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name={`parentData.${index}.middleName`}
                                      />
                                      <ErrorMessage
                                        name={`parentData.${index}.middleName`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl col-xl-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Last Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name={`parentData.${index}.lastName`}
                                      />
                                      <ErrorMessage
                                        name={`parentData.${index}.lastName`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl col-xl-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Email
                                      </label>
                                      <Field
                                        type="email"
                                        className="form-control"
                                        name={`parentData.${index}.email`}
                                      />
                                      <ErrorMessage
                                        name={`parentData.${index}.email`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl col-xl-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Phone
                                      </label>
                                      <PhoneInput
                                        placeholder="Enter phone number"
                                        className="form-control"
                                        name={`parentData.${index}.phoneNumber`}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `parentData.${index}.phoneNumber`,
                                            e
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`parentData.${index}.phoneNumber`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl col-xl-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Parent Type
                                      </label>
                                      <Field
                                        component={CommonSelect}
                                        options={parentTypeDataOptions}
                                        name={`parentData.${index}.parentTypeId`}
                                      />
                                      <ErrorMessage
                                        name={`parentData.${index}.parentTypeId`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl col-xl-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Gender
                                      </label>
                                      <Field
                                        component={CommonSelect}
                                        options={genderDataOptions}
                                        name={`parentData.${index}.genderId`}
                                      />
                                      <ErrorMessage
                                        name={`parentData.${index}.genderId`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-xxl col-xl-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Relationship With Student
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name={`parentData.${index}.relationshipWithStudent`}
                                      />
                                      <ErrorMessage
                                        name={`parentData.${index}.relationshipWithStudent`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xxl col-xl-3 col-md-6">
                                    <div className="mb-3">
                                      <label className="form-label">
                                        Current Address
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name={`parentData.${index}.currentAddress`}
                                      />
                                      <ErrorMessage
                                        name={`parentData.${index}.currentAddress`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </FieldArray>

                      <div className="border rounded mb-2 p-3">
                        <div className="row">
                          {/* Reason For Enrollment (Checkboxes) */}
                          <div className="col-md-12 col-12 mb-3">
                            <label className="form-label">
                              Reason for Enrollment
                            </label>

                            <div className="row d-flex g-4 p-2">
                              <div className="col-auto form-check">
                                <Field
                                  type="checkbox"
                                  name="reasonForEnrollment.qualityEducation"
                                  className="form-check-input"
                                />
                                <label className="form-check-label">
                                  Quality Education
                                </label>
                              </div>

                              <div className="col-auto form-check">
                                <Field
                                  type="checkbox"
                                  name="reasonForEnrollment.extracurricularActivities"
                                  className="form-check-input"
                                />
                                <label className="form-check-label">
                                  Extracurricular Activities
                                </label>
                              </div>

                              <div className="col-auto form-check">
                                <Field
                                  type="checkbox"
                                  name="reasonForEnrollment.proximityToHome"
                                  className="form-check-input"
                                />
                                <label className="form-check-label">
                                  Proximity to Home
                                </label>
                              </div>

                              <div className="col-auto form-check">
                                <Field
                                  type="checkbox"
                                  name="reasonForEnrollment.recommendationsFromOthers"
                                  className="form-check-input"
                                />
                                <label className="form-check-label">
                                  Recommendations from Others
                                </label>
                              </div>
                            </div>
                            <>
                              <div className="mt-3">
                                <label className="form-label">
                                  Other (Specify)
                                </label>
                                <Field
                                  as="textarea"
                                  name="reasonForEnrollment.other"
                                  className="form-control"
                                  placeholder="Specify other reason"
                                />
                              </div>

                              <ErrorMessage
                                name="reasonForEnrollment.other"
                                component="div"
                                className="text-danger"
                              />
                            </>
                          </div>

                          {/* Preferred Communication Mode */}
                          <div className="col-md-12 col-12 mb-3">
                            <label className="form-label">
                              Preferred Communication Mode
                            </label>

                            <div className="row d-flex g-4 p-2">
                              <div className="col-auto form-check">
                                <Field
                                  type="checkbox"
                                  name="preferredCommunicationMode.call"
                                  className="form-check-input"
                                />
                                <label className="form-check-label">Call</label>
                              </div>

                              <div className="col-auto form-check">
                                <Field
                                  type="checkbox"
                                  name="preferredCommunicationMode.whatsapp"
                                  className="form-check-input"
                                />
                                <label className="form-check-label">
                                  WhatsApp
                                </label>
                              </div>

                              <div className="col-auto form-check">
                                <Field
                                  type="checkbox"
                                  name="preferredCommunicationMode.email"
                                  className="form-check-input"
                                />
                                <label className="form-check-label">
                                  Email
                                </label>
                              </div>
                            </div>

                            <h6 className="mt-2">
                              Are You Interested in School Visit ?
                            </h6>
                            <div className="form-check mt-1">
                              <Field
                                type="checkbox"
                                name="interestedInSchoolVisit"
                                className="form-check-input"
                              />
                              <label className="form-check-label">Yes</label>
                            </div>

                            {/* <!-- Additional Comments --> */}
                            <div className="mt-3">
                              <label className="form-label">
                                Additional Comments
                              </label>
                              <Field
                                as="textarea"
                                name="additionalComments"
                                className="form-control"
                                placeholder="Enter any additional comments"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footer text-end">
                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={isSubmitting}
                      >
                        Send Inquiry
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};
export default InquiryForm;
