import apiClient from "../../../api/apiClient";
import { LoginRequest, LoginResponse } from "./authTypes";

const BASE_PATH = "/users";

export const login = async (
  credentials: LoginRequest
): Promise<LoginResponse> => {
  const response = await apiClient.post(`${BASE_PATH}/login`, credentials);
  return response.data;
};

export const forgotPassword = async (
  userUniqueId: string
): Promise<{ message: string }> => {
  const response = await apiClient.post(`${BASE_PATH}/forgot-password`, {
    userUniqueId,
  });
  return response.data;
};

// Reset Password API
export const resetPassword = async (
  token: string,
  newPassword: string
): Promise<{ message: string }> => {
  const response = await apiClient.post(`${BASE_PATH}/reset-password`, {
    token,
    newPassword,
  });
  return response.data;
};

export const fetchSchoolData = async (): Promise<any> => {
  const response = await apiClient.get(`${BASE_PATH}/school-data`);
  return response.data;
};

export const fetchSchoolGroupData = async (): Promise<any> => {
  const response = await apiClient.get(`${BASE_PATH}/school-group-data`);
  return response.data;
};

export const currentUser = async (): Promise<LoginResponse> => {
  const response = await apiClient.get(`${BASE_PATH}/current-user`);
  return response.data;
};
