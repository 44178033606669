import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorToast, successToast } from "../../../../common/toast/toast";
import {
  createAnnouncement,
  fetchAnnouncement,
  sendAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  fetchAnnouncementById,
} from "./announcementApi";
import { Notification, NotificationState } from "./announcementTypes";

const initialState: NotificationState = {
  announcementData: [],
  loading: false,
  fetchLoading: false,
  error: null,
};

export const fetchAnnouncementAsync = createAsyncThunk(
  "announcement/fetchAnnouncement",
  async (
    {
      schoolId,
      academicYearId,
    }: {
      schoolId: number;
      academicYearId: number;
    },
    thunkAPI
  ) => {
    try {
      return await fetchAnnouncement(schoolId, academicYearId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch Announcement"
      );
    }
  }
);

export const fetchAnnouncementByIdAsync = createAsyncThunk(
  "announcement/fetchAnnouncementById",
  async (
    {
      schoolId,
      academicYearId,
      id,
    }: {
      schoolId: number;
      academicYearId: number;
      id: number;
    },
    thunkAPI
  ) => {
    try {
      return await fetchAnnouncementById(schoolId, academicYearId, id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch Announcement"
      );
    }
  }
);

export const createAnnouncementAsync = createAsyncThunk(
  "announcement/createAnnouncement",
  async (
    {
      schoolId,
      academicYearId,
      newAnnouncement,
    }: {
      schoolId: number;
      academicYearId: number;
      newAnnouncement: Notification;
    },
    thunkAPI
  ) => {
    try {
      return await createAnnouncement(
        schoolId,
        academicYearId,
        newAnnouncement
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create Announcement"
      );
    }
  }
);

export const sendAnnouncementAsync = createAsyncThunk(
  "announcement/sendAnnouncement",
  async (
    {
      schoolId,
      academicYearId,
      id,
    }: {
      schoolId: number;
      academicYearId: number;
      id: number;
    },
    thunkAPI
  ) => {
    try {
      await sendAnnouncement(schoolId, academicYearId, id);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to send Announcement"
      );
    }
  }
);

export const updateAnnouncementAsync = createAsyncThunk(
  "announcement/updateAnnouncement",
  async (
    {
      schoolId,
      academicYearId,
      id,
      updateannouncement,
    }: {
      schoolId: number;
      academicYearId: number;
      id: number;
      updateannouncement: Notification;
    },
    thunkAPI
  ) => {
    try {
      return await updateAnnouncement(
        schoolId,
        academicYearId,
        id,
        updateannouncement
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update Announcement"
      );
    }
  }
);

export const deleteAnnouncementAsync = createAsyncThunk(
  "announcement/deleteAnnouncement",
  async (
    {
      schoolId,
      academicYearId,
      id,
    }: {
      schoolId: number;
      academicYearId: number;
      id: number;
    },
    thunkAPI
  ) => {
    try {
      await deleteAnnouncement(schoolId, academicYearId, id);
      return id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete Announcement"
      );
    }
  }
);

const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnnouncementAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchAnnouncementAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.announcementData = action.payload?.data;
      })
      .addCase(fetchAnnouncementAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch Announcement";
        errorToast(state.error);
      })
      .addCase(fetchAnnouncementByIdAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchAnnouncementByIdAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.announcementData = action.payload.data;
      })
      .addCase(fetchAnnouncementByIdAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch Announcement";
        errorToast(state.error);
      })
      .addCase(createAnnouncementAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAnnouncementAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create Announcement";
        errorToast(state.error);
      })
      .addCase(createAnnouncementAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.announcementData.push(action.payload.data);
        successToast(action.payload.message);
      })
      .addCase(sendAnnouncementAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendAnnouncementAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast("Announcement sended successfully");
      })
      .addCase(sendAnnouncementAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to send Announcement";
        errorToast(state.error);
      })
      .addCase(updateAnnouncementAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAnnouncementAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update Announcement";
        errorToast(state.error);
      })
      .addCase(updateAnnouncementAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(deleteAnnouncementAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAnnouncementAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast("Announcement deleted successfully");
      })
      .addCase(deleteAnnouncementAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to deleted Announcement";
        errorToast(state.error);
      });
  },
});

export default announcementSlice.reducer;
