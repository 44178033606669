import { useEffect, useMemo, useRef } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import CommonSelect from "../../../../core/common/commonSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import { createStudentFeeInitialValue } from "./initialValue";
import { createStudentFeeValidationSchema } from "./schema";
import {
  createFeeAsync,
  fetchFeeDiscountAndCategoryAsync,
  fetchFeesByCriteriaAsync,
  fetchStudentFeeRecordsAsync,
  updateFeeAsync,
  updateStudentFeeRecordAsync,
} from "../../../../core/data/redux/features/fee/feeSlice";
import { fetchFeeDiscountTypesAsync } from "../../../../core/data/redux/features/utils/utilsSlice";
import Preloader from "../../../../core/common/loader/preLoader";

const StudentFeeFormModal = ({ selectedStudentFee, studentData }: any) => {
  const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const feeDiscountTypeData = useAppSelector(
    (state) => state.utils.feeDiscountTypeData
  );
  const {
    feeDiscountAndCategory: { discountTypes },
    fetchModelLoading,
  } = useAppSelector((state) => state.fee);
  const formRef = useRef(null);

  const dispatch = useAppDispatch();

  const feeDiscountTypeOptions = [
    { value: null, label: "Select Discount Type" },
    ...feeDiscountTypeData.map((item: any) => ({
      value: item.id,
      label: item.feeDiscountName,
    })),
  ];

  useEffect(() => {
    if (
      schoolId &&
      selectedStudentFee?.classId &&
      selectedStudentFee?.academicYearId
    ) {
      dispatch(
        fetchFeeDiscountAndCategoryAsync({
          schoolId,
          criteria: {
            classId: selectedStudentFee?.classId,
            academicYearId: selectedStudentFee?.academicYearId,
          },
        })
      );
    }
  }, [
    schoolId,
    selectedStudentFee?.classId,
    selectedStudentFee?.academicYearId,
  ]);

  useEffect(() => {
    const modalElement = document.getElementById("add_student_fee");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedStudentFee && discountTypes.length) {
      return {
        discountDetails: discountTypes.map((item: any) => {
          const isDiscountApplied = selectedStudentFee.discountDetails?.find(
            (discount: any) =>
              discount.discountSchoolMappingId === item.discountSchoolMappingId
          );
          if (isDiscountApplied) {
            return {
              ...item,
              discountPercentage: isDiscountApplied.discountPercentage,
              allowedDiscount: true,
            };
          } else {
            return { ...item, allowedDiscount: false };
          }
        }),
      };
    }
  }, [selectedStudentFee, discountTypes]);

  console.log(finalInitialValues);

  const checkDisableStateForDiscountType = (discountName: any) => {
    if (discountName === "Sibling") {
      if (!studentData.siblings || studentData.siblings.length === 0) {
        return true;
      }
    } else if (discountName === "EWS") {
      if (studentData.cast === "GENERAL") {
        return true;
      }
    }
    // else {
    return false;
    // }
  };

  return (
    <div className="modal fade" id="add_student_fee">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        {fetchModelLoading ? (
          <Preloader />
        ) : (
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">
                {selectedStudentFee ? (
                  <p>
                    Apply Discount{" "}
                    <span className="badge badge-soft-primary badge-sm d-inline-flex align-items-center mb-1">
                      {selectedStudentFee?.studentFeeName}
                    </span>{" "}
                  </p>
                ) : (
                  `Add Fee`
                )}
              </h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            {discountTypes.length &&
              finalInitialValues?.discountDetails?.length && (
                <Formik
                  initialValues={finalInitialValues}
                  enableReinitialize
                  innerRef={formRef}
                  validationSchema={createStudentFeeValidationSchema}
                  onSubmit={async (values, actions) => {
                    try {
                      if (schoolId) {
                        let result: any;
                        if (selectedStudentFee) {
                          result = await dispatch(
                            updateStudentFeeRecordAsync({
                              studentFeeRecordId:
                                selectedStudentFee.studentFeeId,
                              updateData: {
                                discountDetails: values.discountDetails
                                  .filter((item: any) => item.allowedDiscount)
                                  .map((item: any) => ({
                                    discountSchoolMappingId:
                                      item.discountSchoolMappingId,
                                    discountPercentage: item.discountPercentage,
                                  })),
                              },
                            })
                          ).unwrap();
                        } else {
                        }

                        const listData = await dispatch(
                          fetchStudentFeeRecordsAsync({
                            studentId: selectedStudentFee?.studentId,
                            studentClassSectionId:
                              selectedStudentFee.studentClassSectionId,
                          })
                        ).unwrap();

                        if (listData) {
                          const cancelButton = document.getElementById(
                            "btn-cancel"
                          ) as HTMLElement;
                          cancelButton.click();
                        }
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                    resetForm,
                    isSubmitting,
                  }) => {
                    // Calculate total discount percentage dynamically
                    const totalDiscountPercentage = values.discountDetails
                      .filter((item: any) => item.allowedDiscount)
                      .reduce(
                        (sum: number, item: any) =>
                          sum + (item.discountPercentage || 0),
                        0
                      );

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                          <div className="mb-3">
                            <div className="alert alert-primary" role="alert">
                              The total discount is {totalDiscountPercentage}%,
                            </div>
                          </div>

                          {values.discountDetails.map(
                            (item: any, index: number) => (
                              <div
                                className="row"
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="col-md-6">
                                  <div className="mb-3 form-check">
                                    <Field
                                      type="checkbox"
                                      id={`discountDetails[${index}].allowedDiscount`}
                                      name={`discountDetails[${index}].allowedDiscount`}
                                      className="form-check-input"
                                      disabled={checkDisableStateForDiscountType(
                                        item.feeDiscountName
                                      )}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`discountDetails[${index}].allowedDiscount`}
                                    >
                                      {item.feeDiscountName}
                                    </label>
                                  </div>
                                </div>

                                {item.allowedDiscount && (
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Field
                                        type="number"
                                        name={`discountDetails[${index}].discountPercentage`}
                                        className="form-control w-auto"
                                        min={0}
                                        max={
                                          discountTypes.find(
                                            (discount) =>
                                              discount.discountSchoolMappingId ===
                                              item.discountSchoolMappingId
                                          )?.maximumAllowedDiscountPercentage ||
                                          0
                                        }
                                        step="1"
                                        value={
                                          values.discountDetails[index]
                                            .discountPercentage || 0
                                        }
                                        onChange={(e: any) => {
                                          let value = Number(e.target.value);
                                          const maxValue =
                                            discountTypes.find(
                                              (discount) =>
                                                discount.discountSchoolMappingId ===
                                                item.discountSchoolMappingId
                                            )
                                              ?.maximumAllowedDiscountPercentage ||
                                            0;

                                          if (value < 0) value = 0;
                                          if (value > maxValue)
                                            value = maxValue;

                                          setFieldValue(
                                            `discountDetails[${index}].discountPercentage`,
                                            value
                                          );
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`discountDetails[${index}].discountPercentage`}
                                        className="text-danger"
                                        component="div"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                        </div>

                        <div className="modal-footer">
                          <div
                            onClick={() => resetForm()}
                            id="btn-cancel"
                            className="btn btn-light me-2"
                            data-bs-dismiss="modal"
                          >
                            Cancel
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            {selectedStudentFee
                              ? "Edit Student Fee"
                              : "Add Student Fee"}
                          </button>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              )}
          </div>
        )}
      </div>
    </div>
  );
};
export default StudentFeeFormModal;
