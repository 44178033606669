import apiClient from "../../../api/apiClient";
import { FeeDiscountSchool } from "./feeDiscountTypeSchoolMappingType";

//Base URL

const BASE_PATH = "/fee-discount-type-school-mapping";

export const fetchFeeDiscountSchoolMapping = async (
  schoolId: number
): Promise<{ data: FeeDiscountSchool[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-fee-discount-types/${schoolId}`
  );
  return response?.data;
};

export const createFeeDiscountSchoolMapping = async (
  schoolId: number,
  newFeeDiscount: FeeDiscountSchool
): Promise<{ data: FeeDiscountSchool; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/add-fee-discount-type/${schoolId}`,
    newFeeDiscount
  );
  return response?.data;
};

export const updateFeeDiscountSchoolMapping = async (
  mappingId: number,
  updatedFeeDiscount: FeeDiscountSchool
): Promise<{ data: FeeDiscountSchool; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-fee-discount-type/${mappingId}`,
    updatedFeeDiscount
  );
  return response?.data;
};

export const deleteFeeDiscountSchoolMapping = async (
    mappingId: number
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/delete-fee-discount-type/${mappingId}`
  );
};
