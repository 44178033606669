export const createSchoolUserInitialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  genderId: null,
  dob: null,
  aadharCardNumber: null,
};
