import { useEffect, useRef, useState } from "react";
// import { classes } from "../../../core/data/json/classes";
// import Table from "../../core"
import Table from "../../core/common/dataTable/index";
// import { activeList } from "../../../core/common/selectoption/selectoption";
// import Select from "react-select";
// import { TableData } from "../../../core/data/interface";
import { TableData } from "../../core/data/interface";
// import PredefinedDateRanges from "../../../core/common/datePicker";
// import CommonSelect from "../../../core/common/commonSelect";
import { Link } from "react-router-dom";
// import TooltipOption from "../../../core/common/tooltipOption";
import { all_routes } from "../router/all_routes";
import { useAppDispatch, useAppSelector } from "../../core/data/redux/hooks";
import {
  deleteClassAsync,
  fetchClassesAsync,
} from "../../core/data/redux/features/class/classSlice";
import {
  fetchAnnouncementAsync,
  deleteAnnouncementAsync,
  sendAnnouncementAsync,
} from "../../core/data/redux/features/announcement/announcementSlice";
// import { useActionPermission } from "../../router/useActionPermission";
import { useActionPermission } from "../router/useActionPermission";
// import { ACTIONS } from "../../../core/data/constants";
import { ACTIONS } from "../../core/data/constants";
// import ClassFormModal from "./class-form-modal";
// import DeleteConfirmationModal from "../../../core/common/delete-confirmation-modal";
import DeleteConfirmationModal from "../../core/common/delete-confirmation-modal";
import AnnouncementModal from "./components/announcement-form-modal";
import { errorToast } from "../../core/common/toast/toast";
import ViewDeatilsModal from "./components/view-details-moadl";

const AnnouncementList = () => {
  const routes = all_routes;
  //   const { classData, fetchLoading } = useAppSelector((state) => state.class);
  const dispatch = useAppDispatch();
  const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );
  const { announcementData, fetchLoading } = useAppSelector(
    (state) => state.announcement
  );
  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<TableData | null>(null);
  const { canPerform } = useActionPermission();

  useEffect(() => {
    if (schoolId && globalAcademicYearId) {
      dispatch(
        fetchAnnouncementAsync({
          schoolId,
          academicYearId: globalAcademicYearId,
        })
      );
    }
  }, [dispatch, schoolId, globalAcademicYearId]);

  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const getStatusBadge = (status: any) => {
    switch (status) {
      case "SENT":
        return "badge-soft-success";
      case "PENDING":
        return "badge-soft-warning";
      case "Complete":
        return "badge-soft-primary";
      case "FAILED":
        return "badge-soft-danger";
      default:
        return "badge-soft-secondary";
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a: TableData, b: TableData) => a.title.length - b.title.length,
    },

    {
      title: "Message",
      dataIndex: "message",
      render: (text: string) =>
        text.length > 80 ? `${text.substring(0, 80)}...` : text,
      sorter: (a: TableData, b: TableData) =>
        a.message.length - b.message.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: any) => (
        <>
          <span
            className={`badge ${getStatusBadge(
              status
            )} d-inline-flex align-items-center`}
          >
            <i className="ti ti-circle-filled fs-5 me-1"></i>
            {status}
          </span>
        </>
      ),
      sorter: (a: TableData, b: TableData) => a.status.localeCompare(b.status),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: TableData) => {
        const handleSendNow = async () => {
          try {
            const result = await dispatch(
              sendAnnouncementAsync({
                schoolId: Number(schoolId),
                academicYearId: Number(globalAcademicYearId),
                id: record.id,
              })
            ).unwrap();
            if (result) {
              dispatch(
                fetchAnnouncementAsync({
                  schoolId: Number(schoolId),
                  academicYearId: Number(globalAcademicYearId),
                })
              );
              // setSelectedAnnouncement(null);
            }
          } catch (error) {
            errorToast("Error sending announcement");
          }
        };

        return (
          <div className="d-flex align-items-center">
            {record.status === "PENDING" && (
              <button
                onClick={handleSendNow}
                className="btn btn-sm btn-success me-2"
              >
                Send Now
              </button>
            )}

            <div className="dropdown">
              <Link
                to="#"
                className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="ti ti-dots-vertical fs-14" />
              </Link>

              <ul className="dropdown-menu dropdown-menu-right p-3">
                {canPerform(ACTIONS.EDIT_CLASS) ? (
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => setSelectedAnnouncement(record)}
                      data-bs-toggle="modal"
                      data-bs-target="#add_announcement_modal"
                    >
                      <i className="ti ti-edit-circle me-2" />
                      Edit
                    </div>
                  </li>
                ) : (
                  <p className="text-danger fs-md d-flex align-items-center">
                    <i className="ti ti-lock me-2" /> Edit : No Access
                  </p>
                )}

                {canPerform(ACTIONS.EDIT_CLASS) ? (
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => setSelectedAnnouncement(record)}
                      data-bs-toggle="modal"
                      data-bs-target="#view_details"
                    >
                      <i className="ti ti-eye me-2" />
                      View
                    </div>
                  </li>
                ) : (
                  <p className="text-danger fs-md d-flex align-items-center">
                    <i className="ti ti-lock me-2" /> View : No Access
                  </p>
                )}

                {canPerform(ACTIONS.DELETE_CLASS) ? (
                  <li>
                    <div
                      className="dropdown-item rounded-1"
                      onClick={() => setSelectedAnnouncement(record)}
                      data-bs-toggle="modal"
                      data-bs-target="#delete-modal"
                    >
                      <i className="ti ti-trash-x me-2" />
                      Delete
                    </div>
                  </li>
                ) : (
                  <p className="text-danger fs-md d-flex align-items-center">
                    <i className="ti ti-lock me-2" /> Delete : No Access
                  </p>
                )}
              </ul>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Announcement</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Announcement </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      All Announcement
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                {/* <TooltipOption /> */}
                <div className="mb-2">
                  {canPerform(ACTIONS.ADD_CLASS) && (
                    <div
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#add_announcement_modal"
                      onClick={() => {
                        setSelectedAnnouncement(null);
                      }}
                    >
                      <i className="ti ti-square-rounded-plus me-2" />
                      Add Announcement
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Guardians List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Announcement List</h4>
                <div className="d-flex align-items-center flex-wrap"></div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Guardians List */}
                <Table
                  columns={columns}
                  dataSource={announcementData}
                  loading={fetchLoading}
                  // Selection={true}
                />
                {/* /Guardians List */}
              </div>
            </div>
            {/* /Guardians List */}
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
      <div>
        {/* Add Edit Class Section */}
        <ViewDeatilsModal selectedAnnouncement={selectedAnnouncement} />
        <AnnouncementModal selectedAnnouncement={selectedAnnouncement} />
        {/* /Add Edit Class Section */}

        {/* Delete Modal */}
        <DeleteConfirmationModal
          onConfirm={async () => {
            if (selectedAnnouncement && schoolId && globalAcademicYearId) {
              const result = await dispatch(
                deleteAnnouncementAsync({
                  schoolId: Number(schoolId),
                  academicYearId: Number(globalAcademicYearId),
                  id: selectedAnnouncement.id,
                })
              );
              if (result) {
                const cancelButton = document.getElementById(
                  "delete-cancel"
                ) as HTMLElement;
                cancelButton.click();
                dispatch(
                  fetchAnnouncementAsync({
                    schoolId,
                    academicYearId: globalAcademicYearId,
                  })
                );
                setSelectedAnnouncement(null);
              }
            }
          }}
        />
        {/* /Delete Modal */}
      </div>
    </div>
  );
};

export default AnnouncementList;
