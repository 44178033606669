import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../core/data/redux/hooks";

export const usePagePermission = () => {
  const pagePermissions = useAppSelector(
    (state) => state.auth.user?.permissions?.pagePermissions
  );
  const location = useLocation();

  const hasAccess = (pageUrl: string) => {
    return pagePermissions?.some((perm) => {
      const regex = new RegExp(`^${perm.pageUrl.replace("*", ".*")}$`);
      return regex.test(pageUrl);
    });
  };

  return { hasAccess, currentPath: location.pathname };
};
