import apiClient from "../../../api/apiClient";
import { Inquiry } from "./inquiryType";

//Base URL

const BASE_PATH = "/inquiry";

export const fetchInquiry = async (
  schoolId: number,
  academicYearId: number
): Promise<{ data: Inquiry[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-inquiries-by-school/${schoolId}/${academicYearId}`
  );
  return response?.data;
};

export const fetchInquiryId = async (
  inquiryId: number
): Promise<{ data: Inquiry[]; message: string }> => {
  const response = await apiClient.get(`${BASE_PATH}/get-inquiry/${inquiryId}`);
  return response?.data;
};

export const createInquiry = async (
  schoolId: number,
  academicYearId: number,
  newInquiry: Inquiry
): Promise<{ data: Inquiry; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/create-inquiry/${schoolId}/${academicYearId}`,
    newInquiry
  );
  return response?.data;
};
