import { useEffect, useRef, useState } from "react";
// import { classes } from "../../../core/data/json/classes";
import Table from "../../../core/common/dataTable/index";
import { activeList } from "../../../core/common/selectoption/selectoption";
import Select from "react-select";
import { TableData } from "../../../core/data/interface";
import PredefinedDateRanges from "../../../core/common/datePicker";
import CommonSelect from "../../../core/common/commonSelect";
import { Link } from "react-router-dom";
import TooltipOption from "../../../core/common/tooltipOption";
import { all_routes } from "../../router/all_routes";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import {
  deleteClassAsync,
  fetchClassesAsync,
} from "../../../core/data/redux/features/class/classSlice";
import { useActionPermission } from "../../router/useActionPermission";
import { ACTIONS } from "../../../core/data/constants";
import ClassFormModal from "./class-form-modal";
import DeleteConfirmationModal from "../../../core/common/delete-confirmation-modal";

const Classes = () => {
  const routes = all_routes;
  const { classData, fetchLoading } = useAppSelector((state) => state.class);
  const dispatch = useAppDispatch();
  const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const [selectedClass, setSelectedClass] = useState<TableData | null>(null);
  const { canPerform } = useActionPermission();

  useEffect(() => {
    if (schoolId) {
      dispatch(fetchClassesAsync({ schoolId }));
    }
  }, [schoolId]);

  const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
  const handleApplyClick = () => {
    if (dropdownMenuRef.current) {
      dropdownMenuRef.current.classList.remove("show");
    }
  };
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   render: (text: string, record: any, index: number) => (
    //     <>
    //       <Link to="#" className="link-primary">
    //         {record.id}
    //       </Link>
    //     </>
    //   ),
    //   sorter: (a: TableData, b: TableData) => a.id.length - b.id.length,
    // },

    {
      title: "Class",
      dataIndex: "className",
      sorter: (a: TableData, b: TableData) =>
        a.className.length - b.className.length,
    },
    {
      title: "Class Display Name",
      dataIndex: "classDisplayName",
      // render: () => (
      //   <>
      //     <span className="badge badge-soft-success d-inline-flex align-items-center">
      //       <i className="ti ti-circle-filled fs-5 me-1"></i>Active
      //     </span>
      //   </>
      // ),
      sorter: (a: TableData, b: TableData) =>
        a.classDisplayName.length - b.classDisplayName.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: TableData) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <Link
                  to="#"
                  className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical fs-14" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right p-3">
                  {canPerform(ACTIONS.EDIT_CLASS) ? (
                    <li>
                      <div
                        className="dropdown-item rounded-1"
                        onClick={() => {
                          setSelectedClass(record);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#add_class"
                      >
                        <i className="ti ti-edit-circle me-2" />
                        Edit
                      </div>
                    </li>
                  ) : (
                    <p className="text-danger fs-md d-flex align-items-center">
                      <i className="ti ti-lock me-2" /> Edit : No Access
                    </p>
                  )}
                  {canPerform(ACTIONS.DELETE_CLASS) ? (
                    <li>
                      <div
                        className="dropdown-item rounded-1"
                        onClick={() => {
                          setSelectedClass(record);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#delete-modal"
                      >
                        <i className="ti ti-trash-x me-2" />
                        Delete
                      </div>
                    </li>
                  ) : (
                    <p className="text-danger fs-md d-flex align-items-center">
                      <i className="ti ti-lock me-2" /> Delete : No Access
                    </p>
                  )}
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
              <div className="my-auto mb-2">
                <h3 className="page-title mb-1">Classes</h3>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to={routes.adminDashboard}>Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#">Classes </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      All Classes
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
                {/* <TooltipOption /> */}
                <div className="mb-2">
                  {canPerform(ACTIONS.ADD_CLASS) && (
                    <div
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#add_class"
                      onClick={() => {
                        setSelectedClass(null);
                      }}
                    >
                      <i className="ti ti-square-rounded-plus-filled me-2" />
                      Add Class
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Guardians List */}
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                <h4 className="mb-3">Class</h4>
                <div className="d-flex align-items-center flex-wrap">
                  {/* <div className="input-icon-start mb-3 me-2 position-relative">
                    <PredefinedDateRanges />
                  </div> */}
                  {/* <div className="dropdown mb-3 me-2">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                    >
                      <i className="ti ti-filter me-2" />
                      Filter
                    </Link>
                    <div
                      className="dropdown-menu drop-width"
                      ref={dropdownMenuRef}
                    >
                      <form>
                        <div className="d-flex align-items-center border-bottom p-3">
                          <h4>Filter</h4>
                        </div>
                        <div className="p-3 border-bottom pb-0">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Section</label>
                                <CommonSelect
                                  className="select"
                                  options={activeList}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label className="form-label">Status</label>
                                <CommonSelect
                                  className="select"
                                  options={activeList}
                                  defaultValue={activeList[0]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-3 d-flex align-items-center justify-content-end">
                          <Link to="#" className="btn btn-light me-3">
                            Reset
                          </Link>
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={handleApplyClick}
                          >
                            Apply
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div> */}
                  {/* <div className="dropdown mb-3">
                    <Link
                      to="#"
                      className="btn btn-outline-light bg-white dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <i className="ti ti-sort-ascending-2 me-2" />
                      Sort by A-Z
                    </Link>
                    <ul className="dropdown-menu p-3">
                      <li>
                        <Link to="#" className="dropdown-item rounded-1 active">
                          Ascending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Descending
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Viewed
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="dropdown-item rounded-1">
                          Recently Added
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="card-body p-0 py-3">
                {/* Guardians List */}
                <Table
                  columns={columns}
                  dataSource={classData}
                  loading={fetchLoading}
                  // Selection={true}
                />
                {/* /Guardians List */}
              </div>
            </div>
            {/* /Guardians List */}
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
      <div>
        {/* Add Edit Class Section */}
        <ClassFormModal selectedClass={selectedClass} />
        {/* /Add Edit Class Section */}

        {/* Delete Modal */}
        <DeleteConfirmationModal
          onConfirm={async () => {
            if (selectedClass) {
              const result = await dispatch(
                deleteClassAsync({
                  schoolId: Number(schoolId),
                  classId: Number(selectedClass.id),
                })
              );
              if (result) {
                const cancelButton = document.getElementById(
                  "delete-cancel"
                ) as HTMLElement;
                cancelButton.click();
                setSelectedClass(null);
              }
            }
          }}
        />
        {/* /Delete Modal */}
      </div>
    </div>
  );
};

export default Classes;
