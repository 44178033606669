import { Inquiry } from "../../../core/data/redux/features/inquiry/inquiryType";
import { GrneratePublicLink } from "../../../core/data/redux/features/schoolAdmin/schoolAdminTypes";

export const studentEnrollmentInitialValues: Inquiry = {
  classId: null,
  acedemicYearId: null,
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  genderId: null,

  parentData: [
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      genderId: null,
      parentTypeId: null,
      relationshipWithStudent: "",
      currentAddress: "",
    },
  ],

  previousSchoolDetails: {
    previousSchoolName: "",
    lastClassAttended: "",
    boardOfEducation: "",
  },

  reasonForEnrollment: {
    qualityEducation: true,
    extracurricularActivities: false,
    proximityToHome: false,
    recommendationsFromOthers: false,
    other: "",
  },

  preferredCommunicationMode: {
    call: true,
    whatsapp: false,
    email: true,
  },

  interestedInSchoolVisit: true,
  additionalComments: "",
};

export const admissonFormSendInitialValues: GrneratePublicLink = {
  classId:null,
  inquiryId: null,
  academicYearId: null,
  validFor: null,
  phoneNumber: "",
  email: "",
};
