import { Link, useLocation, useParams } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";

export const StudentDetailsNavbar = ({ student }: { student?: string }) => {
  let routes = all_routes;
  const location = useLocation();
  const { studentClassSectionId } = useParams();

  const navItems = [
    {
      path: `${routes.studentDetail}/${studentClassSectionId}?student=personal`,
      icon: "ti ti-school",
      label: "Student Details",
      visible: true,
      active: student === "personal" && "active",
    },
    // {
    //   path: routes.studentTimeTable,
    //   icon: "ti ti-table-options",
    //   label: "Time Table",
    //   visible: false,
    // },
    // {
    //   path: routes.studentLeaves,
    //   icon: "ti ti-calendar-due",
    //   label: "Leave & Attendance",
    //   visible: false,
    // },
    {
      path: `${routes.studentDetail}/${studentClassSectionId}?student=fees`,
      icon: "ti ti-report-money",
      label: "Fees",
      visible: true,
      active: student === "fees" && "active",
    },
    // {
    //   path: routes.studentResult,
    //   icon: "ti ti-bookmark-edit",
    //   label: "Exam & Results",
    //   visible: false,
    // },
    // {
    //   path: routes.studentLibrary,
    //   icon: "ti ti-books",
    //   label: "Library",
    //   visible: false,
    // },
  ];

  return (
    <ul className="nav nav-tabs nav-tabs-bottom mb-4">
      {navItems
        .filter((item) => item.visible) // Only include visible items
        .map((item) => (
          <li key={item.path}>
            <Link to={item.path} className={`nav-link ${item.active}`}>
              <i className={`${item.icon} me-2`} />
              {item.label}
            </Link>
          </li>
        ))}
    </ul>
  );
};
