import dayjs from "dayjs";
import { Sibling } from "../../../../core/data/redux/features/admission/admissionTypes";
export interface SiblingsData {
  firstName: string;
  middleName: string;
  lastName: string;
  relationship: string;
  classId: number | null;
  classSectionName: string;
}

export interface ParentData {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  genderId: number | null;
  parentTypeId: number | null;
  relationshipWithStudent: string;
  currentAddress: string;
  qualification: string;
  businessName: string;
  businessAddress: string;
}

export interface UpdateParentData {
  id: number | null;
  studentId: number | null;
  userId: number | null;
  createdBy: number | null;
  createdAt: dayjs.Dayjs | null;
  updatedBy: dayjs.Dayjs | null;
  updatedAt: dayjs.Dayjs | null;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  genderId: number | null;
  parentTypeId: number | null;
  relationshipWithStudent: string;
  currentAddress: string;
  qualification: string;
  businessName: string;
  businessAddress: string;
}

// Initial values for create admission form
export interface CreateAdmissionInitialValues {
  inquiryId?:number;
  academicYearId: number | null;
  classId: number | null;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: null | dayjs.Dayjs;
  email: string;
  phoneNumber: string;
  genderId: number | null;
  interviewDate: null | dayjs.Dayjs;
  schoolId: null;
  aadharCardNumber: string;
  studentBankAccountNumber: string;
  studentBankAccountIFSCCode: string;
  studentBankBranchName: string;
  studentBankAddress: string;
  bloodGroup: string;
  cast: string;
  castCertificate: string;
  isConveyanceNeeded: string;
  lastSchoolName: string;
  isSpecialMedicalNeed: string;
  specialMedicalNeedDetails: string;
  declaration: string;
  declarationSignature: string;
  monthlyIncomeCurrencyId: number | null;
  monthlyIncome: number | null;
  incomeTaxPayer: string;
  PANNumber: string;
  religion: string;
  currentAddress: string;
  permanentAddress: string;
  PENNumber: string;
  notifyStudentAndParent: string;
  parentData: ParentData[];
  parentType: string;
  declarative: string;
  siblings: Sibling[];
}

export const createAdmissionInitialValues: CreateAdmissionInitialValues = {
  academicYearId: null,
  classId: null,
  firstName: "",
  middleName: "",
  lastName: "",
  dob: null,
  email: "",
  phoneNumber: "",
  genderId: null,
  // address: "",
  interviewDate: null,
  schoolId: null,
  aadharCardNumber: "",
  studentBankAccountNumber: "",
  studentBankAccountIFSCCode: "",
  studentBankBranchName: "",
  studentBankAddress: "",
  bloodGroup: "",
  cast: "",
  castCertificate: "",
  isConveyanceNeeded: "",
  lastSchoolName: "",
  isSpecialMedicalNeed: "",
  specialMedicalNeedDetails: "",
  declaration: "",
  declarationSignature: "",
  monthlyIncomeCurrencyId: 67,
  monthlyIncome: null,
  incomeTaxPayer: "",
  PANNumber: "",
  religion: "",
  currentAddress: "",
  permanentAddress: "",
  PENNumber: "",
  notifyStudentAndParent: "Yes",
  parentData: [
    {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      genderId: null,
      parentTypeId: null,
      relationshipWithStudent: "",
      currentAddress: "",
      qualification: "",
      businessName: "",
      businessAddress: "",
    },
  ],
  parentType: "",
  declarative: "",
  siblings: [],
};

// Initial values for update admission form
export interface UpdateAdmissionInitialValues {
  academicYearId: number | null;
  classId: number | null;
  classSectionId: number | null;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: null | dayjs.Dayjs;
  email: string;
  phoneNumber: string;
  genderId: number | null;
  interviewDate: null | dayjs.Dayjs;
  admissionStatusId: number | null;
  approvalDate: null | dayjs.Dayjs;
  admissionDate: null | dayjs.Dayjs;
  schoolId: null;
  aadharCardNumber: string;
  studentBankAccountNumber: string;
  studentBankAccountIFSCCode: string;
  studentBankBranchName: string;
  studentBankAddress: string;
  bloodGroup: string;
  cast: string;
  castCertificate: string;
  isConveyanceNeeded: string;
  lastSchoolName: string;
  isSpecialMedicalNeed: string;
  specialMedicalNeedDetails: string;
  declaration: string;
  declarationSignature: string;
  monthlyIncomeCurrencyId: number | null;
  monthlyIncome: number | null;
  incomeTaxPayer: string;
  PANNumber: string;
  religion: string;
  currentAddress: string;
  permanentAddress: string;
  PENNumber: string;
  notifyStudentAndParent: string;
  parentData: UpdateParentData[];
  siblings: Sibling[];
  parentType: string;
  declarative: string;
}

export const updateAdmissionInitialValues: UpdateAdmissionInitialValues = {
  academicYearId: null,
  classId: null,
  classSectionId: null,
  firstName: "",
  middleName: "",
  lastName: "",
  dob: null,
  email: "",
  phoneNumber: "",
  genderId: null,
  interviewDate: null,
  admissionStatusId: null,
  approvalDate: null,
  admissionDate: null,
  schoolId: null,
  aadharCardNumber: "",
  studentBankAccountNumber: "",
  studentBankAccountIFSCCode: "",
  studentBankBranchName: "",
  studentBankAddress: "",
  bloodGroup: "",
  cast: "",
  castCertificate: "",
  isConveyanceNeeded: "",
  lastSchoolName: "",
  isSpecialMedicalNeed: "",
  specialMedicalNeedDetails: "",
  declaration: "",
  declarationSignature: "",
  monthlyIncomeCurrencyId: null,
  monthlyIncome: null,
  incomeTaxPayer: "",
  PANNumber: "",
  religion: "",
  currentAddress: "",
  permanentAddress: "",
  PENNumber: "",
  notifyStudentAndParent: "",
  parentData: [
    {
      updatedBy: null,
      updatedAt: null,
      createdBy: null,
      createdAt: null,
      studentId: null,
      userId: null,
      id: null,
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      genderId: 1,
      parentTypeId: 1,
      relationshipWithStudent: "",
      currentAddress: "",
      qualification: "",
      businessName: "",
      businessAddress: "",
    },
  ],
  siblings: [],
  parentType: "",
  declarative: "",
};
