import { Link } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import { Button, Upload, Tag, Space } from "antd";
import React, { useState, useEffect } from "react";
import UploadButton from "../../../../core/common/UploadButton";
import PageHeader from "../../../../core/common/PageHeader";
import FeatherIcon from "feather-icons-react";
import dayjs from "dayjs";
import CommonSelect, { Option } from "../../../../core/common/commonSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import { fetchClassesAsync } from "../../../../core/data/redux/features/class/classSlice";
import { errorToast, successToast } from "../../../../core/common/toast/toast";
import { generateExcelForMultipleAdmission } from "../../../../core/data/redux/features/admission/admissionApi";
import * as XLSX from "xlsx";
import { studentArraySchema } from "./arrayOfAdmissionSchema";
import {
  createMultipleAdmissionsAsync,
  getRegisterMultipleStudentsBySchoolAndClassAsync,
  resetMultipleAdmissionsData,
} from "../../../../core/data/redux/features/admission/admissionSlice";
import Table from "../../../../core/common/dataTable/index";

const AddMultipleAdmissions = () => {
  let routes = all_routes;
  const [fileState, setFileState] = useState({
    excelFileUrl: null,
    uploadedFile: null,
    uploadedFileName: null,
  });
  const [selectedClass, setSelectedClass] = useState<number | null>(null);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    schoolId,
    globalAcademicYearId,
    classData,
    academicYearData,
    multipleAdmissionsData,
    fetchLoading,
  } = useAppSelector((state) => ({
    schoolId: state.auth?.user?.schoolId,
    globalAcademicYearId: state.academicYear.globalAcedemicYearId,
    classData: state.class.classData,
    academicYearData: state.academicYear.academicYearData,
    multipleAdmissionsData: state.admission.multipleAdmissionsData,
    fetchLoading: state.admission.fetchLoading,
  }));

  const { classOptions, academicYearOptions } = React.useMemo(
    () => ({
      classOptions:
        classData?.map((item) => ({
          value: item.id as number,
          label: item.className,
        })) || [],
      academicYearOptions:
        academicYearData?.map((item) => ({
          value: item.id as number,
          label: item.academicYearName,
        })) || [],
    }),
    [classData, academicYearData]
  );

  const fetchStudentData = React.useCallback(async () => {
    if (selectedClass && schoolId && globalAcademicYearId) {
      const selectedAcademicYearName = academicYearOptions?.find(
        (option) => option.value === globalAcademicYearId
      )?.label;
      const selectedClassName = classOptions?.find(
        (option) => option.value === selectedClass
      )?.label;

      if (selectedClassName && selectedAcademicYearName) {
        await dispatch(
          getRegisterMultipleStudentsBySchoolAndClassAsync({
            schoolId,
            academicYearName: selectedAcademicYearName,
            className: selectedClassName,
          })
        );
      }
    }
  }, [
    selectedClass,
    schoolId,
    globalAcademicYearId,
    academicYearOptions,
    classOptions,
    dispatch,
  ]);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await fetchStudentData();
    setIsRefreshing(false);
  };

  useEffect(() => {
    if (schoolId) {
      dispatch(fetchClassesAsync({ schoolId }));
    }
  }, [schoolId, dispatch]);

  useEffect(() => {
    fetchStudentData();
    return () => {
      dispatch(resetMultipleAdmissionsData());
    };
  }, [fetchStudentData, dispatch]);

  const handleClassChange = (value: number) => {
    setSelectedClass(value);
    setFileState({
      excelFileUrl: null,
      uploadedFile: null,
      uploadedFileName: null,
    });
  };

  const handleGenerateExcel = async () => {
    if (!selectedClass || !schoolId || !globalAcademicYearId) {
      errorToast("Please select a class first");
      return;
    }

    setIsGenerating(true);

    try {
      // Simulate API call to generate Excel
      const result = await generateExcelForMultipleAdmission(
        schoolId,
        globalAcademicYearId,
        selectedClass
      );
      setFileState({
        excelFileUrl: result?.data,
        uploadedFile: null,
        uploadedFileName: null,
      });
      successToast("Excel file generated successfully");
    } catch (error) {
      errorToast("Failed to generate Excel file");
    } finally {
      setIsGenerating(false);
    }
  };

  const handleFileUpload = (info: any) => {
    if (!selectedClass) {
      errorToast("Please select a class first");
      return;
    }
    if (info.file.status === "done") {
      setFileState({
        excelFileUrl: null,
        uploadedFile: info.file.originFileObj,
        uploadedFileName: info.file.name,
      });

      // Read the Excel file
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = e.target?.result;
          const workbook = XLSX.read(data, { type: "array" });
          const studentSheetName = workbook.SheetNames[0];
          const studentSheet = workbook.Sheets[studentSheetName];
          const extractedKeysForSheet1 = XLSX.utils.sheet_to_json(
            studentSheet,
            {
              header: 1,
              range: 0,
              raw: false,
            }
          )[1] as string[];
          console.log("Extracted Keys:", extractedKeysForSheet1);

          const studentSheetData = XLSX.utils.sheet_to_json(studentSheet, {
            header: extractedKeysForSheet1,
            range: 2,
            raw: false,
          });
          let studentSheetFinalData = studentSheetData.filter(
            (item: any) => item.userUniqueNumber
          );

          let className = classOptions?.find(
            (option) => option.value === selectedClass
          )?.label;

          let checkClassStudentMapping = studentSheetFinalData.every(
            (item: any) => item.className === className
          );
          if (!checkClassStudentMapping) {
            errorToast("Class name mismatch in the Excel file");
            return;
          }
          let studentSheetDataWithParentData = studentSheetFinalData.map(
            (item: any) => {
              const parentSheetName = workbook.SheetNames[1];
              const parentSheet = workbook.Sheets[parentSheetName];
              const extractedKeysForSheet2 = XLSX.utils.sheet_to_json(
                parentSheet,
                {
                  header: 1,
                  range: 0,
                  raw: false,
                }
              )[1] as string[];

              const parentSheetData = XLSX.utils.sheet_to_json(parentSheet, {
                header: extractedKeysForSheet2,
                range: 2,
                raw: false, // Try with raw:false to get formatted values
                defval: null, // Set default value for empty cells
              });

              let parentData =
                parentSheetData?.filter(
                  (parentItem: any) =>
                    parentItem?.userUniqueNumber === item?.userUniqueNumber
                ) || [];

              return {
                ...item,
                parentData,
              };
            }
          );

          let studentSheetDataWithParentDataWithFeeCategory =
            studentSheetDataWithParentData.map((item: any) => {
              const feeCategorySheetName = workbook.SheetNames[2];
              const feeCategorySheet = workbook.Sheets[feeCategorySheetName];
              const extractedKeysForSheet3 = XLSX.utils.sheet_to_json(
                feeCategorySheet,
                {
                  header: 1,
                  range: 0,
                  raw: false,
                }
              )[1] as string[];

              const feeCategorySheetData = XLSX.utils.sheet_to_json(
                feeCategorySheet,
                {
                  header: extractedKeysForSheet3,
                  range: 2,
                  raw: false,
                }
              );

              let feeCategoryData = feeCategorySheetData.filter(
                (feeCategoryItem: any) =>
                  feeCategoryItem.userUniqueNumber === item.userUniqueNumber
              );

              return {
                ...item,
                appliedDiscount: {
                  feeCategories: feeCategoryData,
                },
              };
            });

          let studentSheetDataWithParentDataWithFeeCategoryWithDiscountDetails =
            studentSheetDataWithParentDataWithFeeCategory.map((item: any) => {
              const discountSheetName = workbook.SheetNames[3];
              const discountSheet = workbook.Sheets[discountSheetName];
              const extractedKeysForSheet4 = XLSX.utils.sheet_to_json(
                discountSheet,
                {
                  header: 1,
                  range: 0,
                  raw: false,
                }
              )[1] as string[];

              const discountSheetData = XLSX.utils.sheet_to_json(
                discountSheet,
                {
                  header: extractedKeysForSheet4,
                  range: 2,
                  raw: false,
                }
              );

              let discountData = discountSheetData.filter(
                (discountItem: any) =>
                  discountItem.userUniqueNumber === item.userUniqueNumber
              );

              return {
                ...item,
                appliedDiscount: {
                  ...item.appliedDiscount,
                  discountTypes: discountData,
                },
              };
            });

          // console.log(
          //   "studentSheetDataWithParentDataWithFeeCategoryWithDiscountDetails",
          //   studentSheetDataWithParentDataWithFeeCategoryWithDiscountDetails
          // );
          try {
            let validatedData = await studentArraySchema.validate(
              studentSheetDataWithParentDataWithFeeCategoryWithDiscountDetails,
              { abortEarly: false } // This allows collecting all validation errors
            );

            validatedData = validatedData.map((item: any) => {
              return {
                ...item,
                dob: dayjs(item.dob).format("YYYY-MM-DD"),
                interviewDate: dayjs(item.interviewDate).format("YYYY-MM-DD"),
                approvalDate: dayjs(item.approvalDate).format("YYYY-MM-DD"),
                admissionDate: dayjs(item.admissionDate).format("YYYY-MM-DD"),
              };
            });

            if (schoolId) {
              await dispatch(
                createMultipleAdmissionsAsync({
                  schoolId,
                  admissionsData: {
                    studentData: validatedData.map((student: any) => ({
                      ...student,
                      dob: student.dob,
                      interviewDate: student.interviewDate,
                      approvalDate: student.approvalDate,
                      admissionDate: student.admissionDate,
                    })),
                  },
                })
              );
            } else {
              errorToast("School ID not found");
            }
          } catch (error: any) {
            console.error("Array is invalid:", error);

            if (error.inner) {
              // Handle Yup validation errors
              const errors = error.inner.reduce((acc: any, err: any) => {
                const path = err.path || "unknown";
                if (!acc[path]) {
                  acc[path] = [];
                }
                acc[path].push(err.message);
                return acc;
              }, {});

              // Show all validation errors
              const errorMessage = Object.entries(errors)
                .map(
                  ([field, messages]: [string, any]) =>
                    `${field}: ${messages.join(", ")}`
                )
                .join("\n");
              errorToast(errorMessage);
            } else {
              // Handle other types of errors
              errorToast(error.message || "Invalid data in Excel file");
            }
          }
        } catch (error) {
          errorToast("Error reading Excel file");
          console.error("Error reading Excel:", error);
        } finally {
          setRefresh(!refresh);
        }
      };

      reader.readAsArrayBuffer(info.file.originFileObj);
    } else if (info.file.status === "error") {
      errorToast(`${info.file.name} file upload failed.`);
    }
  };

  const beforeUpload = (file: any) => {
    const isExcel =
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel";

    if (!isExcel) {
      errorToast("You can only upload Excel files!");
    }

    return isExcel || Upload.LIST_IGNORE;
  };

  const handleRemoveFile = () => {
    setFileState({
      excelFileUrl: null,
      uploadedFile: null,
      uploadedFileName: null,
    });
  };

  const columns = [
    {
      title: "Student Name",
      dataIndex: "studentName",
      key: "studentName",
      render: (text: string, record: any) => (
        <span>{`${record.firstName || ""} ${record.middleName || ""} ${
          record.lastName || ""
        }`}</span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => dayjs(text).format("DD-MM-YYYY HH:mm"),
    },
    {
      title: "Academic Year",
      dataIndex: "academicYearName",
      key: "academicYearName",
    },
    {
      title: "Class & Section",
      dataIndex: "classSection",
      key: "classSection",
      render: (text: string, record: any) => (
        <span>{`${record.className} - ${record.classSectionName}`}</span>
      ),
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      render: (text: string, record: any) => (
        <Space direction="vertical" size="small">
          <span>{record.email}</span>
          <span>{record.phoneNumber}</span>
        </Space>
      ),
    },
    {
      title: "Reason of Failure",
      dataIndex: "reasonOfFailure",
      key: "reasonOfFailure",
      render: (text: string) => <span>{text || "-"}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => {
        let color;
        switch (text) {
          case "PROCESSING":
            color = "blue";
            break;
          case "FAILED":
            color = "red";
            break;
          case "COMPLETED":
            color = "green";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      {classOptions?.length && (
        <div className="page-wrapper">
          <div className="content content-two">
            {/* Page Header */}
            <PageHeader
              title="Add Multiple Admission"
              breadcrumbs={[
                { label: "Dashboard", path: routes.adminDashboard },
                { label: "Admission", path: routes.admissionList },
                { label: "Add Multiple Admission" },
              ]}
            />
            {/* /Page Header */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center mb-4">
                      <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
                        <CommonSelect
                          field={{
                            name: "class",
                            value: selectedClass,
                          }}
                          form={{
                            setFieldValue: (_: string, value: number) =>
                              handleClassChange(value),
                            setFieldTouched: () => {},
                          }}
                          options={classOptions as Option[]}
                          className="w-100"
                          disabled={false}
                          isMulti={false}
                        />
                      </div>
                      <div className="col-lg-9 col-md-8 col-sm-12">
                        <div className="d-flex flex-wrap align-items-center justify-content-md-end justify-content-sm-start">
                          <Button
                            type="primary"
                            size="small"
                            className="btn btn-primary me-2 mb-2 d-flex align-items-center justify-content-center"
                            onClick={handleGenerateExcel}
                            disabled={!selectedClass || isGenerating}
                            loading={isGenerating}
                            style={{ minWidth: "130px", height: "32px" }}
                          >
                            {!isGenerating && (
                              <FeatherIcon
                                icon="file"
                                size={14}
                                className="me-1"
                              />
                            )}
                            Generate Template
                          </Button>
                          {fileState.excelFileUrl ? (
                            <Button
                              type="primary"
                              size="small"
                              className="btn btn-success me-2 mb-2 d-flex align-items-center justify-content-center"
                              href={fileState.excelFileUrl}
                              download="admission_template.xlsx"
                              style={{ minWidth: "130px", height: "32px" }}
                            >
                              <FeatherIcon
                                icon="download"
                                size={14}
                                className="me-1"
                              />
                              Download
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row align-items-center mb-2">
                      <div className="col-12">
                        <div className="d-flex flex-wrap align-items-center">
                          <Upload
                            name="excelFile"
                            accept=".xlsx,.xls"
                            showUploadList={false}
                            customRequest={({ file, onSuccess }: any) => {
                              // This prevents the default upload behavior which makes API calls
                              setTimeout(() => {
                                if (onSuccess) onSuccess("ok");
                              }, 0);
                            }}
                            disabled={!selectedClass}
                            beforeUpload={beforeUpload}
                            onChange={handleFileUpload}
                            maxCount={1}
                          >
                            <Button
                              type="primary"
                              size="small"
                              className="btn btn-primary me-3 mb-1 d-flex align-items-center justify-content-center"
                              disabled={!selectedClass}
                              style={{ minWidth: "130px", height: "32px" }}
                            >
                              <FeatherIcon
                                icon="upload"
                                size={14}
                                className="me-1"
                              />
                              Upload Excel
                            </Button>
                          </Upload>
                          {fileState.uploadedFileName && (
                            <div className="d-flex align-items-center mt-1 mt-sm-0 mb-1">
                              <Tag color="blue" className="p-2">
                                <Space>
                                  <FeatherIcon icon="file" size={14} />
                                  {fileState.uploadedFileName}
                                  <span onClick={handleRemoveFile}>
                                    <FeatherIcon
                                      icon="trash-2"
                                      size={14}
                                      className="ms-2 text-danger cursor-pointer"
                                    />
                                  </span>
                                </Space>
                              </Tag>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                        <div className="alert alert-info">
                          <p className="mb-0">
                            <FeatherIcon
                              icon="info"
                              size={14}
                              className="me-2"
                            />
                            Please select a class and generate an Excel
                            template. Fill in the student details in the Excel
                            file and upload it to add multiple admissions at
                            once.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
                  <h4 className="mb-3">Upload Status</h4>
                  <Button
                    type="primary"
                    size="small"
                    className="btn btn-primary d-flex align-items-center justify-content-center mb-3"
                    onClick={handleRefresh}
                    loading={isRefreshing}
                    style={{
                      width: "32px",
                      height: "32px",
                      padding: 0,
                      borderRadius: "6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!isRefreshing && (
                      <FeatherIcon icon="refresh-cw" size={16} />
                    )}
                  </Button>
                </div>
                <div className="card-body p-0 py-3">
                  <Table
                    dataSource={multipleAdmissionsData}
                    columns={columns}
                    loading={fetchLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* /Page Wrapper */}
    </>
  );
};

export default React.memo(AddMultipleAdmissions);
