import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FeeCategoryState } from "./feeCategoryType";

import { fetchFeeCategories } from "./feeCategoryApi";
import { errorToast } from "../../../../common/toast/toast";

const initialState: FeeCategoryState = {
  feeCategoryData: [],
  fetchLoading: false,
  error: null,
};

export const fetchFeeCategoriesAsync = createAsyncThunk(
    "feeCategory/fetchFeeCategories",
    async (schoolId: number, thunkAPI) => {
      try {
        return await fetchFeeCategories(schoolId);
      } catch (error: any) {
        return thunkAPI.rejectWithValue(
          error.response?.data?.message || "Failed to fetch fee categories"
        );
      }
    }
  );
  
const feeCategorySlice = createSlice({
  name: "feeCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fee Categories
      .addCase(fetchFeeCategoriesAsync.pending, (state) => {
        state.fetchLoading = true;
      })
      .addCase(fetchFeeCategoriesAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.feeCategoryData = action.payload.data;
      })
      .addCase(fetchFeeCategoriesAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch fee categories";
        errorToast(state.error);
      });
  },
});

export default feeCategorySlice.reducer;
