import * as Yup from "yup";

export const commonPhoneSchema = (isMandatory: Boolean, message?: string) =>
  Yup.string()
    .nullable()
    .when([], {
      is: () => isMandatory,
      then: (schema) =>
        schema
          .required(message || "Phone Number is required")
          .phone("*" as any, "Please enter a valid Phone Number"),
      otherwise: (schema) =>
        schema.test({
          name: "phoneValidation",
          skipAbsent: true,
          test(value, ctx) {
            if (!value) return true;
            if (!value || !value.includes(" ")) return true;
            const spaceIndex = value.indexOf(" ");
            let splitValue = value.slice(spaceIndex + 1);
            if (!splitValue) return true;

            const isValid = schema
              .phone("*" as any, "Please enter a valid Phone Number")
              .isValidSync(value);

            if (!isValid) {
              return ctx.createError({
                message: "Please enter a valid Phone Number",
              });
            }

            return true;
          },
        }),
    });

export const studentEnrollmentValidationSchema = Yup.object({
  classId: Yup.number().nullable().required("Class is required"),
  firstName: Yup.string().required("First Name is required"),
  middleName: Yup.string().notRequired(),
  lastName: Yup.string().required("Last Name is required"),
  dateOfBirth: Yup.date().notRequired(),
  genderId: Yup.number().nullable().required("Gender is required"),

  parentData: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required("Parent's First Name is required"),
      middleName: Yup.string().notRequired(),
      lastName: Yup.string().required("Parent's Last Name is required"),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Parent's Email is required"),
      phoneNumber: commonPhoneSchema(true, "Parent's Phone number is required"),
      genderId: Yup.number().nullable().required("Parent's Gender is required"),
      parentTypeId: Yup.number().nullable().required("Parent Type is required"),
      relationshipWithStudent: Yup.string().required(
        "Relationship with student is required"
      ),
      currentAddress: Yup.string().required("Current address is required"),
    })
  ),

  previousSchoolDetails: Yup.object().shape({
    previousSchoolName: Yup.string().notRequired(),
    lastClassAttended: Yup.string().notRequired(),
    boardOfEducation: Yup.string().notRequired(),
  }),

  reasonForEnrollment: Yup.object().shape({
    qualityEducation: Yup.boolean().required(),
    extracurricularActivities: Yup.boolean().required(),
    proximityToHome: Yup.boolean().required(),
    recommendationsFromOthers: Yup.boolean().required(),
    other: Yup.string().notRequired(),
  }),

  preferredCommunicationMode: Yup.object().shape({
    call: Yup.boolean().required(),
    whatsapp: Yup.boolean().required(),
    email: Yup.boolean().required(),
  }),

  interestedInSchoolVisit: Yup.boolean().required(),
  additionalComments: Yup.string().notRequired(),
});

export const admissonFormSendValidationSchema = Yup.object({
  inquiryId: Yup.number().required(),
  academicYearId: Yup.number().required(),
  validFor: Yup.number().required(),
  phoneNumber: commonPhoneSchema(true, "Parent's Phone number is required"),
  email: Yup.string().required(),
});
