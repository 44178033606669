import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { TableData } from "../../../core/data/interface";
import { all_routes } from "../../router/all_routes";
import Table from "../../../core/common/dataTable/index";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import DeleteConfirmationModal from "../../../core/common/delete-confirmation-modal";
import {
  fetchFeeDiscountSchoolMappingAsync,
  deleteFeeDiscountSchoolMappingAsync,
} from "../../../core/data/redux/features/feeDiscountTypeSchoolMapping/feeDiscountTypeSchoolMappingSlice";
import FeeDiscountFormModal from "./components/fee-discount-form-modal";
import { useActionPermission } from "../../router/useActionPermission";
import { ACTIONS } from "../../../core/data/constants";
const FeeDiscount = () => {
  const routes = all_routes;
  const [selectedFeeDiscount, setselectedFeeDiscount] =
    useState<TableData | null>(null);
  const { canPerform } = useActionPermission();
  const dispatch = useAppDispatch();
  const { feeSchoolMappingData, fetchLoading } = useAppSelector(
    (state) => state.feeDiscountmap
  );
  const schoolId = useAppSelector((state) => state.auth?.user?.schoolId);

  console.log(feeSchoolMappingData, "feeSchoolMappingData");
  useEffect(() => {
    dispatch(fetchFeeDiscountSchoolMappingAsync(Number(schoolId)));
  }, [schoolId]);

  const columns = [
    {
      title: "Fee Discount Name",
      dataIndex: "feeDiscountName",
      sorter: (a: TableData, b: TableData) =>
        a.academicYearName.length - b.academicYearName.length,
    },
    // {
    //   title: "Discount Percentage",
    //   dataIndex: "discountPercentage",
    //   sorter: (a: TableData, b: TableData) =>
    //     a.academicYearDisplayName.length - b.academicYearDisplayName.length,
    // },
    {
      title: "Maximum Allowed Discount Percentage",
      dataIndex: "maximumAllowedDiscountPercentage",
      sorter: (a: TableData, b: TableData) =>
        a.maximumAllowedDiscountPercentage.length -
        b.maximumAllowedDiscountPercentage.length,
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: TableData) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="dropdown">
                <Link
                  to="#"
                  className="btn btn-white btn-icon btn-sm d-flex align-items-center justify-content-center rounded-circle p-0"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="ti ti-dots-vertical fs-14" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right p-3">
                  {canPerform(ACTIONS.EDIT_FEE_DISCOUNT) ? (
                    <li>
                      <div
                        className="dropdown-item rounded-1"
                        onClick={() => {
                          setselectedFeeDiscount(record);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#add_fee_discount_modal"
                      >
                        <i className="ti ti-edit-circle me-2" />
                        Edit
                      </div>
                    </li>
                  ) : (
                    <p className="text-danger fs-md d-flex align-items-center">
                      <i className="ti ti-lock me-2" />  Edit : No Access
                    </p>
                  )}
                  {canPerform(ACTIONS.DELETE_FEE_DISCOUNT) ? (
                    <li>
                      <div
                        className="dropdown-item rounded-1"
                        onClick={() => {
                          setselectedFeeDiscount(record);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#delete-modal"
                      >
                        <i className="ti ti-trash-x me-2" />
                        Delete
                      </div>
                    </li>
                  ) : (
                    <p className="text-danger fs-md d-flex align-items-center">
                      <i className="ti ti-lock me-2" />  Delete : No Access
                    </p>
                  )}
                </ul>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="d-md-flex d-block align-items-center justify-content-between mb-3">
            <div className="my-auto mb-2">
              <h3 className="page-title mb-1">Discount Settings</h3>
              <nav>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to={routes.adminDashboard}>Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Discount Settings </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    All Discount Settings
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex my-xl-auto right-content align-items-center flex-wrap">
              {/* <TooltipOption /> */}
              <div className="mb-2">
                {canPerform(ACTIONS.ADD_FEE_DISCOUNT) && (
                  <div
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_fee_discount_modal"
                    onClick={() => {
                      setselectedFeeDiscount(null);
                    }}
                  >
                    <i className="ti ti-square-rounded-plus-filled me-2" />
                    Add Discount Settings
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Guardians List */}
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between flex-wrap pb-0">
              <h4 className="mb-3">Discount Settings</h4>
              <div className="d-flex align-items-center flex-wrap"></div>
            </div>
            <div className="card-body p-0 py-3">
              {/* Guardians List */}
              <Table
                columns={columns}
                dataSource={feeSchoolMappingData}
                loading={fetchLoading}
                // Selection={true}
              />
              {/* /Guardians List */}
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* Add Edit Class Section */}
        <FeeDiscountFormModal selectedFeeDiscount={selectedFeeDiscount} />
        {/* /Add Edit Class Section */}

        {/* Delete Modal */}
        <DeleteConfirmationModal
          onConfirm={async () => {
            if (selectedFeeDiscount) {
              const result = await dispatch(
                deleteFeeDiscountSchoolMappingAsync(selectedFeeDiscount.id)
              );
              if (result) {
                const cancelButton = document.getElementById(
                  "delete-cancel"
                ) as HTMLElement;
                cancelButton.click();
                setselectedFeeDiscount(null);
              }
            }
          }}
        />
        {/* /Delete Modal */}
      </div>
    </div>
  );
};

export default FeeDiscount;
