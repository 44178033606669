import { useCallback, useEffect, useMemo, useRef } from "react";
import { Formik, ErrorMessage, Field, Form, FieldArray } from "formik";
import dayjs from "dayjs";
import CommonSelect from "../../../core/common/commonSelect";
import { useAppDispatch, useAppSelector } from "../../../core/data/redux/hooks";
import { fetchClassesAsync } from "../../../core/data/redux/features/class/classSlice";
import { Class } from "../../../core/data/redux/features/class/classTypes";
import { createFeeInitialValue } from "./initialValue";
import { createFeeValidationSchema, updateFeeValidationSchema } from "./schema";
import {
  createFeeAsync,
  fetchFeesByCriteriaAsync,
  updateFeeAsync,
} from "../../../core/data/redux/features/fee/feeSlice";
import {
  fetchAcademicYearsAsync,
  fetchCurrencyListAsync,
  fetchFeeCategoryFrequenciesAsync,
  fetchFeeDiscountTypesAsync,
} from "../../../core/data/redux/features/utils/utilsSlice";
import { fetchFeeCategoriesAsync } from "../../../core/data/redux/features/feeCategory/feeCategorySlice";
import { Currency } from "../../../core/data/redux/features/utils/utilsType";
import { DatePicker } from "antd";
import { Fee, FeeDueDate } from "../../../core/data/redux/features/fee/feeType";
// import { s } from "@fullcalendar/core/internal-common";
import { errorToast } from "../../../core/common/toast/toast";

const FeeFormModal = ({ selectedFee }: any) => {
  console.log("selectedFee1234", selectedFee);
  const classData = useAppSelector((state) => state.class.classData);
  const feeCategoryData = useAppSelector(
    (state) => state.feeCategory.feeCategoryData
  );
  const feeCategoryFrequencyData = useAppSelector(
    (state) => state.utils.feeCategoryFrequencyData
  );

  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );
  const academicYearData = useAppSelector(
    (state) => state.academicYear.academicYearData
  );
  const currecyData = useAppSelector((state) => state.utils.currencyData);
  const feeDiscountTypeData = useAppSelector(
    (state) => state.utils.feeDiscountTypeData
  );

  const schoolId = useAppSelector((state) => state.auth.user?.schoolId);
  const formRef = useRef(null);

  const classDataOption = classData.map((item: Class) => {
    return { value: item.id, label: item.className };
  });

  console.log(academicYearData, "academicYearData");

  const dispatch = useAppDispatch();

  // Add options for select fields
  const feeCategoryOptions = feeCategoryData.map((item: any) => ({
    value: item.id,
    label: item.feeCategoryName,
  }));

  const feeCategoryFrequencyOptions = feeCategoryFrequencyData.map(
    (item: any) => ({
      value: item.id,
      label: item.feeCategoryFrequencyName,
    })
  );
  // console.log( feeCategoryFrequencyOptions)

  const academicYearOptions = academicYearData.map((item: any) => ({
    value: item.id,
    label: item.academicYearName,
  }));

  const currencyOptions = currecyData.map((item: Currency) => ({
    value: item.id,
    label: item.CurrencyISOCode,
  }));

  const feeDiscountTypeOptions = [
    { value: null, label: "Select Discount Type" },
    ...feeDiscountTypeData.map((item: any) => ({
      value: item.id,
      label: item.feeDiscountName,
    })),
  ];

  useEffect(() => {
    if (schoolId && !classData.length) {
      dispatch(fetchClassesAsync({ schoolId }));
    }
    if (schoolId && !feeCategoryData.length) {
      dispatch(fetchFeeCategoriesAsync(schoolId));
    }
    if (!feeCategoryFrequencyData.length) {
      dispatch(fetchFeeCategoryFrequenciesAsync());
    }
    if (!academicYearData.length && schoolId) {
      dispatch(fetchAcademicYearsAsync(schoolId));
    }
    if (!feeDiscountTypeData.length) {
      dispatch(fetchFeeDiscountTypesAsync());
    }

    if (!currecyData.length) {
      dispatch(fetchCurrencyListAsync());
    }
  }, [schoolId]);

  console.log();

  useEffect(() => {
    const modalElement = document.getElementById("add_fee");

    const handleModalClose = () => {
      if (formRef.current) {
        // @ts-ignore
        formRef.current.resetForm();
      }
    };

    modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  const finalInitialValues = useMemo(() => {
    if (selectedFee) {
      return {
        classId: selectedFee?.classId,
        feeCategoryId: selectedFee?.feeCategoryId,
        feeCategoryFrequencyId: selectedFee?.feeCategoryFrequencyId,
        academicYearId: selectedFee?.academicYearId,
        currencyId: selectedFee?.currencyId,
        feeAmount: selectedFee?.feeAmount,
        // discountTypeId: selectedFee?.discountTypeId,
        // discountPercentage: selectedFee?.discountPercentage,
        // discountRemarks: selectedFee?.discountRemarks,
        feeDueDate: selectedFee?.feeDueDate?.map((data: FeeDueDate) => {
          return {
            feeAmount: data.feeAmount,
            startDate: dayjs(data.startDate),
            dueDate: dayjs(data.dueDate),
            gracePeriodDays: data.gracePeriodDays,
            penaltyAmount: data.penaltyAmount,
            feeLabelName: data.feeLabelName,
          };
        }),
      };
    } else {
      return { ...createFeeInitialValue, academicYearId: globalAcademicYearId };
    }
  }, [selectedFee, globalAcademicYearId]);
  console.log("finalInitialValues", finalInitialValues);

  const getInitialValueBasedOnAmountAndFrequency = useCallback(
    (frequency: number, amount: number) => {
      // console.log(academicYearData, "academicYearData");
      const selectedAcademicYear = academicYearData.find(
        (year: any) => year.id === globalAcademicYearId
      );
      if (!selectedAcademicYear || !selectedAcademicYear?.startDate) {
        errorToast(
          "Start date is not available for the Selected Academic Year"
        );
        return [];
      }
      const globalStartDate = dayjs(
        selectedAcademicYear?.startDate || new Date()
      );
      let initialValues = [];
      switch (frequency) {
        case 1:
          for (let i = 0; i < 12; i++) {
            const startDate = globalStartDate.add(i, "month");
            // const dueDate = startDate.clone().add(1, "month");
            initialValues.push({
              feeAmount: amount,
              startDate: startDate,
              dueDate: startDate,
              gracePeriodDays: 5,
              penaltyAmount: null,
            });
          }
          break;
        case 2:
          for (let i = 0; i < 4; i++) {
            const startDate = globalStartDate.add(i * 3, "month");
            // const dueDate = startDate.clone().add(1, "month");
            initialValues.push({
              feeAmount: amount,
              startDate: startDate,
              dueDate: startDate,
              gracePeriodDays: 5,
              penaltyAmount: null,
            });
          }
          break;
        default:
          initialValues.push({
            feeAmount: amount,
            startDate: globalStartDate,
            dueDate: globalStartDate,
            gracePeriodDays: 5,
            penaltyAmount: null,
          });
          break;
      }
      return initialValues;
    },
    [academicYearData, globalAcademicYearId]
  );

  return (
    <div className="modal fade" id="add_fee">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedFee ? "Edit Fee" : "Add Fee"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <Formik
            initialValues={finalInitialValues}
            enableReinitialize
            innerRef={formRef}
            validationSchema={
              selectedFee
                ? updateFeeValidationSchema
                : createFeeValidationSchema
            }
            onSubmit={async (values, actions) => {
              try {
                if (schoolId && globalAcademicYearId) {
                  let result: any;
                  if (selectedFee) {
                    result = await dispatch(
                      updateFeeAsync({
                        schoolId:schoolId,
                        feeId: selectedFee.id,
                        feeData: {
                          feeAmount: values.feeAmount,
                          currencyId: values.currencyId,
                          feeCategoryFrequencyId:
                            values.feeCategoryFrequencyId || null,
                          // discountTypeId: values.discountTypeId || null,
                          // discountPercentage: values.discountPercentage || null,
                          // discountRemarks: values.discountRemarks || null,
                          feeDueDate: values.feeDueDate.map(
                            (data: FeeDueDate) => {
                              const feeLabelName = `${
                                feeCategoryOptions
                                  .find(
                                    (option: any) =>
                                      option.value === values.feeCategoryId
                                  )
                                  ?.label.split(" ")[0] || "N/A"
                              }-${
                                data.startDate
                                  ? dayjs(data.startDate).format("MMM-YYYY")
                                  : "N/A"
                              }`;
                              return {
                                feeAmount: data.feeAmount || null,
                                startDate: dayjs(data.startDate)
                                  .format("YYYY-MM-DD")
                                  ?.toString() as string,
                                dueDate: dayjs(data.dueDate)
                                  .format("YYYY-MM-DD")
                                  ?.toString() as string,
                                gracePeriodDays: data.gracePeriodDays || null,
                                penaltyAmount: data.penaltyAmount || null,
                                feeLabelName: feeLabelName || null,
                              };
                            }
                          ),
                        },
                      })
                    ).unwrap();
                  } else {
                    result = await dispatch(
                      createFeeAsync({
                        schoolId,
                        newFee: {
                          ...(values as Fee),
                          feeDueDate: values.feeDueDate.map(
                            (data: FeeDueDate) => {
                              const feeLabelName = `${
                                feeCategoryOptions
                                  .find(
                                    (option: any) =>
                                      option.value === values.feeCategoryId
                                  )
                                  ?.label.split(" ")[0] || "N/A"
                              }-${
                                data.startDate
                                  ? dayjs(data.startDate).format("MMM-YYYY")
                                  : "N/A"
                              }`;
                              return {
                                feeAmount: data.feeAmount || null,
                                startDate: dayjs(data.startDate)
                                  .format("YYYY-MM-DD")
                                  ?.toString() as string,
                                dueDate: dayjs(data.dueDate)
                                  .format("YYYY-MM-DD")
                                  ?.toString() as string,
                                gracePeriodDays: data.gracePeriodDays || null,
                                penaltyAmount: data.penaltyAmount || null,
                                feeLabelName: feeLabelName || null,
                              };
                            }
                          ),
                        },
                      })
                    ).unwrap();
                  }

                  const cancelButton = document.getElementById(
                    "btn-cancel"
                  ) as HTMLElement;
                  cancelButton.click();

                  await dispatch(
                    fetchFeesByCriteriaAsync({
                      schoolId,
                      criteria: { academicYearId: globalAcademicYearId },
                    })
                  ).unwrap();
                }
              } catch (error) {
                console.error("Error in creating/updating fee", error);
              }
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              resetForm,
              setFieldTouched,
              setFieldValue,
              isSubmitting,
              errors,
            }) => {
              console.log("1234", values, errors);
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="modal-body">
                    <div className="row">
                      {/* First Row */}
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label">Academic Year</label>
                          <Field
                            className="select"
                            component={CommonSelect}
                            options={academicYearOptions}
                            name="academicYearId"
                            disabled={true}
                          />
                          <ErrorMessage
                            name="academicYearId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label">Class</label>
                          <Field
                            className="select"
                            component={CommonSelect}
                            options={classDataOption}
                            isMulti={selectedFee ? false : true}
                            name={selectedFee ? "classId" : "classIds"}
                            disabled={!!selectedFee}
                          />
                          <ErrorMessage
                            name={selectedFee ? "classId" : "classIds"}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label">Fee Category</label>
                          <Field
                            className="select"
                            component={CommonSelect}
                            options={feeCategoryOptions}
                            name="feeCategoryId"
                            disabled={!!selectedFee}
                          />
                          <ErrorMessage
                            name="feeCategoryId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      {/* Second Row */}

                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label">Fee Frequency</label>
                          <Field
                            className="select"
                            component={CommonSelect}
                            options={feeCategoryFrequencyOptions}
                            name="feeCategoryFrequencyId"
                            disabled={!!selectedFee}
                            onValueSelect={(id: number) => {
                              setFieldValue(
                                "feeDueDate",
                                getInitialValueBasedOnAmountAndFrequency(
                                  id,
                                  values.feeAmount
                                )
                              );
                            }}
                          />
                          <ErrorMessage
                            name="feeCategoryFrequencyId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label">Currency</label>
                          <Field
                            className="select"
                            component={CommonSelect}
                            options={currencyOptions}
                            name="currencyId"
                            disabled={true}
                          />
                          <ErrorMessage
                            name="currencyId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label">
                            Amount (
                            {`${
                              feeCategoryFrequencyOptions.find(
                                (option: any) =>
                                  option.value === values.feeCategoryFrequencyId
                              )?.label || "N/A"
                            }`}
                            )
                          </label>
                          <Field
                            type="number"
                            className="form-control"
                            onChange={(e: any) => {
                              handleChange(e);
                              const newAmount = e.target.value;
                              setFieldValue(
                                "feeDueDate",
                                getInitialValueBasedOnAmountAndFrequency(
                                  values.feeCategoryFrequencyId,
                                  newAmount
                                )
                              );
                            }}
                            onBlur={handleBlur}
                            value={values.feeAmount || ""}
                            name="feeAmount"
                          />
                          <ErrorMessage
                            name="feeAmount"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>

                      {/* Field Array */}
                      {values?.feeDueDate?.length ? (
                        <div className="row">
                          <div className="col-xxl col-xl-3 col-md-4">
                            <label className="form-label">Fee Name</label>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-4">
                            <label className="form-label">Due Date</label>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-4">
                            <label className="form-label">
                              Fee Amount For Period
                            </label>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-4">
                            <label className="form-label">
                              Grace Period Days
                            </label>
                          </div>
                          <div className="col-xxl col-xl-3 col-md-4">
                            <label className="form-label">Penalty Amount</label>
                          </div>
                        </div>
                      ) : null}
                      <FieldArray
                        name="feeDueDate"
                        render={(arrayHelpers) => (
                          <div>
                            {values?.feeDueDate?.map(
                              (_: any, index: number) => (
                                <>
                                  <div key={index} className="row">
                                    <div className="col-xxl col-xl-3 col-md-4">
                                      <div className="mb-3">
                                        <div className="input-icon position-relative">
                                          {/* <p>
                                            {feeCategoryOptions
                                              .find(
                                                (option: any) =>
                                                  option.value ===
                                                  values.feeCategoryId
                                              )
                                              ?.label.split(" ")[0] || "N/A"}
                                          </p>

                                          <DatePicker
                                            className="form-control datetimepicker"
                                            disabled={true}
                                            format={{
                                              format: "MMM-YYYY",
                                              type: "mask",
                                            }}
                                            value={
                                              values.feeDueDate[index].startDate
                                            }
                                            name={`feeDueDate.${index}.startDate`}
                                            placeholder="Select Date"
                                          /> */}

                                          {!selectedFee && (
                                            <p className="border p-2 rounded-2">
                                              {`${
                                                feeCategoryOptions
                                                  .find(
                                                    (option: any) =>
                                                      option.value ===
                                                      values.feeCategoryId
                                                  )
                                                  ?.label.split(" ")[0] || "N/A"
                                              }-${
                                                values.feeDueDate[index]
                                                  .startDate
                                                  ? dayjs(
                                                      values.feeDueDate[index]
                                                        .startDate
                                                    ).format("MMM-YYYY")
                                                  : "N/A"
                                              }`}
                                            </p>
                                          )}

                                          {selectedFee && (
                                            <p className="border p-2 rounded-2">
                                              {`${
                                                values.feeDueDate[index]
                                                  .feeLabelName || ""
                                              }`}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xxl col-xl-3 col-md-4">
                                      <div className="mb-3">
                                        {/* <label className="form-label">
                                          Due Date
                                        </label> */}
                                        <div className="input-icon position-relative">
                                          <DatePicker
                                            className="form-control datetimepicker"
                                            format={{
                                              format: "YYYY-MM-DD",
                                              type: "mask",
                                            }}
                                            onChange={(date: dayjs.Dayjs) =>
                                              setFieldValue(
                                                `feeDueDate.${index}.dueDate`,
                                                date
                                              )
                                            }
                                            onBlur={() =>
                                              setFieldTouched(
                                                `feeDueDate.${index}.dueDate`,
                                                true
                                              )
                                            }
                                            value={
                                              values.feeDueDate[index].dueDate
                                            }
                                            name={`feeDueDateObj.${index}.dueDate`}
                                            placeholder="Select Date"
                                          />
                                          <span className="input-icon-addon">
                                            <i className="ti ti-calendar" />
                                          </span>
                                        </div>
                                        <ErrorMessage
                                          name={`feeDueDate.${index}.dueDate`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xxl col-xl-3 col-md-4">
                                      <div className="mb-3">
                                        {/* <label className="form-label">
                                          Fee Amount For Period
                                        </label> */}
                                        <Field
                                          type="number"
                                          className="form-control"
                                          name={`feeDueDate.${index}.feeAmount`}
                                          value={
                                            values.feeDueDate[index]
                                              .feeAmount || ""
                                          }
                                          disabled={true}
                                        />
                                        <ErrorMessage
                                          name={`feeDueDate.${index}.feeAmount`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xxl col-xl-3 col-md-4">
                                      <div className="mb-3">
                                        {/* <label className="form-label">
                                          Grace Period Days
                                        </label> */}
                                        <Field
                                          type="number"
                                          className="form-control"
                                          name={`feeDueDate.${index}.gracePeriodDays`}
                                          value={
                                            values.feeDueDate[index]
                                              .gracePeriodDays || ""
                                          }
                                        />
                                        <ErrorMessage
                                          name={`feeDueDate.${index}.gracePeriodDays`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-xxl col-xl-3 col-md-4">
                                      <div className="mb-3">
                                        {/* <label className="form-label">
                                          Penalty Amount
                                        </label> */}
                                        <Field
                                          type="number"
                                          className="form-control"
                                          name={`feeDueDate.${index}.penaltyAmount`}
                                          value={
                                            values.feeDueDate[index]
                                              .penaltyAmount || ""
                                          }
                                        />
                                        <ErrorMessage
                                          name={`feeDueDate.${index}.penaltyAmount`}
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/* <button
                                    type="button"
                                    onClick={() => arrayHelpers.remove(index)}
                                    className="btn btn-sm btn-light me-2"
                                  >
                                    -
                                  </button>

                                  <button
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.insert(index, {
                                        startDate: null,
                                        dueDate: null,
                                        feeAmount: null,
                                        gracePeriodDays: null,
                                        penaltyAmount: null,
                                      })
                                    }
                                    className="btn btn-sm btn-primary"
                                  >
                                    +
                                  </button> */}
                                </>
                              )
                            )}
                          </div>
                        )}
                      />
                      {values?.feeDueDate?.length ? (
                        <div className="border my-3"></div>
                      ) : null}
                      {/* Field Array */}

                      {/* Third Row */}
                      {/* <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">Discount Type</label>
                          <Field
                            className="select"
                            component={CommonSelect}
                            options={feeDiscountTypeOptions}
                            name="discountTypeId"
                          />
                          <ErrorMessage
                            name="discountTypeId"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div> */}

                      {/* <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Discount Percentage
                          </label>
                          <Field
                            type="number"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.discountPercentage || ""}
                            name="discountPercentage"
                          />
                          <ErrorMessage
                            name="discountPercentage"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div> */}

                      {/* forth Row */}
                      {/* <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label">Discount Remarks</label>
                          <Field
                            type="text"
                            as="textarea"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.discountRemarks || ""}
                            name="discountRemarks"
                          />
                          <ErrorMessage
                            name="discountRemarks"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div
                      onClick={() => resetForm()}
                      id="btn-cancel"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {selectedFee ? "Edit Fee" : "Add Fee"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default FeeFormModal;
