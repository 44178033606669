import { useEffect } from "react";
import { useFormikContext } from "formik";
import { useAppDispatch } from "../../../../../core/data/redux/hooks";
import { fetchFeeDiscountAndCategoryAsync } from "../../../../../core/data/redux/features/fee/feeSlice";

interface DiscountHandlerProps {
  isEdit: boolean;
  schoolId: any;
  globalAcademicYearId: any;
}

function DiscountHandler({
  isEdit,
  schoolId,
  globalAcademicYearId,
}: DiscountHandlerProps) {
  const { setFieldValue, values } = useFormikContext<any>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      isEdit &&
      values.admissionStatusId === 3 &&
      schoolId &&
      globalAcademicYearId &&
      values.classId
    ) {
      dispatch(
        fetchFeeDiscountAndCategoryAsync({
          schoolId,
          criteria: {
            academicYearId: globalAcademicYearId,
            classId: values.classId,
          },
        })
      )
        .unwrap()
        .then((data) => {
          let finalData = data?.data?.discountTypes?.map((item: any) => ({
            ...item,
            discountPercentage: item.discountPercentage || 0,
          }));
          setFieldValue("appliedDiscount", {
            ...data.data,
            discountTypes: finalData,
          });
        })
        .catch((error) => {
          setFieldValue("appliedDiscount", null);
        });
    } else {
      setFieldValue("appliedDiscount", null);
    }
  }, [
    isEdit,
    values.admissionStatusId,
    values.classId,
    schoolId,
    globalAcademicYearId,
    dispatch,
    setFieldValue,
  ]);

  return null;
}

export default DiscountHandler;
