import { Link } from "react-router-dom";

const recipientMapping: Record<number, string> = {
  1: "School Admin",
  2: "Accountant",
  3: "Receptionist",
  4: "Teacher",
  5: "Parent",
  6: "Student",
  7: "School Group Admin",
};

const ViewDetailsModal = ({
  selectedAnnouncement,
}: {
  selectedAnnouncement: any;
}) => {
  console.log("selectedAnnouncement", selectedAnnouncement);

  return (
    <div className="modal fade" id="view_details">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          {/* Modal Header */}
          <div className="modal-header">
            <h4 className="modal-title">
              {selectedAnnouncement?.title || "Announcement Details"}
            </h4>
            <button
              type="button"
              className="btn-close custom-btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>

          {/* Modal Body */}
          <div className="modal-body pb-0">
            {/* Message */}
            <div className="mb-3">
              <pre className="text-wrap text-break overflow-auto">
                {selectedAnnouncement?.message || "No message available."}
              </pre>
            </div>

            {/* Attachment */}
            {selectedAnnouncement?.attachments ? (
              <div className="mb-3">
                <div className="bg-light p-3 pb-2 rounded">
                  <label className="form-label">Attachment</label>
                  <div className="row">
                    <Link
                      target="_blank"
                      to={selectedAnnouncement.attachments[0].attachmentUrl}
                    >
                      {selectedAnnouncement.attachments[0].attachmentName}
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <p>No attachment added</p>
            )}

            {/* Message To (Recipients) */}
            <div className="mb-3">
              <label className="form-label d-block">Message To</label>
              {selectedAnnouncement?.sentTo?.length > 0 ? (
                selectedAnnouncement.sentTo.map((recipient: number) => (
                  <span
                    className="badge badge-soft-primary me-2"
                    key={recipient}
                  >
                    {recipientMapping[recipient] || "Unknown"}
                  </span>
                ))
              ) : (
                <span>No recipients available.</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsModal;
