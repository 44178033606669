import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../core/data/redux/hooks";
import {
  fetchCurrencyListAsync,
  fetchParentTypesAsync,
  fetchAdmissionStatusesAsync,
  fetchGendersAsync,
  fetchAcademicYearsAsync,
} from "../../../../core/data/redux/features/utils/utilsSlice";
import {
  fetchClassesAsync,
  fetchClassSectionsAsync,
} from "../../../../core/data/redux/features/class/classSlice";
import {
  ParentType,
  Currency,
  AcademicYear,
} from "../../../../core/data/redux/features/utils/utilsType";
import { useSearchParams } from "react-router-dom";
import { errorToast } from "../../../../core/common/toast/toast";
import { validatePublicAdmissionLink } from "../../../../core/data/redux/features/utils/utilsApi";
import {
  createAdmissionInitialValues,
  ParentData,
} from "../add-admission/initialValue";
import { Class } from "../../../../core/data/redux/features/class/classTypes";
import { AxiosError } from "axios";

interface PublicData {
  academicYearId: number;
  academicYears: AcademicYear[];
  classes: Class[];
  inquiry: {
    genderId: number;
    classId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    dateOfBirth: any;
    parentData: ParentData[];
    id: number;
  };
  schoolId: number;
}

interface response {
  message: string;
}

export const useAdmissionOptions = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [publicData, setPublicData] = useState<null | PublicData>(null);
  const [refresh, setRefresh] = useState(false);
  const [errormessage, setErormessage] = useState(null);
  const [response, setResponse] = useState<response>();

  const schoolId = useAppSelector((state) => state.auth?.schoolData?.id);

  const { admissionData, fetchLoading } = useAppSelector(
    (state) => state.admission
  );
  const globalAcademicYearId = useAppSelector(
    (state) => state.academicYear.globalAcedemicYearId
  );

  const classData = useAppSelector((state) => state.class.classData);
  const academicYears = useAppSelector(
    (state) => state.academicYear.academicYearData
  );
  const parentTypeData = useAppSelector((state) => state.utils.parentTypeData);
  const currecyData = useAppSelector((state) => state.utils.currencyData);
  const admissionSatusData = useAppSelector(
    (state) => state.utils.admissionStatusData
  );
  const genderData = useAppSelector((state) => state.utils.genderData);
  const classSectionData = useAppSelector(
    (state) => state.class.classSectionData
  );

  // Data fetching effect
  useEffect(() => {
    if (schoolId) {
      if (!classData.length && !token) {
        dispatch(fetchClassesAsync({ schoolId, section: 0 }));
      }
      if (!parentTypeData.length) {
        dispatch(fetchParentTypesAsync());
      }
      if (!academicYears.length && !token) {
        dispatch(fetchAcademicYearsAsync(schoolId));
      }
      if (!currecyData.length) {
        dispatch(fetchCurrencyListAsync());
      }
    }
    if (!admissionSatusData.length) {
      dispatch(fetchAdmissionStatusesAsync());
    }
    if (!genderData.length) {
      dispatch(fetchGendersAsync());
    }
    if (admissionData?.classId && schoolId && !token) {
      dispatch(
        fetchClassSectionsAsync({
          schoolId: Number(schoolId),
          classId: admissionData.classId,
        })
      );
    }
    const fetchDataFromToken = async (schoolId: number, token: string) => {
      try {
        const response = await validatePublicAdmissionLink(schoolId, { token });
        setResponse(response);
        console.log(response, "response");
        if (response?.data) {
          setPublicData(response?.data);
        } else {
          throw new Error("Invalid or expired Link.");
        }
      } catch (error) {
        console.error(error, "hear");
        if (error instanceof AxiosError) {
          setErormessage(
            error.response?.data?.message || "An unexpected error occurred."
          );
        }
      }
    };

    if (token && schoolId) {
      fetchDataFromToken(schoolId, token);
    }
  }, [
    schoolId,
    classData.length,
    parentTypeData.length,
    academicYears.length,
    currecyData.length,
    admissionSatusData.length,
    genderData.length,
    admissionData,
    refresh,
    dispatch,
  ]);

  const finalInitialValues = useMemo(() => {
    if (admissionData) {
      const match =
        admissionData.declaration &&
        admissionData.declaration.match(/^I (.*?) of /);
      const declarative = (match && match[1]) || "";
      return {
        appliedDiscount: admissionData?.appliedDiscount,
        academicYearId: admissionData.academicYearId,
        classId: admissionData.classId,
        classSectionId: admissionData.classSectionId,
        firstName: admissionData.firstName || "",
        middleName: admissionData.middleName || "",
        lastName: admissionData.lastName,
        dob: dayjs(admissionData.dob),
        email: admissionData.email,
        phoneNumber: admissionData.phoneNumber,
        genderId: admissionData.genderId,
        interviewDate: dayjs(admissionData.interviewDate),
        admissionStatusId: admissionData.admissionStatusId,
        approvalDate: admissionData.approvalDate
          ? dayjs(admissionData.approvalDate)
          : null,
        admissionDate: admissionData.admissionDate
          ? dayjs(admissionData.admissionDate)
          : null,
        aadharCardNumber: admissionData?.aadharCardNumber || "",
        studentBankAccountNumber: admissionData?.studentBankAccountNumber || "",
        studentBankAccountIFSCCode:
          admissionData?.studentBankAccountIFSCCode || "",
        studentBankAddress: admissionData?.studentBankAddress || "",
        studentBankBranchName: admissionData?.studentBankBranchName || "",
        bloodGroup: admissionData?.bloodGroup || "",
        castCertificate: admissionData?.castCertificate || "",
        cast: admissionData?.cast || "",
        isConveyanceNeeded: admissionData?.isConveyanceNeeded || "",
        lastSchoolName: admissionData?.lastSchoolName || "",
        isSpecialMedicalNeed: admissionData?.isSpecialMedicalNeed || "",
        specialMedicalNeedDetails:
          admissionData?.specialMedicalNeedDetails || "",
        declaration: admissionData?.declaration || "",
        declarationSignature: admissionData?.declarationSignature || "",
        monthlyIncomeCurrencyId: admissionData?.monthlyIncomeCurrencyId || "",
        monthlyIncome: admissionData?.monthlyIncome || "",
        incomeTaxPayer: admissionData?.incomeTaxPayer || "",
        PANNumber: admissionData?.PANNumber || "",
        religion: admissionData?.religion || "",
        currentAddress: admissionData?.currentAddress || "",
        permanentAddress: admissionData?.permanentAddress || "",
        PENNumber: admissionData?.PENNumber || "",
        notifyStudentAndParent: admissionData?.notifyStudentAndParent || "",
        siblings: admissionData.siblings || [],
        parentData: admissionData?.parentData || [],
        declarative: declarative,
      };
    } else {
      let tempInitialValues = {
        ...createAdmissionInitialValues,
        academicYearId: publicData?.academicYearId || globalAcademicYearId,
      };
      if (token) {
        tempInitialValues.interviewDate = dayjs(new Date());
        tempInitialValues.parentData = publicData?.inquiry.parentData || [];
        tempInitialValues.classId = publicData?.inquiry.classId || null;
        tempInitialValues.dob = dayjs(publicData?.inquiry.dateOfBirth);
        tempInitialValues.firstName = publicData?.inquiry.firstName || "";
        tempInitialValues.middleName = publicData?.inquiry.middleName || "";
        tempInitialValues.lastName = publicData?.inquiry.lastName || "";
        tempInitialValues.genderId = publicData?.inquiry.genderId || null;
        tempInitialValues.inquiryId = publicData?.inquiry.id;
        // tempInitialValues.classId=publicData?.classId;
      }
      return tempInitialValues;
    }
  }, [admissionData, globalAcademicYearId, publicData?.academicYearId]);

  // Memoized options
  const options = useMemo(
    () => ({
      classDataOption: (publicData?.classes || classData).map((item: any) => ({
        value: item.id,
        label: item.className,
      })),

      academicYearsOption: (publicData?.academicYears || academicYears).map(
        (item: any) => ({
          value: item.id,
          label: item.academicYearName,
        })
      ),

      parentTypeDataOptionsForString: parentTypeData.map((item: any) => ({
        value: item.parentTypeName,
        label: item.parentTypeName,
      })),

      parentTypeDataOptions: parentTypeData.map((item: ParentType) => ({
        value: item.id,
        label: item.parentTypeName,
      })),

      currencyOptions: currecyData.map((item: Currency) => ({
        value: item.id,
        label: item.CurrencyISOCode,
      })),

      admissionStatusDataOption: admissionSatusData.map((item: any) => ({
        value: item.id,
        label: item.admissionStatusName,
      })),

      genderDataOption: genderData.map((item: any) => ({
        value: item.id,
        label: item.genderName,
      })),

      classSectionDataOption: [
        { value: null, label: "Select Class Section" },
        ...classSectionData.map((item: any) => ({
          value: item.id,
          label: item.sectionName,
        })),
      ],
    }),
    [
      classData,
      publicData,
      academicYears,
      parentTypeData,
      currecyData,
      admissionSatusData,
      genderData,
      classSectionData,
    ]
  );

  return {
    ...options,
    publicData,
    token,
    schoolId,
    fetchLoading,
    admissionData,
    setRefresh,
    setPublicData,
    errormessage,
    response,
    finalInitialValues,
    globalAcademicYearId: token
      ? publicData?.academicYearId
      : globalAcademicYearId,
  };
};
