import * as Yup from "yup";

const commonschema = {
  academicYearId: Yup.number()
    .required("Academic Year is required")
    .positive("Academic Year must be a positive number"),

  feeCategoryId: Yup.number()
    .required("Fee Category is required")
    .positive("Fee Category must be a positive number"),

  feeCategoryFrequencyId: Yup.number()
    .required("Fee Category Frequency is required")
    .positive("Fee Category Frequency must be a positive number"),

  currencyId: Yup.number()
    .required("Currency is required")
    .positive("Currency must be a positive number"),

  feeAmount: Yup.number()
    .required("Fee Amount is required")
    .positive("Fee Amount must be a positive number")
    .min(0, "Fee Amount cannot be negative"),

  discountTypeId: Yup.number().nullable(),

  discountPercentage: Yup.number()
    .nullable()
    .when("discountTypeId", {
      is: Yup.number(),
      then: (schema) =>
        schema
          .required("Discount Percentage is required")
          .min(0, "Discount Percentage must be between 0 and 100")
          .max(100, "Discount Percentage must be between 0 and 100"),
    }),

  discountRemarks: Yup.string().nullable().default(""),
  feeDueDate: Yup.array()
    .of(
      Yup.object({
        feeAmount: Yup.number()
          .required()
          .positive("Fee Amount must be a positive number"),
        startDate: Yup.string().required("Start Date is required"),
        dueDate: Yup.string().required("Due Date is required"),
        gracePeriodDays: Yup.number()
          .nullable()
          .integer()
          .min(0, "Grace Period Days must be a non-negative integer"),
        penaltyAmount: Yup.number()
          .nullable()
          .positive("Penalty Amount must be a positive number"),
      })
    )
    .when("feeCategoryFrequencyId", {
      is: Yup.number(),
      then: (schema) => schema.min(1, "At least one due date is required"),
    }),
};

export const createFeeValidationSchema = Yup.object({

  ...commonschema, 
  classIds: Yup.array().of(Yup.number().required("Class is required"))
  
});

export const updateFeeValidationSchema = Yup.object({
  ...commonschema, 
  classId: Yup.number()
    .required("Class is required")
    .positive("Class must be a positive number"),
});
