import {FeeCategory} from "./feeCategoryType";
import apiClient from "../../../api/apiClient";
  
  //Base URL
  
  const BASE_PATH = "/fee-category";
  
  export const fetchFeeCategories = async (schoolId: number): Promise<{
    data: FeeCategory[];
    message: string;
  }> => {
    const response = await apiClient.get(
      `${BASE_PATH}/get-fee-category-of-school/${schoolId}`
    );
    return response?.data;
  };
  
