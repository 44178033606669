import { ErrorMessage, Field } from "formik";
import Preloader from "../../../../../core/common/loader/preLoader";
import CommonSelect from "../../../../../core/common/commonSelect";
import CollapsibleCard from "../../../../../core/common/CollapsibleCard";

interface SectionProps {
  fetchLoading: boolean;
  hasErrors: boolean;
}

interface FamilyAttestationProps extends SectionProps {
  yesOrNo: any[];
  currencyOptions: any[];
  religionCategories: any[];
}

const FamilyAttestationSection: React.FC<FamilyAttestationProps> = ({
  fetchLoading,
  yesOrNo,
  currencyOptions,
  religionCategories,
  hasErrors,
}) => (
  <CollapsibleCard
    title="Family Financial and Legal Attestation"
    icon={<i className="ti ti-id-badge fs-16" />}
    hasErrors={hasErrors}
  >
    {fetchLoading ? (
      <Preloader />
    ) : (
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Monthly Income Currency</label>
            <Field
              name="monthlyIncomeCurrencyId"
              component={CommonSelect}
              options={currencyOptions}
              disabled={true}
            />
            <ErrorMessage
              name="monthlyIncomeCurrencyId"
              component="div"
              className="text-danger"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Monthly Income</label>
            <Field
              type="number"
              className="form-control"
              name="monthlyIncome"
            />
            <ErrorMessage
              name="monthlyIncome"
              component="div"
              className="text-danger"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Income Tax Payer?</label>
            <Field
              name="incomeTaxPayer"
              component={CommonSelect}
              options={yesOrNo}
            />
            <ErrorMessage
              name="incomeTaxPayer"
              component="div"
              className="text-danger"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">PAN Number</label>
            <Field type="text" className="form-control" name="PANNumber" />
            <ErrorMessage
              name="PANNumber"
              component="div"
              className="text-danger"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="mb-3">
            <label className="form-label">Religion</label>
            <Field
              name="religion"
              component={CommonSelect}
              options={religionCategories}
              menuPlacement="top"
            />
            <ErrorMessage
              name="religion"
              component="div"
              className="text-danger"
            />
          </div>
        </div>
      </div>
    )}
  </CollapsibleCard>
);

export default FamilyAttestationSection;
