import { errorToast, successToast } from "../../../../common/toast/toast";
import { DashboardData, StaticsState } from "./statisticsTypes";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchSystemAdminDashboardData } from "./statisticsApi";

const initialState: StaticsState = {
  dashboardData: {
    totalStudents: 0,
    totalNewAdmissions: 0,
    totalUsers: {
      schoolStaff: {
        teachers: 0,
        accountants: 0,
        receptionists: 0,
      },
      parents: 0,
      students: 0,
      totalActiveUsers: 0,
      totalInactiveUsers: 0,
    },
    students: {
      totalActiveStudents: "0",
      totalInactiveStudents: 0,
      totalStudents: 0,
    },
    classWiseStudents: {},
    totalOutstandingFees: {
      totalOutstandingFees: "0",
      totalPendingFeesWithinDue: "0",
      totalPendingFeesDue: "0",
    },
    totalFeesCollected: {
      totalFeesCollectedToday: {
        cash: 0,
        online: 0,
      },
      totalFeesCollectedThisMonth: {
        cash: 0,
        online: 0,
      },
      totalFeesCollectedThisYear: {
        cash: 0,
        online: 0,
      },
    },
    totalClasses: 0,
    genderDistribution: [],
    admissionStatusDistribution: [],
    activeModules: [],
  },
  fetchLoading: false,
  loading: false,
  error: null,
};

// export const fetchDashboardDataAsync = createAsyncThunk<
//   DashboardData,
//   void,
//   { rejectValue: string }
// >(
//   "dashboard/fetchDashboardData",
//   async ({academicYearId}:{academicYearId:number}, thunkAPI) => {
//     try {
//       const response = await fetchSystemAdminDashboardData(academicYearId);
//       return response.data;
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue(
//         error.response?.data?.message || "Failed to fetch dashboard data"
//       );
//     }
//   }
// );
export const fetchDashboardDataAsync = createAsyncThunk(
  "dashboard/fetchDashboardData",
  async (
    {
      academicYearId,
    }: {
      academicYearId: number;
    },
    thunkAPI
  ) => {
    try {
      return await fetchSystemAdminDashboardData(academicYearId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update student fee record"
      );
    }
  }
);

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDashboardDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to fetch Remainder types";
        errorToast(state.error);
      })
      .addCase(fetchDashboardDataAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.dashboardData = action.payload.data;
      });
  },
});

export default statisticsSlice.reducer;
