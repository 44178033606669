import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../../core/common/imageWithBasePath";

const LoginDetails = () => {
  return (
    <>
      {/* Login Details */}
      <div className="modal fade" id="login_detail">
        <div className="modal-dialog modal-dialog-centered  modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Login Details</h4>
              <button
                type="button"
                className="btn-close custom-btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body">
              <div className="student-detail-info">
                <span className="student-img">
                  <ImageWithBasePath
                    src="assets/img/students/student-01.jpg"
                    alt="Img"
                  />
                </span>
                <div className="name-info">
                  <h6>
                    Janet <span>III, A</span>
                  </h6>
                </div>
              </div>
              <div className="table-responsive custom-table no-datatable_length">
                <table className="table datanew">
                  <thead className="thead-light">
                    <tr>
                      <th>User Type</th>
                      <th>User Name</th>
                      <th>Password </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Parent</td>
                      <td>parent53</td>
                      <td>parent@53</td>
                    </tr>
                    <tr>
                      <td>Student</td>
                      <td>student20</td>
                      <td>stdt@53</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <Link
                to="#"
                className="btn btn-light me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Details */}
    </>
  );
};
export default LoginDetails;
