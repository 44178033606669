import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAllSchoolUser,
  createSchoolUser,
  updateSchoolUser,
  deleteSchoolUser,
  sendAdmissonForm,
} from "./schoolAdminApi";
import {
  GrneratePublicLink,
  SchoolUsers,
  SchoolUsersState,
} from "./schoolAdminTypes";

import { errorToast, successToast } from "../../../../common/toast/toast";

const initialState: SchoolUsersState = {
  schoolUsersData: [],
  fetchLoading: false,
  loading: false,
  error: null,
};

export const fetchAllSchoolUsersAsync = createAsyncThunk(
  "schoolAdmin/fetchAllSchoolUser",
  async (
    { schoolId, userRoleId }: { schoolId: number; userRoleId: number },
    thunkAPI
  ) => {
    try {
      return await fetchAllSchoolUser(schoolId, userRoleId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to fetch school users"
      );
    }
  }
);

export const createSchoolUsersAsync = createAsyncThunk(
  "schoolAdmin/createSchoolUser",
  async (
    {
      schoolId,
      userRoleId,
      newSchoolUser,
    }: { schoolId: number; userRoleId: number; newSchoolUser: SchoolUsers },
    thunkAPI
  ) => {
    try {
      return await createSchoolUser(schoolId, userRoleId, newSchoolUser);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create school users"
      );
    }
  }
);

export const updateSchoolUsersAsync = createAsyncThunk(
  "schoolAdmin/updateSchoolUser",
  async (
    {
      schoolId,
      userRoleId,
      id,
      updatedSchoolUser,
    }: {
      schoolId: number;
      userRoleId: number;
      id: number;
      updatedSchoolUser: SchoolUsers;
    },
    thunkAPI
  ) => {
    try {
      return await updateSchoolUser(
        schoolId,
        userRoleId,
        id,
        updatedSchoolUser
      );
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to update school users"
      );
    }
  }
);

export const deleteSchoolUserAsync = createAsyncThunk(
  "schoolAdmin/deleteSchoolUser",
  async (
    {
      schoolId,
      userRoleId,
      id,
    }: { schoolId: number; userRoleId: number; id: number },
    thunkAPI
  ) => {
    try {
      await deleteSchoolUser(schoolId, userRoleId, id);
      return schoolId;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to delete school users"
      );
    }
  }
);

export const sendAdmissonFormAsync = createAsyncThunk(
  "schoolAdmin/sendAdmissonForm",
  async (
    {
      schoolId,
      generatedData,
    }: { schoolId: number; generatedData: GrneratePublicLink },
    thunkAPI
  ) => {
    try {
      return await sendAdmissonForm(schoolId, generatedData);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Failed to create school users"
      );
    }
  }
);

const schoolAdminSlice = createSlice({
  name: "schoolAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSchoolUsersAsync.pending, (state) => {
        state.fetchLoading = true;
        state.error = null;
      })
      .addCase(fetchAllSchoolUsersAsync.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.schoolUsersData = action.payload?.data;
      })
      .addCase(fetchAllSchoolUsersAsync.rejected, (state, action) => {
        state.fetchLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch school users";
        errorToast(state.error);
      })
      .addCase(createSchoolUsersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSchoolUsersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to create school users";
        errorToast(state.error);
      })
      .addCase(createSchoolUsersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolUsersData.push(action.payload.data);
        successToast(action.payload.message);
      })
      .addCase(updateSchoolUsersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSchoolUsersAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update school users";
        errorToast(state.error);
      })
      .addCase(updateSchoolUsersAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      })
      .addCase(deleteSchoolUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSchoolUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        successToast("School Users deleted successfully");
      })
      .addCase(deleteSchoolUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to delete School Users";
        errorToast(state.error);
      })
      .addCase(sendAdmissonFormAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendAdmissonFormAsync.rejected, (state, action) => {
        state.loading = false;
        state.error =
          (action.payload as string) || "Failed to send admission form";
        errorToast(state.error);
      })
      .addCase(sendAdmissonFormAsync.fulfilled, (state, action) => {
        state.loading = false;
        successToast(action.payload.message);
      });
  },
});

export default schoolAdminSlice.reducer;
