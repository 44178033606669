import React, { useEffect, useState, useMemo } from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { useAppSelector } from "../../../../../core/data/redux/hooks";
import { Modal } from "bootstrap";
import { errorToast } from "../../../../../core/common/toast/toast";

const AppliedDiscountModal = ({ classId }: any) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { feeDiscountAndCategory } = useAppSelector((state) => state.fee);
  const classData = useAppSelector((state) => state.class.classData);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const admissionData = useAppSelector(
    (state) => state.admission.admissionData
  );
  const classDataOption = useMemo(
    () =>
      classData.map((item: any) => ({
        value: item.id,
        label: item.className,
      })),
    [classData]
  );

  const selectedClass = classDataOption.find(
    (item: any) => item.value === classId
  );

  useEffect(() => {
    if (values.appliedDiscount?.discountTypes) {
      const total = values.appliedDiscount.discountTypes.reduce(
        (sum: number, discount: any) =>
          discount.allowedDiscount
            ? sum + (discount.discountPercentage || 0)
            : sum,
        0
      );
      setTotalDiscount(total);
      console.log(totalDiscount);
    }
  }, [values.appliedDiscount?.discountTypes]);

  console.log(values, values.appliedDiscount?.discountTypes, "values");
  // useEffect(() => {
  //   if (feeDiscountAndCategory.feeCategories.length === 0) {
  //     errorToast("Please Add Fee catelog in this class")
  //   }
  // }, [feeDiscountAndCategory.feeCategories]);
  // If an applied discount exists, we disable all inputs (except for close and cancel).
  const isDisabled = admissionData?.appliedDiscount !== null;

  console.log(isDisabled, "isDisabled");

  const checkDisableStateForDiscountType = (discountName: any) => {
    if (isDisabled) {
      return true;
    } else {
      if (discountName === "Sibling") {
        if (values.siblings.length === 0) {
          return true;
        }
      } else if (discountName === "EWS") {
        if (values.cast === "GENERAL") {
          return true;
        }
      }
    }

    // else {
    return false;
    // }
  };

  return (
    <div
      className="modal fade"
      id="applied_discount_modal"
      tabIndex={-1}
      role="dialog"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add Discount</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>

          <div className="modal-body p-4">
            {/* {totalDiscount >= 100 && (
              <div className="alert alert-danger" role="alert">
                Warning: The total discount is {totalDiscount}%, which is not
                allowed !
              </div>
            )}
            {(values.appliedDiscount?.feeCategories.length === 0 ||
              !values.appliedDiscount?.feeCategories) && (
              <div className="alert alert-danger" role="alert">
                <strong>Action Required:</strong> Please add a fee catalog for
                the class {" "}
                <strong>({selectedClass?.label || "Unknown Class"})</strong> to
                ensure a successful admission process.
              </div>
            )} */}
            <div className="row g-4">
              {/* Discounts Section */}
              <div className="col-md-6">
                <div className="card h-100">
                  <div className="card-header bg-light">
                    <h5 className="mb-0">Discounts</h5>
                  </div>
                  <div className="card-body">
                    {values.appliedDiscount?.discountTypes
                      ?.filter((discount: any) => {
                        // console.log("Sibling Data Length:", values.siblings);
                        // console.log("Cast:", values.cast);
                        // if ( values.siblings?.length === 0 && discount.feeDiscountName === "Sibling") {
                        //   return false;
                        // }
                        // if (discount.feeDiscountName === "EWS" && values.cast !== "SC" && values.cast !== "ST") {
                        //   return false;
                        // }
                        return true;
                      })
                      .map((discount: any) => (
                        <div
                          key={discount.discountSchoolMappingId}
                          className="mb-3"
                        >
                          <div className="d-flex align-items-center gap-3">
                            <div className="form-check mb-0">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id={`discount-${discount.discountSchoolMappingId}`}
                                checked={discount.allowedDiscount}
                                disabled={checkDisableStateForDiscountType(
                                  discount.feeDiscountName
                                )}
                                onChange={(e) => {
                                  setFieldValue(
                                    `appliedDiscount.discountTypes`,
                                    values.appliedDiscount.discountTypes.map(
                                      (d: any) =>
                                        d.discountSchoolMappingId ===
                                        discount.discountSchoolMappingId
                                          ? {
                                              ...d,
                                              allowedDiscount: e.target.checked,
                                            }
                                          : d
                                    )
                                  );
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`discount-${discount.discountSchoolMappingId}`}
                              >
                                {discount.feeDiscountName}
                              </label>
                            </div>
                            {discount.allowedDiscount && (
                              <input
                                type="number"
                                className="form-control w-auto"
                                value={discount.discountPercentage || 0}
                                disabled={isDisabled}
                                min={0}
                                max={
                                  feeDiscountAndCategory.discountTypes.find(
                                    (d: any) =>
                                      d.discountSchoolMappingId ===
                                      discount.discountSchoolMappingId
                                  )?.maximumAllowedDiscountPercentage || 0
                                }
                                step="1"
                                onChange={(e) => {
                                  let value = Number(e.target.value);
                                  const maxValue =
                                    feeDiscountAndCategory.discountTypes.find(
                                      (d: any) =>
                                        d.discountSchoolMappingId ===
                                        discount.discountSchoolMappingId
                                    )?.maximumAllowedDiscountPercentage || 0;
                                  if (value < 0) value = 0;
                                  if (value > maxValue) value = maxValue;

                                  setFieldValue(
                                    `appliedDiscount.discountTypes`,
                                    values.appliedDiscount.discountTypes.map(
                                      (d: any) =>
                                        d.discountSchoolMappingId ===
                                        discount.discountSchoolMappingId
                                          ? { ...d, discountPercentage: value }
                                          : d
                                    )
                                  );
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Fee Categories Section */}
              <div className="col-md-6">
                <div className="card h-100">
                  <div className="card-header bg-light">
                    <h5 className="mb-0">Fee Categories</h5>
                  </div>
                  <div className="card-body">
                    {values.appliedDiscount?.feeCategories?.map(
                      (feeCategory: any) => (
                        <div key={feeCategory.feeId} className="mb-3">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`feeCategory-${feeCategory.feeId}`}
                              checked={feeCategory.allowedDiscount}
                              disabled={isDisabled}
                              onChange={(e) => {
                                setFieldValue(
                                  `appliedDiscount.feeCategories`,
                                  values.appliedDiscount.feeCategories.map(
                                    (f: any) =>
                                      f.feeId === feeCategory.feeId
                                        ? {
                                            ...f,
                                            allowedDiscount: e.target.checked,
                                          }
                                        : f
                                  )
                                );
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`feeCategory-${feeCategory.feeId}`}
                            >
                              {feeCategory.feeCategoryName}
                            </label>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="alert alert-primary" role="alert">
              Current Discount: The total discount is {totalDiscount}%,
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedDiscountModal;
