import apiClient from "../../../api/apiClient";
import { Class, ClassSection } from "./classTypes";

//Base URL

const BASE_PATH = "/class";

export const fetchClassesOfSchool = async (
  schoolId: number,
  section: number | undefined
): Promise<{ data: Class[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-classes-of-school/${schoolId}?section=${section || 0}`
  );
  return response?.data;
};

export const fetchClassesSectionOfSchool = async (
  schoolId: number,
  section: number | undefined
): Promise<{ data: ClassSection[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-classes-of-school/${schoolId}?section=${section || 0}`
  );
  return response?.data;
};

export const fetchClassSections = async (
  schoolId: number,
  classId: number
): Promise<{ data: ClassSection[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-sections-of-class/${schoolId}/${classId}`
  );
  return response?.data;
};

export const createClass = async (
  schoolId: number,
  newClass: Class
): Promise<{ data: Class; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/add-class-to-school/${schoolId}`,
    newClass
  );
  return response?.data;
};

export const updateClass = async (
  classId: number,
  schoolId: number,
  updatedClass: Class
): Promise<{ data: Class; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-class/${schoolId}/${classId}`,
    updatedClass
  );
  return response?.data;
};

export const deleteClass = async (
  schoolId: number,
  classId: number
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/delete-class-from-school/${schoolId}/${classId}`
  );
};

export const createClassSection = async (
  schoolId: number,
  classId: number,
  newSection: ClassSection
): Promise<{ data: ClassSection; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/add-section-to-class/${schoolId}/${classId}`,
    newSection
  );
  return response?.data;
};

export const updateClassSection = async (
  schoolId: number,
  classSectionId: number,
  updatedClassSection: ClassSection
): Promise<{ data: ClassSection; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-section/${schoolId}/${classSectionId}`,
    updatedClassSection
  );
  return response?.data;
};

export const deleteClassSection = async (
  schoolId: number,
  classSectionId: number
): Promise<void> => {
  await apiClient.delete(
    `${BASE_PATH}/delete-section-from-class/${schoolId}/${classSectionId}`
  );
};
