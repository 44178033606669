import apiClient from "../../../api/apiClient";
import { Notification } from "./announcementTypes";

const BASE_PATH = "/announcement";

export const fetchAnnouncementById = async (
  schoolId: number,
  academicYearId: number,
  id: number
): Promise<{ data: Notification[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-announcements/${schoolId}/${academicYearId}/${id}`
  );
  return response?.data;
};

export const fetchAnnouncement = async (
  schoolId: number,
  academicYearId: number
): Promise<{ data: Notification[]; message: string }> => {
  const response = await apiClient.get(
    `${BASE_PATH}/get-all-announcements/${schoolId}/${academicYearId}`
  );
  return response?.data;
};

export const createAnnouncement = async (
  schoolId: number,
  academicYearId: number,
  newAnnouncement: Notification
): Promise<{ data: Notification; message: string }> => {
  const response = await apiClient.post(
    `${BASE_PATH}/create-announcement/${schoolId}/${academicYearId}`,
    newAnnouncement
  );
  return response?.data;
};

export const sendAnnouncement = async (
  schoolId: number,
  academicYearId: number,
  id: number
): Promise<void> => {
  const response = await apiClient.post(
    `${BASE_PATH}/send-announcement-now/${schoolId}/${academicYearId}/${id}`
  );
  return response?.data;
};

export const updateAnnouncement = async (
  schoolId: number,
  academicYearId: number,
  id: number,
  updateannouncement: Notification
): Promise<{ data: Notification; message: string }> => {
  const response = await apiClient.put(
    `${BASE_PATH}/update-announcement/${schoolId}/${academicYearId}/${id}`,
    updateannouncement
  );
  return response?.data;
};

export const deleteAnnouncement = async (
  schoolId: number,
  academicYearId: number,
  id: number
): Promise<void> => {
  const response = await apiClient.delete(
    `${BASE_PATH}/delete-announcement/${schoolId}/${academicYearId}/${id}`
  );
  return response?.data;
};
